import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    MenuItem,
    Modal,
    Select,
    TextField,
    Theme,
    Typography
} from "@mui/material";
import {ChangeEvent, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {PaymentPlanSeedingData} from "../models/PaymentPlanSeedingData";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import DoneIcon from "@mui/icons-material/Done";
import {genericLabelProps} from "../props/GenericLabelProps";
import {ConvertCurrencyToSymbol} from "../helpers/ConvertCurrencyToSymbol";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {DataGrid} from "@mui/x-data-grid";
import { PostNewPaymentPlan } from "../services/PaymentPlanService";
import {PaymentPlan} from "../models/PaymentPlan";
import {PaymentPlanRepayment} from "../models/PaymentPlanRepayment";

type CreatePaymentPlanProps = {
    subscriptionId: string;
    data: PaymentPlanSeedingData[],
    handleCreateNewPaymentPlanModalClose: Function,
    createPaymentPlanModalOpen: boolean,
    setCreatePaymentPlanModalOpen: Function
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContainer: {
            padding: "8px",
            minHeight: "70px"
        },
        modal: {
            position: 'fixed',
            border: '2px solid',
            borderColor: theme.palette.text.primary,
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                height: "100%",
                overflow: 'scroll',
            },
            [theme.breakpoints.up('md')]: {
                width: "50%",

            }
        },
        actionButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "36.5px",
            marginBottom: "10px",
            textAlign: "center"
        }
    })
)

function CreatePaymentPlanModal({
                                    subscriptionId,
                                    data,
                                    createPaymentPlanModalOpen,
                                    setCreatePaymentPlanModalOpen
                                }: CreatePaymentPlanProps): JSX.Element {
    
    const dateFormat = "dd/MM/yy";

    const classes = useStyles();
    const [newPaymentPlanRows, setNewPaymentPlanRows] = useState<PaymentPlanInstallmentRow[]>([]);
    const [numberOfInstalments, setNumberOfInstalments] = useState<number>(0);
    const [selectedSubscriptionBillId, setSelectedSubscriptionBillId] = useState<string>("");    
    const updateNewPaymentPlanAmount = (rowId: number, event: ChangeEvent<HTMLInputElement>) => {
        let newAmount = !!event.target.value ? Number(event.target.value) : undefined;
        let newPaymentPlanRowsCopy = [...newPaymentPlanRows];
        newPaymentPlanRowsCopy.filter(row => row.id === rowId)[0].amount = newAmount;
        setNewPaymentPlanRows(newPaymentPlanRowsCopy);
    }

    const updateNewPaymentPlanRepaymentDate = (rowId: number, newDate: string) => {
        let newPaymentPlanRowsCopy = [...newPaymentPlanRows];
        newPaymentPlanRowsCopy.filter(row => row.id === rowId)[0].repaymentDate = newDate;
        setNewPaymentPlanRows(newPaymentPlanRowsCopy);
    }
    
    const firstAvailableInstallmentDate = new Date();

    const installmentColumns: any[] = [
        {
            field: 'installmentNumber',
            headerName: 'Installment No.',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return (
                    <>{params.row.id + 1}</>
                )
            }
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'number',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return <TextField style={{textAlign: "right"}}
                                  key={'repaymentAmount-' + params.row.id}
                                  type="number"
                                  InputProps={{inputProps: {min: 0}}}
                                  onChange={(event: ChangeEvent<HTMLInputElement>) => updateNewPaymentPlanAmount(params.row.id, event)}
                                  value={newPaymentPlanRows.filter(x => x.id === params.row.id)[0].amount === undefined ? '' : newPaymentPlanRows.filter(x => x.id === params.row.id)[0].amount}
                                  disabled={newPaymentPlanRows.filter(x => x.id === params.row.id)[0].readonly}
                                  variant={"standard"}
                />
            }
        },
        {
            field: 'repaymentDate',
            headerName: 'Date',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            key={'repaymentDate-' + params.row.id}
                            inputFormat={dateFormat}
                            onChange={(newValue: any) => updateNewPaymentPlanRepaymentDate(params.row.id, newValue as string)}
                            value={newPaymentPlanRows.filter(x => x.id === params.row.id)[0].repaymentDate}
                            renderInput={(params) => <TextField {...params} variant={"standard"}/>}
                            disabled={newPaymentPlanRows.filter(x => x.id === params.row.id)[0].readonly}
                            minDate={firstAvailableInstallmentDate}
                        />
                    </LocalizationProvider>
                )
            }
        }
    ]

    function handleCreateNewPaymentPlanModalClose() {        
        setNewPaymentPlanRows([]);
        setNumberOfInstalments(0);
        setSelectedSubscriptionBillId("");
        setCreatePaymentPlanModalOpen(false);
    }

    function handleConfirmNewPaymentPlanClick() {
        const paymentPlan: PaymentPlan = {             
            name: "",
            numberOfRepayments: numberOfInstalments,
            totalAmountDelayed: data.filter(context => context.subscriptionBillId === selectedSubscriptionBillId)[0].amount,
            billId: data.filter(context => context.subscriptionBillId === selectedSubscriptionBillId)[0].billId,
            subscriptionBillId: selectedSubscriptionBillId,
            subscriptionId: subscriptionId,
            billingDate: new Date(data.filter(context => context.subscriptionBillId === selectedSubscriptionBillId)[0].billingDate),
            repayments: newPaymentPlanRows.filter(row => !row.readonly).map((row, i) => {
                return {
                    amount: row.amount,
                    repaymentDate: row.repaymentDate,
                    id: undefined
                } as PaymentPlanRepayment
            }),
            id: undefined,
            billAmount: 0,
            responseException: ""
        }

        PostNewPaymentPlan(paymentPlan).then((returnedPaymentPlan) => {
            if (returnedPaymentPlan.responseException)
            {
                alert(returnedPaymentPlan.responseException)               
            }
            else {
                handleCreateNewPaymentPlanModalClose();
            }
        })
    }

    function handleGenerateInstallmentsClick() {
        const paymentPlanInstallmentRows: PaymentPlanInstallmentRow[] = [];

        const linkedPayments = data.filter(context => context.subscriptionBillId === selectedSubscriptionBillId)[0].linkedPaymentsThatCannotBeCancelled;

        for (let i = 0; i < numberOfInstalments; i++) {

            if (linkedPayments.length > 0 && i < linkedPayments.length) {
                paymentPlanInstallmentRows.push({
                    id: i,
                    amount: linkedPayments[i].amount,
                    repaymentDate: linkedPayments[i].date,
                    readonly: true                    
                })
                continue;
            }

            paymentPlanInstallmentRows.push({id: i, amount: 0, repaymentDate: "", readonly: false})
        }
        setNewPaymentPlanRows(paymentPlanInstallmentRows);
    }
    
    type PaymentPlanInstallmentRow = {
        id: number
        amount: number | undefined
        repaymentDate: string | undefined
        readonly: boolean        
    }
    
    const totalAmountInInstallents = newPaymentPlanRows.map(row => row.amount).reduce((sum, current) => (sum ? sum : 0) + (current ? current : 0), 0)
    const remainingAmountToBeCoveredInInstalments = selectedSubscriptionBillId === "" ? 0 : Math.round((data.filter(context => context.subscriptionBillId === selectedSubscriptionBillId)[0].amount - (totalAmountInInstallents ? totalAmountInInstallents : 0)) * 100) / 100
    const allRowsContainDates = newPaymentPlanRows.filter(row => row.repaymentDate === "").length === 0;
    const canConfirmPaymentPlan = allRowsContainDates && remainingAmountToBeCoveredInInstalments === 0;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Modal open={createPaymentPlanModalOpen} onClose={handleCreateNewPaymentPlanModalClose}>
                <div className={classes.modal}>
                    <Card>
                        <CardHeader title="Set Up A New Payment Plan"/>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Grid container className={classes.modalContainer}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle1">Bill Period</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={selectedSubscriptionBillId ?? ""}
                                                    inputProps={{
                                                        name: "Bill Period",
                                                    }}
                                                    onChange={(event: any) => {
                                                        setSelectedSubscriptionBillId(event.target.value)
                                                    }
                                                    }
                                                    variant="standard"
                                                >
                                                    {data.map((context: PaymentPlanSeedingData) =>
                                                        <MenuItem key={context.subscriptionBillId}
                                                                  value={context.subscriptionBillId}>{context.billingPeriodLabel}</MenuItem>)}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.modalContainer}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle1">Bill Amount</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                style={{width: "100%"}}
                                                value={selectedSubscriptionBillId === "" ? "" : "" + ConvertCurrencyToSymbol(data.filter(context => context.subscriptionBillId === selectedSubscriptionBillId)[0].currencyIsoCode) + Math.round(data.filter(context => context.subscriptionBillId === selectedSubscriptionBillId)[0].amount * 100) / 100}
                                                variant="standard"
                                                disabled
                                                InputLabelProps={genericLabelProps}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.modalContainer}>
                                        {selectedSubscriptionBillId &&
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">No. of Installments</Typography>
                                            </Grid>
                                        }
                                        {selectedSubscriptionBillId &&
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={numberOfInstalments}
                                                    variant="standard"
                                                    InputLabelProps={genericLabelProps}
                                                    type="number"
                                                    onChange={(event: any) => {
                                                        setNumberOfInstalments(event.target.value)
                                                    }
                                                    }
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} style={{display: "flex"}}>
                                    <Grid container className={classes.modalContainer} alignContent="center"
                                          justifyContent="center">
                                        {numberOfInstalments === 0 ? <></> :
                                            <Button
                                                color="primary"
                                                onClick={handleGenerateInstallmentsClick}
                                                variant="contained"
                                                startIcon={<PlaylistAddIcon/>}
                                            >
                                                Generate Installments
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DataGrid
                                        style={{height: '400px'}}
                                        rows={newPaymentPlanRows}
                                        columns={installmentColumns}
                                        getRowHeight={() => 'auto'}
                                    />
                                </Grid>

                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {!canConfirmPaymentPlan ?
                                        <Grid item xs={12} style={{textAlign: "center", paddingBottom: "12px"}}>
                                            {!canConfirmPaymentPlan ? <Typography>Remaining Amount To Be Covered In Installments: {remainingAmountToBeCoveredInInstalments}</Typography> : <></>}    
                                        </Grid> : <></>}   
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6} className={classes.actionButton}>
                                           
                                            <Button

                                                color="primary"
                                                onClick={handleConfirmNewPaymentPlanClick}
                                                variant="contained"
                                                startIcon={<DoneIcon/>}
                                                disabled={!canConfirmPaymentPlan}
                                            >
                                                Confirm
                                            </Button> 
                                                
                                        </Grid>
                                        <Grid item xs={6} className={classes.actionButton}>
                                            <Button
                                                color="primary"
                                                onClick={handleCreateNewPaymentPlanModalClose}
                                                variant="contained"
                                                startIcon={<CloseIcon/>}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
        </LocalizationProvider>

    )
}

export {CreatePaymentPlanModal}
