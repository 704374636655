import {
    LinearProgress,
    Theme,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Modal
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { approveHeldBill, getAllBillingMonths, getHeldNonDraftBillsForMonth, getAllNonDraftBills as getNonDraftBillsForMonth } from "../../services/BillService";
import { Bill } from "../../models/Bill";
import { ConvertBillStatusToString } from "../../helpers/ConvertBillStatusToString";
import { makeStyles, createStyles } from '@mui/styles';
import { BillingRunTable } from "../../components/BillingRunTable";
import { format } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        },
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20,
        },
        details: {
            alignItems: 'center',
        },
        column: {
            flexBasis: '33.33%',
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        }
    }))

const BillingRunOverviewPageFleetBills: React.FC = props => {
    const [bills, setBills] = useState<Bill[]>([])
    const [heldBills, setHeldBills] = useState<Bill[]>([])
    const [mappedBills, setMappedBills] = useState<any[]>([])
    const [mappedHeldBills, setMappedHeldBills] = useState<any[]>([])
    const [showResults, setShowResults] = useState<boolean>(false)
    const [selectedBillingMonth, setSelectedBillingMonth] = useState<string | undefined>(undefined);
    const [allBillingMonths, setAllBillingMonths] = useState<string[]>([]);
    const [approveHeldBillResponseMessage, setApproveHeldBillResponseMessage] = useState<string | undefined>(undefined)
    const [approvalInProgress, setApprovalInProgress] = useState<boolean>(false)
    const classes = useStyles()
    useEffect(() => {
        let mounted = true;
        getAllBillingMonths()
            .then(billMonths =>{
                if (mounted) {
                    setAllBillingMonths(billMonths.reverse())
                    let billingMonth = billMonths[0] ?? '';
                    setSelectedBillingMonth(billingMonth);

                    getNonDraftBillsForMonth(billingMonth)
                        .then(bills => {
                            if (mounted) setBills(bills)
                        })
                    getHeldNonDraftBillsForMonth(billingMonth)
                        .then(heldBills => {
                            if (mounted) {
                                setHeldBills(heldBills)
                            }
                        })
                    }
                })
        

        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const mappedBills = mapBillModelsToDataTableRows(bills)
        const heldMappedBills = (mapBillModelsToDataTableRows(heldBills))
        setMappedBills(mappedBills)
        setMappedHeldBills(heldMappedBills)
        if (bills !== undefined && bills.length > 0) {
            setShowResults(true)
        }
    }, [bills, heldBills])
    
    useEffect(() => {
        if(!selectedBillingMonth) return;

        getNonDraftBillsForMonth(selectedBillingMonth)
            .then(bills => {
                setBills(bills)
            })
        getHeldNonDraftBillsForMonth(selectedBillingMonth)
            .then(heldBills => {
                setHeldBills(heldBills)
            })
    }, [selectedBillingMonth])

    function mapBillModelsToDataTableRows(bills: Bill[]): any[] {
        return bills.map((bill: Bill, index) => {
            return {
                id: index,
                customerName: bill.tradingName,
                invoiceNumber: bill.invoiceNumber,
                currency: bill.currencyIsoCode,
                amount: bill.totalPaymentLine.net,
                dateOfBill: bill.billingDate,
                usagePeriod: format(new Date(bill.billingPeriodEndDate), "MMM yyyy"),
                status: ConvertBillStatusToString(bill.billStatus),
                billId: bill.id,
                downloadButton: `/api/assetoperators/${bill.assetOperatorId}/bills/${bill.id}`,
                viewButton: "/bills/" + bill.id,
                validationErrors: bill.validationErrors,
            }
        });
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedBillingMonth(event.target.value as string);
    };


    let displayBills = mappedBills.filter(bill => bill.status !== "Held for manual review" && bill.status !== "Delivery Failed")
    let displayHeldBills = mappedHeldBills
    if (selectedBillingMonth) {
        displayBills = displayBills.filter(bill => bill.usagePeriod === selectedBillingMonth)
        displayHeldBills = displayHeldBills.filter(bill => bill.usagePeriod === selectedBillingMonth)
    }



    async function getApproveHeldBillResponse(id: string) {
        setApprovalInProgress(true)
        let response = await approveHeldBill(id);
        setApprovalInProgress(false)
        setApproveHeldBillResponseMessage(response);
    }

    return <Grid container spacing={3} direction="column">
        <Grid xs={12} item>
            <Card>
                <CardHeader title="Billing Runs - Fleet Bills" subheader="Manual Review Required"
                    action={
                        selectedBillingMonth &&
                        <FormControl style={{ minWidth: 500 }}>
                            <InputLabel id="date-select-label">Usage Period</InputLabel>
                            <Select
                                labelId="date-select-label"
                                id="date-select"
                                value={selectedBillingMonth}
                                label="Usage Period"
                                onChange={handleChange}
                            >
                                {allBillingMonths.map(month => <MenuItem key={month} value={month}>{month}</MenuItem>)}
                            </Select>
                        </FormControl>
                    } />
                <CardContent style={{ minHeight: '65vh' }}>
                    {
                        showResults
                            ?
                            <>
                                <BillingRunTable bills={displayHeldBills} approveMethod={getApproveHeldBillResponse} />
                                <Modal open={approvalInProgress || !!approveHeldBillResponseMessage} onClose={() => setApproveHeldBillResponseMessage("")}>
                                    <Card style={{
                                        position: 'absolute' as 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                    }}>
                                        <CardHeader title={approvalInProgress ? "Approval In Progress" : "Approval Response Received"} />
                                        <CardContent>
                                            {approvalInProgress && <LinearProgress color="secondary" />}
                                            {approveHeldBillResponseMessage && <Typography variant="subtitle1" gutterBottom>{approveHeldBillResponseMessage}</Typography>}
                                        </CardContent>
                                    </Card>
                                </Modal>

                            </>
                            :
                            <div className={classes.loader}>
                                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                <LinearProgress color="secondary" />
                            </div>
                    }
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Card>
                <CardHeader title="Issued Bills" />
                <CardContent style={{ minHeight: '65vh' }}>
                    {showResults
                        ?
                        (
                            <BillingRunTable bills={displayBills} approveMethod={null} />
                        )
                        :
                        (
                            <div className={classes.loader}>
                                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                <LinearProgress color="secondary" />
                            </div>
                        )
                    }
                </CardContent>
            </Card>
        </Grid>
    </Grid>;

}

export { BillingRunOverviewPageFleetBills }
