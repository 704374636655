import {Subscription} from "./Subscription";
import {DraftSubscriptionViewModel} from "./DraftSubscriptionViewModel";

class DraftSubscription extends Subscription {
    public static Convert(draftSubscriptionViewModel: DraftSubscriptionViewModel): DraftSubscription {
        const draftSubscription: DraftSubscription = {...draftSubscriptionViewModel};       
        
        draftSubscription.feeRate = draftSubscriptionViewModel.vmFeeRate === null ? undefined : draftSubscriptionViewModel.vmFeeRate / 100;
        draftSubscription.taxRate = draftSubscriptionViewModel.vmTaxRate === null ? undefined : draftSubscriptionViewModel.vmTaxRate / 100;

        return draftSubscription;
    }
}


export {DraftSubscription}
