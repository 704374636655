async function getUserInfo(): Promise<any> {
  const response = await fetch('/.auth/me');
  const payload = await response.json();
  const { clientPrincipal } = payload;
  try {
    (window as any).clarity("identify", clientPrincipal?.userDetails);
  } catch {

  }
  return clientPrincipal;
}

export { getUserInfo }