import { useEffect, useState } from "react";
import { ExternalContract } from "../models/ExternalContract";
import { createOrUpdateExternalContract, getAnExternalContract } from "../services/ExternalContractService";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardActions, CardContent, CardHeader, FormControlLabel, Grid, InputAdornment, LinearProgress, ListItemText, MenuItem, Select, Switch, TextField, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { largeLabelProps } from "../props/LargeLabelProps";
import { ConvertCurrencyToSymbol } from "../helpers/ConvertCurrencyToSymbol";
import { parse } from "date-fns";
import { Delete, ExpandMore, FileUpload, Save } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { getAssetOperatorIds } from "../services/AssetOperatorService";
import { CsvToArray } from "../helpers/CsvToArray";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            width: '100%'
        },
    }),
);

const ExternalContractCreateOrEditPage: React.FC = ({ match }: any) => {
    const externalContractId = match.params.externalContractId
    const [externalContract, setExternalContract] = useState<ExternalContract>({} as ExternalContract)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isCreating, setIsCreating] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [expandedAssets, setExpandedAssets] = useState<boolean>(false)
    const [allAssetOperatorIds, setAllAssetOperatorIds] = useState<string[]>([])
    const isValidFloat = (value: string) => {
        if (!value) return true
        if (value.endsWith('.')) return true
        if (value.split('.').length > 2) return false
        return isValidNumber(value, parseFloat)
    }

    const isValidInteger = (value: string) => {
        if (!value) return true
        if (value.indexOf('.') >= 0) return false
        return isValidNumber(value, parseInt)
    }

    const isValidDate = (value: string) => {
        if (!value) return true
        try {
            let result = parse(value, 'dd/MM/yyyy', new Date())

            return result.getFullYear() !== 1970 && result.getFullYear() !== 1
        } catch {
            return false
        }
    }

    const isValidNumber = (value: string, parsingFunc: any) => {
        return !isNaN(parsingFunc(value))
    }
    useEffect(() => {
        if (externalContractId) {
            setIsLoading(true)
            getAnExternalContract(externalContractId).then((externalContract) => {
                setExternalContract(externalContract)
                setIsLoading(false)
                setIsEditing(true)
            })
        } else {
            setIsCreating(true)
        }
        getAssetOperatorIds().then(allFetchedAssetOperatorIds => setAllAssetOperatorIds(allFetchedAssetOperatorIds))
    }
        , [externalContractId])

    const onSave = () => {
        createOrUpdateExternalContract(externalContract, isEditing).then(() => {
            window.location.href = '/externalcontracts'
        })
    }
    const toggleExpandedAssets = () => {
        setExpandedAssets(!expandedAssets)
    }

    const replaceAssets = () => {
        const csvFileInput = document.getElementById('csvInput') as HTMLInputElement;
        if (csvFileInput?.files?.length === 1) {

            const file = csvFileInput.files[0];
            const reader = new FileReader();

            reader.onload = function (e) {
                let objectsFromCsvFile = CsvToArray(e?.target?.result as string, ',')
                let assets = objectsFromCsvFile.map(objectFromCsvFile => { return { usageSourceId: objectFromCsvFile["VIN"], alternativeId: objectFromCsvFile["License Plate"] } })
                assets = assets.filter(asset => asset.usageSourceId !== undefined && asset.alternativeId !== undefined)
                setExternalContract({ ...externalContract, assets: assets })
            };

            reader.readAsText(file);
        }
    }
    const classes = useStyles();
    const theme = useTheme();
    return (
        isLoading
            ?
            <Card>
                <CardHeader title={(isCreating ? "Create" : "Edit") + " External Contract"} />
                <CardContent>
                    <LinearProgress color="secondary" />
                    <Box height={9} />
                </CardContent>
            </Card>
            :
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={(isCreating ? "Create" : "Edit") + " External Contract"} />
                        <CardActions>
                            <Button startIcon={<Delete />} style={{ marginLeft: 'auto' }} color="secondary" variant="contained" href="/externalcontracts">Discard Changes</Button>
                            <Button startIcon={<Save />} style={{ marginLeft: theme.spacing(2) }} variant="contained" onClick={onSave}>Save and Activate</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Contract ID's" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">ID</Typography>
                                    <TextField variant="standard" className={classes.input} aria-label="Edit ID" value={externalContract.id} onChange={event => setExternalContract({ ...externalContract, id: event.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">Name</Typography>
                                    <TextField variant="standard" className={classes.input} aria-label="Edit Name" value={externalContract.name} onChange={event => setExternalContract({ ...externalContract, name: event.target.value })} />
                                </Grid>
                                <Grid xs={12} sm={6} item >
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">Asset Operator</Typography>
                                    <Select
                                        className={classes.input}
                                        value={externalContract.assetOperatorId ?? ""}
                                        aria-label="Edit Asset Operator"
                                        onChange={(event) => setExternalContract({ ...externalContract, assetOperatorId: event.target.value as string })}
                                        variant="standard"
                                    >
                                        {allAssetOperatorIds.map((assetOperatorId) => (
                                            <MenuItem key={assetOperatorId} value={assetOperatorId}>
                                                <ListItemText primary={assetOperatorId} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Assets" />
                        <CardContent>
                            <Accordion expanded={expandedAssets} onChange={toggleExpandedAssets}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography variant="subtitle1">{externalContract.assets?.length ?? 0} Assets</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DataGrid
                                        autoHeight
                                        key="asset-list-data-grid"
                                        columns={[{
                                            field: 'usageSourceId', headerName: 'VIN', flex: 1
                                        }, {
                                            field: 'alternativeId', headerName: 'License Plate', flex: 1
                                        }]}
                                        pageSize={10}
                                        rows={externalContract?.assets?.map(asset => { return { ...asset, id: asset.usageSourceId ?? asset.alternativeId } }) ?? []}
                                        loading={isLoading}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="secondary"
                                component="label"
                                style={{ marginLeft: 'auto' }}
                                startIcon={<FileUpload />}
                            >
                                {(externalContract?.assets?.length ?? 0) > 0 ? "Replace Assets From CSV" : "Upload CSV"}
                                <input
                                    type="file"
                                    accept=".csv"
                                    id="csvInput"
                                    hidden
                                    onChange={replaceAssets}
                                />
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Contract Financial Information" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2" id="currency-label">Currency</Typography>
                                    <Select labelId="currency-label" defaultValue="GBP" variant="standard" className={classes.input} aria-label="Edit Currency" value={externalContract.currencyIsoCode} onChange={event => setExternalContract({ ...externalContract, currencyIsoCode: event.target.value })}>
                                        <MenuItem value={"GBP"}>GBP</MenuItem>
                                        <MenuItem value={"USD"}>USD</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">Total Facility</Typography>
                                    <TextField
                                        InputLabelProps={largeLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start">{ConvertCurrencyToSymbol(externalContract.currencyIsoCode)}</InputAdornment>
                                            ),
                                        }}
                                        error={!isValidFloat(externalContract.totalFacility?.toString())}
                                        variant="standard"
                                        className={classes.input}
                                        aria-label="Edit Total Facility"
                                        value={externalContract.totalFacility?.toLocaleString()}
                                        onChange={event => setExternalContract({ ...externalContract, totalFacility: event.target.value as unknown as number })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">Total Contribution (Deposit, Discount etc.)</Typography>
                                    <TextField
                                        InputLabelProps={largeLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start">{ConvertCurrencyToSymbol(externalContract.currencyIsoCode)}</InputAdornment>
                                            ),
                                        }}
                                        error={!isValidFloat(externalContract.totalContribution?.toString())}
                                        variant="standard"
                                        className={classes.input}
                                        aria-label="Edit Total Contribution"
                                        value={externalContract.totalContribution?.toLocaleString()}
                                        onChange={event => setExternalContract({ ...externalContract, totalContribution: event.target.value as unknown as number })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">Monthly Repayment</Typography>
                                    <TextField
                                        InputLabelProps={largeLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start">{ConvertCurrencyToSymbol(externalContract.currencyIsoCode)}</InputAdornment>
                                            ),
                                        }}
                                        error={!isValidFloat(externalContract.monthlyRepayment?.toString())}
                                        variant="standard"
                                        className={classes.input}
                                        aria-label="Edit Monthly Payment"
                                        value={externalContract.monthlyRepayment?.toLocaleString()}
                                        onChange={event => setExternalContract({ ...externalContract, monthlyRepayment: event.target.value as unknown as number })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">Total Expected Repayment</Typography>
                                    <TextField
                                        InputLabelProps={largeLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start">{ConvertCurrencyToSymbol(externalContract.currencyIsoCode)}</InputAdornment>
                                            ),
                                        }}
                                        error={!isValidFloat(externalContract.totalExpectedRepayment?.toString())}
                                        variant="standard"
                                        className={classes.input}
                                        aria-label="Edit Total Expected Repayment" value={externalContract.totalExpectedRepayment?.toLocaleString()}
                                        onChange={event => setExternalContract({ ...externalContract, totalExpectedRepayment: event.target.value as unknown as number })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2">Contract Length (Months)</Typography>

                                    <TextField
                                        InputLabelProps={largeLabelProps}
                                        error={!isValidInteger(externalContract.contractLengthInMonths?.toString())}
                                        variant="standard"
                                        className={classes.input}
                                        aria-label="Edit Contract Length In Months" value={externalContract.contractLengthInMonths?.toLocaleString()}
                                        onChange={event => setExternalContract({ ...externalContract, contractLengthInMonths: event.target.value as unknown as number })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography style={{ color: 'rgba(255, 255, 255, 0.7)' }} variant="body2" id="currency-label">Contract Start Date</Typography>
                                    <TextField
                                        error={!isValidDate(externalContract.contractStartDate?.toString())}
                                        variant="standard"
                                        type="date"
                                        className={classes.input}
                                        aria-label="Edit Contract Start Date"
                                        value={externalContract.contractStartDate}
                                        onChange={event => setExternalContract({ ...externalContract, contractStartDate: event.target.value })}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Contract Options" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch color="primary"
                                                checked={externalContract.bulletPaymentRequired} />
                                        }
                                        onChange={() =>
                                            setExternalContract({
                                                ...externalContract,
                                                bulletPaymentRequired: !externalContract.bulletPaymentRequired
                                            })
                                        }
                                        label="Bullet Payment Required?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={<Switch color="primary"
                                            checked={externalContract.includeCarbonAndNoxSavingsOnPublicWebsite} />}
                                        onChange={() => setExternalContract({
                                            ...externalContract,
                                            includeCarbonAndNoxSavingsOnPublicWebsite: !externalContract.includeCarbonAndNoxSavingsOnPublicWebsite
                                        })}
                                        label="Include CO2 and NOx savings on main website?"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >


    );
}
export { ExternalContractCreateOrEditPage };