import {
    LinearProgress,
    Theme,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
    Card,
    CardHeader,
    CardContent,
    CardActions
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { Payment } from "../../models/Payment";
import { cancelPayment, getAllPayments } from "../../services/PaymentService";
import { getAssetOperators } from "../../services/AssetOperatorService";
import { CountdownToNextPaymentSubmission } from "../../components/CountdownToNextPaymentSubmission";
import { ConvertPaymentStatusToString } from "../../helpers/ConvertPaymentStatusToString";
import { AllPaymentsTable } from "../../components/Payments/AllPaymentsTable";
import { makeStyles, createStyles } from '@mui/styles';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))
type PaymentGroup = {
    commonPaymentMonth: Date,
    payments: Payment[]
}

const AllPaymentsPage: React.FC = props => {
    const [payments, setPayments] = useState<Payment[]>([])
    const [mappedPayments, setMappedPayments] = useState<any[]>([])
    const [selectedPaymentGroup, setSelectedPaymentGroup] = useState<PaymentGroup | undefined>()
    const [selectedPaymentGroupDate, setSelectedPaymentGroupDate] = useState<string>('')
    const [feedbackMessage, setFeedbackMessage] = useState<string>()
    const [feedbackMessageColor, setFeedbackMessageColor] = useState<string>()

    const groupNonQueuedPaymentsByPaymentMonth = (payments: Payment[]): PaymentGroup[] => {
        let paymentGroups: PaymentGroup[] = []
        for (let payment of payments) {
            let createdDate = new Date(payment.dateTimePaymentCreated)
            let createdMonth = createdDate.getMonth()
            let createdYear = createdDate.getFullYear()
            const matchingPaymentGroupIndex = paymentGroups.findIndex(x => x.commonPaymentMonth.valueOf() === new Date(createdYear, createdMonth, 1).valueOf())
            if (matchingPaymentGroupIndex === -1) {
                paymentGroups.push({ commonPaymentMonth: new Date(createdYear, createdMonth, 1), payments: [payment] })
            } else {
                paymentGroups[matchingPaymentGroupIndex].payments.push(payment)
            }
        }
        return paymentGroups;
    }

    const classes = useStyles()
    useEffect(() => {
        let mounted = true;
        let alteredPayments: Payment[] = []
        getAllPayments()
            .then(payments => {
                getAssetOperators()
                    .then(assetOperators =>
                        payments.map(
                            (payment) => {
                                let assetOperator = assetOperators.find(operator => operator.id === payment.customerId)
                                payment.assetOperatorName = assetOperator == null ? '' : assetOperator.tradingName
                                return alteredPayments.push(payment)
                            }))

                            .then(() =>{
                                if (mounted) {
                                    setPayments(alteredPayments)
                                }
                            })
                })

        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    async function onCancelClick(params: any, theme: Theme): Promise<void> {
        let payment = params.row;
        let response = await cancelPayment(payment.paymentId);
        let paymentName = payment.subscriptionId + ' - ' + (payment.assetOperatorName === '' ? payment.customerId : payment.assetOperatorName)
        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully cancelled')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: Payment[] = []
            getAllPayments()
                .then(payments => {
                    getAssetOperators()

                    .then(assetOperators => 
                        payments.map(
                            (payment) => {
                                let assetOperator = assetOperators.find(operator => operator.id === payment.customerId)
                                payment.assetOperatorName = assetOperator == null ? '' : assetOperator.tradingName
                                return alteredPayments.push(payment)
                            }))
                            .then(() =>{
                                setPayments(alteredPayments)
                                var grouped = groupNonQueuedPaymentsByPaymentMonth(alteredPayments).sort((a, b) => new Date(b.commonPaymentMonth).getTime() - new Date(a.commonPaymentMonth).getTime())
                                let filteredGroupedPayments = grouped.filter(paymentGroup => paymentGroup.commonPaymentMonth.toLocaleDateString() === selectedPaymentGroupDate as string)[0]
                                setSelectedPaymentGroup(filteredGroupedPayments)
                                var localMappedPayments = mapPaymentModelsToDataTableRows(filteredGroupedPayments.payments)
                                setMappedPayments(localMappedPayments)
                            })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to cancel')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    function mapPaymentModelsToDataTableRows(payments: Payment[]): any[] {
        return payments.map((payment, i) => {
            return {
                assetOperatorName: payment?.assetOperatorName,
                id: i,
                customerId: payment?.customerId,
                targetDate: payment.targetPaymentDate,
                currency: payment?.currencyIsoCode,
                paymentAmount: payment?.amountCharged,
                paymentType: payment?.paymentType,
                paymentStatus: ConvertPaymentStatusToString(payment?.status),
                paymentId: payment?.id,
                subscriptionId: payment?.subscriptionId,
                currencyIsoCode: payment?.currencyIsoCode,
                arrearsManagement: payment?.previouslyFailedAttempts?.length > 0 ? "Retry attempt: " + payment?.previouslyFailedAttempts?.length : ''
            }
        });
    }

    const paymentsGrouped = groupNonQueuedPaymentsByPaymentMonth(payments).sort((a, b) => new Date(b.commonPaymentMonth).getTime() - new Date(a.commonPaymentMonth).getTime())

    useEffect(() => {
        if (selectedPaymentGroupDate === '') {
            if (paymentsGrouped[0]?.commonPaymentMonth.toLocaleDateString() !== undefined) {
                setSelectedPaymentGroupDate(paymentsGrouped[0]?.commonPaymentMonth.toLocaleDateString())
                let filteredGroupedPayments = paymentsGrouped?.filter(paymentGroup => paymentGroup.commonPaymentMonth.toLocaleDateString() === paymentsGrouped[0]?.commonPaymentMonth.toLocaleDateString() as string)[0]
                setSelectedPaymentGroup(filteredGroupedPayments)
                var localMappedPayments = mapPaymentModelsToDataTableRows(filteredGroupedPayments?.payments)
                setMappedPayments(localMappedPayments)
            }
        }
    }, [paymentsGrouped, selectedPaymentGroupDate, payments])

    return (
        <Card>
            <CardHeader action={selectedPaymentGroup && <Select
                id="demo-simple-select"
                value={selectedPaymentGroupDate}
                onChange={(event: SelectChangeEvent) => {
                    setSelectedPaymentGroupDate(event.target.value as string)
                    let filteredGroupedPayments = paymentsGrouped.filter(paymentGroup => paymentGroup.commonPaymentMonth.toLocaleDateString() === event.target.value as string)[0]
                    setSelectedPaymentGroup(filteredGroupedPayments)
                    var localMappedPayments = mapPaymentModelsToDataTableRows(filteredGroupedPayments.payments)
                    setMappedPayments(localMappedPayments)
                }}
            >
                {paymentsGrouped.map(paymentGroup => <MenuItem value={paymentGroup.commonPaymentMonth.toLocaleDateString()}>{paymentGroup.commonPaymentMonth.toLocaleString('default', { month: 'long' })} {paymentGroup.commonPaymentMonth.getFullYear()}</MenuItem>)}
            </Select>}
                title={"All Payments created in " + (selectedPaymentGroup?.commonPaymentMonth.toLocaleString('default', { month: 'long' }) ?? "...") + " " + (selectedPaymentGroup?.commonPaymentMonth.getFullYear() ?? "")} />
            <CardContent style={{ height: '65vh' }}>
                {
                    selectedPaymentGroup !== undefined
                        ?
                        (
                            <>

                                <AllPaymentsTable payments={mappedPayments} onCancelClick={onCancelClick} />
                            </>
                        )
                        :
                        (
                            <div className={classes.loader}>
                                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                <LinearProgress color="secondary" />
                            </div>
                        )
                }
            </CardContent>
            <CardActions>
                <CountdownToNextPaymentSubmission />
                <Typography style={{ color: feedbackMessageColor }} variant="subtitle1">{feedbackMessage}</Typography>
            </CardActions>
        </Card>
    )
}

export { AllPaymentsPage }