import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { InvestorViewModel } from '../models/InvestorViewModel';
import { archiveInvestor, getInvestor, getInvestorSubscriptions } from '../services/InvestorService';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@mui/lab';
import { Archive } from '@mui/icons-material';
import { SubscriptionViewModel } from '../models/SubscriptionViewModel';

const InvestorArchivePage: React.FC = ({ match }: any) => {
    const [investorViewModel, setInvestorViewModel] = useState<InvestorViewModel | null>(null)
    const [soloSubscriptions, setSoloSubscriptions] = useState<SubscriptionViewModel[]>([]);
    let history = useHistory();

    const onClickArchive = () => {
        archiveInvestor(investorViewModel?.id ?? "").then(() => history.push("/investors"))
    }

    useEffect(() => {
        let mounted = true;
        getInvestor(match.params.investorId)
            .then(item => {
                if (mounted) {
                    setInvestorViewModel(item)
                }
            })
        getInvestorSubscriptions(match.params.investorId)
            .then(items => {
                const soloItems = items.filter(subscription => subscription.investorIds.length === 1)
                setSoloSubscriptions(soloItems);
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id])
    let anySoloSubscriptions = soloSubscriptions.length > 0;
    return (
        <>
            {
                investorViewModel == null
                    ?
                    <Skeleton height={500} />
                    :
                    (
                        <Card>
                            <CardHeader title={"Warning: " + investorViewModel?.name + " will automatically be removed from their subscriptions."} />
                            <CardContent>
                                {anySoloSubscriptions ?
                                    <>
                                        <Typography variant="h6" gutterBottom>Please manually add a new investor for the below subscriptions, for which {investorViewModel?.name} is currently the sole investor, to proceed. You may need to refresh this page once you have resolved subscription ownership.</Typography>
                                        {soloSubscriptions.map(subscription =>
                                            <Typography variant='body1'>{subscription.id} ({subscription.shortId}) : {subscription.name}</Typography>)}
                                    </>
                                    :
                                    <>
                                        <Typography variant="body1" gutterBottom>Are you sure that you want to archive this investor?</Typography>
                                    </>
                                }
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    style={{  marginLeft: 'auto' }}
                                    startIcon={<Archive />}
                                    onClick={onClickArchive}
                                    disabled={anySoloSubscriptions}
                                >
                                    Archive
                                </Button>
                            </CardActions>
                        </Card >
                    )

            }



        </>
    )
}

export { InvestorArchivePage }
