import { Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { AddNewCustomerEmailToDemoModal } from "../components/AddNewCustomerEmailToDemoModal";
import { DemoUserAccess } from "../models/DemoUserAccess";
import { getDemoUsers } from "../services/DemoFleetServices";
import { ExtendExpireOnDemoAccountModal } from "../components/ExtendExpireOnDemoAccountModal";

const now = Date.now();



function renderRemainingTime(expiryDate: Date) {
    const dateDifference = Math.floor((new Date(expiryDate).getTime() - now) / (1000 * 60 * 60 * 24));
    if (dateDifference < 0) {
        return "No Access"
    } else if (dateDifference === 0) {
        return "Expires today at 23:59"
    } else if (dateDifference === 1) {
        return dateDifference + " day"
    } else {
        return dateDifference + " days"
    }
}



const DemoFleetsPage: React.FC = () => {
    const [demoUsers, setDemoUsers] = useState<DemoUserAccess[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addNewCustomerModalOpen, setAddNewCustomerModalOpen] = useState<boolean>(false);
    const [feedbackMessage, setFeedbackMessage] = useState<string>("");
    const [extendExpireDateModalOpen, setExtendExpireDateModalOpen] = useState<boolean>(false);
    const [extendExpireFeedbackMessage, setExtendExpireFeedbackMessage] = useState<string>("");
    const [customerToExtend, setCustomerToExtend] = useState<DemoUserAccess | undefined>(undefined);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setIsLoading(true)
            getDemoUsers().then(response => {
                setDemoUsers(response);
                setIsLoading(false);
            })
        }

        return () => {
            mounted = false;
        };
    }, [feedbackMessage, extendExpireFeedbackMessage]);

    const columns = [
        {
            field: 'customerEmail',
            headerClassName: 'zeti-column-headers',
            headerName: 'Customer Email',
            flex: 1
        },
        {
            field: 'portalAccess',
            headerClassName: 'zeti-column-headers',
            headerName: 'Portal Access',
            flex: 1
        },
        {
            field: 'remainingTime',
            headerClassName: 'zeti-column-headers',
            headerName: 'Remaining Time',
            flex: 1,
            renderCell: (params: any): JSX.Element => {
                return (<Typography style={{ fontSize: 14 }}>{renderRemainingTime(params.row.expiryDate)}</Typography>)
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params: any): JSX.Element => {
                return (renderRemainingTime(params.row.expiryDate) === "No Access" ? <></> : <>
                    <Button
                        onClick={() => onExtendClick(params.row.id, params.row.user)}
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginRight: 16 }}
                    >
                        Extend
                    </Button>
                </>
                )
            }
        }
    ];

    async function onExtendClick(id: string, user: DemoUserAccess) {
        setCustomerToExtend(user);
        setExtendExpireDateModalOpen(true);
    }

    const rows = demoUsers.map(user => {
        var portalAccess = "";
        if(user.assetOperatorId === "CUSTUKDEMOHGV"){
            portalAccess = "UK HGV";
        } else {
            portalAccess = user.assetOperatorId.substring(4, 6)
        }
        return {
            id: `${user.email}${user.assetOperatorId}${user.expiryDate}`,
            customerEmail: user.email,
            portalAccess: portalAccess,
            expiryDate: user.expiryDate,
            user: user
        }
    })

    function addCustomer() {
        setFeedbackMessage("");
        setAddNewCustomerModalOpen(true);
    }

    return (
        <Card>
            <CardHeader
                action={<Button style={{ "margin": "10px" }}
                    color="primary"
                    variant="contained"
                    onClick={() => addCustomer()}
                >
                    Add Customer
                </Button>}
                title="Demo Fleet Management" />
            <AddNewCustomerEmailToDemoModal addNewCustomerModalOpen={addNewCustomerModalOpen}
                setAddNewCustomerModalOpen={setAddNewCustomerModalOpen}
                setFeedbackMessage={setFeedbackMessage}
                feedbackMessage={feedbackMessage}
            />
            <ExtendExpireOnDemoAccountModal extendExpireOnDemoAccountModalOpen={extendExpireDateModalOpen}
                setExtendExpireOnDemoAccountModalOpen={setExtendExpireDateModalOpen}
                setExtendExpireFeedbackMessage={setExtendExpireFeedbackMessage}
                extendExpireFeedbackMessage={extendExpireFeedbackMessage}
                user={customerToExtend}
            />
            <CardContent>
                <DataGrid
                    key="normal-data-grid"
                    autoHeight
                    columns={columns}
                    rows={rows}
                    components={{ Toolbar: GridToolbar }}
                    loading={isLoading}
                />
            </CardContent>

        </Card>
    )
}

export { DemoFleetsPage };
