import {Investor} from "./Investor";
export class InvestorViewModel extends Investor {
    
    vmTaxRateChargedOnFees: null | number = null;  

    constructor(investor: Investor) {
        super();
        const investorViewModel: InvestorViewModel = {
            ...investor, 
            vmTaxRateChargedOnFees : investor.taxRateChargedOnFees === null || investor.taxRateChargedOnFees === undefined ? null : parseFloat((investor.taxRateChargedOnFees * 100).toFixed(2))
        };
        
        return investorViewModel;
    }
}
