class PaymentPlanRepaymentPaymentStatus {
    static Complete: string = "Complete";
    static FailedAndRetried: string = "FailedAndRetried";
    static Cancelled: string = "Cancelled";
    static Failed: string = "Failed";
    static Queued: string = "Queued";
    static Pending: string = "Pending";
    static NA: string = "NA";
}

export { PaymentPlanRepaymentPaymentStatus }
