import { Card, CardActions, CardContent, CardHeader, IconButton, Modal, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            border: '2px solid #000',
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
    }))

type CreateNewMinimumUsagePeriodsModalProps = {
    deleteMinimumUsagePeriodRowModalOpen: boolean
    setDeleteMinimumUsagePeriodRowModalOpen: Function
    rowIdToDelete: number
    deleteMinimumUsageRow: Function
}


function DeleteMinimumUsagePeriodRowModal({ deleteMinimumUsagePeriodRowModalOpen, setDeleteMinimumUsagePeriodRowModalOpen, rowIdToDelete, deleteMinimumUsageRow }: CreateNewMinimumUsagePeriodsModalProps): JSX.Element {

    const classes = useStyles();

    async function confirmDeleteRow() {
        deleteMinimumUsageRow(rowIdToDelete)
        setDeleteMinimumUsagePeriodRowModalOpen(false);
    }

    function handleDeleteMinimumUsagePeriodRowModalClose() {
        setDeleteMinimumUsagePeriodRowModalOpen(false);
    }

    return (
        <Modal open={deleteMinimumUsagePeriodRowModalOpen}
            onClose={handleDeleteMinimumUsagePeriodRowModalClose}>
            <div className={classes.modal}>
                <Card>
                    <CardHeader
                        action={
                            <IconButton
                                color="primary"
                                onClick={handleDeleteMinimumUsagePeriodRowModalClose}>
                                <CloseIcon />
                            </IconButton>}
                        title="Delete minimum usage period row" />

                    <CardContent>
                        <Typography>Are you sure you want to delete period {rowIdToDelete}?</Typography>
                    </CardContent>
                    <CardActions>
                        <LoadingButton
                            style={{ marginLeft: 'auto' }}
                            variant="contained"
                            color="primary"
                            onClick={confirmDeleteRow}>
                            DELETE
                        </LoadingButton>
                    </CardActions>
                </Card>
            </div>
        </Modal>);
}

export { DeleteMinimumUsagePeriodRowModal }
