import { Button, Card, CardActions, CardContent, CardHeader, LinearProgress, Typography, useTheme } from "@mui/material"
import React, { useEffect, useState } from 'react';
import { Bill } from "../../models/Bill";
import { ConvertBillStatusToString } from "../../helpers/ConvertBillStatusToString";
import { getLatestTrialBills, getLatestTrialBillsCsv, triggerTrialBillingRun } from "../../services/BillService";
import { TrialBillingRunTable } from "../../components/TrialBillingRunTable";
import { makeStyles, createStyles } from '@mui/styles';
import { FileDownloadDataAndName } from "../../models/FileDownloadDataAndName";
import { downloadFileFromFileDownloadDataAndName } from "../../services/FileDownloadService";

const useStyles = makeStyles(() =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))

const TrialBillingRunOverviewPageFleetBills: React.FC = () => {
    const [bills, setBills] = useState<Bill[]>([])
    const [mappedBills, setMappedBills] = useState<any[]>([])
    const [showResults, setShowResults] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [triggerButtonEnabled, setTriggerButtonEnabled] = useState<boolean>(true)

    useEffect(() => {
        var mappedBills = mapBillModelsToDataTableRows(bills)
        setMappedBills(mappedBills)
        if (bills !== undefined && bills.length > 0) {
            setShowResults(true)
        }
    }, [bills])

    useEffect(() => {
        let mounted = true;
        getLatestTrialBills()
            .then(bills => {
                if (mounted) setBills(bills)
            })

        return () => {
            mounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function mapBillModelsToDataTableRows(bills: Bill[]): any[] {
        return bills.map((bill: Bill, index) => {
            return {
                id: index,
                customerName: bill.tradingName,
                dateOfBill: bill.billingDate,
                billingPeriodStartDate: bill.billingPeriodStartDate,
                billingPeriodEndDate: bill.billingPeriodEndDate,
                amount: bill.totalPaymentLine.net,
                status: ConvertBillStatusToString(bill.billStatus),
                currency: bill.currencyIsoCode,
                validationErrors: bill.validationErrors,
                billId: bill.id,
                assetOperatorId: bill.assetOperatorId,
                invoiceNumber: bill.invoiceNumber,
            }
        });
    }

    async function triggerTrialingBillingRunClick() {
        const successful = await triggerTrialBillingRun()
        setIsError(!successful)
        setTriggerButtonEnabled(false)
    }

    function downloadCsv() {
        getLatestTrialBillsCsv().then((csvDownloadDataAndName: FileDownloadDataAndName) => {
            downloadFileFromFileDownloadDataAndName('text/csv', csvDownloadDataAndName)
        })
    }

    const classes = useStyles()
    const theme = useTheme()

    const calculationTimeHeader = bills !== undefined && bills.length > 0 ? "Calculated at " + new Date(bills[0]?.calculatedAtDateTime).toLocaleDateString() + " " + new Date(bills[0]?.calculatedAtDateTime).toLocaleTimeString() : ""
    const billingRunIdHeader = bills !== undefined && bills.length > 0 ? "Billing Run Id: " + bills[0].billingRunReportId : ""

    return <Card>
        <CardHeader title="Latest Trial Billing Run - Fleet Bills" subheader={calculationTimeHeader + ", " + billingRunIdHeader} />
        <CardContent style={{minHeight: '65vh'}}>
                {isError ? (
                    <Typography gutterBottom style={{ textAlign: "center", color: theme.palette.warning.main }}>There was an issue
                        triggering the Trial Billing Run - please try again later </Typography>) :

                    showResults !== undefined
                        ?
                        (
                            <>
                                {mappedBills.length > 0 ?
                                    <TrialBillingRunTable bills={mappedBills} /> : <div className={classes.loader}>
                                        <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for
                                            your
                                            patience</Typography>
                                        <LinearProgress color="secondary" />
                                    </div>}
                            </>
                        )
                        :
                        (
                            <div className={classes.loader}>
                                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your
                                    patience</Typography>
                                <LinearProgress color="secondary" />
                            </div>
                        )
                }
        </CardContent>
        <CardActions>
            <Button variant="contained" color="primary"
                href={`/api/latestDraftBills/zip`}
                disabled={!triggerButtonEnabled}>{triggerButtonEnabled ? "Download All Pdfs As Zip" : "Run In Progress"}</Button>
            <Button variant="contained" color="primary" style={{ marginLeft: theme.spacing(1) }}
                onClick={downloadCsv}
                disabled={!triggerButtonEnabled}
            >{triggerButtonEnabled ? "Download Table Data As Csv" : "Run In Progress"}</Button>
            {triggerButtonEnabled ? (
                <Button variant="contained" color="primary" style={{ marginLeft: 'auto' }} onClick={async () => {
                    await triggerTrialingBillingRunClick()
                }}
                    disabled={!triggerButtonEnabled}>{triggerButtonEnabled ? "Trigger Run" : "Run In Progress"}</Button>) :

                (<Typography variant="body1" style={{ marginLeft: 'auto', color: theme.palette.success.main }}>Trial Billing Run In Progress.....please
                    refresh screen in 5mins</Typography>)}
        </CardActions>
    </Card>;
}

export { TrialBillingRunOverviewPageFleetBills }