import { ArrearsReport } from "../models/ArrearsReport"
import { getSettings } from "./SettingsService"

async function GetArrearsReport(subscriptionId: string): Promise<ArrearsReport>{
    const settings = await getSettings()
    const response = await fetch(`${settings.BillingServiceBaseUri}/subscriptions/${subscriptionId}/arrears`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET'
    })
    return await response.json()
}

export { GetArrearsReport }