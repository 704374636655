import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme, Tooltip,
    Typography,
    useTheme
} from '@mui/material'
import { ArrearsReport } from "../models/ArrearsReport"
import { GetArrearsReport } from "../services/ArrearsReportService"
import Countup from 'react-countup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton } from '@mui/lab'
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import InfoIcon from "@mui/icons-material/Info";
import { Delete } from '@mui/icons-material'
import { deleteAManualPayment } from '../services/PaymentService'

type IndividualArrearsReportProps = {
    subscriptionId: string
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: '100%',
        },
        spacer: {
            height: '5vh'
        },
        cardHeader: {
            minHeight: '80px'
        },
        arrearsCard: {
            minHeight: '100%'
        },
        cardActions: {
            justifyContent: 'center'
        },
        redTitle: {
            color: theme.palette.warning.main
        },
        greenTitle: {
            color: theme.palette.success.main,
        },
    }),
);

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableCellCurrency = withStyles((theme) => ({
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableCellDate = withStyles((theme) => ({
    body: {
        fontSize: 14,
    },
}))(TableCell);

function ArrearData(props: any) {
    const classes = useStyles()
    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                titleTypographyProps={{ variant: 'subtitle1', align: 'center', gutterBottom: true }}
                title={props.title}
                classes={props.classes}
            />
            <CardActions disableSpacing className={classes.cardActions}>
                <Typography align="center" variant="subtitle1">
                    {props.subheader[0]}
                    <Countup separator={','} decimals={2} duration={1.5} end={Number.parseFloat(props.subheader.slice(1))} />
                </Typography>
            </CardActions>
        </Card>
    )
}


const IndividualArrearsReport: React.FC<IndividualArrearsReportProps> = ({ subscriptionId }: IndividualArrearsReportProps) => {
    const classes = useStyles();
    const [arrearsReport, setArrearsReport] = useState<ArrearsReport | null>(null);
    const [paymentIdToDelete, setPaymentIdToDelete] = useState<string>("");
    const [paymentBeingDeleted, setPaymentBeingDeleted] = useState<boolean>(false);
    const deletePayment = (paymentId: string) => {
        deleteAManualPayment(paymentId).then(ok => {
            if (ok) {
                GetArrearsReport(subscriptionId)
                    .then(item => {
                        setArrearsReport(item)
                        setPaymentBeingDeleted(false)
                    })
            } else {
                setPaymentBeingDeleted(false)
                setPaymentIdToDelete(paymentId)
            }
        })
    }
    const theme = useTheme();
    useEffect(() => {
        let mounted = true;
        GetArrearsReport(subscriptionId)
            .then(item => {
                if (mounted) {
                    setArrearsReport(item)
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionId])
    return (
        <>
            <Card elevation={10} className={classes.card}>
                <CardHeader title={"Arrears for subscription: " + subscriptionId} titleTypographyProps={{ style: { fontSize: 16 } }} />
                <CardContent>
                    {

                        arrearsReport == null
                            ?
                            (
                                <Skeleton variant="rectangular" height={300} />
                            )
                            :
                            (
                                <Grid container direction="row" justifyContent="center" spacing={1}>
                                    <Grid xs={12} item>
                                        <Card>
                                            <CardHeader
                                                className={classes.cardHeader}
                                                titleTypographyProps={{ variant: 'h5', align: 'center', gutterBottom: true }}
                                                title={"Balance"}
                                                style={{ paddingTop: '0px', paddingBottom: '0px', minHeight: '40px' }}
                                            />

                                            <CardActions disableSpacing className={classes.cardActions}>

                                                <Typography align="center"
                                                    style={{ color: arrearsReport.currentBalance >= 0 ? theme.palette.success.main : theme.palette.warning.main }}
                                                    variant="h5">
                                                    {arrearsReport.currencySymbol}
                                                    <Countup separator={','} decimals={2} duration={1.5}
                                                        end={arrearsReport.currentBalance} />

                                                </Typography>
                                                <Tooltip title={arrearsReport.currentBalance >= 0 ? "Fleet Operator Has Overpaid" : "Fleet Operator Has An Outstanding Payment(s) Due"}>
                                                    <InfoIcon style={{ paddingLeft: "15px" }}
                                                        fontSize="small" />
                                                </Tooltip>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                    <Grid xs={12} sm={6} item>
                                        <ArrearData title="Total Arrears"
                                            color={
                                                arrearsReport.arrearsOlderThanArrearsPeriod === 0 ? theme.palette.success.main : theme.palette.warning.main
                                            }
                                            subheader={arrearsReport.currencySymbol + "" + arrearsReport.arrearsOlderThanArrearsPeriod}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <ArrearData title="Total Billed and Due Amount"
                                            subheader={arrearsReport.currencySymbol + "" + arrearsReport.totalBilledAmountMoreThanArrearsPeriod}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <ArrearData title="Total Payments Completed"
                                            subheader={arrearsReport.currencySymbol + "" + arrearsReport.totalPaymentsCompleted}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <ArrearData title="Total Billed Amount"
                                            subheader={arrearsReport.currencySymbol + "" + arrearsReport.totalBilledAmountOverall}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <ArrearData title="Total Payments Pending"
                                            subheader={arrearsReport.currencySymbol + "" + arrearsReport.totalPaymentsPending}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <ArrearData title="Total Payments Failed"
                                            subheader={arrearsReport.currencySymbol + "" + arrearsReport.totalPaymentsFailed}
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography style={{ fontSize: 16 }} >Billing & Payment Info</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell>Month</StyledTableCell>
                                                                <StyledTableCell>Date</StyledTableCell>
                                                                <StyledTableCell>Description</StyledTableCell>
                                                                <StyledTableCell>Amount</StyledTableCell>
                                                                <StyledTableCell>Balance</StyledTableCell>
                                                                <StyledTableCell>Delete</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {arrearsReport.billingAndPaymentInfoTable.slice().map((row) => (
                                                                <TableRow style={{ backgroundColor: row.rowPositionInTable % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper }} key={row.rowPositionInTable + '' + row.subPositionInRow}>
                                                                    {
                                                                        <>
                                                                            <StyledTableCell >
                                                                                {row.month}
                                                                            </StyledTableCell>
                                                                            <StyledTableCellDate>
                                                                                {new Date(row.date).toLocaleDateString()}
                                                                            </StyledTableCellDate>
                                                                            <StyledTableCell>
                                                                                {row.description}
                                                                            </StyledTableCell>
                                                                            <StyledTableCellCurrency>
                                                                                {arrearsReport.currencySymbol + row.amount.toFixed(2)}
                                                                            </StyledTableCellCurrency>
                                                                            <StyledTableCellCurrency>
                                                                                {row.balance !== null ? arrearsReport.currencySymbol + row.balance.toFixed(2) : ''}
                                                                            </StyledTableCellCurrency>
                                                                            <StyledTableCell>
                                                                                {row.paymentId && <IconButton onClick={(event) => setPaymentIdToDelete(row.paymentId ?? "")}><Delete color="primary" /></IconButton>}
                                                                            </StyledTableCell>
                                                                        </>
                                                                    }
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            )
                    }
                </CardContent>
            </Card>
            <Modal open={!!paymentIdToDelete} onClose={() => setPaymentIdToDelete("")}>
                <Card style={{ width: 400, top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)' }}>
                    <CardHeader title="Are you sure you want to delete this payment?" />
                    <CardActions style={{ float: 'right' }}>
                        <Button variant="contained" color="secondary" onClick={() => setPaymentIdToDelete("")}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={() => { deletePayment(paymentIdToDelete); setPaymentBeingDeleted(true); setPaymentIdToDelete("") }}>Confirm</Button>
                    </CardActions>
                </Card>
            </Modal>
            <Modal open={paymentBeingDeleted}>
                <div style={{ width: 40, top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)' }}>
                    <CircularProgress />
                </div>
            </Modal>
        </>
    );
}

export { IndividualArrearsReport }
