import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton, InputAdornment,
    InputLabel, LinearProgress,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Address } from '../models/Address';
import { InvestorViewModel } from '../models/InvestorViewModel';
import { createInvestor, createStripeConnectAccount, createStripeFeePaymentAccount, getInvestor, getInvestorSubscriptions, getStripeConnectLink, getStripeFeePaymentAchSecret, getStripeFeePaymentLink } from '../services/InvestorService';
import { Investment } from "../models/Investment";
import { Countries } from "../data/Countries";
import { InvoicingAndPaymentCollectionMethod } from '../models/InvoicingAndPaymentCollectionMethod';
import { makeStyles, createStyles } from '@mui/styles';
import { InvestorStripeSetUpDetails } from '../models/InvestorStripeSetUpDetails';
import { getStripePublishableKey } from '../services/PaymentService';
import { loadStripe } from '@stripe/stripe-js';
import { Archive, Check } from '@mui/icons-material';
import { InvestmentManagementFeeBillTable } from '../components/InvestmentManagementFeeBillTable';
import { FeeCollectionMethods } from '../models/FeeCollectionMethods';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { SubscriptionViewModel } from '../models/SubscriptionViewModel';
import { getSubscriptions } from '../services/SubscriptionService';
import { Link } from 'react-router-dom';
import { QueueOneOffInvoice } from '../components/QueueOneOffInvoice';
import { CurrencyIsoCodes } from '../models/CurrencyIsoCodes';
import InvestorUserAccessManagement from '../components/InvestorUserAccessManagement';
import {largeLabelProps} from "../props/LargeLabelProps";

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '10px'
        },        
        tooltip: {
            fontSize: "1rem",
        },
        dropDown: {
            textAlign: 'left',
            width: '100%',
            marginBottom: '10px'
        }, 
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))
const genericLabelProps = {
    style: {
        fontSize: "1.2rem",
        paddingBottom: "0.5rem",
    },
    shrink: true,
};


let stripePromise: any = null
getStripePublishableKey().then((publishableKey: string) => stripePromise = loadStripe(publishableKey))

function isReadyForStripeAccount(investorViewModel: InvestorViewModel | undefined): boolean {
    if (investorViewModel && investorViewModel.registeredAddress && investorViewModel.registeredAddress.lineOne && investorViewModel.registeredAddress.city && investorViewModel.registeredAddress.postCode && investorViewModel.name) return true
    return false
}

function isInvestorUsaBased(investorViewModel: InvestorViewModel | null): boolean {
    return (investorViewModel && investorViewModel.registeredAddress && investorViewModel.registeredAddress.countryCode === "US") ?? false
}

const InvestorEditPage: React.FC = ({ match }: any) => {

    let history = useHistory();
    const classes = useStyles()
    const [investorViewModel, setInvestorViewModel] = useState<InvestorViewModel | null>(null)
    const [stripeConnectedAccountSetupUrl, setStripeConnectedAccountSetupUrl] = useState<string | null>(null)
    const [stripeConnectedAccountId, setStripeConnectedAccountId] = useState<string | undefined>(undefined)
    const [copied, setCopied] = useState<boolean>(false)
    const [stripeFeePaymentAccountSetupUrl, setStripeFeePaymentAccountSetupUrl] = useState<string | null>(null)
    const [stripeFeePaymentAchDirectDebitSecret, setStripeFeePaymentAchDirectDebitSecret] = useState<string | null>(null)
    const [displayUsaAchConfirmation, setDisplayUsaAchConfirmation] = useState<boolean>(false)
    const [achSetupSucceeded, setAchSetupSucceeded] = useState<boolean>(false)
    const [stripeFeePaymentAccountId, setStripeFeePaymentAccountId] = useState<string | undefined>(undefined)
    const [feePaymentAccountSetUpLinkCopied, setFeePaymentAccountSetUpLinkCopied] = useState<boolean>(false)
    const [showWelcomeEmailSentMessage, setShowWelcomeEmailSentMessage] = useState<boolean>(false)
    const [allSubscriptionFeesPaidByOperator, setAllSubscriptionFeesPaidByOperator] = useState<boolean>(false)
    const [allSubscriptions, setAllSubscriptions] = useState<SubscriptionViewModel[]>([]);

    const onClickGetLink = (investorId: string) => {
        getStripeConnectLink(investorId)
            .then((stripeUrl) => {
                setStripeConnectedAccountSetupUrl(stripeUrl)
            })
    }

    const onClickCreateStripeAccount = (investorId: string) => {
        createStripeConnectAccount(investorId)
            .then((setUpDetails: InvestorStripeSetUpDetails) => {
                setStripeConnectedAccountId(setUpDetails.connectedAccountId)
                if (setUpDetails.sentWelcomeEmail) setShowWelcomeEmailSentMessage(true)
            })
    }

    const onClickCopyFeePaymentAccountLink = (url: string) => {
        navigator.clipboard.writeText(url)
        setFeePaymentAccountSetUpLinkCopied(true)
    }

    const onClickGetFeePaymentAccountLink = (investorId: string) => {
        if (isInvestorUsaBased(investorViewModel)) {
            getStripeFeePaymentAchSecret(investorId).then((stripeAchSecret: string) => {
                setStripeFeePaymentAchDirectDebitSecret(stripeAchSecret)
            })
        } else {
            getStripeFeePaymentLink(investorId)
                .then((stripeUrl) => {
                    setStripeFeePaymentAccountSetupUrl(stripeUrl)
                })
        }
    }

    const onClickCreateFeePaymentStripeAccount = (investorId: string) => {
        createStripeFeePaymentAccount(investorId)
            .then((accountId) => {
                setStripeFeePaymentAccountId(accountId)
            })
    }

    const onClickCopy = (url: string) => {
        navigator.clipboard.writeText(url)
        setCopied(true)
    }

    const onClickStripeAchSetup: (clientSecret: string) => void = (clientSecret: string) => {
        stripePromise.then((stripe: any) => {
            stripe?.collectBankAccountForSetup({
                clientSecret: clientSecret,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {
                            name: investorViewModel?.name ?? "",
                            email: investorViewModel?.contactEmail ?? "",
                        },
                    },
                },
                expand: ['payment_method'],
            })
                .then(({ setupIntent, error }: any) => {
                    if (error) {
                        // PaymentMethod collection failed for some reason.
                    } else if (setupIntent?.status === 'requires_payment_method') {
                        // Customer canceled the hosted verification modal. Present them with other
                        // payment method type options.
                    } else if (setupIntent?.status === 'requires_confirmation') {
                        // We collected an account - possibly instantly verified, but possibly
                        // manually-entered. Display payment method details and mandate text
                        // to the customer and confirm the intent once they accept
                        // the mandate.
                        setDisplayUsaAchConfirmation(true)
                    }
                });
        })

    }



    function confirmAch(clientSecret: string) {
        stripePromise.then((stripe: any) => {
            stripe?.confirmUsBankAccountSetup(clientSecret)
                .then(({ setupIntent, error }: any) => {
                    if (error) {
                        console.error(error.message);
                        // The payment failed for some reason.
                    } else if (setupIntent?.status === "requires_payment_method") {
                    } else if (setupIntent?.status === "succeeded") {
                        setAchSetupSucceeded(true)
                    } else if (setupIntent?.next_action?.type === "verify_with_microdeposits") {
                        // Display a message to consumer with next steps (consumer waits for
                        // microdeposits, then enters a statement descriptor code on a page sent to them via email).
                    }
                    setDisplayUsaAchConfirmation(false)
                });
        });
    }

    const onSubscriptionSelect = (event: SelectChangeEvent<string[]>) => {
        if (investorViewModel === null) return;

        const { target: { value }, } = event;

        var newInvestments: Investment[] = [];
        (value as string[]).forEach((id: string) => {
            var investment: Investment = { subscriptionId: id }
            newInvestments.push(investment)
        })

        setInvestorViewModel({ ...investorViewModel, investments: newInvestments });
    }

    useEffect(() => {
        let mounted = true;
        getInvestor(match.params.investorId)
            .then(item => {
                if (mounted) {
                    setInvestorViewModel(item)
                    getInvestorSubscriptions(match.params.investorId).then(subscriptions => {
                        var subFeesPaidByInvestor = subscriptions.filter(sub => sub.feeCollectionMethod === FeeCollectionMethods.ServiceFeeAndTelematicsOnInvestorBill)
                        if (subFeesPaidByInvestor.length === 0) setAllSubscriptionFeesPaidByOperator(true)
                    })
                }
            })
        getSubscriptions()
            .then(subs => {
                if (mounted) {
                    setAllSubscriptions(subs);
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id])

    let accountLink = "https://dashboard.stripe.com/connect/accounts/" + (stripeConnectedAccountId == null ? investorViewModel?.stripeConnectedAccountId : stripeConnectedAccountId)
    let feePaymentAccountLink = "https://dashboard.stripe.com/customers/" + (stripeFeePaymentAccountId == null ? investorViewModel?.stripeConnectedAccountId : stripeConnectedAccountId)

    const onClickEdit = () => {
        if (investorViewModel === null) return;
        createInvestor(investorViewModel).then((investorId) => history.push("/investors/" + investorId))
    }, countriesDropDown: any[] = [];

    Countries.forEach(country => {
        countriesDropDown.push(<MenuItem value={country.IsoCode}>{country.Name}</MenuItem>)
    })

    const currenciesDropDown: any[] = Object.values(CurrencyIsoCodes).map(currency => <MenuItem value={currency}>{currency}</MenuItem>);

    const toArchive = `/investors/archive/${match.params.investorId}`

    return investorViewModel === null ? <>
        <div className={classes.loader}>
            <Typography variant="subtitle1" gutterBottom>Please Wait</Typography>
            <LinearProgress color="secondary"/>
        </div>
        </> : <>
        <Grid
            style={{ marginBottom: '20px', marginTop: '20px' }}
            justifyContent="space-between"
            container
        >
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={"Lender: " + investorViewModel?.name ?? ""}
                        subheader="View/Edit a Lender"
                    />
                    <CardActions>
                        <Button
                            component={Link}
                            to={toArchive}
                            variant="contained"
                            color="warning"
                            style={{ marginRight: 16, marginLeft: 'auto' }}
                            startIcon={<Archive />}
                        >
                            Archive
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => onClickEdit()}>Update</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item>

            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="stretch" spacing={5}>
            <Grid item xs={12} sm={6}>
                <Card elevation={10} style={{ marginBottom: "20px" }} >
                    <CardHeader titleTypographyProps={{ style: { fontSize: 16 } }} title="Stripe Account for collecting Direct Debits from Asset Operators" />
                    <CardContent>
                        {
                           
                                investorViewModel?.stripeConnectedAccountId || stripeConnectedAccountId
                                    ?
                                    (
                                        <Grid container direction="row" spacing={5}>
                                            <Grid item>
                                                <Button color="primary" variant="contained" href={accountLink}>View Profile On Stripe</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button color="primary" variant="contained" onClick={() => onClickGetLink(investorViewModel?.id ?? "")} disabled={!!stripeConnectedAccountSetupUrl}>Generate Stripe Account Setup Link</Button>
                                            </Grid>
                                            {showWelcomeEmailSentMessage ?
                                                <Grid item>
                                                    <Typography>Welcome email sent to investor</Typography>
                                                </Grid>
                                                :
                                                <></>
                                            }
                                            <Grid item>
                                                {
                                                    stripeConnectedAccountSetupUrl
                                                        ?
                                                        (
                                                            <>
                                                                <div style={{ maxWidth: "30vw" }}>
                                                                    <Typography style={{ lineHeight: '35px' }} noWrap>{stripeConnectedAccountSetupUrl}</Typography>
                                                                </div>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                            </>
                                                        )
                                                }

                                            </Grid>
                                            <Grid item>
                                                {
                                                    stripeConnectedAccountSetupUrl
                                                        ?
                                                        (
                                                            <IconButton
                                                                style={{ marginTop: '-10px' }}
                                                                aria-label="copy"
                                                                onClick={() => onClickCopy(stripeConnectedAccountSetupUrl ?? "")}
                                                                size="large">
                                                                {
                                                                    copied
                                                                        ?
                                                                        (
                                                                            <DoneAllIcon />
                                                                        )
                                                                        :
                                                                        (
                                                                            <FileCopyIcon />
                                                                        )
                                                                }
                                                            </IconButton>
                                                        )
                                                        : (
                                                            <>
                                                            </>
                                                        )
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <>
                                            <Typography>Please note, setting up a Stripe account (below) will also send a welcome email to this investor.</Typography>
                                            <Button variant="contained" color="primary" onClick={() => onClickCreateStripeAccount(investorViewModel?.id ?? "")}>Create Account On Stripe</Button>
                                        </>
                                    )

                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card elevation={10} style={{ marginBottom: "20px" }} >
                    <CardHeader titleTypographyProps={{ style: { fontSize: 16 } }} title="Stripe Account for Paying Zeti Fees" />
                    <CardContent>
                        {
                            investorViewModel == null
                                ?
                                (
                                    <Skeleton variant="rectangular" height={50} />
                                )
                                :
                                investorViewModel.stripeFeePaymentCustomerId || stripeFeePaymentAccountId
                                    ?
                                    (
                                        <Grid container direction="row" spacing={5}>
                                            <Grid item>
                                                <Button color="primary" variant="contained" href={feePaymentAccountLink}>View Profile On Stripe</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button color="primary" variant="contained" onClick={() => onClickGetFeePaymentAccountLink(investorViewModel?.id ?? "")} disabled={!!stripeFeePaymentAccountSetupUrl}>Setup Payment Details</Button>
                                            </Grid>
                                            <Grid item>
                                                {
                                                    stripeFeePaymentAccountSetupUrl
                                                        ?
                                                        (
                                                            <>
                                                                <div style={{ maxWidth: "30vw" }}>
                                                                    <Typography style={{ lineHeight: '35px' }} noWrap>{stripeFeePaymentAccountSetupUrl}</Typography>
                                                                </div>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                            </>
                                                        )
                                                }

                                            </Grid>
                                            <Grid item>
                                                {
                                                    stripeFeePaymentAchDirectDebitSecret ?
                                                        !achSetupSucceeded
                                                            ?
                                                            (
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={() => onClickStripeAchSetup(stripeFeePaymentAchDirectDebitSecret)}>Authorize ACH</Button>
                                                            )
                                                            :
                                                            (
                                                                <Check style={{ height: '100%' }} color="primary" />
                                                            )
                                                        :
                                                        (
                                                            <></>
                                                        )
                                                }
                                                <Dialog
                                                    open={displayUsaAchConfirmation}
                                                    onClose={() => setDisplayUsaAchConfirmation(false)}
                                                    aria-labelledby="ach-confirmation-title"
                                                    aria-describedby="ach-confirmation-description"
                                                >
                                                    <DialogTitle id="ach-confirmation-title">
                                                        {"Confirm Zeti's ACH permission"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="ach-confirmation-description">
                                                            By clicking accept, you authorize Zeti, Inc. to debit the bank account specified above for any amount owed for charges arising from your use of Zeti, Inc.’s services and/or purchase of products from Zeti, Inc., pursuant to Zeti, Inc.’s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Zeti, Inc. with 30 (thirty) days notice. If you use Zeti, Inc.’ services or purchase additional products periodically pursuant to Zeti, Inc.’ terms, you authorize Zeti, Inc. to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => setDisplayUsaAchConfirmation(false)}>Cancel</Button>
                                                        <Button onClick={() => confirmAch(stripeFeePaymentAchDirectDebitSecret ?? "")} autoFocus>
                                                            Accept
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                                {
                                                    stripeFeePaymentAccountSetupUrl
                                                        ?
                                                        (
                                                            <IconButton
                                                                style={{ marginTop: '-10px' }}
                                                                aria-label="copy"
                                                                onClick={() => onClickCopyFeePaymentAccountLink(stripeFeePaymentAccountSetupUrl ?? "")}
                                                                size="large">
                                                                {
                                                                    feePaymentAccountSetUpLinkCopied
                                                                        ?
                                                                        (
                                                                            <DoneAllIcon />
                                                                        )
                                                                        :
                                                                        (
                                                                            <FileCopyIcon />
                                                                        )
                                                                }
                                                            </IconButton>
                                                        )
                                                        : (
                                                            <>
                                                            </>
                                                        )
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    isReadyForStripeAccount(investorViewModel)
                                        ?
                                        (
                                            <>
                                                <Button variant="contained" color="primary" onClick={() => onClickCreateFeePaymentStripeAccount(investorViewModel?.id ?? "")}>Create Fee Payment Account On Stripe</Button>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Tooltip title="You need to set up the investor's basic details (trading name, address line one, city, county and postcode) before you can set up their stripe account. Please go to the investor edit screen to complete this">
                                                    <Button variant="contained" color="secondary">Create Fee Payment Account On Stripe</Button>
                                                </Tooltip>
                                            </>
                                        )

                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={6} style={{ marginBottom: "20px" }}>
                {
                    !allSubscriptionFeesPaidByOperator ?                         
                            (
                                <InvestmentManagementFeeBillTable investorViewModel={investorViewModel} />
                            )
                        :
                        null
                }
            </Grid>
        </Grid>
        <Grid item spacing={5}>
            <QueueOneOffInvoice investorViewModel={investorViewModel} key={"one-off-invoice-" + investorViewModel.id} />
        </Grid>
        <Grid container spacing={5}>
            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader titleTypographyProps={{ style: { fontSize: 16 } }} title="Basic Account Info" />
                    <CardContent>
                        <Grid container spacing={5} style={{ marginBottom: '10px' }}>
                            <Grid xs={12} md={6} item>
                                <TextField
                                    style={{ width: '100%' }}
                                    label="Lender ID"
                                    disabled={true}
                                    InputLabelProps={genericLabelProps}
                                    variant="standard"
                                    value={investorViewModel?.id}
                                />
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <TextField
                                    style={{ width: '100%' }}
                                    label="Short ID"
                                    disabled={true}
                                    InputLabelProps={genericLabelProps}
                                    variant="standard"
                                    value={investorViewModel?.shortId}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            style={{ width: '100%', marginBottom: '10px' }}
                            label="Welcome email sent?"
                            disabled={true}
                            InputLabelProps={genericLabelProps}
                            variant="standard"
                            value={investorViewModel?.welcomeEmailSent ? 'Yes' : 'No'}
                        />
                        <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title="Please do not edit without discussion with team"
                            >
                            <TextField style={{paddingBottom: "10px"}}
                                className={classes.textField}
                                label="Company Number"
                                variant="standard"
                                InputLabelProps={genericLabelProps}                            
                                value={investorViewModel?.companyNumber}
                                onChange={(event) => {
                                    setInvestorViewModel({ ...investorViewModel, companyNumber: event.target.value})
                                }}
                            />
                        </Tooltip>

                        <TextField style={{width: "100%"}}
                                   variant="standard"
                                   label="Tax Rate Charged On Fees"
                                   InputLabelProps={largeLabelProps}
                                   InputProps={{
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               %
                                           </InputAdornment>
                                       ),
                                   }}
                                   value={investorViewModel.vmTaxRateChargedOnFees}
                                   onChange={(e) => setInvestorViewModel({...investorViewModel, vmTaxRateChargedOnFees: e.target.value as unknown as number})}
                        />


                        <InputLabel id="SelectCountryLabel" htmlFor="selectCountry">Country</InputLabel>
                        <Select className={classes.dropDown}
                            labelId="SelectCountryLabel"
                            id="SelectCountry"
                            variant="standard"
                            value={investorViewModel?.registeredAddress?.countryCode ?? "GB"}
                            onChange={(event) => {
                                let address: Address = {
                                    ...investorViewModel.registeredAddress,
                                    countryCode: (event.target.value as string)
                                }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        >
                            {countriesDropDown}

                        </Select>

                        <InputLabel id="SelectPrimaryCurrencyLabel" htmlFor="selectPrimaryCurrency">Currency</InputLabel>
                        <Select className={classes.dropDown}
                            labelId="SelectPrimaryCurrencyLabel"
                            id="SelectPrimaryCurrency"
                            variant="standard"
                            value={investorViewModel?.primaryCurrencyIsoCode ?? CurrencyIsoCodes.Gbp}
                            onChange={(event) => {
                                setInvestorViewModel({ ...investorViewModel, primaryCurrencyIsoCode: event.target.value as string })
                            }}
                        >
                            {currenciesDropDown}

                        </Select>

                        <TextField
                            className={classes.textField}
                            label="Lender Company Name"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel.name}
                            onChange={(event) => setInvestorViewModel({ ...investorViewModel, name: event.target.value })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Address Line One"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.lineOne}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, lineOne: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="Address Line Two"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.lineTwo}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, lineTwo: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            label={investorViewModel?.registeredAddress?.countryCode === "US" ? "Zip Code" : "Post Code"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.postCode}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, postCode: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="City"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.city}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, city: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label={investorViewModel?.registeredAddress?.countryCode === "US" ? "State" : "County"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.county}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, county: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="Email"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel.contactEmail}
                            onChange={(event) => setInvestorViewModel({ ...investorViewModel, contactEmail: event.target.value })}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={6} item direction='column'>
                <InvestorUserAccessManagement investorViewModel={investorViewModel} setInvestor={setInvestorViewModel} />
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="stretch" spacing={5}>
            <Grid item xs={12} sm={6}>
                <Card elevation={10} style={{ marginBottom: '20px' }}>
                    <CardHeader titleTypographyProps={{ style: { fontSize: 16 } }} title="Investment Info" />
                    <CardContent>
                        <Select
                            variant="standard"
                            style={{ width: "100%" }}
                            labelId="subscriptionId-multiple-checkbox-label"
                            id="subscriptionId-multiple-checkbox"
                            multiple
                            value={investorViewModel.investments?.map((x: Investment) => x.subscriptionId) ?? []}
                            onChange={onSubscriptionSelect}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {allSubscriptions?.map((subscription: SubscriptionViewModel) =>
                                <MenuItem key={subscription.id} value={subscription.id}>
                                    <Checkbox checked={investorViewModel.investments?.map(x => x.subscriptionId).indexOf(subscription.id) > -1} />
                                    <ListItemText primary={subscription.id} />
                                </MenuItem>
                            )}
                        </Select>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card elevation={10} style={{ marginBottom: '20px' }}>
                    <CardHeader titleTypographyProps={{ style: { fontSize: 16 } }} title="Billing Information" />
                    <CardContent>
                        <TextField
                            className={classes.textField}
                            label="Billing Email Address (To enter multiple recipients, please separate with a comma)"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel.contactEmail}
                            onChange={(event) => setInvestorViewModel({ ...investorViewModel, contactEmail: event.target.value })}
                        />
                        <TextField
                            type="number"
                            className={classes.textField}
                            variant="standard"
                            
                            label="Direct Debit Day of Month For Fees"
                            disabled
                            InputLabelProps={genericLabelProps}                                
                            value={22}                              
                        />
                        <InputLabel id="invoicing-label">Invoicing and Payment Collection Method</InputLabel>
                        <Select
                            style={{
                                width: "100%",
                                "marginBottom": "10px"
                            }}
                            value={investorViewModel.investmentManagementFeeInvoicingAndPaymentCollectionMethod}
                            inputProps={{
                                name: "Invoicing and Payment Collection Method",
                                style: {
                                    "width": "100%",
                                    "marginBottom": "10px"
                                }
                            }}
                            onChange={(e) => setInvestorViewModel({ ...investorViewModel, investmentManagementFeeInvoicingAndPaymentCollectionMethod: e?.target?.value as InvoicingAndPaymentCollectionMethod })}
                            labelId="invoicing-label"
                            id="InvoicingMethodSelect"
                            variant="standard"
                        >
                            <MenuItem value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment}>System generated invoice and system-collected payment (e.g. Direct Debit)</MenuItem>
                            <MenuItem value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceManualPayment}>System generated Invoice, Manual Payment (e.g. Bank Transfer)</MenuItem>
                            <MenuItem value={InvoicingAndPaymentCollectionMethod.OffSystem}>Off System Invoice and Payments (e.g. manually generated invoice paid by Bank Transfer)</MenuItem>
                        </Select>
                       
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>;
}

export { InvestorEditPage }
