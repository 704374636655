import { Theme } from "@mui/material";

function GetAssetOperatorStatusTextColor(status: string | undefined, theme: Theme): string {
    switch (status) {
        case "Active":
            return theme.palette.success.main
        case "Inactive":
            return theme.palette.warning.main
        case "Demo":
            return theme.palette.info.main
        default:
            return theme.palette.text.primary
    }
}

export { GetAssetOperatorStatusTextColor };