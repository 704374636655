class RegisteredCountry {
    IsoCode!: string
    Name!: string

    constructor(isoCode: string, name: string) {
        this.IsoCode = isoCode;
        this.Name = name;

    }
}

export {RegisteredCountry};