import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, LinearProgress, TextField, Theme, Typography, useTheme } from "@mui/material";
import { SubscriptionViewModel } from "../models/SubscriptionViewModel";
import { makeStyles, createStyles } from '@mui/styles';
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { genericLabelProps } from "../props/GenericLabelProps";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MinimumUsagePeriod } from "../models/MinimumUsagePeriod";
import { CreateNewMinimumUsagePeriodsModal } from "./CreateNewMinimumUsagePeriodsModal";
import { addDays, endOfDay } from "date-fns";
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteMinimumUsagePeriodRowModal } from "./DeleteMinimumUsagePeriodRowModal";
import { calculateMinimumUsageDates } from "../services/SubscriptionService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    })
);

interface SixMonthMinimumUsageProps {
    subscription?: SubscriptionViewModel
    setSubscription: Function
    setUsagePeriodUpdatePending: Function
    usagePeriodUpdatePending: boolean
}

type SixMonthMinimumUsageRow = {
    id: number
    period: number
    startDate: Date|undefined
    endDate: Date|undefined
    minimumUsage: number|undefined
  }

function SixMonthMinimumUsage({subscription, setSubscription, setUsagePeriodUpdatePending, usagePeriodUpdatePending}: SixMonthMinimumUsageProps): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    const [totalMinimumUsageAmount, setTotalMinimumUsageAmount] = useState<number>(subscription?.minimumUsagePeriods?.map(minimumUsagePeriod => minimumUsagePeriod.minimumUsage).reduce((usageSum, currentUsage) => usageSum + currentUsage, 0) ?? 0);
    const [numberOfMinimumUsagePeriods, setNumberOfMinimumUsagePeriods] = useState<number>(subscription?.minimumUsagePeriods?.length ?? 0);
    const [defaultMinimumUsageValue, setDefaultMinimumUsageValue] = useState<number>(0);
    const [rowData, setRowData] = useState<SixMonthMinimumUsageRow[]>([]);
    const [createNewMinimumUsagePeriodsModalOpen, setCreateNewMinimumUsagePeriodsModalOpen] = useState<boolean>(false);
    const [deleteMinimumUsagePeriodRowModalOpen, setDeleteMinimumUsagePeriodRowModalOpen] = useState<boolean>(false);
    const [rowIdToDelete, setRowIdToDelete] = useState<number|undefined>(undefined);
    const [validationWarning, setValidationWarning] = useState<boolean>(false);
    const [validationWarningsList, setValidationWarningsList] = useState<string[]>([]);

    const dateFormat = "dd/MM/yy";

    useEffect(() => {
        validate(rowData)
        // eslint-disable-next-line
    }, [subscription?.maximumEndDate, subscription?.billingStartDate, rowData])

    const updateMinimumUsages = (id: number, e: ChangeEvent<HTMLInputElement>) => {
        let value = !!e.target.value ? Number(e.target.value) : undefined
        var rowDataCopy = [...rowData];
        rowDataCopy.filter(x => x.id === id)[0].minimumUsage = value;
        setRowData(rowDataCopy)
        setSubscription({...subscription, minimumUsagePeriods: rowDataCopy as unknown as MinimumUsagePeriod[]})

        calculateTotalOfMinimumUsagePeriods(rowDataCopy)
        if(!usagePeriodUpdatePending) setUsagePeriodUpdatePending(true)
    }

    function updatePeriodStartDates(id: number, value: string): void {
        let dateValue = !!value ? new Date(value) : undefined
        var rowDataCopy = [...rowData];

        var midnight = undefined;
        //use 1am so that it's always on the right day despite timezones
        if (typeof (dateValue) != 'undefined') midnight = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), 1, 0, 0)

        rowDataCopy.filter(x => x.id === id)[0].startDate = midnight;
        setRowData(rowDataCopy)
        setSubscription({...subscription, minimumUsagePeriods: rowDataCopy as unknown as MinimumUsagePeriod[]})
        
        if(!usagePeriodUpdatePending) setUsagePeriodUpdatePending(true)
    }

    function updatePeriodEndDates(id: number, value: string): void {
        let dateValue = !!value ? new Date(value) : undefined
        var rowDataCopy = [...rowData];

        var midnight = undefined;
        //use 1am so that it's always on the right day despite timezones
        if (typeof (dateValue) != 'undefined') midnight = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), 1, 0, 0)

        rowDataCopy.filter(x => x.id === id)[0].endDate = midnight;
        setRowData(rowDataCopy)
        setSubscription({...subscription, minimumUsagePeriods: rowDataCopy as unknown as MinimumUsagePeriod[]})
        
        if(!usagePeriodUpdatePending) setUsagePeriodUpdatePending(true)
    }

    function mapDataToRows(minimumUsagePeriods: MinimumUsagePeriod[]){
        var mappedData = minimumUsagePeriods.map((period, index) => {
            return {
                id: index,
                period: index,
                startDate: period.startDate,
                endDate: period.endDate,
                minimumUsage: period.minimumUsage
            }
        })
        setRowData(mappedData as unknown as SixMonthMinimumUsageRow[])
    }

    function validate(rows: SixMonthMinimumUsageRow[]) {
        if(rows.length <= 1) {
             setValidationWarning(false)
             setValidationWarningsList([])
        }

        var validationWarnings: string[] = [];
        for(var index = 1; index < rows.length; index++){
            var period = rows[index];
            var indexToShowUser = index + 1;
            var previousIndex = index-1;
            var previousPeriod = rows[previousIndex];
            var previousIndexToShowUser = previousIndex + 1;

            if(index === 1 && previousPeriod.startDate && subscription?.billingStartDate
                && (new Date(previousPeriod.startDate).getFullYear() !== new Date(subscription?.billingStartDate).getFullYear()
                || new Date(previousPeriod.startDate).getMonth() !== new Date(subscription?.billingStartDate).getMonth()
                || new Date(previousPeriod.startDate).getDate() !== new Date(subscription?.billingStartDate).getDate())){
                validationWarnings.push('Period 1\'s start date is not the contract\'s billing start date.');
            }

            if(period.startDate && previousPeriod.endDate && new Date(period.startDate) <= new Date(previousPeriod.endDate)){
                validationWarnings.push('Period '  + previousIndexToShowUser + '\'s end date overlaps with period ' + indexToShowUser + '\'s start date.')
            }

            if(period.startDate && period.endDate && new Date(period.startDate) >= new Date(period.endDate)){
                validationWarnings.push('Period '  + indexToShowUser + '\'s start date is later than its end date.')
            }
            
            if(period.startDate && previousPeriod.endDate && new Date(period.startDate) > addDays(new Date(previousPeriod.endDate),1)){
                validationWarnings.push('There is a gap between period '  + previousIndexToShowUser + '\'s end date and period ' + indexToShowUser + '\'s start date.')
            }

            if(period.endDate && subscription?.maximumEndDate && (endOfDay(new Date(period.endDate)) > endOfDay(new Date(subscription?.maximumEndDate)))){
                validationWarnings.push('Period ' + indexToShowUser + '\'s end date is after the contract maximum end date.')
            }
        }

        if(validationWarnings.length > 0) setValidationWarning(true)
        setValidationWarningsList(validationWarnings)
    }

    function calculateTotalOfMinimumUsagePeriods(rows: SixMonthMinimumUsageRow[]) {
        let totalMinimumUsageAmount = 0;
        rows.forEach(row => {
            totalMinimumUsageAmount += row.minimumUsage ?? 0
        });
        setTotalMinimumUsageAmount(totalMinimumUsageAmount)
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if(subscription?.minimumUsagePeriods){
                mapDataToRows(subscription.minimumUsagePeriods)
            } else {
                setRowData([])
            }
        }
    
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns: any[] = [
        {
            field: 'period',
            headerName: 'Period',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return (
                    <>{params.row.period + 1}</>
                )
            }
        },
        {
            field: 'periodStartDate',
            headerName: 'Period Start Date',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            key={'periodStartDate-' + params.row.id}
                            inputFormat={dateFormat}
                            onChange={(newValue: any) => updatePeriodStartDates(params.row.id, newValue as string)}
                            value={rowData.filter(x => x.id === params.row.id)[0].startDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                )
            }
        },
        {
            field: 'periodEndDate',
            headerName: 'Period End Date',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            key={'periodEndDate-' + params.row.id}
                            inputFormat={dateFormat}
                            onChange={(newValue: any) => updatePeriodEndDates(params.row.id, newValue as string)}
                            value={rowData.filter(x => x.id === params.row.id)[0].endDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                )
            }
        },
        {
            field: 'minimumUsage',
            headerName: 'Minimum Mileage',
            type: 'number',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return <TextField
                    key={'minimumUsage-' + params.row.id}
                    type="number"
                    error={rowData.filter(x => x.id === params.row.id)[0].minimumUsage === undefined}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => updateMinimumUsages(params.row.id, event)}
                    value={rowData.filter(x => x.id === params.row.id)[0].minimumUsage === undefined ? '' : rowData.filter(x => x.id === params.row.id)[0].minimumUsage}
                />
              }
        },
        {
            field: 'deleteRow',
            headerName: '',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return <IconButton onClick={() => onDeleteRow(params.row.id)}>
                    <DeleteIcon color="primary"/>
                </IconButton>
              }
        },
    ]

    function onCreateClick(): void {
        setCreateNewMinimumUsagePeriodsModalOpen(true)
    }

    function onDeleteRow(rowId: number): void {
        setRowIdToDelete(rowId)
        setDeleteMinimumUsagePeriodRowModalOpen(true)
    }

    function deleteMinimumUsageRow(rowId: number): void{
        var rowDataCopy = [...rowData];
        rowDataCopy.splice(rowId, 1);
        for(var rowIndex = 0; rowIndex < rowDataCopy.length; rowIndex++){
            rowDataCopy[rowIndex].id = rowIndex
            rowDataCopy[rowIndex].period = rowIndex
        }
        setRowData(rowDataCopy)
        setSubscription({...subscription, minimumUsagePeriods: rowDataCopy as unknown as MinimumUsagePeriod[]})
        calculateTotalOfMinimumUsagePeriods(rowDataCopy)
    }

    function createNewMinimumUsagePeriods(){
        let numberOfRowsToCreate = numberOfMinimumUsagePeriods;
        createXNewMinimumUsageRows(numberOfRowsToCreate)
        
        if(!usagePeriodUpdatePending) setUsagePeriodUpdatePending(true)
    }

    function createXNewMinimumUsageRows(numberOfRowsToCreate: number) {
        if(!subscription) return [];

        let newMinUsageRows: any[] = [];
        calculateMinimumUsageDates(subscription.billingStartDate, numberOfRowsToCreate).then(dates => {
            for(var count = 0; count < dates.length; count++){
                var newRow = {
                    id: count,
                    period: count,
                    startDate: dates[count].startDate,
                    endDate: dates[count].endDate,
                    minimumUsage: Number(defaultMinimumUsageValue)
                }
                newMinUsageRows = [...newMinUsageRows, newRow]
            }

            setRowData(newMinUsageRows as unknown as SixMonthMinimumUsageRow[])
            setSubscription({...subscription, minimumUsagePeriods: newMinUsageRows as unknown as MinimumUsagePeriod[]})
            calculateTotalOfMinimumUsagePeriods(newMinUsageRows)
        })
    }

    function createNewMinimumUsageRow() {
        let today = new Date()
        var rowDataCopy = [...rowData];
        var totalExistingRows = rowDataCopy.length
        var lastExistingRowEndDate = totalExistingRows > 0 ? rowDataCopy[totalExistingRows - 1].endDate : new Date(today.getFullYear(), today.getMonth(), today.getUTCDate(), 1, 0, 0)
        rowDataCopy.push({
            id: totalExistingRows,
            period: totalExistingRows,
            startDate: lastExistingRowEndDate,
            endDate: lastExistingRowEndDate,
            minimumUsage: 0
        })
        setRowData(rowDataCopy)
        setSubscription({...subscription, minimumUsagePeriods: rowDataCopy as unknown as MinimumUsagePeriod[]})
        calculateTotalOfMinimumUsagePeriods(rowDataCopy)
    }

    return (
        subscription ?
        (<Card>
            <CardHeader title="Six Month Minimum Mileage Management"></CardHeader>
            <CreateNewMinimumUsagePeriodsModal
                setCreateNewMinimumUsagePeriodsModalOpen={setCreateNewMinimumUsagePeriodsModalOpen}
                createNewMinimumUsagePeriodsModalOpen={createNewMinimumUsagePeriodsModalOpen}
                createNewMinimumUsagePeriods={createNewMinimumUsagePeriods}
            />
            {rowIdToDelete !== undefined ?
                <DeleteMinimumUsagePeriodRowModal
                    setDeleteMinimumUsagePeriodRowModalOpen={setDeleteMinimumUsagePeriodRowModalOpen}
                    deleteMinimumUsagePeriodRowModalOpen={deleteMinimumUsagePeriodRowModalOpen}
                    rowIdToDelete={rowIdToDelete}
                    deleteMinimumUsageRow={deleteMinimumUsageRow}
                />
                :
                null
            }
            <CardContent>
                <Grid container>
                    <Card elevation={2} style={{ marginBottom: "15px", width: "100%" }}>
                        <CardContent>
                            <Grid container>
                                <Grid xs={6} item>
                                    <Typography variant="subtitle1" align="right">Total Contracted Usage</Typography>
                                </Grid>
                                <Grid xs={6} item style={{ "borderBottom": "black solid 1px" }}>
                                    <Typography variant="body1"
                                        align="right">{Number(subscription.totalContractedUsage).toLocaleString()}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={6} item>
                                    <Typography variant="subtitle1" align="right">Total of Minimum Usage Periods</Typography>
                                </Grid>
                                <Grid xs={6} item style={{ "borderBottom": "black solid 1px" }}>
                                    <Typography variant="body1"
                                        align="right">{Number(totalMinimumUsageAmount).toLocaleString()}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={6} item>
                                    {totalMinimumUsageAmount !== subscription.totalContractedUsage ?
                                        <Typography variant="subtitle1"
                                            color="error"
                                            align="right">Difference</Typography> : null
                                    }
                                </Grid>
                                <Grid xs={6} item>
                                    {totalMinimumUsageAmount !== subscription.totalContractedUsage ?
                                        <Typography variant="body1"
                                            color="error"
                                            align="right">{(totalMinimumUsageAmount - subscription.totalContractedUsage).toLocaleString()}</Typography> : null
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card elevation={2} style={{ marginBottom: "15px", width: "100%" }}>
                        <CardContent>
                            <Grid container>
                            <Grid container direction="column" spacing={3} width="50%">
                                <Grid xs={3} item width="100%" >
                                    <TextField
                                        variant="standard"
                                        label="How many six-monthly minimum mileage periods are needed for this contract?"
                                        InputLabelProps={genericLabelProps}
                                        fullWidth
                                        type="number"
                                        value={numberOfMinimumUsagePeriods}
                                        onChange={(e) => setNumberOfMinimumUsagePeriods(e.target.value as unknown as number)}
                                    />
                                </Grid>
                                <Grid xs={3} item width="100%" >
                                    <TextField
                                        variant="standard"
                                        label="What is the six-monthly minimum usage?"
                                        InputLabelProps={genericLabelProps}
                                        fullWidth
                                        type="number"
                                        value={defaultMinimumUsageValue}
                                        onChange={(e) => setDefaultMinimumUsageValue(e.target.value as unknown as number)}
                                    />
                                </Grid>
                                <Grid xs={3} item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{marginTop: '10px'}}
                                        onClick={() => onCreateClick()}>
                                        Create
                                    </Button>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={4} style={{ "textAlign": "left" }}>
                                        {(usagePeriodUpdatePending) ?
                                            <Typography variant="caption" color="secondary">
                                                Please click submit above to save the changes
                                            </Typography>
                                            : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{ height: "100%", marginLeft: '10px' }} width="50%" >
                                    {(validationWarning) ?
                                            (<Fragment>
                                                <Grid item xs={12}></Grid>
                                                <Grid item xs={12} style={{ "textAlign": "left" }} width="100%">
                                                    <TextField label="Warnings"
                                                        inputProps={{ style: { color: theme.palette.warning.main } }}
                                                        color="error"
                                                        multiline
                                                        minRows={4}
                                                        maxRows={4}
                                                        value={validationWarningsList.join('\n')}
                                                        fullWidth>
                                                    </TextField>
                                                </Grid>
                                            </Fragment>)
                                        : null
                                    }
                            </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card elevation={2} style={{ marginBottom: "15px", width: "100%", height: 500 }}>
                        <CardContent>
                            <DataGrid
                                style={{height: '400px'}}
                                rows={rowData}
                                columns={columns}
                                getRowHeight={() => 'auto'}
                            />
                        </CardContent>
                        <CardActions disableSpacing style={{justifyContent: 'right', marginRight: '10px'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => createNewMinimumUsageRow()}>
                                Add new row
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </CardContent>
        </Card>)
        :
        (
            <div className={classes.loader}>
                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                <LinearProgress color="secondary" />
            </div>
        )
    )
}

export { SixMonthMinimumUsage };
