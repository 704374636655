import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Input, InputLabel, Link, MenuItem, Modal, Select, SelectChangeEvent, Theme, Tooltip, Typography, useTheme } from "@mui/material"
import { DataGrid, GridColumnHeaderParams } from "@mui/x-data-grid"
import InfoIcon from '@mui/icons-material/Info';
import { useCallback, useEffect, useState } from "react";
import { getAllVehicleSpecs } from "../services/AssetsService";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { MarketValueMatrix } from "../models/MarketValueMatrix";
import { getMarketValueMatrices, uploadMarketValueMatrix } from "../services/MarketValueMatrixService";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                height: "100%",
                overflow: 'scroll',
            },
            [theme.breakpoints.up('md')]: {
                width: "20%",
            }
        }
    }));

const CurrentMarketValueMatrixUpload: React.FC = props => {
    const [files, setFiles] = useState<File[]>([])
    const [rowData, setRowData] = useState<any[]>([])
    const [allVehicleSpecs, setAllVehicleSpecs] = useState<string[]>([])
    const [selectedVehicleSpec, setSelectedVehicleSpec] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalErrorText, setModalErrorText] = useState<string>('');
    const [currentlyUploading, setCurrentlyUploading] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string>("GBP");
    const [containsTotalUsageRatherThanAnnualisedUsage, setContainsTotalUsageRatherThanAnnualisedUsage] = useState<boolean>(false);
    const [source, setSource] = useState<string>("Glass");
    const [msrpValue, setMsrpValue] = useState<number | undefined>(undefined)
    const theme = useTheme()
    const classes = useStyles();

    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

    const mapSubscriptionToRow = useCallback((matrix: MarketValueMatrix) => {
        return {
            id: matrix.id,
            vehicle: matrix.vehicleSpecification,
            currency: matrix.currency,
            matrixFile: matrix.uploadedFileName,
            source: matrix.source,
            msrpValue: msrpValue,
            uploaded: matrix.uploadedAt,
            outOfDate: (new Date().getTime() - new Date(matrix.uploadedAt).getTime()) > thirtyDaysInMilliseconds
        }
    }, [thirtyDaysInMilliseconds, msrpValue])

    const makeRows = useCallback((matrices: MarketValueMatrix[]) => {
        return matrices.map(mapSubscriptionToRow);
    }, [mapSubscriptionToRow])

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setIsLoading(true)
            getAllVehicleSpecs().then(specs => setAllVehicleSpecs(specs));
            getMarketValueMatrices().then(matrices => {
                setRowData(makeRows(matrices))
                setIsLoading(false)
            })
            return () => {
                mounted = false;
            };
        }
    }, [makeRows])

    function handleSelectedVehicleSpec(e: SelectChangeEvent) {
        setSelectedVehicleSpec(e.target.value)
    }

    const columns: any[] = [
        {
            field: 'vehicle',
            headerName: 'Vehicle',
            width: 200,
            flex: 1.5
        },
        {
            field: 'matrixFile',
            headerName: 'Matrix File',
            flex: 2,
            renderCell: (params: any): JSX.Element => {
                return <Link href={"/api/marketvalues/matrix/" + params.row.id}>{params.row.matrixFile}</Link>
            }
        },
        {
            field: 'uploaded',
            headerName: 'Uploaded',
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title={"Dates in orange require for an updated market value matrix"}>
                    <Grid container>
                        <Grid item>
                            Uploaded
                        </Grid>
                        <Grid item>
                            <InfoIcon style={{ marginBottom: "-5px", paddingLeft: "5px" }} fontSize="small" />
                        </Grid>
                    </Grid>
                </Tooltip>
            ),
            renderCell: (params: any): JSX.Element => {
                return <Typography style={{ fontSize: '14px', color: params.row.outOfDate ? theme.palette.warning.main : theme.palette.text.primary }}>
                    {params.row.uploaded == null ? '' : new Date(params.row.uploaded).toLocaleDateString()}
                    {params.row.outOfDate ? <ReportProblemIcon style={{ maxHeight: '14px' }} /> : null}
                </Typography>
            }
        },
    ]

    function uploadMatrix(): void {
        if (!selectedVehicleSpec) return;
        setCurrentlyUploading(true)
        uploadMarketValueMatrix({
            id: "",
            vehicleSpecification: selectedVehicleSpec,
            uploadedFileName: "string",
            uploadedAt: new Date().toISOString(),
            source: source,
            containsTotalUsageRatherThanAnnualisedUsage: containsTotalUsageRatherThanAnnualisedUsage,
            msrpValue: msrpValue,
            currency: currency
        }, files[0]).then((response) => {
            if (response !== 'OK') {
                setModalErrorText(response);
            } else {
                setModalOpen(false)
                setFiles([])
                setIsLoading(true)
                getMarketValueMatrices().then(matrices => {
                    setSelectedVehicleSpec('')
                    setRowData(makeRows(matrices))
                    setIsLoading(false)
                }).finally(() => { setIsLoading(false) })
            }
            setCurrentlyUploading(false)
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Card>
                    <CardHeader title="Upload Market Value Matrix" />
                    <CardContent>
                        <InputLabel id="market-value-matrix-vehicle-specification-label">Vehicle Specification</InputLabel>
                        <Select
                            labelId="market-value-matrix-vehicle-specification-label"
                            id="market-value-matrix-vehicle-specification-simple-select"
                            value={selectedVehicleSpec}
                            onChange={handleSelectedVehicleSpec}
                            disabled={allVehicleSpecs.length === 0}
                            style={{ width: '100%' }}
                        >
                            {allVehicleSpecs.map(spec => {
                                return (
                                    <MenuItem key={spec} value={spec}>{spec}</MenuItem>
                                )
                            })}
                        </Select>
                        <Select
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            style={{ width: '100%', marginTop: '1rem' }}
                        >
                            <MenuItem value={"GBP"}>GBP</MenuItem>
                            <MenuItem value={"USD"}>USD</MenuItem>
                        </Select>

                        <Select
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
                        >
                            <MenuItem value={"Glass"}>Glass</MenuItem>
                            <MenuItem value={"CAP"}>CAP</MenuItem>
                            <MenuItem value={"Combined CAP and Glass"}>Combined CAP and Glass</MenuItem>
                            <MenuItem value={"Black Book"}>Black Book</MenuItem>
                            <MenuItem value={"Kelley Blue Book"}>Kelley Blue Book</MenuItem>
                            <MenuItem value={"J.D. Power"}>J.D. Power</MenuItem>
                        </Select>
                        MSRP Value:    <Input type="number" value={msrpValue} onChange={(event) => setMsrpValue(Number.parseFloat(event.target.value))}></Input>
                        <br />
                        Contains total usage rather than annualised usage<Checkbox checked={containsTotalUsageRatherThanAnnualisedUsage} onChange={(e) => setContainsTotalUsageRatherThanAnnualisedUsage(e.target.checked)} />
                        <Typography>Your files need to be in a certain format for us to be able to read them. The format you should use for this is a .csv with the topleft cell containing a 0, then age in months across the top row and mileage down the first column, below the 0. Please do not format the cells to contain currency symbols.</Typography>
                        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                            <Card className={classes.modal}>
                                <Grid container direction="row" justifyContent="space-between">
                                    <CardHeader title="Confirm Upload" />
                                    <IconButton onClick={() => setModalOpen(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <CardContent>
                                    <Typography>Uploaded Matrix</Typography>
                                    <Typography variant="caption" style={{ textOverflow: "ellipsis", overflow: "hidden", width: '50px', whiteSpace: 'nowrap' }}>
                                        {
                                            files.map(file => file.name).join(", ")
                                        }
                                    </Typography>

                                </CardContent>
                                <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                                    <LoadingButton variant="contained"
                                        onClick={() => uploadMatrix()}
                                        disabled={!selectedVehicleSpec}
                                        loading={currentlyUploading}>
                                        Submit
                                    </LoadingButton>

                                    {modalErrorText ?
                                        <Typography style={{ color: theme.palette.error.main }}>{modalErrorText}</Typography>
                                        : null}
                                </CardActions>
                            </Card>
                        </Modal>
                    </CardContent>
                    <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
                        <Typography variant="caption" style={{ marginRight: theme.spacing(1), textOverflow: "ellipsis", overflow: "hidden", whiteSpace: 'nowrap', textAlign: 'right' }}>
                            {
                                files.map(file => file.name).join(", ")
                            }
                        </Typography>
                        <Button
                            variant="contained"
                            component="label" >
                            <FileUploadIcon /> Choose File
                            <input
                                onChange={event => setFiles(Array.from(event.target.files ?? []))}
                                type="file"
                                accept="text/csv"
                                hidden
                            />
                        </Button>

                        <Button variant="contained"
                            onClick={() => setModalOpen(true)}
                            disabled={!selectedVehicleSpec || files.length === 0}
                            style={{ marginLeft: theme.spacing(1) }}>
                            Upload Matrix
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card>
                    <CardHeader title="Existing Market Value Matrices" />
                    <CardContent>
                        <DataGrid style={{ height: '400px' }} columns={columns} rows={rowData} loading={isLoading} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
export { CurrentMarketValueMatrixUpload }