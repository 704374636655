import { ExternalContract } from "../models/ExternalContract"
import { ExternalContractRow } from "../models/ExternalContractRow"

const createOrUpdateExternalContract = async (externalContract: ExternalContract, alreadyExists: boolean): Promise<ExternalContract> => {
    let path = `/api/externalcontracts`
    if(alreadyExists) path = `/api/externalcontracts/${externalContract.id}`
    const options = { method: alreadyExists ? 'PUT' : 'POST', body: JSON.stringify(externalContract)}
    const response = await fetch(path, options)
    const responseObject = await response.json()
    return responseObject
}

const getAllExternalContractRows = async (): Promise<ExternalContractRow[]> => {
    const path = `/api/externalcontracts`
    const options = { method: 'GET'}
    const response = await fetch(path, options)
    const responseObject = await response.json()
    return responseObject
}

const getAnExternalContract = async (id: string): Promise<ExternalContract> => {
    const path = `/api/externalcontracts/${id}`
    const options = { method: 'GET'}
    const response = await fetch(path, options)
    const responseObject = await response.json()
    return responseObject
}

export { createOrUpdateExternalContract, getAllExternalContractRows, getAnExternalContract}