import React from "react";
import { Button, CardContent, CardHeader, Theme, Typography } from "@mui/material";
import { SubscriptionViewModel } from "../models/SubscriptionViewModel";
import { makeStyles, createStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ConvertAssetOperatorIdToName, ConvertInvestorIdToName, ConvertSubscriptionIdToName } from "../helpers/ConvertSubscriptionIdsToNames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      width: '100%',
      textAlign: 'center'
    }
  })
);

const columns: GridColDef[] = [
  { field: 'col1', headerName: 'Contract', flex: 1 },
  { field: 'col2', headerName: 'Asset Operator Name', flex: 1, renderCell: (params: any): JSX.Element => {
    return (
        <>
          <Typography component={Link} to={`/assetoperators/${params.value}/edit`} color="primary">{ConvertAssetOperatorIdToName(params.value)}</Typography>
        </>
    )
} },
  { field: 'col3', headerName: 'Lender Name', flex: 1, renderCell: (params: any): JSX.Element => {
    var investorIds: string[] = params.value;
    var firstInvestorId = investorIds[0];
    var remainingInvestorIds = investorIds.slice(1);

    return (
        <>
          <Typography component={Link} to={"/investors/edit/" + firstInvestorId} color="primary">{ConvertInvestorIdToName(firstInvestorId)}</Typography>
          {remainingInvestorIds.map((investorId: string) => 
          <>
          {",\u00A0"}
          <Typography component={Link} to={"/investors/edit/" + investorId} color="primary">{ConvertInvestorIdToName(investorId)}</Typography>
          </>
          )}
        </>
    )
} },
  { field: 'col4', headerName: 'Assets Financed', flex: 1 },
  { field: 'col5', headerName: 'Details', flex: 1, renderCell: (params: any): JSX.Element => {
    return (
        <>
        <Button variant="contained" component={Link} color="primary" to={"/subscriptions/" + params.value}>Manage</Button>
        </>
    )
}, }

];

const mapSubscriptionToRow = (subscription: SubscriptionViewModel, i: number) => {
  return {
      id: i,
      col1: ConvertSubscriptionIdToName(subscription.id),
      col2: subscription.assetOperatorId,
      col3: subscription.investorIds,
      col4: subscription.assets?.filter(asset => !asset.isArchived).length ?? 0,
      col5: subscription.id
  }
}

type SubscriptionTableProps = {
    subscriptions: SubscriptionViewModel[];
};

const SubscriptionTable: React.FC<SubscriptionTableProps> = ({
  subscriptions
}: SubscriptionTableProps) => {
    const classes = useStyles();

    const makeRows = (subscriptions: SubscriptionViewModel[]) => {
      const alphabeticallySortedSubs = subscriptions.sort((a, b) => {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      })
      const soloTradersAtBottom = alphabeticallySortedSubs.filter((x) => !x.id.startsWith("SUBTBO")).concat(alphabeticallySortedSubs.filter((x) => x.id.startsWith("SUBTBO")))
      const rows = soloTradersAtBottom.map(mapSubscriptionToRow)
      return rows;
    }

    
  return (
    <>
    <CardHeader title="Contracts" />
      <CardContent>
      <Box height="500px">
                {
                    subscriptions.length > 0
                        ?
                        (
                            <DataGrid rows={makeRows(subscriptions)} columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }} />
                        )
                        :
                        (
                            <div className={classes.loader}>
                                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                <LinearProgress color="secondary" />
                            </div>
                        )
                }
            </Box>
      </CardContent>
    </>
  );
};

export default SubscriptionTable;
