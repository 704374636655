import {
    Box,
    Button,
    LinearProgress,
    Theme,
    Typography,
    Card,
    CardHeader,
    CardContent,
    CardActions
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { InvestorViewModel } from '../models/InvestorViewModel'
import { getInvestors } from '../services/InvestorService'
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { makeStyles, createStyles } from '@mui/styles';

const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Name', flex: 1 },
    { field: 'col2', headerName: 'ID', flex: 1 },
    {
        field: 'col3', headerName: 'View', width: 100, renderCell: (params: any): JSX.Element => {
            let toEdit = `/investors/edit/${params.value}`
            return (
                <>
                    <Button
                        component={Link}
                        to={toEdit}
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginRight: 16 }}
                    >
                        View
                    </Button>
                </>
            )
        },
    }
];

const mapInvestorToRow = (investorViewModel: InvestorViewModel, i: number) => {
    return {
        id: i,
        col1: investorViewModel.name,
        col2: investorViewModel.id,
        col3: investorViewModel.id
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))

const InvestorListingPage: React.FC = () => {
    const [investorViewModels, setInvestorViewModels] = useState<InvestorViewModel[]>([])
    const classes = useStyles()
    useEffect(() => {
        let mounted = true;
        getInvestors()
            .then(items => {
                if (mounted) {
                    setInvestorViewModels(items)
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <Card>
        <CardHeader title="Lenders" />
        <CardContent>
            <Box height="500px">
                {
                    investorViewModels.length > 0
                        ?
                        (
                            <DataGrid rows={investorViewModels.map(mapInvestorToRow)} columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }} />
                        )
                        :
                        (
                            <div className={classes.loader}>
                                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                <LinearProgress color="secondary" />
                            </div>
                        )
                }
            </Box>
        </CardContent>
        <CardActions>
            <Button style={{ marginLeft: 'auto' }} variant="contained" component={Link} color="primary" to={"investors/create"}>Create</Button>
        </CardActions>
    </Card>;
}

export { InvestorListingPage }
