function convertFromTonnesToGrams(numberInTonnesAsString:string|undefined): string|undefined {
    if(numberInTonnesAsString === undefined || numberInTonnesAsString === "") return numberInTonnesAsString;
    let numberInTonnes = numberInTonnesAsString as unknown as number;
    return (numberInTonnes * 1000000) as unknown as string;
}

function convertFromGramsToTonnes(numberInGramsAsString:string|undefined): string|undefined {
    if(numberInGramsAsString === undefined || numberInGramsAsString === "") return numberInGramsAsString;
    let numberInGrams = numberInGramsAsString as unknown as number;
    return (numberInGrams / 1000000) as unknown as string;
}

export {convertFromTonnesToGrams, convertFromGramsToTonnes}