import { VehicleClass } from "../models/VehicleClass"



async function searchVehicleClasses(make: string, model: string, trim: string, year: string): Promise<VehicleClass[]> {
    let url = "/api/residualvalues/vehicleClasses?make=" + make + "&model=" + model + "&trim=" + trim + "&year=" + year
    let response = await fetch(url)
    if(response.ok){
        let vehicleClasses = await response.json()
        return vehicleClasses;
    } else {
        throw new Error("HTTP error: " + response.status)
    }
}

export { searchVehicleClasses }