import {Button, Card, CardActions} from "@mui/material";
import React, { useEffect, useState } from "react";
import SubscriptionTable from "../components/SubscriptionTable";
import { SubscriptionViewModel } from "../models/SubscriptionViewModel";
import {getSubscriptions} from "../services/SubscriptionService";
import {Link} from "react-router-dom";

const SubscriptionsListingPage: React.FC = ({ match }: any) => {
  const [subscriptions, setSubscrptions] = useState<SubscriptionViewModel[]>(
    []
  );

  useEffect(() => {
    let mounted = true;
    getSubscriptions().then((item) => {
      if (mounted) {
        setSubscrptions(item);
      }
    });

      return () => {
      mounted = false;
    };
  }, [match.params]);

  return (
    <Card>
      <SubscriptionTable
        subscriptions={subscriptions}
      />
      <CardActions>
        <Button style={{ marginLeft: 'auto' }} variant="contained" component={Link} color="primary" to={"draftSubscriptions/create"}>Create Draft</Button>
      </CardActions>
    </Card> 
  );
};
export { SubscriptionsListingPage };
