import { Card, CardContent, CardHeader, Grid, InputAdornment, InputLabel, Select, TextField, Typography, Tooltip, SelectChangeEvent, MenuItem } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Address } from '../models/Address';
import { AssetOperator} from '../models/AssetOperator';
import {InvoicingAndPaymentCollectionMethod} from "../models/InvoicingAndPaymentCollectionMethod";
import {CompanyType} from "../models/CompanyType";
import {genericLabelProps} from "../props/GenericLabelProps";
import {AssetOperatorStatus} from "../models/AssetOperatorStatus";
import { makeStyles,createStyles } from '@mui/styles';
import { Countries } from '../data/Countries';

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '10px'
        },
        tooltip: {
            fontSize: "1rem",
        },
        dropDown: {
            textAlign: 'left',
            width: '100%',
            marginBottom: '10px'
        }
    }))

interface AssetOperatorCreateProps {
    assetOperator: AssetOperator
    setAssetOperator: React.Dispatch<React.SetStateAction<AssetOperator>>
    setMandatoryAssetOperatorFieldsFilled: React.Dispatch<React.SetStateAction<boolean>>
}

function AssetOperatorCreate({assetOperator, setAssetOperator, setMandatoryAssetOperatorFieldsFilled}: AssetOperatorCreateProps): JSX.Element {
    const [preferredDebitDayError, setPreferredDebitDayError] = useState<boolean>(false)
    const classes = useStyles()

    const countriesDropDown: any[] = [];

    Countries.forEach(country => {
        countriesDropDown.push(<MenuItem key={country.IsoCode} value={country.IsoCode}>{country.Name}</MenuItem>)
    })
    
    const handlePreferredDirectDebitPaymentDayOfMonth = (e: ChangeEvent<HTMLInputElement>) => {
        let value = !!e.target.value ? Number(e.target.value) : undefined
        if (value !== undefined && (value < 3 || value > 28)) {
            setPreferredDebitDayError(true)
        } else {
            setPreferredDebitDayError(false)
        }

        setAssetOperator(
            { ...assetOperator, preferredDirectDebitPaymentDayOfMonth: value }
        )
    }

    useEffect(() => {
        if (assetOperatorIsReadyToReceiveWelcomeEmail(assetOperator)) {
            setMandatoryAssetOperatorFieldsFilled(true)
        } else {
            setMandatoryAssetOperatorFieldsFilled(false)
        }
        // eslint-disable-next-line
    }, [assetOperator, preferredDebitDayError])

    const emailAddressIsValid = (emailAddress: string) => {
        const firstEmailAddress = emailAddress.split(',')[0];
        const atSymbolIndex = firstEmailAddress.indexOf('@');
        if (atSymbolIndex > 0 && firstEmailAddress.substring(atSymbolIndex).includes('.')) {
            return true;
        }
        return false;
    }

    function assetOperatorIsReadyToReceiveWelcomeEmail(assetOperator: AssetOperator | null): boolean {
        if (assetOperator === null) return false;
        const directDebitDay = assetOperator.preferredDirectDebitPaymentDayOfMonth;
        const assetOperatorName = assetOperator.tradingName;
        if (directDebitDay && assetOperatorName && assetOperator.billingEmailAddress != null && assetOperator.billingEmailAddress.length > 0 && emailAddressIsValid(assetOperator.billingEmailAddress) && assetOperator.registeredAddress != null) {
            return true;
        }
        return false;
    }

    return <>
        <Grid
            style={{ marginBottom: '20px', marginTop: '20px' }}
            justifyContent="space-between"
            container
        >
        </Grid>
        <Grid container spacing={5}>
            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Basic Account Info" />
                    <CardContent>
                        <TextField
                            style={{marginBottom:'10px'}}
                            label="Asset Operator ID"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            InputProps={
                                assetOperator?.id?.substring(0, 4) === "CUST"

                                    ? {
                                        startAdornment: (
                                            <InputAdornment position="end"> </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {"CUST"}
                                            </InputAdornment>
                                        ),
                                    }
                            }
                            value={assetOperator?.id}
                            onChange={(e: { target: { value: string; }; }) => setAssetOperator({ ...assetOperator, id: e.target.value?.substring(0, 4) === "CUST" ? e.target.value : "CUST" + e.target.value })}
                        />
                        
                        <InputLabel htmlFor="SelectCountryLabel">Country</InputLabel>
                        <Select className={classes.dropDown}
                                labelId="SelectCountryLabel"
                                variant="standard"
                                id="SelectCountry"
                                value={assetOperator?.registeredAddress?.countryCode ?? "GB"}
                                onChange={(event) => {                                       
                                    let address: Address = {
                                        ...assetOperator.registeredAddress,
                                        countryCode: (event.target.value as string)
                                    }                              
                                    setAssetOperator({...assetOperator, registeredAddress: address})
                                }}
                        >                               
                            {countriesDropDown}                         

                        </Select>

                        <TextField
                            className={classes.textField}
                            label="Trading Name"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator.tradingName}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, tradingName: event.target.value })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Registered Company Name"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator.registeredCompanyName}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, registeredCompanyName: event.target.value })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Company Number"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator.companyNumber}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, companyNumber: event.target.value })}
                        />

                        {assetOperator?.registeredAddress?.countryCode === "US" ? 
                            <></>
                            :
                            <TextField
                                className={classes.textField}
                                label="VAT Number"
                                variant="standard"
                                hidden
                                InputLabelProps={genericLabelProps}
                                value={assetOperator.vatNumber}
                                onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, vatNumber: event.target.value })}
                            />
                        }

                        <TextField
                            className={classes.textField}
                            label="Telephone Number"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator.phoneNumber}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, phoneNumber: event.target.value })}
                        />
                        <InputLabel id="company-type-label">Company Type</InputLabel>
                        <Select
                            className={classes.dropDown}
                            variant="standard"
                            id="SelectCompanyType"
                            value={assetOperator.companyType?.toString()}
                            inputProps={{
                                name: "Company Type",
                                style: {
                                    "width": "100%"
                                }
                            }}
                            onChange={(e: SelectChangeEvent) => setAssetOperator({ ...assetOperator, companyType: Number.parseInt(e?.target?.value) as CompanyType })}
                            labelId="company-type-label"
                        >
                            <MenuItem value={CompanyType.Fleet}>Fleet</MenuItem>
                            <MenuItem value={CompanyType.SoleTrader}>Sole Trader</MenuItem>
                        </Select>

                        <InputLabel id="status-label" style={{marginTop: "10px"}}>Status</InputLabel>
                        <Select
                            className={classes.dropDown}
                            value={assetOperator.status}  
                            variant="standard"   
                            id="SelectStatus"                           
                            inputProps={{
                                name: "Status",
                                style: {
                                    "width": "100%"
                                }
                            }}
                            onChange={(e: SelectChangeEvent) => setAssetOperator({...assetOperator, status: e?.target?.value })}
                            labelId="status-label"
                        >
                            <MenuItem value={AssetOperatorStatus.Active}>{AssetOperatorStatus.Active}</MenuItem>
                            <MenuItem value={AssetOperatorStatus.Inactive}>{AssetOperatorStatus.Inactive}</MenuItem>
                            <MenuItem value={AssetOperatorStatus.Demo}>{AssetOperatorStatus.Demo}</MenuItem>
                        </Select>                           
                    </CardContent>
                </Card>
            </Grid>

            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Registered Address" />
                    <CardContent>
                        <TextField
                            className={classes.textField}
                            label="Address Line One"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.lineOne}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, lineOne: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="Address Line Two"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.lineTwo}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, lineTwo: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            label={assetOperator?.registeredAddress?.countryCode === "US" ? "Zip Code" : "Post Code"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.postCode}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, postCode: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="City"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.city}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, city: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label={assetOperator?.registeredAddress?.countryCode === "US" ? "State" : "County"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.county}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, county: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Billing Information" />
                    <CardContent>
                        <TextField
                            className={classes.textField}
                            label="Billing Email Address (To enter multiple recipients, please separate with a comma)"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator.billingEmailAddress}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, billingEmailAddress: event.target.value })}
                        />
                        <Tooltip
                            classes={{tooltip: classes.tooltip}}
                            title="Please note changing this value will not change the repayment dates of any active payment plan on this account"
                        >
                            <TextField
                                type="number"
                                className={classes.textField}
                                variant="standard"
                                error={preferredDebitDayError}
                                helperText={preferredDebitDayError ? "Please enter a number from 4 to 17" : ""}
                                label="Preferred Direct Debit Day of Month (4th - 17th)"
                                InputLabelProps={genericLabelProps}
                                InputProps={{ inputProps: { min: 4, max: 17 } }}
                                value={assetOperator.preferredDirectDebitPaymentDayOfMonth ?? ""}
                                onChange={handlePreferredDirectDebitPaymentDayOfMonth}
                            />
                        </Tooltip>

                        <InputLabel id="invoicing-label">Invoicing and Payment Collection Method</InputLabel>
                        <Select
                            style={{
                                width: "100%",
                                "marginBottom": "10px"
                            }}
                            variant="standard"
                            value={assetOperator.invoicingAndPaymentCollectionMethod?.toString()}
                            inputProps={{
                                name: "Invoicing and Payment Collection Method",
                                style: {
                                    "width": "100%",
                                    "marginBottom": "10px"
                                }
                            }}
                            onChange={(e: SelectChangeEvent) => setAssetOperator({ ...assetOperator, invoicingAndPaymentCollectionMethod: Number.parseInt(e?.target?.value) as InvoicingAndPaymentCollectionMethod })}
                            labelId="invoicing-label"
                            id="SelectInvoicingMethod"
                        >
                            <MenuItem value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment}>System generated invoice and system-collected payment (e.g. Direct Debit)</MenuItem>
                            <MenuItem value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceManualPayment}>System generated Invoice, Manual Payment (e.g. Bank Transfer)</MenuItem>
                            <MenuItem value={InvoicingAndPaymentCollectionMethod.OffSystem}>Off System Invoice and Payments (e.g. manually generated invoice paid by Bank Transfer)</MenuItem>
                        </Select>                    
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader title="User Access Control" />
                    <CardContent>
                        <Typography>Please use comma seperated values with no spaces, e.g. mickey@disney.com,minnie@disney.com,pluto@disney.com</Typography>
                        <TextField
                            className={classes.textField}
                            label="Read/Write Admin Email Addresses"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.adminIds?.join(",")}
                            onChange={(event: { target: { value: string; }; }) => setAssetOperator({ ...assetOperator, adminIds: event.target.value.split(",") })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Read Only User Email Addresses"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.userIds?.join(",")}
                            onChange={(event: { target: { value: string; }; }) => setAssetOperator({ ...assetOperator, userIds: event.target.value.split(",") })}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>;

}

export { AssetOperatorCreate }