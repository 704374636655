import { Card, CardActions, CardHeader, Grid, Theme, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ConvertPaymentStatusToString } from '../../helpers/ConvertPaymentStatusToString';
import { ConvertCurrencyToSymbol } from '../../helpers/ConvertCurrencyToSymbol';
import Countup from 'react-countup'
import { GetPaymentStatusTextColor } from '../../helpers/GetPaymentStatusTextColor';
import { InvestmentManagementFeePayment } from '../../models/InvestmentManagementFeePayment';
import { getInvestmentManagementFeePayment } from '../../services/InvestmentManagementFeePaymentService';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: '100%',
        },
        spacer: {
            height: '5vh'
        },
        cardHeader: {
            minHeight: '80px'
        },
        cardActions: {
            justifyContent: 'center'
        },
    }),
);

function PaymentData(props: any) {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                titleTypographyProps={{ variant: 'subtitle1', align: 'center', gutterBottom: true }}
                title={props.title}
                classes={props.classes}
            />
            <CardActions disableSpacing className={classes.cardActions}>
                <Typography align="center" style={{ color: props.color || theme.palette.text.primary }} variant="h5">
                    {props.subheader == null ? '-' : props.subheader}
                    <Countup separator={','} decimals={2} duration={1.5} end={Number.parseFloat(props.subheader)} />
                </Typography>
            </CardActions>
        </Card>
    )
}

const InvestmentManagementFeePaymentPage: React.FC = ({ match }: any) => {
    const [payment, setPayment] = useState<InvestmentManagementFeePayment | undefined>(undefined);
    useEffect(() => {
        let mounted = true;
        getInvestmentManagementFeePayment(match.params.paymentId)
            .then(payment => {
                if (mounted) {
                    setPayment(payment)
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.paymentId])
    const theme = useTheme()
    const classes = useStyles()
    return (
        <>
            <Card className={classes.card} style={{ marginBottom: theme.spacing(2) }}>
                <CardHeader title={payment?.dateTimePaymentCreated == null ? null : new Date(payment.dateTimePaymentCreated).toLocaleDateString() + " Investment Management Fee Payment for " + payment?.investorId ?? "..."} />
            </Card>
            <Grid container direction="row" alignItems="stretch" spacing={1}>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Payment ID"
                        subheader={payment?.id}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Status"
                        color={GetPaymentStatusTextColor(ConvertPaymentStatusToString(payment?.status), theme)}
                        subheader={payment?.status == null ? null : ConvertPaymentStatusToString(payment.status)}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Lender ID"
                        subheader={payment?.investorId}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Date Charged"
                        subheader={payment?.dateTimePaymentCharged == null ? null : new Date(payment.dateTimePaymentCharged).toLocaleDateString()}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Target Payment Date"
                        subheader={payment?.targetPaymentDate == null ? null : new Date(payment.targetPaymentDate).toLocaleDateString()}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Amount Charged"
                        subheader={payment?.amountCharged == null ? null : payment?.currencyIsoCode == null ? null : ConvertCurrencyToSymbol(payment.currencyIsoCode) + payment?.amountCharged?.toLocaleString()}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Amount Received After Fees"
                        subheader={payment?.amountReceivedAfterFees == null ? null : payment?.currencyIsoCode == null ? null : ConvertCurrencyToSymbol(payment.currencyIsoCode) + payment?.amountReceivedAfterFees?.toLocaleString()}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Stripe Payment ID"
                        subheader={payment?.stripePaymentId}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Payment Type"
                        subheader={payment?.paymentType}
                    />
                </Grid>
                <Grid xs={12} sm={6} item>
                    <PaymentData title="Previously Failed Attempts"
                        subheader={payment?.previouslyFailedAttempts?.join(',')}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export { InvestmentManagementFeePaymentPage }