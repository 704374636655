import {FeeCollectionMethods} from "../models/FeeCollectionMethods";

function ConvertFeeCollectionMethodTypeToLabel(feeCollectionMethod: FeeCollectionMethods): string {
    switch (feeCollectionMethod) {
        case FeeCollectionMethods.ServiceFeeAndTelematicsOnAssetOperatorBill:
            return "Service Fee and Telematics on Asset Operator Bill";
        case FeeCollectionMethods.ServiceFeeAndTelematicsOnInvestorBill:
            return "Service Fee and Telematics on Investor Bill";
        default:
            return "";
    }
}

export {ConvertFeeCollectionMethodTypeToLabel};
