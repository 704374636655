import { ResidualValueMatrix } from "../models/ResidualValueMatrix";

async function uploadAndSaveResidualValueMatrix(subscriptionId: string, residualValueMatrix: ResidualValueMatrix, file: File): Promise<Response> {
    const formData = new FormData();
    formData.append('matrixFile', file);
    formData.append("make", residualValueMatrix.make)
    formData.append("model", residualValueMatrix.model)
    formData.append("trim", residualValueMatrix.trim)
    formData.append("yearOrVersion", residualValueMatrix.yearOrVersion)
    formData.append("guaranteed", (residualValueMatrix?.guaranteed ?? false).toString())
    formData.append("sourceCompany", residualValueMatrix.sourceCompany)
    formData.append("guaranteeCompany", residualValueMatrix.guaranteeCompany)
    const response = await fetch(`/api/subscriptions/${subscriptionId}/residualvaluematrices`, {
        method: 'POST',
        body: formData
    })
    return response;
}

export { uploadAndSaveResidualValueMatrix }