import { subscriptionIdsToNames } from "../App"

function ConvertSubscriptionIdToName(subscriptionId : string) {
    var correspondingSubscriptionIdToNameObject = subscriptionIdsToNames.find(idsToNames => idsToNames.subscriptionId === subscriptionId)
    return correspondingSubscriptionIdToNameObject?.subscriptionName ?? subscriptionId
}

function ConvertAssetOperatorIdToName(assetOperatorId : string) {
    var correspondingAssetOperatorIdToNameObject = subscriptionIdsToNames.find(idsToNames => idsToNames.assetOperatorId === assetOperatorId)
    return correspondingAssetOperatorIdToNameObject?.assetOperatorName ?? assetOperatorId
}

function ConvertInvestorIdToName(investorId : string) {
    var correspondingInvestorIdToNameObject = subscriptionIdsToNames.find(idsToNames => idsToNames.investors.map(investor => investor.id).includes(investorId))
    return correspondingInvestorIdToNameObject?.investors.find(investor => investor.id === investorId)?.name ?? investorId
}

export { ConvertSubscriptionIdToName, ConvertAssetOperatorIdToName, ConvertInvestorIdToName }