import { Bill } from '../models/Bill'
import {Box, Card, CardContent, CardHeader, IconButton, Typography, useTheme} from '@mui/material'
import { ConvertCurrencyToSymbol } from '../helpers/ConvertCurrencyToSymbol';
import { ConvertBillStatusToString } from '../helpers/ConvertBillStatusToString';
import { GetBillStatusTextColor } from '../helpers/GetBillStatusTextColor';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import GetAppIcon from '@mui/icons-material/GetApp';
import {GetBillPaymentStatusTextColor} from "../helpers/GetBillPaymentStatusTextColor";


type BillTableProps = {
    bills: Bill[]
}


const mapBillToRow = (bill: Bill, i: number) => {
    var currencySymbol = ConvertCurrencyToSymbol(bill.currencyIsoCode);
    var billingPeriod = new Date(bill.billingDate).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' })    
    
    return {
        id: i,
        billPeriod: billingPeriod,
        amountBilled: currencySymbol + bill.totalPaymentLine.net.toLocaleString(),
        billStatus: ConvertBillStatusToString(bill.billStatus),
        billPaymentStatus: bill.billPaymentStatus,
        viewButton: JSON.stringify(bill)
    }
}

function getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
}

function BillTable({ bills }: BillTableProps): JSX.Element {
    const theme = useTheme()
    const columns: GridColDef[] = [
        { field: 'billPeriod', headerName: 'Bill Period', flex: 1 },
        { field: 'amountBilled', headerName: 'Gross Amount', flex: 1 },
        { field: 'billStatus', headerName: 'Bill Status', flex: 1, renderCell: (params: any): JSX.Element => {
            return (<Typography style={{color: GetBillStatusTextColor(params.row.billStatus, theme), fontSize: '14px'}}>{params.row.billStatus}</Typography>)
        } 
        },
        { field: 'billPaymentStatus', headerName: 'Payment Status', flex: 1, renderCell: (params: any): JSX.Element => {
            return (<Typography style={{color: GetBillPaymentStatusTextColor(params.row.billPaymentStatus, theme), fontSize: '14px'}}>{params.row.billPaymentStatus}</Typography>)
        } 
        },
        
        {
            field: 'viewButton', headerName: '', width: 150, renderCell: (params: any): JSX.Element => {
                let bill: Bill = JSON.parse(params?.value?.toString() ?? "")
                return (<>
                    {new Date(bill.billingDate) < new Date("2021-05-01") ?
                        <Tooltip title="Unable to download? Contact support@zeti.group">
                            <span>                      
                                <IconButton disabled style={{ paddingTop: "25px", alignItems: 'right' }} >
                                    <GetAppIcon fontSize="large" color="primary" style={{ paddingBottom: '10px' }} />
                                </IconButton>
                            </span>
                        </Tooltip>
                        :        
                        <IconButton style={{ paddingTop: "25px" }} href={`/api/assetoperators/${bill.assetOperatorId}/bills/${bill.id}`}>
                            <GetAppIcon fontSize="large" color="primary" style={{ paddingBottom: '10px' }} />
                        </IconButton>}
                    </>
                )
            },
        }
    ];
    return (
        <Card elevation={10}>
            <CardHeader title="Bills" titleTypographyProps={{style: {fontSize:16}}} />
            <CardContent>
  
                <Box height={300}>
                   
                    <DataGrid rows={bills?.sort((a, b) => getTime(b.billingDate) - getTime(a.billingDate))?.map(mapBillToRow) ?? []} columns={columns} />
                </Box>
            </CardContent>
        </Card>
    )
}

export { BillTable }
