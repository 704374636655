import { Button, Typography, useTheme } from "@mui/material";
import { ConvertCurrencyToSymbol } from "../../helpers/ConvertCurrencyToSymbol";
import { GetPaymentStatusTextColor } from "../../helpers/GetPaymentStatusTextColor";
import { InvestmentManagementFeePayment } from "../../models/InvestmentManagementFeePayment";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

interface AllInvestorFeePaymentsTableProps {
    payments: InvestmentManagementFeePayment[]
    onCancelClick: Function
}

const AllInvestmentManagementFeePaymentsTable: React.FC<AllInvestorFeePaymentsTableProps> = props => {
    const theme = useTheme()
    const columns: any[] = [
        { field: 'investorName', headerName: 'Lender Name', width: 200 },
        { field: 'investorId', headerName: 'Lender Id', width: 160 },
        {
            field: 'dateTimePaymentCreated',
            headerName: 'Payment Charged On',
            width: 170,
            filterable: false,
            renderCell: (params: any): string => {
                if (!params.row.dateTimePaymentCreated) return '';

                const targetDate = new Date(params.row.dateTimePaymentCreated);
                const timePart = targetDate.toLocaleTimeString();
                const datePart = targetDate.toLocaleDateString();

                return datePart + ' ' + timePart
            }
        },
        {
            field: 'currencyIsoCode', headerName: 'Currency', width: 130
        },
        {
            field: 'amountCharged',
            headerName: 'Amount Charged',
            width: 140,
            type: 'number',
            renderCell: (params: any): JSX.Element => {

                const currencySymbol = (!params.row.currencyIsoCode) ? '' : ConvertCurrencyToSymbol(params.row.currencyIsoCode);
                return (<Typography style={{ fontSize: 14, width: '100%' }}>{(params.row.amountCharged as number) === -1 ? "-" : currencySymbol + (params.row.amountCharged as number).toLocaleString(["en-gb"], { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</Typography>)
            },
        },
        { field: 'paymentType', headerName: 'Type', width: 160 },
        {
            field: 'paymentStatus', headerName: 'Status', width: 80, renderCell: (params: any): JSX.Element => {
                return (<Typography
                    style={{ fontSize: 14, color: GetPaymentStatusTextColor(params.row.paymentStatus, theme) }}>{params.row.paymentStatus}</Typography>)
            }
        },
        { field: 'arrearsManagement', headerName: 'Arrears Management', width: 180 },
        {
            field: 'detailsButton', headerName: ' ', width: 200, renderCell: (params: any): JSX.Element => {
                let toDetails = `/investmentmanagementfeepayments/details/${params.row.id}`
                return (<>
                    <Button
                        href={toDetails}
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginRight: 16 }}
                    >
                        Details
                    </Button>
                    {params.row.paymentStatus === "Queued" ?
                        <Button
                            onClick={() => props.onCancelClick(params, theme)}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: 16 }}
                        >
                            Cancel
                        </Button>
                        :
                        <></>
                    }
                </>
                )
            },
        }
    ];

    return (
        <div style={{ height: '63vh', width: '100%' }}>
            <DataGrid
                rows={props.payments}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                initialState={{
                    pagination:{
                        pageSize: 10
                    }
                }}
            />
        </div>
    )
}
export { AllInvestmentManagementFeePaymentsTable }