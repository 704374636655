import {
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Theme,
    Typography
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { getAllNonDraftInternalInvoices } from "../../services/BillService";
import { InternalInvoice } from "../../models/InternalInvoice";
import { createStyles, makeStyles } from '@mui/styles';
import { TrialBillingRunInternalInvoiceTable } from "../../components/TrialBillingRunInternalInvoiceTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        },
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20,
        },
        details: {
            alignItems: 'center',
        },
        column: {
            flexBasis: '33.33%',
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        }
    }))

const BillingRunOverviewPageInternalInvoices: React.FC = props => {
    const [invoices, setInvoices] = useState<InternalInvoice[]>([])
    const [mappedInvoices, setMappedInvoices] = useState<any[]>([])
    const [showResults, setShowResults] = useState<boolean>(false)
    const [selectedBillingMonth, setSelectedBillingMonth] = useState<string | undefined>(undefined);
    const [allBillingMonths, setAllBillingMonths] = useState<string[]>([]);

    const classes = useStyles()
    useEffect(() => {
        let mounted = true;
        getAllNonDraftInternalInvoices()
            .then(invoices => {
                if (mounted) setInvoices(invoices)
            })

        return () => {
            mounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const dateOrderedInvoices = invoices.sort((a, b) => new Date(b.billingPeriodEndDate).getTime() - new Date(a.billingPeriodEndDate).getTime())
        const mappedInvoices = mapInvoiceModelsToDataTableRows(dateOrderedInvoices)
        setMappedInvoices(mappedInvoices)
        const billingMonthsWithDuplicates = mappedInvoices.map(invoice => invoice.usagePeriod ?? undefined)
        const uniqueBillingMonths = billingMonthsWithDuplicates.filter((invoice, i) => invoice !== undefined && billingMonthsWithDuplicates.indexOf(invoice) === i);
        setAllBillingMonths([...uniqueBillingMonths])
        setSelectedBillingMonth(uniqueBillingMonths[0])
        if (invoices !== undefined && invoices.length > 0) {
            setShowResults(true)
        }
    }, [invoices])

    function mapInvoiceModelsToDataTableRows(invoices: InternalInvoice[]): any[] {
        const mappedInvoices = invoices.map((invoice, index) => {
            return {
                id: index,
                invoiceNumber: invoice.invoiceNumber,
                subscriptionId: invoice.subscriptionId,
                dateOfBill: invoice.billingDate,
                billingPeriodStartDate: invoice.billingPeriodStartDate,
                billingPeriodEndDate: invoice.billingPeriodEndDate,
                amount: invoice.totalPaymentLine.net,
                invoiceId: invoice.id,
                usagePeriod: new Date(invoice.billingPeriodEndDate).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }),
            }
        });

        return mappedInvoices;
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedBillingMonth(event.target.value as string);
    };

    return <Card>
        <CardHeader title="Billing Runs - Internal Invoices"
            action={
                selectedBillingMonth &&
                <FormControl style={{ minWidth: 500, marginLeft: 'auto' }}>
                    <InputLabel id="date-select-label">Usage Period</InputLabel>
                    <Select
                        labelId="date-select-label"
                        id="date-select"
                        value={selectedBillingMonth}
                        label="Usage Period"
                        onChange={handleChange}
                    >
                        {allBillingMonths.map(month => <MenuItem key={month} value={month}>{month}</MenuItem>)}
                    </Select>
                </FormControl>
            } />
        <CardContent style={{ minHeight: '65vh' }}>
            {
                showResults ?
                    <>
                        <Grid container direction='column'>
                            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>

                            </Grid>
                        </Grid>
                        {selectedBillingMonth ?
                            <>
                                <TrialBillingRunInternalInvoiceTable invoices={mappedInvoices.filter(invoice => invoice.usagePeriod === selectedBillingMonth)} />
                            </> :
                            <>
                                <TrialBillingRunInternalInvoiceTable invoices={mappedInvoices} />
                            </>
                        }
                    </>
                    :
                    <div className={classes.loader}>
                        <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                        <LinearProgress color="secondary" />
                    </div>
            }
        </CardContent>
    </Card>
        ;
}

export { BillingRunOverviewPageInternalInvoices }
