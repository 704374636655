import { format } from "date-fns";
import { UploadAMeterReadingModel } from "../models/UploadAMeterReading";
import { getSettings } from "./SettingsService";

async function uploadAndSaveAnMeterReading({ assetId,usageSourceId, assetOperatorId, usageAmount, subscriptionId, usageUnit, evidence, readingDateTime, adjustFutureReadings, recalculateLatestBill }: UploadAMeterReadingModel): Promise<Response> {
    const settings = await getSettings()
    const formData = new FormData();
    for (let photo of evidence) {
        formData.append('evidence', photo);
    }
    formData.append("usageAmount", usageAmount.toString())
    formData.append("usageUnit", usageUnit)
    formData.append("readingDateTime", format(readingDateTime, "yyyy-MM-dd'T'HH:mm:ss"))
    formData.append("adjustFutureReadings", adjustFutureReadings.toString());
    formData.append("usageSourceId", usageSourceId);
    formData.append("recalculateLatestBill", recalculateLatestBill.toString());

    const response = await fetch(`${settings.BillingServiceBaseUri}/assetoperators/${assetOperatorId}/subscriptions/${subscriptionId}/assets/${assetId}/manualmeterreadings`, {
        method: 'POST',
        body: formData
    })
    return response;
}

export { uploadAndSaveAnMeterReading }