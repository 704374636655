import 'date-fns';
import React, {  useState } from 'react'
import { Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField, Theme } from '@mui/material'
import { AssetOperator } from '../models/AssetOperator';
import { uploadAndSaveAnMeterReading } from "../services/MeterReadingService"
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { makeStyles,createStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

type UploadMeterReadingProps = {
    assetOperator: AssetOperator
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: '100%'
        },
        button: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        success: {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.main
        },
        warning: {
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main
        }
    }),
);


function UploadMeterReading({ assetOperator }: UploadMeterReadingProps) {
    const classes = useStyles();
    let now = new Date()
    const [readingDate, setReadingDate] = useState<Date>(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0, 0))
    const [subscriptionId, setSubscriptionId] = useState<string>("");
    const [usageUnit, setUsageUnit] = useState<string>("")
    const [usage, setUsage] = useState<number>(0);
    const [adjustFutureReadings, setAdjustFutureReadings] = useState<boolean>(true);
    const [assetId, setAssetId] = useState<string>("")
    const [photos, setPhotos] = useState<File[]>([])
    const [successfulUpload, setSuccessfulUpload] = useState<boolean | null>(null)
    const [recalculateLatestBill, setRecalculateLatestBill] = useState<boolean>(true);
    const handleBillDateChange = (value: unknown) => {
        let date = value as Date | null;
        if (date != null) {
            const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 0, 0);
            setReadingDate(adjustedDate);
        }
    };

    const handleUsageUnitChange = (event: SelectChangeEvent) => {
        setUsageUnit(event.target.value as string);
    };

    const handleUsageChange = (event: any) => {
        setUsage(event.target.value as number);
    };

    const handleAssetIdChange = (event: SelectChangeEvent) => {
        let assetId = event.target.value;
        setAssetId(assetId as string);
    };

    const handleSubscriptionIdChange = (event: SelectChangeEvent) => {
        let subId = event.target.value;
        setSubscriptionId(subId as string);
    };


    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files = Array.from(e.target.files ?? [])
        setPhotos(files)
    }

    const handleClick = async () => {
        const usageSourceId = assets.filter(x => x.id === assetId)[0].usageSourceId
        const response = await uploadAndSaveAnMeterReading({ usageSourceId: usageSourceId, assetId: assetId, assetOperatorId: assetOperator?.id, subscriptionId: subscriptionId, usageAmount: usage, readingDateTime: readingDate, evidence: photos, usageUnit: usageUnit, adjustFutureReadings: adjustFutureReadings, recalculateLatestBill })
        setSuccessfulUpload(response.ok)
    }



    const handleBoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdjustFutureReadings(event.target.checked);
    };

    const handleRecalculateBill = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecalculateLatestBill(event.target.checked);
    };
    

    let subscriptions = assetOperator?.subscriptions
    let usageUnits = subscriptions?.map(subscription => subscription.usageUnit)?.filter((usageUnit, index, self) => self.indexOf(usageUnit) === index);
    let assets = subscriptions?.flatMap(x => x.assets);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Card className={classes.card} elevation={10}>
                <CardHeader title="Upload a Manual Usage Reading" titleTypographyProps={{style: {fontSize:16}}} />
                <CardContent>
                    <FormControl className={classes.card} component="fieldset">
                        <FormGroup aria-label="position">
                            <FormControl margin="normal"
                                variant="outlined">
                                <InputLabel id="sub-id-label">Subscription</InputLabel>
                                <Select
                                    labelId="sub-id-label"
                                    id="sub-id"
                                    value={subscriptionId}
                                    onChange={handleSubscriptionIdChange}
                                    label="Subscription"
                                >
                                    {
                                        subscriptions?.map(sub => {
                                            return (
                                                <MenuItem key={sub?.id} value={sub?.id}>{sub?.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl margin="normal"
                                variant="outlined">
                                <InputLabel id="asset-id-label">Asset</InputLabel>
                                <Select
                                    labelId="asset-id-label"
                                    id="asset-id"
                                    value={assetId}
                                    onChange={handleAssetIdChange}
                                    label="Asset"
                                >
                                    {
                                        assets?.map(asset => {
                                            return (
                                                <MenuItem key={asset?.id} value={asset?.id}>{asset?.alternativeId}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <TextField value={usage} onChange={handleUsageChange} margin="normal" id="Meter-reading-amount" label="Usage Reading" type="number" variant="outlined" />
                            <FormControl margin="normal"
                                variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">Usage Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={usageUnit}
                                    onChange={handleUsageUnitChange}
                                    label="Usage Unit"
                                    style={{marginBottom: '15px'}}
                                >
                                    {
                                        usageUnits?.map(usageUnit => {
                                            return (
                                                <MenuItem key={usageUnit} value={usageUnit}>{usageUnit}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <DesktopDatePicker
                                label="Choose the date and time the usage reading was taken"
                                inputFormat="dd/MM/yyyy"
                                value={readingDate}
                                onChange={handleBillDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <Button
                                className={classes.button}

                                variant="contained"
                                component="label"
                            >
                                Upload Image
                                <input
                                    onChange={handleFileSelected}
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    hidden
                                />
                            </Button>
                            {
                                photos.map(photo => photo.name).join(", ")
                            }
                            <FormControlLabel
                                label="Do you want to use this reading to adjust the baseline for future readings?"
                                labelPlacement="end"
                                control={<Switch
                                    checked={adjustFutureReadings}
                                    onChange={handleBoolChange}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                            />
                            
                            <FormControlLabel
                                label="Do you want to recalculate the latest bill and associated payment?"
                                labelPlacement="end"
                                control={<Switch
                                    checked={recalculateLatestBill}
                                    onChange={handleRecalculateBill}
                                    color="primary"
                                    name="checkedC"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                            />
                            {
                                successfulUpload == null
                                    ?
                                    (<Button variant="contained" color="primary" onClick={handleClick}>
                                        Submit Reading
                                    </Button>)
                                    :
                                    successfulUpload
                                        ?
                                        (
                                            <Button variant="contained" className={classes.success}>
                                                Successful Reading Upload
                                            </Button>
                                        )
                                        :
                                        (
                                            <Button variant="contained" className={classes.warning} onClick={handleClick}>
                                                Failed, Please Try Again Here
                                            </Button>

                                        )
                            }
                        </FormGroup>
                    </FormControl>
                </CardContent>
            </Card>
        </LocalizationProvider >
    )
}

export { UploadMeterReading }
