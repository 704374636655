import { PaymentPlanRepaymentPaymentStatus } from "../models/PaymentPlanRepaymentPaymentStatus";

function ConvertPaymentPlanRepaymentPaymentStatusToString(paymentPlanRepaymentPaymentStatus: string|undefined): string {
    switch (paymentPlanRepaymentPaymentStatus) {
        case PaymentPlanRepaymentPaymentStatus.Failed:
            return "Failed";
        case PaymentPlanRepaymentPaymentStatus.Cancelled:
            return "Cancelled";
        case PaymentPlanRepaymentPaymentStatus.Pending:
            return "Submitted";
        case PaymentPlanRepaymentPaymentStatus.Complete:
            return "Successfully Completed";
        case PaymentPlanRepaymentPaymentStatus.Queued:
            return "Queued";
        case PaymentPlanRepaymentPaymentStatus.FailedAndRetried:
            return "Failed - retried";
        case PaymentPlanRepaymentPaymentStatus.NA:
            return "NA";
        default:
            return "";
    }
}

export { ConvertPaymentPlanRepaymentPaymentStatusToString };