import React from "react";
import { Button, Theme } from "@mui/material";
import { getUserInfo } from "./AuthService"
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      loginButton: {
        color: theme.palette.primary.contrastText,
      },
    })
  )

const SignInSignOutButton = () => {
    const classes = useStyles()
    const [user, setUser] = React.useState<object|null>(null)
    React.useEffect(() => {
      getUserInfo().then(userInfo => setUser(userInfo))
    }, [])
    

    return (
        <>
                {
                  user
                  ?
                  (
                    <Button variant="contained" href="/logout" className={classes.loginButton} fullWidth>Sign Out</Button>
                  )
                  :
                  (
                    <Button variant="contained" href="/login" className={classes.loginButton} fullWidth>Sign In</Button>
                  )
                }                
        </>
    );
};

export { SignInSignOutButton}