import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';
import { Address } from '../models/Address';
import { Countries } from "../data/Countries";
import { InvoicingAndPaymentCollectionMethod } from '../models/InvoicingAndPaymentCollectionMethod';
import { makeStyles, createStyles } from '@mui/styles';
import { CurrencyIsoCodes } from '../models/CurrencyIsoCodes';
import {InvestorViewModel} from "../models/InvestorViewModel";
import { largeLabelProps } from '../props/LargeLabelProps';

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '10px'
        },
        dropDown: {
            textAlign: 'left',
            width: '100%',
            marginBottom: '10px'
        },
        percentageField: {
            width: '100%',
            marginBottom: '4px',
            marginTop: '6px'
        },
    }))
const genericLabelProps = {
    style: {
        fontSize: "1.2rem",
        paddingBottom: "0.5rem",
    },
    shrink: true,
};

interface InvestorCreateProps {
    investorViewModel: InvestorViewModel
    setInvestorViewModel: React.Dispatch<React.SetStateAction<InvestorViewModel>>
}

function InvestorCreate({ investorViewModel, setInvestorViewModel }: InvestorCreateProps): JSX.Element {
    const classes = useStyles()
    const countriesDropDown: any[] = [];

    Countries.forEach(country => {
        countriesDropDown.push(<MenuItem value={country.IsoCode}>{country.Name}</MenuItem>)
    })

    const currenciesDropDown: any[] = Object.values(CurrencyIsoCodes).map(currency => <MenuItem value={currency}>{currency}</MenuItem>);

    return <>
        <Grid container spacing={5} direction="row">
            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Basic Account Info" />
                    <CardContent>
                        <TextField
                            className={classes.textField}
                            label="Lender ID"
                            InputLabelProps={genericLabelProps}
                            variant="standard"
                            InputProps={
                                investorViewModel?.id?.substring(0, 3) === "INV"

                                    ? {
                                        startAdornment: (
                                            <InputAdornment position="end"> </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {"INV"}
                                            </InputAdornment>
                                        ),
                                    }
                            }
                            value={investorViewModel?.id}
                            onChange={(e) => setInvestorViewModel({
                                ...investorViewModel,
                                id: e.target.value?.substring(0, 3) === "INV" ? e.target.value : "INV" + e.target.value
                            })}
                        />
                        <TextField
                            className={classes.textField}
                            label="Company Number"
                            variant="standard"
                            InputLabelProps={genericLabelProps}                            
                            value={investorViewModel?.companyNumber}
                            onChange={(event) => {
                                setInvestorViewModel({ ...investorViewModel, companyNumber: event.target.value})
                            }}
                        />
                        <TextField
                            className={classes.percentageField}
                            variant="standard"
                            label="Tax Rate Charged On Fees"
                            InputLabelProps={largeLabelProps}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                ),
                            }}
                            value={investorViewModel.vmTaxRateChargedOnFees}
                            onChange={(e) => setInvestorViewModel({...investorViewModel, vmTaxRateChargedOnFees: e.target.value as unknown as number})}
                        />
                        <InputLabel id="SelectCountryLabel" htmlFor="selectCountry">Country</InputLabel>
                        <Select className={classes.dropDown}
                            labelId="SelectCountryLabel"
                            id="SelectCountry"
                            variant="standard"
                            value={investorViewModel?.registeredAddress?.countryCode ?? "GB"}
                            onChange={(event) => {
                                let address: Address = {
                                    ...investorViewModel.registeredAddress,
                                    countryCode: (event.target.value as string)
                                }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        >
                            {countriesDropDown}

                        </Select>

                        <InputLabel id="SelectPrimaryCurrencyLabel" htmlFor="selectPrimaryCurrency">Currency</InputLabel>
                        <Select className={classes.dropDown}
                                labelId="SelectPrimaryCurrencyLabel"
                                id="SelectPrimaryCurrency"
                                variant="standard"
                                value={investorViewModel?.primaryCurrencyIsoCode ?? CurrencyIsoCodes.Gbp}
                                onChange={(event) => {                                                              
                                    setInvestorViewModel({...investorViewModel, primaryCurrencyIsoCode: event.target.value as string})
                                }}
                        >                               
                            {currenciesDropDown}                         

                        </Select>

                        <TextField
                            className={classes.textField}
                            label="Lender Company Name"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel.name}
                            onChange={(event) => setInvestorViewModel({ ...investorViewModel, name: event.target.value })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Address Line One"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.lineOne}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, lineOne: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="Address Line Two"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.lineTwo}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, lineTwo: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label={investorViewModel?.registeredAddress?.countryCode === "US" ? "Zip Code" : "Post Code"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.postCode}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, postCode: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="City"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.city}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, city: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label={investorViewModel?.registeredAddress?.countryCode === "US" ? "State" : "County"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel?.registeredAddress?.county}
                            onChange={(event) => {
                                let address: Address = { ...investorViewModel.registeredAddress, county: event.target.value }
                                setInvestorViewModel({ ...investorViewModel, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="Email"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={investorViewModel.contactEmail}
                            onChange={(event) => setInvestorViewModel({ ...investorViewModel, contactEmail: event.target.value })}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} md={6} item>
                <Grid xs={12} md={12} item>
                    <Card elevation={10} style={{marginBottom: '20px'}}>
                        <CardHeader title="User Access Control" />
                        <CardContent>
                            <Typography>Please use comma seperated values, e.g.
                                mickey@disney.com,minnie@disney.com,pluto@disney.com</Typography>
                            <TextField
                                className={classes.textField}
                                label="Read/Write Admin Email Addresses"
                                variant="standard"
                                InputLabelProps={genericLabelProps}
                                value={investorViewModel?.adminIds?.join(",")}
                                onChange={(event) => setInvestorViewModel({
                                    ...investorViewModel,
                                    adminIds: event.target.value.split(",")
                                })}
                            />

                            <TextField
                                className={classes.textField}
                                label="Read Only User Email Address"
                                variant="standard"
                                InputLabelProps={genericLabelProps}
                                value={investorViewModel?.userIds?.join(",")}
                                onChange={(event) => setInvestorViewModel({ ...investorViewModel, userIds: event.target.value.split(",") })}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={12} item>
                    <Card elevation={10}>
                        <CardHeader title="Billing Information" />
                        <CardContent>
                            <InputLabel id="invoicing-label">Invoicing and Payment Collection Method</InputLabel>
                            <Select
                                style={{
                                    width: "100%",
                                    "marginBottom": "10px"
                                }}
                                value={investorViewModel.investmentManagementFeeInvoicingAndPaymentCollectionMethod}
                                inputProps={{
                                    name: "Invoicing and Payment Collection Method",
                                    style: {
                                        "width": "100%",
                                        "marginBottom": "10px"
                                    }
                                }}
                                onChange={(e) => setInvestorViewModel({ ...investorViewModel, investmentManagementFeeInvoicingAndPaymentCollectionMethod: e?.target?.value as InvoicingAndPaymentCollectionMethod })}
                                labelId="invoicing-label"
                                id="InvoicingMethodSelect"
                                variant="standard"
                            >
                                <MenuItem value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment}>System generated invoice and system-collected payment (e.g. Direct Debit)</MenuItem>
                                <MenuItem value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceManualPayment}>System generated Invoice, Manual Payment (e.g. Bank Transfer)</MenuItem>
                                <MenuItem value={InvoicingAndPaymentCollectionMethod.OffSystem}>Off System Invoice and Payments (e.g. manually generated invoice paid by Bank Transfer)</MenuItem>
                            </Select>
                            <TextField
                                className={classes.textField}
                                label="Billing Email Address (To enter multiple recipients, please separate with a comma)"
                                variant="standard"
                                InputLabelProps={genericLabelProps}
                                value={investorViewModel.contactEmail}
                                onChange={(event) => setInvestorViewModel({ ...investorViewModel, contactEmail: event.target.value })}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </>;

}

export { InvestorCreate }
