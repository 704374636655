import { MarketValueMatrix } from "../models/MarketValueMatrix";

async function getMarketValueMatrices() : Promise<MarketValueMatrix[]> {
    const response = await fetch(`/api/marketvalues`, {
        method: 'GET'
    })
    return response.json();
}

async function uploadMarketValueMatrix(marketValueMatrix: MarketValueMatrix, file: File): Promise<string> {
    const formData = new FormData();
    formData.append('matrixFile', file);
    formData.append("vehicleSpecification", marketValueMatrix.vehicleSpecification)
    formData.append("uploadedAt", marketValueMatrix.uploadedAt)
    formData.append("currency", marketValueMatrix.currency)
    formData.append("msrpValue", marketValueMatrix.msrpValue?.toString() ?? "")
    formData.append("source", marketValueMatrix.source)
    formData.append("containsTotalUsageRatherThanAnnualisedUsage", marketValueMatrix.containsTotalUsageRatherThanAnnualisedUsage.toString())

    const response = await fetch(`/api/marketvalues`, {
        method: 'PUT',
        body: formData
    })
    
    if(!response.ok){
        return "Unknown error - please try again in a few minutes or get in touch";
    }
    return response.text();
}

export { getMarketValueMatrices, uploadMarketValueMatrix }