import 'date-fns';
import React, { useState } from 'react'
import { Button, FormControl, FormGroup, MenuItem, TextField, Theme, Typography } from '@mui/material'
import DateFnsUtils from '@date-io/date-fns';
import { InvestmentManagementFeeBill } from '../models/InvestmentManagementFeeBill';
import { CreateAPaymentMessage} from '../models/CreateAPaymentMessage';
import CurrencyTextField from './CurrencyTextField'
import {TypeOfPayment} from "../models/TypeOfPayment";
import {ConvertCurrencyToSymbol} from "../helpers/ConvertCurrencyToSymbol";
import {CurrencyIsoCodes} from "../models/CurrencyIsoCodes";
import { CreateAnInvestmentManagementFeePaymentByRest } from '../services/InvestmentManagementFeePaymentService';
import { LocalizationProvider } from '@mui/lab';
import { makeStyles, createStyles } from '@mui/styles';

type CreateAnInvestmentManagementFeePaymentProps = {
    investmentManagementFeeBills: InvestmentManagementFeeBill[] | undefined
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1)
            }
        },
        card: {
            width: '100%'
        },
        success: {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.main
        },
        warning: {
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main
        }
    }),
);


function CreateAnInvestmentManagementFeePayment({ investmentManagementFeeBills }: CreateAnInvestmentManagementFeePaymentProps) {
    const classes = useStyles();
    let now = new Date()

    const [paymentDate] = useState<Date>(now)
    const [currencyIsoCode, setCurrencyIsoCode] = useState<string>(CurrencyIsoCodes.Gbp)
    const [billId, setBillId] = useState<string>("HOLDING_STRING")
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [paymentCreatedSuccessfully, setPaymentCreatedSuccessfully] = useState<boolean | null>(null);

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrencyIsoCode(event.target.value);
    };

    const handleClick = async () => {
        let message: CreateAPaymentMessage = {
            datePaymentShouldBeTaken: paymentDate,
            typeOfPayment: TypeOfPayment.Manual,
            amount: paymentAmount,
            currencyIsoCode: currencyIsoCode,
            billId: billId
        }
        let bill = investmentManagementFeeBills?.find(x => x.id === billId)
        let result = await CreateAnInvestmentManagementFeePaymentByRest(bill?.investorId ?? "", billId ?? "", message);
        setPaymentCreatedSuccessfully(result.ok)
    }


    return (
        <LocalizationProvider utils={DateFnsUtils}>
            <Typography variant="h5">Take an Investment Management Fee Payment</Typography>

            <FormControl className={classes.card} component="fieldset">
                <FormGroup className={classes.root} aria-label="position">
                    {
                        investmentManagementFeeBills !== undefined
                            ?
                            (
                                <TextField
                                    value={billId}
                                    onChange={event => setBillId(event.target.value)}
                                    select
                                    id="amount"
                                    label="Bill"
                                    variant="outlined"
                                >
                                    {
                                        investmentManagementFeeBills?.map(x => (
                                            <MenuItem key={x.id} value={x.id}>
                                                {x.invoiceNumber} {new Date(x.billingDate).toLocaleDateString()}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            )
                            :
                            (
                                <></>
                            )
                    }

                    <TextField
                        value={currencyIsoCode}
                        onChange={handleCurrencyChange}
                        select
                        id="amount"
                        label="Currency"
                        variant="outlined"
                    >
                        <MenuItem key={CurrencyIsoCodes.Gbp} value={CurrencyIsoCodes.Gbp}>
                            {ConvertCurrencyToSymbol(CurrencyIsoCodes.Gbp)} {CurrencyIsoCodes.Gbp}
                        </MenuItem>
                        <MenuItem key={CurrencyIsoCodes.Usd} value={CurrencyIsoCodes.Usd}>
                            {ConvertCurrencyToSymbol(CurrencyIsoCodes.Usd)} {CurrencyIsoCodes.Usd}
                        </MenuItem>
                    </TextField>
                    <CurrencyTextField
                        label="Amount"
                        variant="outlined"
                        value={paymentAmount}
                        currencySymbol={ConvertCurrencyToSymbol(currencyIsoCode)}
                        outputFormat="number"
                        onChange={(event: any, value: number) => setPaymentAmount(value)}
                    />
                    {
                        paymentCreatedSuccessfully == null
                            ?
                            (<Button variant="contained" color="primary" onClick={handleClick}>
                                Take a Payment
                            </Button>)
                            :
                            paymentCreatedSuccessfully
                                ?
                                (
                                    <Button variant="contained" className={classes.success}>
                                        Successful Payment Creation
                                    </Button>
                                )
                                :
                                (
                                    <Button variant="contained" className={classes.warning} onClick={handleClick}>
                                        Failed, Please Try Again Here
                                    </Button>

                                )
                    }

                </FormGroup>
            </FormControl>
        </LocalizationProvider>
    )
}


export { CreateAnInvestmentManagementFeePayment }
