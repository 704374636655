import React, { useState } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ConvertSubscriptionIdToName } from "../helpers/ConvertSubscriptionIdsToNames";
import { DraftSubscriptionViewModel } from "../models/DraftSubscriptionViewModel";
import { CheckIfDraftSubscriptionHasMandatoryFieldsFilled } from "../helpers/CheckIfDraftSubscriptionHasMandatoryFieldsFilled";
import DoneIcon from "@mui/icons-material/Done";
import { DeleteDraftSubscriptionModal } from "./DeleteDraftSubscriptionModal";



type DraftSubscriptionTableProps = {
    subscriptions: DraftSubscriptionViewModel[];
    setSubscriptions: Function;
};

function sortAlphabetically(subscriptions: DraftSubscriptionViewModel[]) {
    return subscriptions.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
}

const DraftSubscriptionTable: React.FC<DraftSubscriptionTableProps> = ({ subscriptions, setSubscriptions }: DraftSubscriptionTableProps) => {
    const [draftSubscriptionToDelete, setDraftSubscriptionToDelete] = useState<string | undefined>(undefined)
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

    const makeRows = (subscriptions: DraftSubscriptionViewModel[]) => {
        const alphabeticallySortedSubs = sortAlphabetically(subscriptions)
        return alphabeticallySortedSubs.map(mapSubscriptionToRow);
    }

    const mapSubscriptionToRow = (subscription: DraftSubscriptionViewModel, i: number) => {
        return {
            id: i,
            contractId: ConvertSubscriptionIdToName(subscription.id),
            contractName: subscription.name,
            editButton: subscription.id,
            canActivate: CheckIfDraftSubscriptionHasMandatoryFieldsFilled(subscription),
            deleteButton: subscription.id,
            subscription: subscription
        }
    }

    const columns: GridColDef[] = [
        { field: 'contractId', headerName: 'Contract', flex: 2 },
        { field: 'contractName', headerName: 'Name', flex: 2 },
        {
            field: 'editButton', headerName: 'Edit', flex: 1, renderCell: (params: any): JSX.Element => {
                return (
                    <Button variant="contained" component={Link} color="primary"
                        to={"/draftsubscriptions/" + params.value}>Edit</Button>
                )
            },
        },
        {
            field: 'activateButton', headerName: 'Can Activate?', flex: 1, renderCell: (params: any): JSX.Element => {
                return (
                    params.row.canActivate ?
                        <DoneIcon />
                        :
                        <></>
                )
            },
        },
        {
            field: 'deleteButton', headerName: 'Delete', flex: 1, renderCell: (params: any): JSX.Element => {
                return (
                    <Button variant="contained" color="primary" onClick={() => onDeleteClick(params.value)}>Delete</Button>
                )
            },
        }

    ];

    function onDeleteClick(subscriptionId: string) {
        setDraftSubscriptionToDelete(subscriptionId)
        setDeleteModalOpen(true)
    }

    return (
        <>
            <DataGrid rows={makeRows(subscriptions)} columns={columns}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }} />
            <DeleteDraftSubscriptionModal
                deleteModalOpen={deleteModalOpen}
                setDeleteModalOpen={setDeleteModalOpen}
                draftSubscriptionToDelete={draftSubscriptionToDelete}
                setDraftSubscriptionToDelete={setDraftSubscriptionToDelete}
                subscriptions={subscriptions}
                setSubscriptions={setSubscriptions}
            />
        </>
    );
};

export default DraftSubscriptionTable;

