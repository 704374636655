import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import { getAssets, getTelematicsDataForVins } from "../services/AssetsService";
import { EnrichedAsset } from "../models/EnrichedAsset";
import { RoadVehicle } from "../models/RoadVehicle";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { format } from "date-fns";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';


function buildMakeOrModel(asset: EnrichedAsset) {
    if (asset.make || asset.model || asset.trim || asset.yearOrVersion) {
        var make = asset.make === null ? '' : asset.make
        if (make.toLowerCase() === "carbodies") make = "LEVC"
        var model = asset.model === null ? '' : asset.model
        var trim = asset.trim === null ? '' : asset.trim
        var yearOrVersion = asset.yearOrVersion === null ? '' : asset.yearOrVersion
        return make + ' ' + model + ' ' + trim + ' ' + yearOrVersion
    }
    return '';
}

function buildTelematicsProvider(matchingTelematics: RoadVehicle) {
    var providers = [];
    if (matchingTelematics?.brandOfTelematicsInstalled) providers.push(matchingTelematics?.brandOfTelematicsInstalled)
    if (matchingTelematics?.brandOfIntegratedTelematicsInstalled) providers.push(matchingTelematics?.brandOfIntegratedTelematicsInstalled)

    if (providers.length === 0) return '';

    else return providers.join(',')
}

function isNotInUse(notInUseStartDate: string | undefined, notInUseEndDate: string | undefined): boolean {

    if (notInUseStartDate && notInUseEndDate) {
        const now = new Date();

        const startDate = new Date(notInUseStartDate);
        const endDate = new Date(notInUseEndDate);

        return now >= startDate && now <= endDate;
    }

    return false;
}

const TelematicsPage: React.FC = () => {
    const [enrichedAssets, setEnrichedAssets] = useState<EnrichedAsset[]>([]);
    const [telematicsInfo, setTelematicsInfo] = useState<RoadVehicle[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sortModel, setSortModel] = useState<any[]>([
        {
            field: 'lastConnected',
            sort: 'asc',
        },
    ]);
    const theme = useTheme()

    const dateFormat = "dd/MM/yy HH:mm:ss";

    useEffect(() => {
        var mounted = true;
        if (mounted) {
            setIsLoading(true)
            getAssets().then(assetsReturned => {
                const nonArchivedEnrichedAssets = assetsReturned.filter(asset => !asset.isArchived)
                setEnrichedAssets(nonArchivedEnrichedAssets)
                getTelematicsDataForVins(nonArchivedEnrichedAssets.map((asset) => asset.usageSourceId)).then((telematics) => {
                    setTelematicsInfo(telematics)
                })
                setIsLoading(false)
            });
        }

        return () => {
            mounted = false;
        };
    }, []);

    const columns = [
        {
            field: 'id',
            headerClassName: 'zeti-column-headers',
            headerName: 'VIN',
            flex: 2
        },
        {
            field: 'licensePlate',
            headerClassName: 'zeti-column-headers',
            headerName: 'License Plate',
            flex: 1
        },
        {
            field: 'details',
            headerClassName: 'zeti-column-headers',
            headerName: 'Make & Model',
            flex: 2
        },
        {
            field: 'name',
            headerClassName: 'zeti-column-headers',
            headerName: 'Name',
            flex: 2
        },
        {
            field: 'finance',
            headerClassName: 'zeti-column-headers',
            headerName: 'Finance',
            flex: 2
        },
        {
            field: 'mileage',
            headerClassName: 'zeti-column-headers',
            headerName: 'Mileage (approx)',
            flex: 1,
            type: 'number',
            renderCell: (params: any): JSX.Element => {
                return (<Typography
                    style={{ fontSize: 14, width: '100%' }}>{(params.row.mileage as number) === -1 ? "-" : (params.row.mileage as number).toLocaleString(["en-gb"], {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0
                    })}</Typography>)
            }
        },
        {
            field: 'telematicsStatus',
            headerClassName: 'zeti-column-headers',
            headerName: 'Telematics Status',
            flex: 1,
            renderCell: (params: any): JSX.Element => {
                return (<Typography
                    style={{ fontSize: 14, color: params.row.telematicsStatus === 'Connected' ? theme.palette.text.primary : theme.palette.warning.main }}>{params.row.telematicsStatus}</Typography>)
            }
        },
        {
            field: 'lastConnected',
            headerClassName: 'zeti-column-headers',
            headerName: 'Last Connected',
            flex: 1,
            renderCell: (params: any): JSX.Element => {
                return typeof (params.row.lastConnected) === 'undefined' || params.row.lastConnected === null || params.row.lastConnected > params.row.longestDisconnection ?
                    <></> :
                    params.row.lastConnected === 0 ?

                        <><Typography style={{ fontSize: 14 }}>
                            {'Under 1 day'}
                        </Typography>
                            <Tooltip title={"Last connected: " + params.row.lastConnectedTimestamp}><InfoIcon style={{ marginLeft: '5px' }} fontSize="small" /></Tooltip></> :
                        <Typography style={{ fontSize: 14 }}>
                            {params.row.lastConnected === 1 ? '1 day' : params.row.lastConnected + ' days'}
                        </Typography>
            }
        },
        {
            field: 'mileageLastUpdated',
            headerClassName: 'zeti-column-headers',
            headerName: 'Odometer Last Read',
            flex: 1,
            type: 'date',
            renderCell: (params: any): JSX.Element => {
                return (<Typography style={{ fontSize: 14 }}>{params.row.mileageLastUpdated}</Typography>)
            }
        },
        {
            field: 'provider',
            headerClassName: 'zeti-column-headers',
            headerName: 'Provider',
            flex: 1
        },
        {
            field: 'inUse',
            headerClassName: 'zeti-column-headers',
            headerName: 'In Use',
            flex: 1,
            type: 'boolean',
            renderCell: (params: any): JSX.Element => {
                return (<Typography
                    style={{ color: params.row.inUse ? theme.palette.success.main : theme.palette.warning.main }}>{params.row.inUse ? <CheckIcon /> : <CloseIcon />}</Typography>)
            }
        }
    ];

    const noTelematicsInfoYet = telematicsInfo.length === 0
    const rows = enrichedAssets?.filter(x => x.usageSourceId)?.map(asset => {
        var matchingTelematics = telematicsInfo.filter(vehicle => vehicle.vin === asset.usageSourceId)[0]
        var makeOrModel = buildMakeOrModel(asset)
        var telematicsProvider = buildTelematicsProvider(matchingTelematics)

        var lastConnectedDate = ''
        if (typeof (matchingTelematics?.state?.gpsLocation?.lastUpdated) !== "undefined") lastConnectedDate = format(new Date(matchingTelematics?.state?.gpsLocation?.lastUpdated), dateFormat)
        var longestDisconnection = telematicsInfo.length > 0 ? Math.max(...telematicsInfo.map(x => x.fullDaysSinceConnection)) : 0
        var fullDaysSinceConnection = matchingTelematics?.gpsDataIsRecent === null || typeof (matchingTelematics?.gpsDataIsRecent) === 'undefined' ? longestDisconnection + 1 : matchingTelematics?.gpsDataIsRecent === true ? longestDisconnection + 2 : matchingTelematics?.fullDaysSinceConnection
        var subscriptionName = asset.subscriptionName.indexOf("-") > -1 ? asset.subscriptionName.substring(asset.subscriptionName.indexOf("-") + 2) : asset.subscriptionName

        return {
            licensePlate: asset.alternativeId,
            id: asset.usageSourceId,
            details: makeOrModel,
            name: subscriptionName,
            finance: asset.subscriptionId,
            mileage: isNaN(parseInt(matchingTelematics?.state?.mileage?.toFixed(0))) ? -1 : parseInt(matchingTelematics?.state?.mileage?.toFixed(0)),
            telematicsStatus: noTelematicsInfoYet ? 'Loading' : matchingTelematics?.gpsDataIsRecent === true ? 'Connected' : 'Not Connected',
            lastConnected: fullDaysSinceConnection,
            lastConnectedTimestamp: lastConnectedDate,
            longestDisconnection: longestDisconnection,
            provider: telematicsProvider,
            mileageLastUpdated: matchingTelematics?.state?.mileageLastUpdated ? format(new Date(matchingTelematics?.state?.mileageLastUpdated), dateFormat) : '',
            inUse: !(isNotInUse(asset.notInUseStartDate, asset.notInUseEndDate))
        }
    }) ?? []

    useEffect(() => {
        var mounted = true;
        if (mounted) {

        }

        return () => {
            mounted = false;
        };
    }, []);

    return (
        <Card>
            <CardHeader title="Telematics Status" />
            <CardContent>
                <DataGrid
                    key="normal-data-grid"
                    autoHeight
                    columns={columns}
                    rows={rows}
                    initialState={{
                        columns: {
                            columnVisibilityModel: { id: false, details: false, finance: false, name: false, inUse: false  }
                        }
                    }}
                    components={{ Toolbar: CustomToolbar }}
                    loading={isLoading}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                />
            </CardContent>
        </Card>
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport csvOptions={{ allColumns: true }} printOptions={{ allColumns: true }} />
        </GridToolbarContainer>
    );
}



export { TelematicsPage };
