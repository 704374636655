import {MenuLink} from "../models/MenuLink";

const menuLinks: MenuLink[] = [
    {
        title: "Lenders",
        url: "/investors",
    },
    {
        title: "Fleet Operators",
        url: "/operators",
    },
    {
        title: "Contracts",
        nestedLinks: [
            {
                title: "Active",
                url: "/subscriptions"
            },
            {
                title: "Draft",
                url: "/draftSubscriptions"
            },
            {
                title: "External",
                url: "/externalContracts"
            }
        ]
    },
    {
        title: "Billing Runs",
        nestedLinks: [
            {
                title: "Fleet Bills",
                url: "/billingRunReports/fleetBills",
            },
            {
                title: "Investor Bills",
                url: "/billingRunReports/investorBills",
            },
            {
                title: "Internal Invoices",
                url: "/billingRunReports/internalInvoices"
            },
            {
                title: "Trial Fleet Bills",
                url: "/trialBillingRuns/fleetBills",
            },
            {
                title: "Trial Investor Bills",
                url: "/trialBillingRuns/investorBills",
            },
            {
                title: "Trial Internal Invoices",
                url: "/trialBillingRuns/internalInvoices"
            }
        ]
    },
    {
        title: "Payments",
        nestedLinks: [
            {
                title: "Fleet Payments To Action",
                url: "/payments/toaction",
            },
            {
                title: "All Fleet Payments",
                url: "/payments",
            },
            {
                title: "Lender Fees To Action",
                url: "/payments/investmentManagementFeeToaction",
            },
            {
                title: "All Lender Fees",
                url: "/payments/investmentManagementFee",
            }
        ],
    },
    {
        title: "Vehicles",
        nestedLinks: [
            {
                title: "Telematics Status",
                url: "/vehicles/telematics",
            },
            {
                title: "Odometer Readings",
                url: "/vehicles/odometerReadings",
            }
        ]
    },
    {
        title: "Demo Fleets",
        url: "/demofleets",
    },
    {
        title: "Market Values",
        url: "/marketvalues",
    }
];

export {menuLinks};
