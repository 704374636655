import { Box, Card, CardContent, CardHeader, IconButton, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react';
import { BillStatus } from '../models/BillStatus';
import { InvestmentManagementFeeBill } from '../models/InvestmentManagementFeeBill';
import { CreateAnInvestmentManagementFeePayment } from './CreateAnInvestmentManagementFeePayment'
import { ConvertBillStatusToString } from "../helpers/ConvertBillStatusToString";
import { ConvertCurrencyToSymbol } from "../helpers/ConvertCurrencyToSymbol";
import { GetBillStatusTextColor } from '../helpers/GetBillStatusTextColor';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Skeleton } from "@mui/material";
import { getInvestmentManagementFeeBills } from '../services/InvestorService';
import {InvestorViewModel} from "../models/InvestorViewModel";
import { format } from 'date-fns';
import GetAppIcon from '@mui/icons-material/GetApp';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GetBillPaymentStatusTextColor } from '../helpers/GetBillPaymentStatusTextColor';
import { Link } from 'react-router-dom';

type InvestmentManagementFeeBillTableProps = {
    investorViewModel: InvestorViewModel
}

const useStyles = makeStyles(
    createStyles({
      tableHeader: {
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
        },
      },
    }),
  );

const mapBillToRow = (bill: InvestmentManagementFeeBill, i: number) => {
    var currencySymbol = ConvertCurrencyToSymbol(bill.currencyIsoCode);

    return {
        id: i,
        invoiceNumber: bill.invoiceNumber,
        billDate: format(new Date(bill.billingDate), "MMM yyyy"),
        billStatus: ConvertBillStatusToString(bill.billStatus),
        grossAmount: currencySymbol + bill.totalPaymentLine.net?.toLocaleString(),
        billPaymentStatus: bill.billPaymentStatus,
        billPaymentId: bill.billPaymentId,
        downloadButton: JSON.stringify(bill)
    }
}

function getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
}

function InvestmentManagementFeeBillTable({ investorViewModel }: InvestmentManagementFeeBillTableProps): JSX.Element {
    const [investmentManagementFeeBills, setInvestmentManagementFeeBills] = useState<InvestmentManagementFeeBill[] | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const theme = useTheme()
    const classes = useStyles();

    useEffect(() => {
        let mounted = true;
        setIsLoading(true);
        getInvestmentManagementFeeBills(investorViewModel?.id ?? "")
            .then(bills => {
                if (mounted) {
                    setInvestmentManagementFeeBills(bills?.filter(bill => bill.isDraft === false && (bill.billStatus === BillStatus.IssuedAndLive || bill.billStatus === BillStatus.IssuedButRetracted)))
                    setIsLoading(false);
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'invoiceNumber',
            headerClassName: classes.tableHeader,
            headerName: 'Invoice Number',
            flex: 1
        },
        { 
            field: 'billDate',
            headerClassName: classes.tableHeader,
            headerName: 'Bill Period',
            flex: 1
        },
        {
            field: 'grossAmount',
            headerClassName: classes.tableHeader,
            headerName: 'Gross Amount',
            flex: 1
        },
        {
            field: 'billStatus', 
            headerClassName: classes.tableHeader,
            headerName: 'Bill Status',
            flex: 1,
            renderCell: (params: any): JSX.Element => {
                return (<Typography style={{ color: GetBillStatusTextColor(params.row.billStatus, theme), fontSize: '14px' }}>{params.row.billStatus}</Typography>)
            }
        },
        {
            field: 'billPaymentStatus', 
            headerClassName: classes.tableHeader,
            headerName: 'Payment Status',
            flex: 1,
            renderCell: (params: any): JSX.Element => {
                return (
                    params.row.billPaymentId && params.row.billPaymentStatus !== "NA" ? 
                    <Typography 
                        style={{ color: GetBillPaymentStatusTextColor(params.row.billPaymentStatus, theme), fontSize: '14px' }}
                        component={Link}
                        to={"/investmentmanagementfeepayments/details/" + params.row.billPaymentId} 
                    >
                        {params.row.billPaymentStatus}
                    </Typography>
                    :
                    <Typography 
                        style={{ color: GetBillPaymentStatusTextColor(params.row.billPaymentStatus, theme), fontSize: '14px' }}
                    >
                        {params.row.billPaymentStatus}
                    </Typography>)
            }
        },
        {
            field: 'downloadButton',
            headerClassName: classes.tableHeader,
            headerName: '',
            width: 150,
            renderCell: (params: any): JSX.Element => {
                let bill: InvestmentManagementFeeBill = JSON.parse(params?.value?.toString() ?? "")
                return (
                    <IconButton style={{ paddingTop: "25px" }} href={`/api/investors/${bill.investorId}/investmentmanagementfeebills/${bill.id}`} download={bill.invoiceNumber + ".pdf"}>
                        <GetAppIcon fontSize="large" color="primary" style={{ paddingBottom: '10px' }} />
                    </IconButton>
                )
            },
        }
    ];
    return (
        <Card elevation={10}>
            <CardHeader titleTypographyProps={{style: {fontSize:16}}} title="Investment Management Fee Bills" />
            <CardContent>
                {
                    investmentManagementFeeBills === undefined
                        ?
                        (
                            <Skeleton height={300} />
                        )
                        :
                        (
                            <Box height={300}>
                                <DataGrid rows={investmentManagementFeeBills?.sort((a, b) => getTime(new Date(b.billingDate)) - getTime(new Date(a.billingDate)))?.map(mapBillToRow) ?? []} columns={columns} loading={isLoading} />
                            </Box>
                        )
                }
                <CreateAnInvestmentManagementFeePayment investmentManagementFeeBills={investmentManagementFeeBills} />
            </CardContent>
        </Card >
    )
}

export { InvestmentManagementFeeBillTable }
