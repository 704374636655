import {BillStatus} from "../models/BillStatus";

function ConvertBillStatusToString(billStatus: BillStatus): string {
    switch (billStatus) {
        case BillStatus.LegacyBillStatus: {
            return "Legacy Bill"
        }
        case BillStatus.IssuedAndLive: {
            return "Issued"
        }
        case BillStatus.IssuedButRetracted: {
            return "Retracted"
        }
        case BillStatus.GeneratedButHeldAndNotIssued: {
            return "Held for manual review"
        }
        case BillStatus.GeneratedButSendingFailedAndNotIssued: {
            return "Delivery Failed"
        }
        case BillStatus.ApprovedAutomatically: {
            return "Approved Automatically"
        }
    }
}

export {ConvertBillStatusToString};
