import { Theme } from "@mui/material"

function GetPaymentStatusTextColor(paymentStatus: string | undefined, theme: Theme): string {
    switch (paymentStatus) {
        case "Successfully Completed":
            return theme.palette.success.main
        case "Failed":
            return theme.palette.error.main
        case "Failed - retried":
            return theme.palette.error.main
        case "Queued":
            return theme.palette.warning.main
        case "Submitted":
            return theme.palette.text.secondary
        case "Cancelled":
            return theme.palette.info.main
        default:
            return theme.palette.text.primary
    }
}

export { GetPaymentStatusTextColor };