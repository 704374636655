/* eslint-disable react/no-array-index-key */
import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { makeStyles, createStyles } from '@mui/styles';

import { Link } from 'react-router-dom'
import { MenuLink } from "../models/MenuLink";
import { ListItem, ListItemText, Collapse, Divider, List, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
    },
    menuItemIcon: {
      color: theme.palette.text.primary
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary
    }
  })
)

const AppMenuItem: React.FC<MenuLink> = props => {
  const { title, url, nestedLinks = [] } = props
  const classes = useStyles()
  const isExpandable = nestedLinks && nestedLinks.length > 0
  const [open, setOpen] = React.useState(false)

  function handleClick(event: any) {
    setOpen(!open)
    event.stopPropagation()
  }

  const MenuItemRoot = (
    <ListItem button className={classes.menuItem} onClick={handleClick}>
      <ListItemText
        primary={
          isExpandable ? (
            <>{title}</>
          ) : (
            <Link className={classes.link} to={url ?? ""}>
              {title}
            </Link>
          )
        }
        inset
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <ExpandMoreIcon />}
      {isExpandable && open && <ExpandLessIcon />}
    </ListItem>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {nestedLinks.map((item, index) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

export default AppMenuItem