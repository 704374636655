import { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Modal, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteDraftSubscription } from '../services/DraftSubscriptionService';
import { DraftSubscriptionViewModel } from '../models/DraftSubscriptionViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            border: '2px solid #000',
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
    }))

type DeleteDraftSubscriptionModalProps = {
    deleteModalOpen: boolean,
    setDeleteModalOpen: Function,
    draftSubscriptionToDelete: string|undefined,
    setDraftSubscriptionToDelete: Function
    subscriptions: DraftSubscriptionViewModel[]
    setSubscriptions: Function
}

function DeleteDraftSubscriptionModal({
    deleteModalOpen,
    setDeleteModalOpen,
    draftSubscriptionToDelete,
    setDraftSubscriptionToDelete,
    subscriptions,
    setSubscriptions
}: DeleteDraftSubscriptionModalProps): JSX.Element {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deleteDraftSubscriptionFeedbackMessage, setDeleteDraftSubscriptionFeedbackMessage] = useState<string>("");

    const classes = useStyles();

    async function handleDeleteDraftSubscription() {
        if (draftSubscriptionToDelete) {
            setIsLoading(true);
            deleteDraftSubscription(draftSubscriptionToDelete).then((message) => {
                setIsLoading(false)
                if (message === "Success") {
                    setSubscriptions(subscriptions.filter(x => x.id !== draftSubscriptionToDelete))
                    handleModalClose()
                } else {
                    setDeleteDraftSubscriptionFeedbackMessage("Failed to delete draft contract")
                }
            })
        }
    }

    function handleModalClose() {
        setDeleteDraftSubscriptionFeedbackMessage("");
        setDraftSubscriptionToDelete(undefined);
        setDeleteModalOpen(false);
    }

    return (
        <Modal open={deleteModalOpen}
            onClose={handleModalClose}>
            <div className={classes.modal}>
                <Card>
                    <CardHeader
                        action={
                            <IconButton
                                color="primary"
                                onClick={handleModalClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title="Delete Draft Contract"
                    />
                    <CardContent>
                        <Typography variant="subtitle1" style={{ paddingTop: "16px" }}>Are you sure you want to delete the following draft contract?: {draftSubscriptionToDelete}</Typography>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="center">
                            <Grid container direction="row" justifyContent="space-evenly" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                <Grid item>
                                    <LoadingButton
                                        variant="contained"
                                        style={{ marginLeft: "auto" }}
                                        startIcon={<DeleteIcon />}
                                        color="primary"
                                        onClick={handleDeleteDraftSubscription}
                                        loading={isLoading}
                                    >
                                        Delete
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        style={{ marginLeft: "auto" }}
                                        startIcon={<CloseIcon />}
                                        color="primary"
                                        onClick={handleModalClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                            <Typography variant="subtitle1" textAlign="center" color="error">{deleteDraftSubscriptionFeedbackMessage}</Typography>
                        </Grid>
                    </CardActions>
                </Card>
            </div>
        </Modal>);
}

export { DeleteDraftSubscriptionModal }
