import { useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Modal, TextField, Theme, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { extendCustomersRemainingTime } from "../services/DemoFleetServices";
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoUserAccess } from "../models/DemoUserAccess";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            border: '2px solid #000',
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
    }))

type ExtendExpireOnDemoAccountModalProps = {
    extendExpireOnDemoAccountModalOpen: boolean
    setExtendExpireOnDemoAccountModalOpen: Function
    extendExpireFeedbackMessage: string
    setExtendExpireFeedbackMessage: Function
    user: DemoUserAccess | undefined
}


function ExtendExpireOnDemoAccountModal({
    extendExpireOnDemoAccountModalOpen,
    setExtendExpireOnDemoAccountModalOpen,
    setExtendExpireFeedbackMessage,
    extendExpireFeedbackMessage,
    user
}: ExtendExpireOnDemoAccountModalProps): JSX.Element {

    let thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newExpirationDate, setNewExpirationDate] = useState<Date>(thirtyDaysFromNow);

    const classes = useStyles();

    async function extendExpirationDate() {
        setIsLoading(true);

        const confirmationMessage = await extendCustomersRemainingTime(user, newExpirationDate);

        setIsLoading(false);

        if (confirmationMessage === "COMPLETED") {
            setExtendExpireOnDemoAccountModalOpen(false);
        }

        setExtendExpireFeedbackMessage(confirmationMessage);
    }

    function handleModalClose() {
        setExtendExpireFeedbackMessage("");
        setNewExpirationDate(thirtyDaysFromNow);
        setExtendExpireOnDemoAccountModalOpen(false);
    }

    const handleExpiryDateChange = (value: unknown) => {
        let date = value as Date | null;
        if (date != null) {
            const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0);
            setNewExpirationDate(adjustedDate);
        }
    };

    return (
        <Modal open={extendExpireOnDemoAccountModalOpen}
            onClose={handleModalClose}>
            <div className={classes.modal}>
                <Card>
                    <CardHeader
                        action={
                            <IconButton
                                color="primary"
                                onClick={handleModalClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title="Extend Expiration Date"
                    />
                    <CardContent>
                        <Grid container style={{ paddingTop: "20px" }}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1" style={{ paddingTop: "16px" }}>New Expiry Date</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        disablePast={true}
                                        inputFormat="dd/MM/yyyy"
                                        value={newExpirationDate}
                                        onChange={handleExpiryDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Typography variant="subtitle1">{extendExpireFeedbackMessage}</Typography>
                        <LoadingButton
                            variant="contained"
                            style={{ marginLeft: "auto" }}
                            startIcon={<SendIcon style={{ "paddingRight": "10px" }} />}
                            color="primary"
                            onClick={extendExpirationDate}
                            loading={isLoading}
                        >
                            CONFIRM
                        </LoadingButton>
                    </CardActions>
                </Card>



            </div>
        </Modal>);
}

export { ExtendExpireOnDemoAccountModal }
