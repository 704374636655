import {getSettings} from "./SettingsService"
import {CreateAPaymentMessage} from "../models/CreateAPaymentMessage"
import {Payment} from "../models/Payment"

async function CreateAPaymentByRest(assetOperatorId: string, billId: string, paymentCreationRequest: CreateAPaymentMessage): Promise<Response> {
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/assetoperators/${assetOperatorId}/bills/${billId}/payments`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(paymentCreationRequest)
    })
}
async function CreateAManualPaymentByRest(assetOperatorId: string,paymentCreationRequest: CreateAPaymentMessage): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/assetoperators/${assetOperatorId}/manualPayments`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(paymentCreationRequest)
    })
}

async function GetPaymentsForABill(billId: string): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/bills/${billId}/payments`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET'
    })
}

async function getAllPayments(): Promise<Payment[]>{
    const settings = await getSettings()

    var response = await fetch(`${settings.BillingServiceBaseUri}/payments`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET'
    })
    return await response.json();
}

async function getPayment(paymentId: string): Promise<Payment>{
    const settings = await getSettings()
    var response = await fetch(`${settings.BillingServiceBaseUri}/payments/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET'
    })
    return await response.json();
}

async function cancelPayment(paymentId: string): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/payments/cancel/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT'
    });
}

async function archiveFailedPayment(paymentId: string): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/payments/archive/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT'
    });
}

async function retryFailedPayment(paymentId: string): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/payments/retry/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT'
    });
}

async function getStripePublishableKey(): Promise<string> {
    const settings = await getSettings();
    const assetOperatorUri =`${settings.BillingServiceBaseUri}/stripeusapublishablekey`
    const options = {
        method: "GET",
    };
    const stripeSetupUrlResponse = await fetch(assetOperatorUri,options)
    const stripeUsaPublishableKey = await stripeSetupUrlResponse.json()
    return stripeUsaPublishableKey.publishableKey;
}

async function deleteAManualPayment(paymentId: string): Promise<boolean>{
    const settings = await getSettings()
    const response =await fetch(`${settings.BillingServiceBaseUri}/payments/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'DELETE'
    });
    return response.ok;
}

export { deleteAManualPayment, CreateAPaymentByRest, GetPaymentsForABill, getAllPayments, getPayment, cancelPayment, archiveFailedPayment, retryFailedPayment, getStripePublishableKey, CreateAManualPaymentByRest }
