import { EnrichedAsset } from "../models/EnrichedAsset"
import { RoadVehicle } from "../models/RoadVehicle"
import { getSettings } from "./SettingsService";

const getAssets = async (): Promise<EnrichedAsset[]> => {
    const settings = await getSettings();
    const assetsUri = `${settings.BillingServiceBaseUri}/assets`
    const options = {
        method: "GET",
    };
    const response = await fetch(assetsUri, options)
    const responseObject = await response.json()
    return responseObject
}

const getTelematicsDataForVins = async (vins: string[]): Promise<RoadVehicle[]> => {
    const chunkSize = 100;
    const resultingArray = []
    for (let i = 0; i < vins.length; i += chunkSize) {
        const chunkOfVins = vins.slice(i, i + chunkSize);
        var vinsString = chunkOfVins.join(',')
        const settings = await getSettings();
        const telematicsUri = `${settings.BillingServiceBaseUri}/assets/telematics?vins=${vinsString}`
        const options = {
            method: "GET",
        };
        const response = await fetch(telematicsUri, options)
        const responseObject = (await response.json()) as any[]
        resultingArray.push(...responseObject)
    }
    return resultingArray
}

async function archiveAsset(subscriptionId: string, assetId: string): Promise<void> {
    const settings = await getSettings();
    const assetUri = `${settings.BillingServiceBaseUri}/subscriptions/${subscriptionId}/assets/${assetId}/archive`
    const options = {
        method: "PUT"
    };
    const response = await fetch(assetUri, options)
    const responseObject = await response.json()
    return responseObject
}

const getTelematicsData = async (subscriptionId : string): Promise<RoadVehicle[]> => {
    const settings = await getSettings();
    const assetUri = `${settings.BillingServiceBaseUri}/subscriptions/${subscriptionId}/assets/*/telematics`
    const options = {}
    const response = await fetch(assetUri, options)
    const responseObject = response.json()
    return responseObject    
}

const bulkUploadAssetsWithFile = async (files: File[]): Promise<Response> => {
    const formData = new FormData();
    for (let file of files) {
        formData.append('assetFiles', file);
    }
    const settings = await getSettings();
    const response = await fetch(`${settings.BillingServiceBaseUri}/bulkUploadAssetsFile`, {
        method: 'POST',
        body: formData
    })
    

    return response
}

const getAllVehicleSpecs = async (): Promise<string[]> => {
    const response = await fetch(`/api/vehiclespecs`, {
        method: 'GET'
    })
    return response.json();
}

export { getAssets, getTelematicsDataForVins, archiveAsset, getTelematicsData, bulkUploadAssetsWithFile, getAllVehicleSpecs }
