import { createTheme, darken, responsiveFontSizes } from '@mui/material/styles'

const primary = "#E1FF00";
const secondary = "#5ADBFF";
const error = "#FE9000";

const backgroundColor = "#263948"
const resedaGreen = "#778071";
// A custom theme for this app
let theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: backgroundColor,
            width: '0.4em',
            height: '0.4em'
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            backgroundColor: primary,
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: darken(primary,0.3),
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            background: backgroundColor
          }
        },
      },
    },
		MuiInputBase: {
			defaultProps: {
				// Needed to prevent adding a global style for every input field
				disableInjectingGlobalStyles: true,
			},
		},
	},
  shape: {
    borderRadius: 10
  },
  typography: {
    fontFamily: "Manrope,Roboto,sans-serif",

  },
  palette: {
    mode: 'dark',
    primary: {
      main: primary,
      light: '#f1fe90'
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: '#FF9494',
    },
    warning: {
      main: error,
    },
    info: {
      main: secondary,
    },
    success: {
      main: primary,
    },
    background: {
      default: backgroundColor,
      paper: backgroundColor
    },
    darkGreen: {
      main: resedaGreen
    }

  }
} as any)

theme = responsiveFontSizes(theme);


export { theme }
