import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubscriptionViewModel } from '../models/SubscriptionViewModel';
import { deleteSubscription, getSubscription } from '../services/SubscriptionService';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import { Skeleton } from "@mui/material";

const SubscriptionDeletePage: React.FC = ({ match }: any) => {
    const [subscription, setSubscription] = useState<SubscriptionViewModel | null>(null)
    let history = useHistory();

    const onClickDelete = () => {
        deleteSubscription(subscription?.id ?? "").then(() => history.push("/subscriptions"))
    }

    useEffect(() => {
        let mounted = true;
        getSubscription(match.params.subscriptionId)
            .then(item => {
                if (mounted) {
                    setSubscription(item)
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id])
    return (
        <>
            {
                subscription == null
                    ?
                    <Skeleton height={500} />
                    :
                    (
                        <Card>
                            <CardHeader title={"Subscription: " + subscription?.name} />
                            <CardContent>
                                <Typography variant="body1" gutterBottom>Are you sure that you want to delete this subscription?</Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    style={{ marginLeft: 'auto' }}
                                    startIcon={<DeleteIcon />}
                                    onClick={onClickDelete}
                                >
                                    Delete
                                </Button>
                            </CardActions>

                        </Card>

                    )
            }

        </>
    )
}

export { SubscriptionDeletePage }
