import { useEffect, useState } from "react";
import { ExternalContractRow } from "../models/ExternalContractRow";
import { getAllExternalContractRows } from "../services/ExternalContractService";
import { Box, Button, Card, CardActions, CardContent, CardHeader, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

const ExternalContractListingPage = () => {
    const [externalContractRows, setExternalContractRows] = useState<ExternalContractRow[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        setIsLoading(true);
        getAllExternalContractRows().then((rows) => {
            setExternalContractRows(rows);
            setIsLoading(false);
        });
    }, []);

    const rows = externalContractRows;
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'assetOperatorId', headerName: 'Asset Operator Id', flex: 1 },
        {
            field: 'edit', headerName: 'Edit', headerAlign: 'right', flex: 1, renderCell: (params: any): JSX.Element => {
                return (
                    <Button style={{ marginLeft: 'auto' }} variant="contained" href={`/externalcontracts/${params.row.id}/edit`}>Edit</Button>
                )
            }
        },
    ];
    return (
        <Card>
            <CardHeader title="External Contracts" />
            <CardContent>
                <Box height={500}>
                    {
                        isLoading
                            ?
                            <LinearProgress color="secondary" />
                            :
                            <DataGrid columns={columns} rows={rows} components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }} />
                    }
                </Box>
            </CardContent>
            <CardActions>
                <Button style={{ marginLeft: 'auto' }} variant="contained" href="/externalContracts/create">Create</Button>
            </CardActions>
        </Card>
    )
}
export { ExternalContractListingPage }