import {SubscriptionAsset} from "./SubscriptionAsset";
import {Frequency} from "./Frequency";
import {AdjustmentToCostPerUsageUnit} from "./AdjustmentToCostPerUsageUnit";
import {MinimumUsagePeriod} from "./MinimumUsagePeriod";
import {ResidualValueMatrix} from "./ResidualValueMatrix";
import {PaymentPlan} from "./PaymentPlan";
import {SubscriptionViewModel} from "./SubscriptionViewModel";
import { DrawdownPeriod } from "./DrawdownPeriod";

export class Subscription {
    id!: string;
    name!: string;
    currencyIsoCode!: string;
    investorIds!: string[];
    isActive!: boolean;
    startingCostPerUsageUnit!: number;
    costPerUsageUnit!: number;
    assetOperatorId!: string;
    assets!: SubscriptionAsset[];
    totalAssetsCollected!: number;
    totalAssetsRemainingToCollect!: number;
    totalFinancePerAsset!: number;
    deliveryDate!: string;
    billingStartDate!: string;
    numberOfDaysUntilBillFallsIntoArrears!: number;
    targetContractLengthInYears!: number;
    contractExtensionInYears!: number | undefined;
    targetEndDate!: string;
    maximumContractLengthInYears!: number;
    maximumEndDate!: string;
    usageUnit!: string;
    totalContractedUsage!: number;
    remainingContractedUsage!: number;
    contractedMinimumUsagePeriodUsage!: number;
    contractedMinimumUsagePeriodFrequency!: Frequency;
    totalFacility!: number;
    totalExpectedRepayment!: number;
    deposit!: number | undefined;
    loanToValueRatio!: number;
    projectedInternalRateOfReturn!: number;
    adjustCostPerUsageUnitWithInflation!: boolean;
    adjustmentsToCostPerUsageUnit!: AdjustmentToCostPerUsageUnit[];
    minimumUsagePeriods!: MinimumUsagePeriod[];
    isBalloonPaymentRequired!: boolean;
    offSystemPaymentsOnly!: boolean;
    vatApplicable!: boolean;
    telematicsCostPerAsset!: number | undefined;
    showStartEndOdometerReadingsOnBill!: boolean | undefined;
    automaticallyCollectCatchupPayments!: boolean;
    feeRate!: number | undefined;
    residualValueMatrices!: ResidualValueMatrix[] | undefined;
    feeCollectionMethod!: string;
    taxRate!: number | undefined
    paymentPlan!: PaymentPlan;
    paymentPlans!: PaymentPlan[];
    shortId!: string
    billMessage!: string
    monthsInContract!: number | undefined
    includeInPublicCO2AndNOxCount!: boolean
    lengthOfDrawdownPeriodInMonths!: number | undefined
    drawdownPeriodProfile!: DrawdownPeriod[]
    enableProRataingMinimumUsagePeriods!: boolean 

    public static Convert(subscriptionViewModel: SubscriptionViewModel): Subscription {

        const subscription = new Subscription();

        subscription.id = subscriptionViewModel.id;
        subscription.name = subscriptionViewModel.name;
        subscription.currencyIsoCode = subscriptionViewModel.currencyIsoCode;
        subscription.investorIds = subscriptionViewModel.investorIds;
        subscription.isActive = subscriptionViewModel.isActive;
        subscription.startingCostPerUsageUnit = subscriptionViewModel.startingCostPerUsageUnit;
        subscription.costPerUsageUnit = subscriptionViewModel.costPerUsageUnit;
        subscription.assetOperatorId = subscriptionViewModel.assetOperatorId;
        subscription.assets = subscriptionViewModel.assets;
        subscription.totalAssetsCollected = subscriptionViewModel.totalAssetsCollected;
        subscription.totalAssetsRemainingToCollect = subscriptionViewModel.totalAssetsRemainingToCollect;
        subscription.totalFinancePerAsset = subscriptionViewModel.totalFinancePerAsset;
        subscription.deliveryDate = subscriptionViewModel.deliveryDate;
        subscription.billingStartDate = subscriptionViewModel.billingStartDate;
        subscription.numberOfDaysUntilBillFallsIntoArrears = subscriptionViewModel.numberOfDaysUntilBillFallsIntoArrears;
        subscription.targetContractLengthInYears = subscriptionViewModel.targetContractLengthInYears;
        subscription.contractExtensionInYears = subscriptionViewModel.contractExtensionInYears;
        subscription.targetEndDate = subscriptionViewModel.targetEndDate;
        subscription.maximumContractLengthInYears = subscriptionViewModel.maximumContractLengthInYears;
        subscription.maximumEndDate = subscriptionViewModel.maximumEndDate;
        subscription.usageUnit = subscriptionViewModel.usageUnit;
        subscription.totalContractedUsage = subscriptionViewModel.totalContractedUsage;
        subscription.remainingContractedUsage = subscriptionViewModel.remainingContractedUsage;
        subscription.contractedMinimumUsagePeriodUsage = subscriptionViewModel.contractedMinimumUsagePeriodUsage;
        subscription.contractedMinimumUsagePeriodFrequency = subscriptionViewModel.contractedMinimumUsagePeriodFrequency;
        subscription.totalFacility = subscriptionViewModel.totalFacility;
        subscription.totalExpectedRepayment = subscriptionViewModel.totalExpectedRepayment;
        subscription.deposit = subscriptionViewModel.deposit;
        subscription.loanToValueRatio = subscriptionViewModel.loanToValueRatio;
        subscription.projectedInternalRateOfReturn = subscriptionViewModel.projectedInternalRateOfReturn;
        subscription.adjustCostPerUsageUnitWithInflation = subscriptionViewModel.adjustCostPerUsageUnitWithInflation;
        subscription.adjustmentsToCostPerUsageUnit = subscriptionViewModel.adjustmentsToCostPerUsageUnit;
        subscription.minimumUsagePeriods = subscriptionViewModel.minimumUsagePeriods;
        subscription.isBalloonPaymentRequired = subscriptionViewModel.isBalloonPaymentRequired;
        subscription.offSystemPaymentsOnly = subscriptionViewModel.offSystemPaymentsOnly;
        subscription.vatApplicable = subscriptionViewModel.vatApplicable;
        subscription.telematicsCostPerAsset = subscriptionViewModel.telematicsCostPerAsset;
        subscription.showStartEndOdometerReadingsOnBill = subscriptionViewModel.showStartEndOdometerReadingsOnBill;
        subscription.automaticallyCollectCatchupPayments = subscriptionViewModel.automaticallyCollectCatchupPayments;
        subscription.feeRate = subscriptionViewModel.vmFeeRate === null ? undefined: subscriptionViewModel.vmFeeRate / 100;
        subscription.residualValueMatrices = subscriptionViewModel.residualValueMatrices;
        subscription.feeCollectionMethod = subscriptionViewModel.feeCollectionMethod;
        subscription.taxRate = subscriptionViewModel.vmTaxRate === null ? undefined : subscriptionViewModel.vmTaxRate / 100;
        subscription.paymentPlan = subscriptionViewModel.paymentPlan;
        subscription.paymentPlans = subscriptionViewModel.paymentPlans;
        subscription.shortId = subscriptionViewModel.shortId;
        subscription.billMessage = subscriptionViewModel.billMessage;
        subscription.monthsInContract = subscriptionViewModel.monthsInContract;
        subscription.includeInPublicCO2AndNOxCount = subscriptionViewModel.includeInPublicCO2AndNOxCount;
        subscription.lengthOfDrawdownPeriodInMonths = subscriptionViewModel.lengthOfDrawdownPeriodInMonths;
        subscription.drawdownPeriodProfile = subscriptionViewModel.drawdownPeriodProfile;
        subscription.enableProRataingMinimumUsagePeriods = subscriptionViewModel.enableProRataingMinimumUsagePeriods;

        return subscription;
    }
}




