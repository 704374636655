
import { Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const CountdownToNextPaymentSubmission: React.FC = props => {
    const [paymentSubmissionCountdown, setPaymentSubmissionCountdown] = useState<string>()
    const [paymentSubmissionCountdownColor, setPaymentSubmissionCountdownColor] = useState<string>()
    const theme = useTheme()
    const nineAm = 9;
    const elevenAm = 11;
    const TwoPm = 14;
    const nineAmTomorrow = 31;
    const thirtyMinutesInMs = 1000*60*30;

    useEffect(() => {
    //Trigger times: 9am, 11am, 2pm
    let secTimer = setInterval( () => {
        var now = new Date();

        var nextTriggerHour = getNextPaymentSubmissionTime(now);

        var nextTrigger = new Date(now.getFullYear(), now.getMonth(), now.getDate(), nextTriggerHour, 0, 0);
        var nextTriggerInMs = nextTrigger.getTime();
        var differenceInMs = nextTriggerInMs - now.getTime();

        if(differenceInMs < thirtyMinutesInMs) setPaymentSubmissionCountdownColor(theme.palette.warning.main)
        else setPaymentSubmissionCountdownColor(theme.palette.text.primary)

        setPaymentSubmissionCountdown(msToTime(differenceInMs))
        },1000)

        return () => clearInterval(secTimer);
    }, [thirtyMinutesInMs, theme.palette.warning.main, theme.palette.text.primary]);

    function getNextPaymentSubmissionTime(now: Date){
        var nextTriggerHour;
        if(nineAm - now.getHours() > 0) nextTriggerHour = nineAm;
            else if(elevenAm - now.getHours() > 0) nextTriggerHour = elevenAm;
            else if(TwoPm - now.getHours() > 0) nextTriggerHour = TwoPm;
            else nextTriggerHour = nineAmTomorrow;
        return nextTriggerHour;
    }

    // taken from https://stackoverflow.com/questions/19700283/how-to-convert-time-in-milliseconds-to-hours-min-sec-format-in-javascript
    function msToTime(duration : number) {
        var seconds = Math.floor((duration / 1000) % 60)
        var minutes = Math.floor((duration / (1000 * 60)) % 60)
        var hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
  
        return hours + " hours " + minutes + " minutes " + seconds + " seconds";
    }

    return (<Typography style={{color: paymentSubmissionCountdownColor}} variant="subtitle1">Time until next payment submission check: {paymentSubmissionCountdown}</Typography>)
}

export{ CountdownToNextPaymentSubmission }