import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, FormControlLabel, FormGroup, Switch, TextField, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { createNewInvestorUser, deleteSelectedInvestorUsers, makeInvestorUsersAdmin, makeInvestorUsersNormal } from '../services/InvestorService';
import {InvestorViewModel} from "../models/InvestorViewModel";

function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function validateEmailAddress(email: string) {
    const atSymbol = "@";
    const dotSymbol = ".";
    const positionOfAtSymbol = email.indexOf(atSymbol);
    if (positionOfAtSymbol >= 1) {
        const substringAfterAtSymbol = email.substring(positionOfAtSymbol);
        return (substringAfterAtSymbol.includes(dotSymbol))
    }
    return false;
}

type UserAccessManagementProps = {
    investorViewModel: InvestorViewModel
    setInvestor: Function
}

export default function UserAccessManagement({ investorViewModel, setInvestor }: UserAccessManagementProps) {
    const [checked, setChecked] = React.useState<readonly string[]>([]);
    const [adminUsers, setAdminUsers] = React.useState<readonly string[]>(investorViewModel.adminIds ?? []);
    const [normalUsers, setNormalUsers] = React.useState<readonly string[]>(investorViewModel.userIds ?? []);
    const [newUserEmailAddress, setNewUserEmailAddress] = React.useState<string>("")
    const [message, setMessage] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isSuccessful, setIsSuccessful] = React.useState<boolean | null>(null);
    const [newUserIsAdmin, setNewUserIsAdmin] = React.useState<boolean>(false);
    const checkedAdminUsers = intersection(checked, adminUsers);
    const checkedNormalUsers = intersection(checked, normalUsers);

    const theme = useTheme()

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleMakeUsersNormal = () => {
        setIsLoading(true);
        makeInvestorUsersNormal(investorViewModel.id ?? "", [...checked])
            .then((message: [string, boolean]) => {
                setMessage(message[0]);
                const isSuccess = message[1]
                setIsSuccessful(isSuccess);
                if (isSuccess) {
                    let newAdminIds = investorViewModel.adminIds ? investorViewModel.adminIds.filter(id => !checked.includes(id)) : []
                    let newUserIds = investorViewModel.userIds ? investorViewModel.userIds.concat(checked) : [...checked]
                    setInvestor({...investorViewModel, adminIds : newAdminIds, userIds : newUserIds})
                }
                setChecked([])
                setIsLoading(false);
            });
    };

    const handleMakeUsersAdmin = () => {
        setIsLoading(true);
        makeInvestorUsersAdmin(investorViewModel.id ?? "", [...checked])
            .then((message: [string, boolean]) => {
                setMessage(message[0]);
                const isSuccess = message[1]
                setIsSuccessful(isSuccess);
                if (isSuccess) {
                    let newAdminIds = investorViewModel.adminIds ? investorViewModel.adminIds.concat(checked) : [...checked]
                    let newUserIds = investorViewModel.userIds ? investorViewModel.userIds.filter(id => !checked.includes(id)) : []
                    setInvestor({...investorViewModel, adminIds : newAdminIds, userIds : newUserIds})
                }
                setChecked([])
                setIsLoading(false);
            });
    };

    const handleCreateClicked = () => {
        setIsLoading(true);
        createNewInvestorUser(investorViewModel.id ?? "", newUserEmailAddress, newUserIsAdmin)
            .then((message: [string, boolean]) => {
                setMessage(message[0]);
                const isSuccess = message[1]
                setIsSuccessful(isSuccess);
                if (isSuccess) {
                    if (newUserIsAdmin) {
                        let newAdminIds = investorViewModel.adminIds ?? []
                        newAdminIds.push(newUserEmailAddress)
                        setInvestor({...investorViewModel, adminIds : newAdminIds})
                    } else {
                        let newUserIds = investorViewModel.userIds ?? []
                        newUserIds.push(newUserEmailAddress)
                        setInvestor({...investorViewModel, userIds : newUserIds})
                    }
                }
                setChecked([])
                setIsLoading(false);
                setNewUserEmailAddress("");
                setNewUserIsAdmin(false);
            })
    }

    const handleDeleteSelected = () => {
        setIsLoading(true);
        deleteSelectedInvestorUsers(investorViewModel.id ?? "", [...checked])
            .then((message: [string, boolean]) => {
                setMessage(message[0]);
                const isSuccess = message[1]
                setIsSuccessful(isSuccess);
                if (isSuccess) {
                    let newAdminIds = investorViewModel.adminIds.filter(id => !checked.includes(id))
                    let newUserIds = investorViewModel.userIds.filter(id => !checked.includes(id))
                    setInvestor({...investorViewModel, adminIds : newAdminIds, userIds : newUserIds})
                }
                setChecked([])
                setIsLoading(false);
            })
    }

    const handleSwitchChange = () => {
        setNewUserIsAdmin(!newUserIsAdmin);
    }

    useEffect(() => {
        setAdminUsers(investorViewModel.adminIds ?? []);
        setNormalUsers(investorViewModel.userIds ?? []);
        setChecked([]);
    }, [investorViewModel])

    const customList = (items: readonly string[], listTitle: string) => (
        <Paper sx={{ width: '100%', minWidth: '250px', height: 230, overflow: 'auto' }}>
            <Typography variant="subtitle1" paddingLeft="2%" paddingTop="2%">
                {listTitle}
            </Typography>
            <List dense component="div" role="list">
                {items.map((value: string) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Card elevation={10} style={{ marginBottom: '20px' }} sx={{ width: '100%' }}>
            <CardHeader titleTypographyProps={{ style: { fontSize: 16 } }} title={"Manage Access to " + investorViewModel.id} subheader={`Access will change immediately, without needing to click the 'update' button at the top of the page.`} />
            <CardContent sx={{ width: '100%' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" sx={{ width: '100%' }}>
                    <Grid item sx={{ width: '100%', paddingRight: '16px' }}>{customList(adminUsers, "Admin Users")}</Grid>
                    <Grid item sx={{ width: '100%' }}>
                        <Grid container direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                            <Grid item>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="contained"
                                    size="small"
                                    onClick={handleMakeUsersNormal}
                                    disabled={checkedAdminUsers.length === 0}
                                    aria-label="move selected right"
                                >
                                    Down
                                </Button></Grid>
                            <Grid item>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="contained"
                                    size="small"
                                    onClick={handleMakeUsersAdmin}
                                    disabled={checkedNormalUsers.length === 0}
                                    aria-label="move selected left"
                                >
                                    Up
                                </Button></Grid>
                            <Grid item>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="contained"
                                    size="small"
                                    onClick={handleDeleteSelected}
                                    disabled={checked.length === 0}
                                    aria-label="delete selected"
                                >
                                    Delete
                                </Button></Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ width: '100%', padding: '16px' }}>{customList(normalUsers, "Normal Users")}</Grid>
                </Grid>
                <Grid container direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                    <Grid item>
                        <TextField InputLabelProps={{ style: { top: '-7px' } }} label="Email Address" id="email-address-field" variant="outlined" value={newUserEmailAddress} onChange={event => { setNewUserEmailAddress(event.target.value); setMessage(""); setIsSuccessful(null) }} />
                    </Grid>
                    <Grid item>
                        <FormGroup>
                            <FormControlLabel control={<Switch aria-label='make new user admin'
                                checked={newUserIsAdmin}
                                onChange={handleSwitchChange}
                            />} label="Make Admin" />
                        </FormGroup>
                    </Grid><Grid item>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="contained"
                            size="small"
                            onClick={handleCreateClicked}
                            aria-label="add new user"
                            disabled={!validateEmailAddress(newUserEmailAddress) || isLoading}
                        >
                            Create
                        </Button>
                    </Grid>
                    <Grid item>
                        {isLoading && (<span>Loading</span>)}
                        {message && !isLoading && (<span style={{ color: isSuccessful ? theme.palette.success.main : theme.palette.error.main }}>
                            {message}
                        </span>)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

