import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Modal,
    Select,
    Switch,
    TextField,
    Theme,
    Typography
} from "@mui/material";
import {genericLabelProps} from "../props/GenericLabelProps";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {SubscriptionAsset} from "../models/SubscriptionAsset";
import { convertFromGramsToTonnes, convertFromTonnesToGrams } from "../helpers/ConvertTonnesAndGrams";
import { addHours } from "date-fns";

type EditAssetModalProps = {
    editAssetModalOpen: boolean,
    setEditAssetModalOpen: Function,
    assetBeingEdited: SubscriptionAsset | undefined,
    setAssetBeingEdited: Function,
    assets: SubscriptionAsset[],
    setAssets: Function,
    indexOfAssetBeingEdited: number

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            border: '2px solid',
            borderColor: theme.palette.text.primary,
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                height: "100%",
                overflow: 'scroll',
            },
            [theme.breakpoints.up('md')]: {
                width: "50%",

            }
        },
        modalContainer: {
            padding: "8px",
            minHeight: "70px"
        },
        actionButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "36.5px",
            marginBottom: "10px",
            textAlign: "center"
        }
    }));


function EditAssetModal({
                            editAssetModalOpen,
                            setEditAssetModalOpen,
                            assetBeingEdited,
                            setAssetBeingEdited,
                            assets,
                            setAssets,
                            indexOfAssetBeingEdited
                        }: EditAssetModalProps): JSX.Element {

    const classes = useStyles();
    const [changeHasBeenMade, setChangeHasBeenMade] = React.useState(false);

    function handleEditAssetClose() {
        setEditAssetModalOpen(false);
        setChangeHasBeenMade(false);
    }

    const handleConfirmClick = () => {
        let currentAssets = assets ?? [];
        if (assetBeingEdited) {
            currentAssets[indexOfAssetBeingEdited] = assetBeingEdited
            setAssets([...currentAssets])
            setChangeHasBeenMade(false);
            setEditAssetModalOpen(false);
        }
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Modal open={editAssetModalOpen} onClose={handleEditAssetClose}>
                    <div className={classes.modal}>
                        <Card>
                            <CardHeader title="Edit Asset"/>

                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Vin</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={assetBeingEdited?.usageSourceId ?? ""}
                                                    variant="standard"
                                                    InputLabelProps={genericLabelProps}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                usageSourceId: event.target.value
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}/>

                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">License Plate</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={assetBeingEdited?.alternativeId ?? ""}
                                                    variant="standard"
                                                    InputLabelProps={genericLabelProps}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                alternativeId: event.target.value
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}/>

                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Make</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={assetBeingEdited?.make ?? ""}
                                                    variant="standard"
                                                    InputLabelProps={genericLabelProps}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                make: event.target.value
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Model</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={assetBeingEdited?.model ?? ""}
                                                    variant="standard"
                                                    InputLabelProps={genericLabelProps}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                model: event.target.value
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Trim</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={assetBeingEdited?.trim ?? ""}
                                                    variant="standard"
                                                    InputLabelProps={genericLabelProps}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                trim: event.target.value
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">CO2 Saved Per Usage Unit</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={convertFromTonnesToGrams(assetBeingEdited?.co2SavedPerUsageUnit) ?? ""}
                                                    variant="standard"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                            g
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    InputLabelProps={genericLabelProps} onChange={(event: any) => {
                                                    if (assetBeingEdited) {
                                                        setAssetBeingEdited({
                                                            ...assetBeingEdited,
                                                            co2SavedPerUsageUnit: convertFromGramsToTonnes(event.target.value)
                                                        })
                                                        setChangeHasBeenMade(true)
                                                    }
                                                }}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">NOX Saved Per Usage Unit</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={convertFromTonnesToGrams(assetBeingEdited?.noxSavedPerUsageUnit) ?? ""}
                                                    variant="standard"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                            g
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    InputLabelProps={genericLabelProps} onChange={(event: any) => {
                                                    if (assetBeingEdited) {
                                                        setAssetBeingEdited({
                                                            ...assetBeingEdited,
                                                            noxSavedPerUsageUnit: convertFromGramsToTonnes(event.target.value)
                                                        })
                                                        setChangeHasBeenMade(true)
                                                    }
                                                }}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Asset
                                                    Type</Typography>
                                            </Grid>
                                            <Grid item xs={6}>

                                                <FormControl fullWidth>

                                                    <Select
                                                        value={assetBeingEdited?.assetType ?? ""}
                                                        inputProps={{
                                                            name: "Asset Type",
                                                        }}
                                                        onChange={(event: any) => {
                                                            if (assetBeingEdited) {
                                                                setAssetBeingEdited({
                                                                    ...assetBeingEdited,
                                                                    assetType: event.target.value
                                                                })
                                                                setChangeHasBeenMade(true)
                                                            }
                                                        }}
                                                        variant="standard"
                                                    >
                                                        <MenuItem key={0} value={0}>Vehicle</MenuItem>
                                                        <MenuItem key={1} value={1}>Tyres</MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Year</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    style={{width: "100%"}}
                                                    value={assetBeingEdited?.yearOrVersion ?? ""}
                                                    variant="standard"
                                                    InputLabelProps={genericLabelProps}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                yearOrVersion: event.target.value
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}/>

                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Collection
                                                    Date</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DesktopDatePicker
                                                    inputFormat="dd-MM-yyyy"
                                                    value={assetBeingEdited?.collectionDate ?? ""}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                collectionDate: event
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1">Install Date</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DesktopDatePicker
                                                    inputFormat="dd-MM-yyyy"
                                                    componentsProps={{
                                                        actionBar: {
                                                          actions: ['clear']
                                                        }
                                                    }}
                                                    value={assetBeingEdited?.installDate}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            var date = event === null ? null : addHours(event, 3);
                                                            
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                installDate: date
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params} />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Not In Use Start Date</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DesktopDatePicker
                                                    inputFormat="dd-MM-yyyy"
                                                    value={assetBeingEdited?.notInUseStartDate ?? ""}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                notInUseStartDate: event
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params} error={false} />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Not In Use End Date</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DesktopDatePicker
                                                    inputFormat="dd-MM-yyyy"
                                                    value={assetBeingEdited?.notInUseEndDate ?? ""}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                notInUseEndDate: event
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params} error={false} />}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.modalContainer}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    variant="subtitle1">Archived</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    control={<Switch color="primary"
                                                                     checked={assetBeingEdited?.isArchived}/>}
                                                    onChange={(event: any) => {
                                                        if (assetBeingEdited) {
                                                            setAssetBeingEdited({
                                                                ...assetBeingEdited,
                                                                isArchived: event.target.checked
                                                            })
                                                            setChangeHasBeenMade(true)
                                                        }
                                                    }}
                                                    label=""
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {changeHasBeenMade ?
                                            <>
                                                <Grid container>
                                                    <Grid item xs={6} className={classes.actionButton}>
                                                        <Button

                                                            color="primary"
                                                            onClick={handleConfirmClick}
                                                            variant="contained"
                                                            startIcon={<DoneIcon/>}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.actionButton}>
                                                        <Button
                                                            color="primary"
                                                            onClick={handleEditAssetClose}
                                                            variant="contained"
                                                            startIcon={<CloseIcon/>}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className={classes.actionButton}>
                                                    <Typography variant="subtitle2">These changes have still not been
                                                        submitted to the database, once confirmed please click the
                                                        submit button at the top of the screen</Typography>
                                                </Grid>
                                            </>
                                            : <>
                                                <Grid container>
                                                    <Grid item xs={6} className={classes.actionButton}>
                                                        <Button
                                                            disabled
                                                            color="primary"
                                                            onClick={handleConfirmClick}
                                                            variant="contained"
                                                            startIcon={<DoneIcon/>}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.actionButton}>
                                                        <Button
                                                            color="primary"
                                                            onClick={handleEditAssetClose}
                                                            variant="contained"
                                                            startIcon={<CloseIcon/>}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className={classes.actionButton}>
                                                    <Typography variant="subtitle2"></Typography>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                </Modal>
            </LocalizationProvider>
        </>
    )
}

export {EditAssetModal}