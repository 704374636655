import {DemoUserAccess} from "../models/DemoUserAccess";
import {getSettings} from "./SettingsService"

async function addCustomerEmailToDemoAccount(subscriptionId: string, customerEmail: string, expiryDate: Date): Promise<string> {

    const settings = await getSettings();       
    
    const subscriptionUri =`${settings.BillingServiceBaseUri}/subscriptions/${subscriptionId}/addCustomerEmailToDemoAccount/`
    const options = {
        method: "POST",
        body: JSON.stringify({ customerEmail: customerEmail, expiryDate: expiryDate })
    };
    
    const response = await fetch(subscriptionUri, options) 
    
    if (response.status === 500) {
        return "ERROR SENDING EMAIL"
    }
  
  return await response.text();
}

async function extendCustomersRemainingTime(user: DemoUserAccess | undefined, newExpiryDate: Date) : Promise<string> {
    const settings = await getSettings();    
   
    const extendCustomerUri =`${settings.BillingServiceBaseUri}/assetOperator/${user?.assetOperatorId}/extendCustomersRemainingTime/`
    
    const options = {
        method: "POST",
        body: JSON.stringify({ customerEmail: user?.email, expiryDate: newExpiryDate })
    };
    
    const response = await fetch(extendCustomerUri, options)

    if (response.status === 500) {
        return "ERROR UPDATING EXPIRY DATE"
    }

    return await response.text();
}

async function getDemoUsers(): Promise<DemoUserAccess[]> {
    const settings = await getSettings();
    let demoUsers: DemoUserAccess[];
    
    const demoUsersUri =`${settings.BillingServiceBaseUri}/demoUserAccess/`
    const options = {
        method: "GET",
    };
    
    const response = await fetch(demoUsersUri, options);
    demoUsers = await response.json()
   
    return demoUsers;
}

export { addCustomerEmailToDemoAccount, getDemoUsers , extendCustomersRemainingTime }
