import { Button, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { ConvertCurrencyToSymbol } from "../../helpers/ConvertCurrencyToSymbol";
import { GetPaymentStatusTextColor } from "../../helpers/GetPaymentStatusTextColor";
import { Payment } from "../../models/Payment"

interface PaymentsToActionTableProps {
    payments: Payment[];
    onCancelClick: Function;
    onArchiveClick: Function;
    onRetryClick: Function;
  }

const PaymentsToActionTable: React.FC<PaymentsToActionTableProps> = props => {
    const theme = useTheme()
    const columns: any[] = [
        { field: 'assetOperatorName', headerName: 'Asset Operator Name', width: 200 },
        { field: 'subscriptionId', headerName: 'Subscription Id', width: 200 },
        { field: 'targetDate', headerName: 'Target Date', width: 120, filterable: false, renderCell: (params: any): string => {
            return params.row.targetDate == null ? '' : new Date(params.row.targetDate).toLocaleDateString()
          } },
        { field: 'currency', headerName: 'Currency', width: 100 },
        { field: 'paymentAmount', headerName: 'Amount', width: 110, renderCell: (params: any): string => {
            return params.row.currencyIsoCode == null ? '' : ConvertCurrencyToSymbol(params.row.currencyIsoCode) + params.row.paymentAmount.toLocaleString()
          }, },
        { field: 'paymentType', headerName: 'Type', width: 160 },
        { field: 'paymentStatus', headerName: 'Status', width: 90, renderCell: (params: any): JSX.Element => {
            return (<Typography style={{fontSize: 14, color: GetPaymentStatusTextColor(params.row.paymentStatus, theme)}}>{params.row.paymentStatus}</Typography>)
        } },
        { field: 'arrearsManagement', headerName: 'Arrears Management', width: 150 },
        {
          field: 'detailsButton', headerName: ' ', width: 350, renderCell: (params: any): JSX.Element => {
              let toDetails = `/payments/details/${params.row.paymentId}`
              return (<>
                  <Button
                      component={Link}
                      to={toDetails}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginRight: 12 }}
                  >
                      Details
                  </Button>
                  {params.row.paymentStatus === "Queued" ?
                  <Button
                      onClick={() => props.onCancelClick(params, theme)}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginRight: 12 }}
                  >
                  Cancel
                  </Button>
              :
              params.row.paymentStatus === "Failed" && params.row.numberOfPreviouslyFailedAttempts !== 0 ?
              <>
                <Button
                    onClick={() => props.onArchiveClick(params, theme)}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: 12 }}
                >
                    Archive Record
                </Button>
                <Button
                    onClick={() => props.onRetryClick(params, theme)}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: 12 }}
                >
                    Retry Payment
                </Button>
              </>
              :
              params.row.paymentStatus === "Failed" && params.row.numberOfPreviouslyFailedAttempts === 0 ?
              <><Tooltip  title="Currently in an automatic retry process">
                <span>
                    <Button
                        onClick={() => props.onArchiveClick(params, theme)}
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled
                        style={{ marginRight: 12 }}
                    >
                        Archive Record
                    </Button>
                    </span>
                </Tooltip>
                <Tooltip  title="Currently in an automatic retry process">
                    <span>
                        <Button
                            onClick={() => props.onRetryClick(params, theme)}
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled
                            style={{ marginRight: 12 }}
                        >
                            Retry Payment
                        </Button>
                    </span>
                </Tooltip>
              </>
              :
              <></>
              }
              </>
              )
          },
      }
      ];

return (
    <div style={{ height: '63vh', width: '100%' }}>
        <DataGrid
            rows={props.payments}
            columns={columns}
            rowsPerPageOptions={[10,50,100]}
            initialState={{
                pagination:{
                    pageSize: 10
                }
            }}
        />
    </div>
    )
}
export { PaymentsToActionTable }