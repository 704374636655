import { UsageUnit } from "../models/UsageUnit";

function ConvertUsageUnitToContractType(usageUnit: UsageUnit): string {
    switch (usageUnit) {
        case UsageUnit.Miles: {
            return "Pay Per Mile"
        }
        case UsageUnit.ActiveAssets: {
            return "Pay Per Vehicle"
        }
        default:
            return "Pay Per Mile"
    }
}

export {ConvertUsageUnitToContractType};
