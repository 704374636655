import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Address } from '../models/Address';
import { createInvestor } from '../services/InvestorService';
import { Investment } from "../models/Investment";
import { Countries } from "../data/Countries";
import { InvoicingAndPaymentCollectionMethod } from '../models/InvoicingAndPaymentCollectionMethod';
import { SubscriptionViewModel } from '../models/SubscriptionViewModel';
import { getSubscriptions } from '../services/SubscriptionService';
import { InvestorCreate } from '../components/InvestorCreate';
import {InvestorViewModel} from "../models/InvestorViewModel";

const InvestorCreatePage: React.FC = ({ match }: any) => {
    let history = useHistory();
    const [investorViewModel, setInvestorViewModel] = useState<InvestorViewModel>({ investmentManagementFeeInvoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, registeredAddress: { countryCode: 'GB' } as Address, vmTaxRateChargedOnFees: 20 } as InvestorViewModel);
    const [allSubscriptions, setAllSubscriptions] = useState<SubscriptionViewModel[]>([]);
    const [submitButtonText, setSubmitButtonText] = useState<string>("Create");
    const onSubscriptionSelect = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;

        var newInvestments: Investment[] = [];
        (value as string[]).forEach((id: string) => {
            var investment: Investment = { subscriptionId: id }
            newInvestments.push(investment)
        })

        setInvestorViewModel({ ...investorViewModel, investments: newInvestments });
    }

    useEffect(() => {
        let mounted = true;
        getSubscriptions()
            .then(subs => {
                if (mounted) {
                    setAllSubscriptions(subs);
                }
            })
        return () => {
            mounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickCreate = () => {
        createInvestor(investorViewModel).then((investorId) => 
        {
            if (investorId === "FAIL")
            {
                setSubmitButtonText("Failed to create investor")
            } else {
            history.push("/investors/" + investorId)
            }
        })
        
    }, countriesDropDown: any[] = [];

    Countries.forEach(country => {
        countriesDropDown.push(<MenuItem value={country.IsoCode}>{country.Name}</MenuItem>)
    })

    const emailAddressIsValid = (emailAddress: string | undefined) => {
        if (typeof emailAddress === 'string') {
            const atSymbolIndex = emailAddress.indexOf('@');
            if (atSymbolIndex > 0 && emailAddress.substring(atSymbolIndex).includes('.')) {
                return true;
            }
        }

        return false;
    }
    const theme = useTheme();
    return <>
        <Card style={{ marginBottom: theme.spacing(3) }}>
            <CardHeader title="Create a Lender"
                action={emailAddressIsValid(investorViewModel.contactEmail) ?
                    <Button variant="contained" color="primary" onClick={() => onClickCreate()} disabled={submitButtonText !== "Create"}>{submitButtonText}</Button>
                    : <>
                        <Typography variant="caption" style={{ color: theme.palette.warning.main }}>
                            Please add a valid contact email address
                        </Typography>
                        <Button variant="contained" color="primary" style={{ marginLeft: theme.spacing(1) }} disabled>Create</Button>
                    </>
                } />
        </Card>
        <Grid container spacing={5} style={{ marginBottom: '20px' }}>
            <Grid item style={{ width: '100vw', marginLeft: 'auto', marginRight: 'auto' }}>
                <InvestorCreate investorViewModel={investorViewModel} setInvestorViewModel={setInvestorViewModel} />
            </Grid>
            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Investment Info" />
                    <CardContent>
                        <Select
                            style={{ width: "100%" }}
                            labelId="subscriptionId-multiple-checkbox-label"
                            id="subscriptionId-multiple-checkbox"
                            multiple
                            value={investorViewModel.investments?.map((x: Investment) => x.subscriptionId) ?? []}
                            onChange={onSubscriptionSelect}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {allSubscriptions?.map((subscription: SubscriptionViewModel) =>
                                <MenuItem key={subscription.id} value={subscription.id}>
                                    <Checkbox checked={investorViewModel.investments?.map(x => x.subscriptionId).indexOf(subscription.id) > -1} />
                                    <ListItemText primary={subscription.id} />
                                </MenuItem>
                            )}
                        </Select>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>;

}

export { InvestorCreatePage }
