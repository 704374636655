import {CurrencyIsoCodes} from "../models/CurrencyIsoCodes";

function ConvertCurrencyToSymbol(currencyIsoCode: string): string {
    switch (currencyIsoCode) {
        case CurrencyIsoCodes.Gbp:
            return "£";
        case CurrencyIsoCodes.Usd:
            return "$";
        
        default:
            return "£";
    }
}

export {ConvertCurrencyToSymbol};
