import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    Select,
    TextField,
    Theme,
    Typography,
    useTheme
} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {genericLabelProps} from "../props/GenericLabelProps";
import {ConvertCurrencyToSymbol} from "../helpers/ConvertCurrencyToSymbol";
import {DataGrid} from "@mui/x-data-grid";
import {format} from "date-fns";
import {Subscription} from "../models/Subscription";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from "@mui/icons-material/Done";
import {PaymentPlan} from "../models/PaymentPlan";
import {getPaymentPlanViewModel, PostPaymentPlanToEdit} from "../services/PaymentPlanService";
import {GetPaymentStatusTextColor} from "../helpers/GetPaymentStatusTextColor";
import {PaymentPlanRepayment} from "../models/PaymentPlanRepayment";
import {
    ConvertPaymentPlanRepaymentPaymentStatusToString
} from "../helpers/ConvertPaymentPlanRepaymentPaymentStatusToString";

type EditPaymentPlanProps = {
    subscription: Subscription,
    reloadSubscription: Function,
    paymentPlanIdToEdit: string,
    handleEditNewPaymentPlanModalClose: Function,
    editPaymentPlanModalOpen: boolean,
    setEditPaymentPlanModalOpen: Function
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContainer: {
            padding: "8px",
            minHeight: "70px"
        },
        modal: {
            position: 'fixed',
            border: '2px solid',
            borderColor: theme.palette.text.primary,
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                height: "100%",
                overflow: 'scroll',
            },
            [theme.breakpoints.up('md')]: {
                width: "50%",

            }
        },
        actionButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "36.5px",
            marginBottom: "10px",
            textAlign: "center"
        }
    })
)

function EditPaymentPlanModal({
                                    subscription,
                                    reloadSubscription,
                                    paymentPlanIdToEdit,
                                    editPaymentPlanModalOpen,
                                    setEditPaymentPlanModalOpen
                                }: EditPaymentPlanProps): JSX.Element {
    
    const dateFormat = "dd/MM/yy";
    const theme = useTheme()
    const classes = useStyles();
    const [totalAmountInPaymentPlan, setTotalAmountInPaymentPlan] = useState<number>(0);

    const [paymentPlanToEdit, setPaymentPlanToEdit] = useState<PaymentPlan | undefined>(undefined);

    useEffect(() => {
        if(paymentPlanIdToEdit === "") return;

        getPaymentPlanViewModel(subscription.id, paymentPlanIdToEdit).then((paymentPlanViewModel) => {
            setPaymentPlanToEdit(paymentPlanViewModel)
            setTotalAmountInPaymentPlan(paymentPlanViewModel?.repayments.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.amount ?? 0),
                0,
              ) ?? 0)
        })     
    }, [subscription, paymentPlanIdToEdit])

    const makeRows = (repayments: PaymentPlanRepayment[] | undefined) => {
        if(repayments === undefined) return [];

        return repayments.map((repayment, index) => {
            return {
                id: repayment.id ?? index,
                installmentNumber: index + 1,
                amount: repayment.amount,
                repaymentDate: repayment.repaymentDate,
                paymentStatus: ConvertPaymentPlanRepaymentPaymentStatusToString(repayment.paymentStatus),
                isEditable: repayment.isEditable
            }
        });
    }
    
    const editExistingPaymentPlanRepaymentDate = (repaymentId: string, newDate: string) => {
        if(paymentPlanToEdit === undefined) return;

        let paymentPlanToEditCopy = {...paymentPlanToEdit};
        debugger
        paymentPlanToEditCopy.repayments.filter(repayment => repayment.id === repaymentId)[0].repaymentDate = newDate;
        setPaymentPlanToEdit(paymentPlanToEditCopy);
    }

    const editExistingPaymentPlanAmount = (repaymentId: string, event: ChangeEvent<HTMLInputElement>) => {
        if(paymentPlanToEdit === undefined) return;

        let newAmount = !!event.target.value ? Number(event.target.value) : undefined;

        let paymentPlanToEditCopy = {...paymentPlanToEdit};
        paymentPlanToEditCopy.repayments.filter(row => row.id === repaymentId)[0].amount = newAmount;
        setPaymentPlanToEdit(paymentPlanToEditCopy);
    }

    const deleteRow = (repaymentId: string) => {
        if(paymentPlanToEdit === undefined) return;

        let paymentPlanToEditCopy = {...paymentPlanToEdit};

        const newRepaymentsArray = paymentPlanToEditCopy.repayments?.filter(function (obj) {
            return obj.id !== repaymentId;
        });

        paymentPlanToEditCopy.repayments = newRepaymentsArray;
        paymentPlanToEditCopy.numberOfRepayments = newRepaymentsArray?.length ?? 0;
        setPaymentPlanToEdit(paymentPlanToEditCopy)      
    }

    const installmentColumns: any[] = [
        {
            field: 'installmentNumber',
            headerName: 'Installment No.',
            width: 200
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'number',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return <TextField style={{textAlign: "right"}}
                    key={'repaymentAmount-' + params.row.id}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => editExistingPaymentPlanAmount(params.row.id, event)}
                    type="number"
                    value={params.row.amount ?? ''}
                    disabled={!params.row.isEditable}
                    error={params.row.amount === undefined || params.row.amount === 0 || params.row.amount < 0}
                    variant={"standard"}
                />
            }
        },
        {
            field: 'repaymentDate',
            headerName: 'Date',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return params.row.isEditable ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            key={'repaymentDate-' + params.row.id}
                            inputFormat={dateFormat}
                            onChange={(newValue: any) => editExistingPaymentPlanRepaymentDate(params.row.id, newValue as string)}
                            value={params.row.repaymentDate}
                            renderInput={(params) => <TextField {...params} variant={"standard"}/>}
                        />
                    </LocalizationProvider>
                    :
                    <>{format(new Date(params.row.repaymentDate), 'dd/MM/yy')}</>
            }
        },
        {
            field: 'paymentStatus',
            headerName: 'Status',
            width: 200,
            renderCell: (params: any): JSX.Element => {
                return <Typography sx={{fontSize: '14px'}} color={GetPaymentStatusTextColor(params.row.paymentStatus, theme)}>{params.row.paymentStatus}</Typography>
            }
        },
        {
            field: 'deleteButton',
            headerName: '',
            width: 100,
            renderCell: (params: any): JSX.Element => {
                return params.row.isEditable ? <IconButton
                color="primary"
                onClick={() => deleteRow(params.row.id)}
                >
                <DeleteIcon />
                </IconButton>
                :
                <></>
            }
        }
    ]

    function handleEditExistingPaymentPlanModalClose() {
        //setPaymentPlanToEdit(undefined)
        setEditPaymentPlanModalOpen(false);
    }

    function handleSaveEditedPaymentPlanClick() {
        if(paymentPlanToEdit === undefined) return;

        PostPaymentPlanToEdit(paymentPlanToEdit).then((returnedPaymentPlan) => {
            reloadSubscription();

            if (returnedPaymentPlan.responseException)
            {
                alert(returnedPaymentPlan.responseException)
            }
            else {
                handleEditExistingPaymentPlanModalClose();
            }     
        })
    }

    function addNewInstallmentRow() {
        if(paymentPlanToEdit === undefined) return;

        let paymentPlanToEditCopy = {...paymentPlanToEdit};
        const totalNumberOfRows = paymentPlanToEditCopy.repayments?.length ?? 0;
        const tempIdToUse = "tempId" + (totalNumberOfRows + 1);
        const newRow: PaymentPlanRepayment = {
            amount: 0,
            id: tempIdToUse.toString(),
            repaymentDate: format(new Date(), "yyyy-MM-dd"),
            paymentStatus: undefined,
            isEditable: true
        };
        
        paymentPlanToEditCopy.repayments?.push(newRow)
        paymentPlanToEditCopy.numberOfRepayments = paymentPlanToEditCopy.repayments.length;
        setPaymentPlanToEdit(paymentPlanToEditCopy)
    }
    
    const totalAmountInInstallents = paymentPlanToEdit?.repayments.map(row => row.amount).reduce((sum, current) => (sum ? sum : 0) + (current ? current : 0), 0)
    const remainingAmountToBeCoveredInInstalments = !paymentPlanToEdit ? 0 : Math.round((totalAmountInPaymentPlan - (totalAmountInInstallents ? totalAmountInInstallents : 0)) * 100) / 100
    const allRowsContainDates = paymentPlanToEdit?.repayments.filter(row => row.repaymentDate === "").length === 0;
    const rowsExist = (paymentPlanToEdit?.repayments?.length ?? 0) > 0;
    const allRowsHaveAmounts = paymentPlanToEdit?.repayments.filter(row => row.amount === undefined || row.amount === 0 || row.amount < 0).length === 0;
    const canConfirmPaymentPlan = rowsExist && allRowsContainDates && allRowsHaveAmounts && remainingAmountToBeCoveredInInstalments === 0;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Modal open={editPaymentPlanModalOpen} onClose={handleEditExistingPaymentPlanModalClose}>
                <div className={classes.modal}>
                    <Card>
                        <CardHeader title="Edit Payment Plan"/>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Grid container className={classes.modalContainer}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle1">Bill Period</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={paymentPlanToEdit?.billingDate ?? ""}
                                                    inputProps={{
                                                        name: "Bill Period",
                                                    }}
                                                    variant="standard"
                                                    disabled
                                                >
                                                    <MenuItem key={paymentPlanToEdit?.billingDate?.toString()}
                                                        value={paymentPlanToEdit?.billingDate?.toString()}>
                                                            {paymentPlanToEdit?.billingDate ? format(new Date(paymentPlanToEdit?.billingDate), "MMM") + " '" + format(new Date(paymentPlanToEdit?.billingDate), "yy") : ""}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.modalContainer}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle1">Bill Amount</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                style={{width: "100%"}}
                                                value={ConvertCurrencyToSymbol(subscription.currencyIsoCode) + (paymentPlanToEdit?.billAmount ? Math.round(paymentPlanToEdit?.billAmount * 100) / 100 : "")}
                                                variant="standard"
                                                disabled
                                                InputLabelProps={genericLabelProps}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.modalContainer}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle1">No. of Installments</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                style={{width: "100%"}}
                                                value={paymentPlanToEdit?.numberOfRepayments ?? ""}
                                                variant="standard"
                                                InputLabelProps={genericLabelProps}
                                                type="number"
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DataGrid
                                        style={{height: '400px'}}
                                        rows={makeRows(paymentPlanToEdit?.repayments)}
                                        columns={installmentColumns}
                                        getRowHeight={() => 'auto'}
                                    />
                                </Grid>

                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {!canConfirmPaymentPlan ?
                                        <Grid item xs={12} style={{textAlign: "center", paddingBottom: "12px"}}>
                                            {!canConfirmPaymentPlan ? <Typography>Remaining Amount To Be Covered In Installments: {remainingAmountToBeCoveredInInstalments}</Typography> : <></>}    
                                        </Grid> : <></>}
                                        
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4} className={classes.actionButton}>
                                            <Button

                                                color="primary"
                                                onClick={addNewInstallmentRow}
                                                variant="contained"
                                                disabled={paymentPlanToEdit?.repayments?.length === 24 ?? false}
                                            >
                                                + New Installment
                                            </Button> 
                                        </Grid>
                                        <Grid item xs={4} className={classes.actionButton}>
                                            <Button

                                                color="primary"
                                                onClick={handleSaveEditedPaymentPlanClick}
                                                variant="contained"
                                                startIcon={<DoneIcon/>}
                                                disabled={!canConfirmPaymentPlan}
                                            >
                                                Confirm
                                            </Button> 
                                                
                                        </Grid>
                                        <Grid item xs={4} className={classes.actionButton}>
                                            <Button
                                                color="primary"
                                                onClick={handleEditExistingPaymentPlanModalClose}
                                                variant="contained"
                                                startIcon={<CloseIcon/>}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} style={{textAlign: "center", paddingBottom: "12px"}}>
                                            <Typography style={{color: theme.palette.warning.main}}>Please note that this will override any existing queued payments for {paymentPlanToEdit?.billingDate ? format(new Date(paymentPlanToEdit?.billingDate), "MMM") + " '" + format(new Date(paymentPlanToEdit?.billingDate), "yy") : "this"} bill</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </Modal>
        </LocalizationProvider>

    )
}

export {EditPaymentPlanModal}

