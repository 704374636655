import React, {useEffect, useState} from "react";
import {InvestorViewModel} from "../models/InvestorViewModel";
import {SubscriptionViewModel} from "../models/SubscriptionViewModel";
import {
    createSubscription,    
    getSubscription
} from "../services/SubscriptionService";
import {getInvestors, sendInvestorWelcomeEmail} from "../services/InvestorService";
import {getAssetOperator, getAssetOperatorIds, sendAssetOperatorWelcomeEmail} from "../services/AssetOperatorService";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,    
    InputAdornment,
    InputLabel,
    LinearProgress,
    ListItemText,
    MenuItem,    
    Select,
    SelectChangeEvent,
    Switch,
    Tab,
    Tabs,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import {genericLabelProps} from "../props/GenericLabelProps";
import {SubscriptionAsset} from "../models/SubscriptionAsset";
import {CurrencyIsoCodes} from "../models/CurrencyIsoCodes";
import {FeeCollectionMethods} from "../models/FeeCollectionMethods";
import {format} from "date-fns";
import {SubmitStatus} from "../models/SubmitStatus";
import {AssetOperator} from "../models/AssetOperator";
import SaveIcon from '@mui/icons-material/Save';
import {ConvertCurrencyToSymbol} from "../helpers/ConvertCurrencyToSymbol";
import {largeLabelProps} from "../props/LargeLabelProps";
import {ConvertFeeCollectionMethodTypeToLabel} from "../helpers/ConvertFeeCollectionMethodTypeToLabel";
import {SubscriptionComponent5} from "../components/SubscriptionComponent5";
import ErrorIcon from '@mui/icons-material/Error';
import {SixMonthMinimumUsage} from "../components/SixMonthMinimumUsage";
import {createStyles, makeStyles} from '@mui/styles';
import {AssetsTab} from "../components/AssetsTab";
import {UsageUnit} from "../models/UsageUnit";
import {ConvertUsageUnitToContractType} from "../helpers/ConvertUsageUnitToContractType";
import {IRRDrawdownPeriodTab} from "../components/IRRDrawdownPeriodTab";
import {DrawdownPeriod} from "../models/DrawdownPeriod";
import {CreatePaymentPlanModal} from "../components/CreatePaymentPlanModal";
import {PaymentPlanSeedingData} from "../models/PaymentPlanSeedingData";
import {PaymentPlansTable} from "../components/PaymentPlansTable";

import {GetSeedingForPaymentPlan} from "../services/PaymentPlanService";
import { EditPaymentPlanModal } from "../components/EditPaymentPlanModal";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        },
        modal: {
            position: 'fixed',
            border: '2px solid #000',
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
    })
);

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
                {children}
          </Box>
        )}
      </Box>
    );
  }
  
const SubscriptionPage: React.FC = ({match}: any) => {
    const theme = useTheme()
    const classes = useStyles()
    const [subscription, setSubscription] = useState<SubscriptionViewModel | null>(null)
    const [investorViewModels, setInvestorViewModels] = useState<InvestorViewModel[] | null>(null)
    const [allInvestorIds, setAllInvestorViewModelIds] = useState<string[] | null>(null)
    const [allAssetOperatorIds, setAllAssetOperatorIds] = useState<string[] | null>(null)
    const [value, setValue] = React.useState(0);
    const [mandatoryFieldsFilled, setMandatoryFieldsFilled] = useState<boolean>(false);
    const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(SubmitStatus.NotSent)   
    const [currencyMismatchMessage, setCurrencyMismatchMessage] = useState<string>('');
    const [usagePeriodUpdatePending, setUsagePeriodUpdatePending] = useState<boolean>(false);
    const [assetOperator, setAssetOperator] = useState<AssetOperator>();
    const [calculatedTotalExpectedRepayment, setCalculatedTotalExpectedRepayment] = useState<number|undefined>()
    const [drawdownPeriodWarning, setDrawdownPeriodWarning] = useState<boolean>(false)
    const [numberOfAssetsWarningMessage, setNumberOfAssetsWarningMessage] = useState<string>("")
    const [totalLoanWarningMessage, setTotalLoanWarningMessage] = useState<string>("")
    const [billingStartDateWarningMessage, setBillingStartDateWarningMessage] = useState<string>("")
    const [lengthOfDrawdownPeriodWarningMessage, setLengthOfDrawdownPeriodWarningMessage] = useState<string>("")
    const [maximumEndDateWarningMessage, setMaximumEndDateWarningMessage] = useState<string>("")
    const [createPaymentPlanModalOpen, setCreatePaymentPlanModalOpen] = useState<boolean>(false);
    const [editPaymentPlanModalOpen, setEditPaymentPlanModalOpen] = useState<boolean>(false);
    const [seedingForPaymentPlans, setSeedingForPaymentPlans] = useState<PaymentPlanSeedingData[]>([]);
    const [paymentPlanIdToEdit, setPaymentPlanIdToEdit] = useState<string>();

    useEffect(() => {
        let mounted = true;
        if(mounted && !createPaymentPlanModalOpen){
            loadPage();
        }      
        
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.subscriptionId, createPaymentPlanModalOpen])

    useEffect(() => {       
        const d = new Date();
        const dateToday = d.toDateString();

        if (subscription)
        {
            if (subscription.usageUnit === UsageUnit.Miles) {
                if (subscription.costPerUsageUnit
                    && subscription.investorIds
                    && subscription.assetOperatorId
                    && subscription.totalContractedUsage
                    && subscription.assets
                    && subscription.assets?.filter(asset => !asset.isArchived).length > 0
                    && subscription.id
                    && subscription.billingStartDate
                    && subscription.targetEndDate !== dateToday
                    && subscription.maximumEndDate !== dateToday
                    && subscription.minimumUsagePeriods?.length > 0) {
                    setMandatoryFieldsFilled(true);
                } else {
                    setMandatoryFieldsFilled(false);
                }
            } else if (subscription.usageUnit === UsageUnit.ActiveAssets) {
                if (subscription.costPerUsageUnit && subscription.investorIds && subscription.assetOperatorId && subscription.id && subscription.totalExpectedRepayment && subscription.monthsInContract && subscription.billingStartDate && subscription.maximumEndDate !== dateToday) {
                    setMandatoryFieldsFilled(true);
                } else {
                    setMandatoryFieldsFilled(false);
                }
            }

            GetSeedingForPaymentPlan(subscription.id).then((data: PaymentPlanSeedingData[]) => {
                setSeedingForPaymentPlans(data);

            });
        } else {
            setMandatoryFieldsFilled(false);
        }
    }, [subscription])
    
    useEffect(() => {
        if (subscription && subscription.costPerUsageUnit !== undefined && subscription.totalExpectedRepayment !== undefined && subscription.monthsInContract !== undefined) {
            let total = subscription.monthsInContract * (subscription.assets?.length ?? 0) * subscription.costPerUsageUnit
            setCalculatedTotalExpectedRepayment(total)
        } else {
            setCalculatedTotalExpectedRepayment(undefined)
        }      
                
    }, [subscription, subscription?.costPerUsageUnit, subscription?.totalExpectedRepayment, subscription?.assets?.length, subscription?.monthsInContract])

    useEffect(() => {
        if (subscription && subscription.drawdownPeriodProfile && subscription.drawdownPeriodProfile.length > 0) {
            let displayWarning = false

            const numberOfAssetsInDrawdownPeriod = subscription.drawdownPeriodProfile.map(x => x.numberOfAssets).reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue)))
            if (numberOfAssetsInDrawdownPeriod !== subscription.assets.length) {
                setNumberOfAssetsWarningMessage(`Total number of assets in drawdown period (${numberOfAssetsInDrawdownPeriod}) does not match total number of assets on contract (${subscription.assets.length}).`)
                displayWarning = true
            } else {
                setNumberOfAssetsWarningMessage("")
            }

            const totalLoanInDrawdownPeriod = subscription.drawdownPeriodProfile.map(x => x.totalLoanForDateTime).reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue)))
            if (totalLoanInDrawdownPeriod !== Number(subscription.totalFacility)) {
                setTotalLoanWarningMessage(`Total loan in drawdown period (${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${totalLoanInDrawdownPeriod}) does not match total facility on contract (${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${subscription.totalFacility}).`)
                displayWarning = true
            } else {
                setTotalLoanWarningMessage("")
            }

            if (subscription.billingStartDate)
            {
                const billingStartDateString = format(new Date(subscription.billingStartDate), "yyyy-MM-dd")
                const orderedDatesOfDrawdownMonths = [...subscription.drawdownPeriodProfile].map(x => new Date(x.dateTime)).sort((a, b) => (a.getTime() - b.getTime()))
                const firstDrawdownMonth = format(new Date(orderedDatesOfDrawdownMonths[0]), "yyyy-MM-dd")
                if (firstDrawdownMonth !== billingStartDateString) {
                    setBillingStartDateWarningMessage(`First month of drawdown period (${firstDrawdownMonth}) does not match billing start date on contract (${billingStartDateString}).`)
                    displayWarning = true
                } else {
                    setBillingStartDateWarningMessage("")
                }
            }
            
            if (Number(subscription.lengthOfDrawdownPeriodInMonths) !== subscription.drawdownPeriodProfile.length) {
                setLengthOfDrawdownPeriodWarningMessage(`Number of rows in drawdown period (${subscription.drawdownPeriodProfile.length}) does not match drawdown period length in months on contract (${subscription.lengthOfDrawdownPeriodInMonths}).`)
                displayWarning = true
            } else {
                setLengthOfDrawdownPeriodWarningMessage("")
            }

            if (subscription.maximumEndDate && subscription.usageUnit === UsageUnit.ActiveAssets) {
                const maximumEndDateOnSubscription = new Date(subscription.maximumEndDate)
                let finalMonthInDrawdownPeriod = new Date(subscription.drawdownPeriodProfile[subscription.drawdownPeriodProfile.length-1].dateTime)
                let maximumEndDateFromDrawdown = new Date(finalMonthInDrawdownPeriod)
                maximumEndDateFromDrawdown.setMonth(finalMonthInDrawdownPeriod.getMonth()+Number(subscription.monthsInContract ?? 0)-1)
                if (maximumEndDateOnSubscription.getFullYear() !== maximumEndDateFromDrawdown.getFullYear() || maximumEndDateOnSubscription.getMonth() !== maximumEndDateFromDrawdown.getMonth()) {
                    setMaximumEndDateWarningMessage(`Maximum End Date on contract (${format(new Date(subscription.maximumEndDate), "MMM yyyy")}) does not match that calculated from the drawdown period and months in contract (${format(new Date(maximumEndDateFromDrawdown), "MMM yyyy")}).`)
                    displayWarning = true
                } else {
                    setMaximumEndDateWarningMessage("")
                }
            } else {
                setMaximumEndDateWarningMessage("")
            }

            if (displayWarning) {
                setDrawdownPeriodWarning(true)
            } else {
                setDrawdownPeriodWarning(false)
            }
        } else if (subscription && subscription.lengthOfDrawdownPeriodInMonths !== undefined && subscription.lengthOfDrawdownPeriodInMonths > 0) {
            setLengthOfDrawdownPeriodWarningMessage(`Number of rows in drawdown period (0) does not match drawdown period length in months on contract (${subscription.lengthOfDrawdownPeriodInMonths}).`)
            setNumberOfAssetsWarningMessage("")
            setTotalLoanWarningMessage("")
            setBillingStartDateWarningMessage("")
            setMaximumEndDateWarningMessage("")
            setDrawdownPeriodWarning(true)
        } else {
            setNumberOfAssetsWarningMessage("")
            setTotalLoanWarningMessage("")
            setBillingStartDateWarningMessage("")
            setLengthOfDrawdownPeriodWarningMessage("")
            setMaximumEndDateWarningMessage("")
            setDrawdownPeriodWarning(false)
        }
    }, [subscription?.lengthOfDrawdownPeriodInMonths, subscription?.drawdownPeriodProfile, subscription, subscription?.assets?.length, subscription?.totalFacility, subscription?.billingStartDate, subscription?.maximumEndDate, subscription?.monthsInContract])

    function reloadSubscription() {
        getSubscription(match.params.subscriptionId)
                .then((item: SubscriptionViewModel) => {
                    setSubscription(item)
                    if(item.assetOperatorId) {
                        getAssetOperator(item.assetOperatorId, true).then(assetOperator => setAssetOperator(assetOperator))
                    }
                })
    }

    function loadPage() {
        getSubscription(match.params.subscriptionId)
                .then((item: SubscriptionViewModel) => {
                    setSubscription(item)
                    if(item.assetOperatorId) {
                        getAssetOperator(item.assetOperatorId, true).then(assetOperator => setAssetOperator(assetOperator))
                    }
                })

            getInvestors().then((investorViewModels: InvestorViewModel[]) => {
                let investorViewModelIds: string[] = []
                investorViewModels.forEach(investorViewModel => {
                if(investorViewModel.id != null) investorViewModelIds.push(investorViewModel.id)
                })
                setAllInvestorViewModelIds(investorViewModelIds)
                setInvestorViewModels(investorViewModels)
            })
                    
            getAssetOperatorIds().then(allFetchedAssetOperatorIds => setAllAssetOperatorIds(allFetchedAssetOperatorIds))
    }

   

    function onSubmit() {
        if(!subscription) return;

        setSubmitStatus(SubmitStatus.Pending)

        subscription.assets = subscription.assets?.map(asset => asset = {
            ...asset
        });

        createSubscription(subscription).then(_ => {
            setSubmitStatus(SubmitStatus.Success)
            if(assetOperator && assetOperatorIsReadyToReceiveWelcomeEmail(assetOperator)) {
                sendAssetOperatorWelcomeEmail(assetOperator.id)
            }
            for (const investorId of subscription.investorIds) {
                sendInvestorWelcomeEmail(investorId)
            }
            setUsagePeriodUpdatePending(false)
            loadPage();
        }).catch(() => setSubmitStatus(SubmitStatus.Failed))
    }
    
    function buildCurrencyWarningStringForMultipleInvestors(uniqueInvestorCurrencies: string[], linkedInvestorViewModels: InvestorViewModel[]) {
        if(!subscription) return;

        let warningString = 'The linked investors have a range of currencies: ';
        uniqueInvestorCurrencies.forEach(currency => {
            var investorsWithCurrency = linkedInvestorViewModels.filter(investor => investor.primaryCurrencyIsoCode === currency).map(x => x.id);
            warningString += currency + ' (' + investorsWithCurrency.join(', ') + '), '
        })
        warningString = warningString.slice(0, -2);
        warningString += '. The subscription currency is: ' + subscription.currencyIsoCode + '. '
        warningString += 'We would normally expect the subscription currency and all investor currencies to match.'

        return warningString;
    }

    useEffect(() => {
        if(!subscription) return;

        if (subscription.investorIds != null && subscription.investorIds.filter(x => x !== '').length > 0) {
            let linkedInvestors = investorViewModels?.filter(x => typeof (x.id) !== 'undefined' && subscription.investorIds.includes(x.id))
            let linkedInvestorCurrencies = linkedInvestors?.map(x => x.primaryCurrencyIsoCode);
            let uniqueInvestorCurrencies = [...new Set(linkedInvestorCurrencies)]

            if (uniqueInvestorCurrencies.length > 1) {
                let warningString = buildCurrencyWarningStringForMultipleInvestors(uniqueInvestorCurrencies, linkedInvestors ?? [])
                setCurrencyMismatchMessage(warningString ?? '')
            } else if (uniqueInvestorCurrencies[0] !== subscription.currencyIsoCode) {
                let warningString = 'Subscription currency ' + subscription.currencyIsoCode + ' differs from linked investor currency ' + uniqueInvestorCurrencies[0]
                warningString += '. We would normally expect the subscription currency and investor currency to match.'
                setCurrencyMismatchMessage(warningString)
            } else {
                setCurrencyMismatchMessage('')
            }
        }
        // eslint-disable-next-line
    }, [subscription, investorViewModels])
 
    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function assetOperatorIsReadyToReceiveWelcomeEmail(assetOperator: AssetOperator | null): boolean {
        if (assetOperator === null) return false;
        if (assetOperator?.welcomeEmailSent) return false;
        const directDebitDay = assetOperator.preferredDirectDebitPaymentDayOfMonth;
        const assetOperatorName = assetOperator.tradingName;
        let subscriptions = assetOperator.subscriptions;
        if (subscriptions.length > 0) {
            const firstSubscription = subscriptions[0]
            const costPerUsageUnit = firstSubscription.costPerUsageUnit;
            const currency = firstSubscription.currencyIsoCode;
            if ((firstSubscription.minimumUsagePeriods?.length ?? 0) > 0) {
                const minimumUsage = firstSubscription.minimumUsagePeriods[0].minimumUsage;
                if ((firstSubscription.assets?.length ?? 0) > 0) {
                    const numberOfAssets = firstSubscription.assets.filter(asset => !asset.isArchived).length;
                    if (costPerUsageUnit && currency && directDebitDay && minimumUsage && numberOfAssets && assetOperatorName && assetOperator.billingEmailAddress != null && assetOperator.billingEmailAddress.length > 0 && assetOperator.registeredAddress != null) {
                        return true;
                    }
                }
            }
        }
        return false;
    }  
    
    const assetOperatorHandleChange = (event: SelectChangeEvent<string>) => {
        if(!subscription) return;
        const { target: { value } } = event;
        getAssetOperator(value as string, true).then(assetOperator => setAssetOperator(assetOperator));
        setSubscription({...subscription, assetOperatorId: value as string});
    }

    const investorIdsHandleChange = (event: SelectChangeEvent<string[]>) => {
        if(!subscription) return;
        const { target: { value } } = event;
        setSubscription({...subscription, investorIds: value as string[]});
    }

    const handleLengthOfDrawdownPeriodChange = (newLengthOfDrawdownPeriod: number) => {
        if(!subscription) return;
        if (subscription.lengthOfDrawdownPeriodInMonths === undefined || subscription.drawdownPeriodProfile === null || (subscription.drawdownPeriodProfile && subscription.drawdownPeriodProfile.length === 0) || (subscription.drawdownPeriodProfile && subscription.drawdownPeriodProfile.filter(x => x.numberOfAssets > 0).length === 0)) {
            const newDrawdownPeriodProfile: DrawdownPeriod[] = [];
            const billingStartDate = new Date(subscription.billingStartDate ? Date.parse(subscription.billingStartDate) : Date.now())
            billingStartDate.setMonth(billingStartDate.getMonth()-1)
            billingStartDate.setDate(1)
            
            for (let i = 0; i < (newLengthOfDrawdownPeriod); i++) {
                const thisMonthDate = new Date(billingStartDate.setMonth(billingStartDate.getMonth()+1))
                if (i === 0) {
                    thisMonthDate.setDate(new Date(subscription.billingStartDate ? Date.parse(subscription.billingStartDate) : Date.now()).getDate())
                }
                const newDrawdownPeriod: DrawdownPeriod = {
                    dateTime : format(thisMonthDate, "yyyy-MM-dd"),
                    numberOfAssets : 0,
                    loanPerAsset : (subscription.totalFacility / subscription.assets.length) ?? 0,
                    totalLoanForDateTime : 0
                }
                newDrawdownPeriodProfile.push(newDrawdownPeriod)
            }
            setSubscription({...subscription, lengthOfDrawdownPeriodInMonths: newLengthOfDrawdownPeriod, drawdownPeriodProfile: newDrawdownPeriodProfile})
        } else {
            setSubscription({...subscription, lengthOfDrawdownPeriodInMonths: newLengthOfDrawdownPeriod})
        }
    }

    function SubmitButton({ mandatoryFieldsFilled, drawdownPeriodWarning }: { mandatoryFieldsFilled: boolean, drawdownPeriodWarning: boolean }) {
        switch (submitStatus) {
            case SubmitStatus.NotSent: {
                return (
                    (mandatoryFieldsFilled
                        ) ?
                        (drawdownPeriodWarning === false ? 
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onSubmit()}
                            startIcon={<SaveIcon />}
                            style={{ margin: 16 }}
                        >
                            Submit
                        </Button>
                        :
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onSubmit()}
                            startIcon={<SaveIcon />}
                            style={{ margin: 16 }}
                            disabled={true}
                        >
                            Submit
                        </Button>
                        )
                        :
                        <Tooltip title={subscription && subscription.usageUnit === UsageUnit.Miles ? 
                            "Please add at least one asset, one six month minimum mileage period, and complete the mandatory fields: Contract ID, Asset Operator ID, Lender IDs, Cost per Usage Unit, Total Contracted Usage, and Billing Start Date. Target End Date and Maximum End Date must not be today's date."
                            : subscription && subscription.usageUnit === UsageUnit.ActiveAssets ? "Please complete the mandatory fields: Contract ID, Asset Operator ID, Lender IDs, Cost per Usage Unit, Total Expected Repayment, Months per Asset, and Billing Start Date. Maximum End Date must not be today's date."
                            : ""}>
                            <span>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => onSubmit()}
                                    startIcon={<SaveIcon />}
                                    style={{ margin: 16 }}
                                    disabled={true}
                                >
                                    Submit
                                </Button>
                            </span>
                        </Tooltip>
                )
            }
            case SubmitStatus.Pending: {
                return <Button
                    color="primary"
                    variant="contained"
                    style={{ margin: 16 }}
                >
                    Pending
                </Button>
            }
            case SubmitStatus.Failed: {
                return <Button
                    color="secondary"
                    variant="contained"
                    style={{ margin: 16 }}
                >
                    Failed, Please Try Again
                </Button>
            }
            case SubmitStatus.FailedIdInUse: {
                return <Button
                    color="secondary"
                    variant="contained"
                    style={{ margin: 16 }}
                >
                    Failed, Id In Use
                </Button>
            }
            case SubmitStatus.Success: {
                return <Button
                    color="primary"
                    style={{ margin: 16 }}
                    variant="contained"
                    onClick={() => onSubmit()}
                >
                    Success
                </Button>
            }
        }

    }

    function handleCurrencyChange(e: SelectChangeEvent<string>) {
        if(!subscription) return;
        if(e.target.value === CurrencyIsoCodes.Usd){
            setSubscription({...subscription, currencyIsoCode: e.target.value, feeCollectionMethod: FeeCollectionMethods.ServiceFeeAndTelematicsOnInvestorBill})
        } else {
            setSubscription({...subscription, currencyIsoCode: e.target.value})
        }
    }

    const handleCreateNewPaymentPlanModalOpen = () => {       
        setCreatePaymentPlanModalOpen(true)}; 
    const handleCreateNewPaymentPlanModalClose = () => setCreatePaymentPlanModalOpen(false);
    const handleEditNewPaymentPlanModalClose = () => setEditPaymentPlanModalOpen(false);
    
    return (
        subscription ?
        <>
            <Card sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CardHeader title={subscription.name} subheader={subscription.id} />
                {currencyMismatchMessage !== '' ?
                    <Grid
                        container spacing={4}>
                        <Grid xs={1} sm={1} md={1} item>
                            <ErrorIcon color="warning" style={{ marginTop: 10, marginLeft: 10 }} />
                        </Grid>
                        <Grid xs={11} sm={11} md={11} item>
                            <Typography color={theme.palette.warning.main} style={{ marginTop: 10 }}>
                                {currencyMismatchMessage}
                            </Typography>
                        </Grid>
                    </Grid>
                    :
                    null
                }
                {drawdownPeriodWarning ?
                    <Grid
                        container spacing={4}>
                        <Grid xs={1} sm={1} md={1} item>
                            <ErrorIcon color="warning" style={{ marginTop: 10, marginLeft: 10 }} />
                        </Grid>
                        <Grid xs={11} sm={11} md={11} item>
                            <Typography color={theme.palette.warning.main} style={{ marginTop: 10 }}>
                            Please fix the warnings in the 'IRR Drawdown Period' tab.
                            </Typography>
                        </Grid>
                    </Grid>
                    :
                    null
                }
                <SubmitButton mandatoryFieldsFilled={mandatoryFieldsFilled} drawdownPeriodWarning={drawdownPeriodWarning} />
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Contract Details" {...a11yProps(0)} />
                    <Tab label={"Assets (" + (subscription.assets?.length ?? 0) + ")"} {...a11yProps(1)} />
                    <Tab label="Payment Plans" {...a11yProps(2)} />
                    <Tab label="IRR Drawdown Period" {...a11yProps(3)} />
                </Tabs>
            </Card>
            <TabPanel value={value} index={0}>
            {subscription && allInvestorIds && allAssetOperatorIds && investorViewModels ?
            <>
                <Grid
                    style={{ marginTop: '20px' }}
                    justifyContent="space-between"
                    container
                >
                    <Grid xs={12} item style={{marginBottom:'20px'}}>
                        <Card>
                            <CardHeader title="Contract IDs"></CardHeader>
                            <CardContent>
                                <Grid container sm={12} md={12} spacing={4} item>
                                    <Grid xs={3} sm={3} md={3} item>
                                        <TextField
                                            variant="standard"
                                            label="Contract ID"
                                            InputLabelProps={genericLabelProps}
                                            InputProps={
                                                subscription?.id?.substring(0, 3) === "SUB"
                                                    ? {
                                                        endAdornment: (
                                                            <InputAdornment position="end"> </InputAdornment>
                                                        ),
                                                    }
                                                    : {
                                                        startAdornment: (
                                                            <InputAdornment position="start">{"SUB"}</InputAdornment>
                                                        ),
                                                    }
                                            }
                                            value={subscription.id ?? ""}
                                            onChange={(e) => setSubscription({...subscription, id: e.target.value})}
                                            disabled={!!subscription.id}
                                        />
                                    </Grid>
                                    {subscription?.shortId &&
                                        <Grid xs={3} sm={3} md={3} item>
                                            <TextField
                                                variant="standard"
                                                label="Contract Short ID"
                                                InputLabelProps={genericLabelProps}
                                                InputProps={

                                                    subscription?.shortId?.substring(0, 3) === "SUB" ? {}
                                                        : {
                                                            startAdornment: (
                                                                <InputAdornment position="start">{"SUB"}</InputAdornment>
                                                            ),
                                                        }
                                                }
                                                value={subscription?.shortId ?? ''}
                                                disabled={true}
                                            />
                                        </Grid>}
                                    <Grid xs={6} item  >
                                        <TextField style={{ width: "100%" }}
                                            variant="standard"
                                            label="Name"
                                            InputLabelProps={genericLabelProps}
                                            value={subscription.name ?? ""}
                                            onChange={(e) => setSubscription({...subscription, name: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} item >

                                        <InputLabel id="assetOperatorId-multiple-checkbox-label">Asset Operator</InputLabel>
                                        <Select
                                            style={{ width: "100%" }}
                                            labelId="assetOperatorId-multiple-checkbox-label"
                                            id="assetOperatorId-multiple-checkbox"
                                            value={subscription.assetOperatorId ?? ""}
                                            onChange={assetOperatorHandleChange}
                                            variant="standard"
                                        >
                                            {allAssetOperatorIds.map((assetOperatorId) => (
                                                <MenuItem key={assetOperatorId} value={assetOperatorId}>
                                                    <ListItemText primary={assetOperatorId} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} item >
                                        <InputLabel id="investorId-multiple-checkbox-label">Lender</InputLabel>
                                        <Select
                                            style={{ width: "100%" }}
                                            labelId="investorId-multiple-checkbox-label"
                                            id="investorId-multiple-checkbox"
                                            multiple
                                            value={subscription.investorIds}
                                            onChange={investorIdsHandleChange}
                                            variant="standard"
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {allInvestorIds.map((investorId) => (
                                                <MenuItem key={investorId} value={investorId}>
                                                    <Checkbox checked={subscription.investorIds.indexOf(investorId) > -1} />
                                                    <ListItemText primary={investorId} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} item>

                                    <TextField style={{ width: "100%" }}
                                            variant="standard"
                                            label="Message to appear on every bill"
                                            InputLabelProps={genericLabelProps}
                                            value={subscription.billMessage ?? ""}
                                            onChange={(e) => setSubscription({...subscription, billMessage: e.target.value})}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} item style={{marginBottom:'20px'}}>
                        <Card>
                            <CardHeader title="Billing Information"></CardHeader>
                            <CardContent>
                                {/* eslint-disable-next-line */}
                                {subscription.usageUnit === UsageUnit.ActiveAssets && calculatedTotalExpectedRepayment != subscription.totalExpectedRepayment ?
                                    <Typography gutterBottom color="error">
                                        Total Expected Repayment{subscription.totalExpectedRepayment ? ` (${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${subscription.totalExpectedRepayment})` : ""} does not match what is calculated from Number of Assets, Months per Asset, and Monthly Cost per Asset{calculatedTotalExpectedRepayment ? ` (${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${calculatedTotalExpectedRepayment})` : ""}.
                                    </Typography>
                                    :
                                    null
                                }
                                <Grid container sm={12} md={12} spacing={4} item>
                                    <Grid xs={6} sm={4} md={4} item>
                                        <TextField
                                            variant="standard"
                                            style={{width: "100%"}}
                                            label="Contract Type"
                                            InputLabelProps={genericLabelProps}
                                            disabled
                                            value={ConvertUsageUnitToContractType(subscription.usageUnit)}
                                        />
                                    </Grid>
                                    <Grid xs={6} sm={4} md={4} item>
                                        <FormControl fullWidth>
                                            <InputLabel id="currency-input" style={genericLabelProps.style}>Currency</InputLabel>
                                            <Select
                                                value={subscription.currencyIsoCode}
                                                inputProps={{
                                                    name: "Currency",
                                                }}
                                                labelId="currency-input"
                                                id="CurrencySelect"
                                                variant="standard"
                                                defaultValue={CurrencyIsoCodes.Gbp}
                                                onChange={(e) => handleCurrencyChange(e)}
                                            >
                                                <MenuItem value={CurrencyIsoCodes.Gbp}>{CurrencyIsoCodes.Gbp}</MenuItem>
                                                <MenuItem value={CurrencyIsoCodes.Usd}>{CurrencyIsoCodes.Usd}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6} sm={4} md={4} item>
                                        <FormControl fullWidth>
                                            <InputLabel id="usage-unit-input" style={genericLabelProps.style}>Usage Unit</InputLabel>
                                            <Select
                                                value={subscription.usageUnit}
                                                labelId="usage-unit-input"
                                                id="UsageUnitSelect"
                                                variant="standard"
                                                defaultValue={UsageUnit.Miles}
                                                onChange={(e) => setSubscription({...subscription, usageUnit: e.target.value})}
                                            >
                                                <MenuItem value={UsageUnit.Miles}>Miles</MenuItem>
                                                <MenuItem value={UsageUnit.ActiveAssets}>Number of Active Assets</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6} sm={4} md={4} item>
                                        <TextField
                                            variant="standard"
                                            style={{width: "100%"}}
                                            label={subscription.usageUnit === UsageUnit.Miles ? "Cost per Mile": subscription.usageUnit === UsageUnit.ActiveAssets ? "Monthly Cost per Asset" : ""}
                                            InputLabelProps={genericLabelProps}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {ConvertCurrencyToSymbol(subscription.currencyIsoCode)}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={subscription.costPerUsageUnit}
                                            onChange={(e) => setSubscription({...subscription, costPerUsageUnit: e.target.value as unknown as number})}
                                        />
                                    </Grid>
                                    {subscription.usageUnit === UsageUnit.Miles &&
                                        <Grid xs={6} sm={4} md={4} item style={{"width": "100%"}}>
                                            <TextField
                                                variant="standard"
                                                style={{width: "100%"}}
                                                label="Total Contracted Usage"
                                                InputLabelProps={largeLabelProps}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">{subscription.usageUnit}</InputAdornment>
                                                    ),
                                                }}
                                                value={subscription.totalContractedUsage?.toLocaleString()}
                                                onChange={(e) => setSubscription({...subscription, totalContractedUsage: e.target.value as unknown as number})}
                                            />
                                        </Grid>}
                                        {subscription.usageUnit === UsageUnit.ActiveAssets &&
                                            <Grid xs={6} sm={4} md={4} item style={{"width": "100%"}}>
                                                <TextField
                                                    variant="standard"
                                                    style={{width: "100%"}}
                                                    label="Total Expected Repayment"
                                                    InputLabelProps={largeLabelProps}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">{ConvertCurrencyToSymbol(subscription.currencyIsoCode)}</InputAdornment>
                                                        ),
                                                    }}
                                                    value={subscription.totalExpectedRepayment?.toLocaleString()}
                                                    onChange={(e) => setSubscription({...subscription, totalExpectedRepayment: e.target.value as unknown as number})}
                                                />
                                            </Grid>}
                                    {subscription.usageUnit === UsageUnit.ActiveAssets && 
                                        <Grid xs={6} sm={4} md={4} item>
                                            <TextField style={{width: "100%"}}
                                                variant="standard"
                                                label="Months per Asset"
                                                InputLabelProps={largeLabelProps}
                                                value={subscription.monthsInContract}
                                                onChange={(e) => setSubscription({...subscription, monthsInContract: e.target.value as unknown as number})}
                                            />
                                        </Grid>}
                                    {subscription.usageUnit === UsageUnit.ActiveAssets && 
                                        <Grid xs={6} sm={4} md={4} item>
                                            <TextField style={{width: "100%"}}
                                                variant="standard"
                                                label="Number of Assets"
                                                InputLabelProps={largeLabelProps}
                                                value={subscription.assets?.length ?? 0}
                                                disabled={true}
                                            />
                                        </Grid>}
                                        <Grid xs={6} sm={4} md={4} item>
                                            <TextField style={{width: "100%"}}
                                                    variant="standard"
                                                    label="Monthly Telematics Cost per Asset"
                                                    InputLabelProps={largeLabelProps}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {ConvertCurrencyToSymbol(subscription.currencyIsoCode)}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={subscription.telematicsCostPerAsset}
                                                    onChange={(e) => setSubscription({...subscription, telematicsCostPerAsset: e.target.value as unknown as number})}
                                            />
                                        </Grid>
                                    <Grid xs={6} sm={4} md={4} item>
                                        <TextField style={{width: "100%"}}
                                                variant="standard"
                                                label="Investment Management Fee Rate"
                                                InputLabelProps={largeLabelProps}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={subscription.vmFeeRate}
                                                onChange={(e) => setSubscription({...subscription, vmFeeRate: e.target.value as unknown as number})}
                                        />
                                    </Grid> 
                                    <Grid xs={6} sm={4} md={4} item>
                                        <TextField style={{width: "100%"}}
                                                variant="standard"
                                                label="Tax Rate"
                                                InputLabelProps={largeLabelProps}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={subscription.vmTaxRate}
                                                onChange={(e) => setSubscription({...subscription, vmTaxRate: e.target.value as unknown as number})}
                                        />
                                    </Grid>
                                    <Grid xs={6} sm={8} md={8} item style={{"width": "100%"}}>
                                        <FormControl fullWidth>
                                            <InputLabel style={genericLabelProps.style}>Fee Collection Method</InputLabel>
                                            <Select
                                                variant="standard"
                                                labelId="feeCollectionMethodLabel"
                                                id="fee-collection-select"
                                                value={subscription.feeCollectionMethod}
                                                onChange={
                                                    (e) => {
                                                        setSubscription({...subscription, feeCollectionMethod: e.target.value as string})
                                                    }
                                                }
                                            >
                                                <MenuItem
                                                    value={FeeCollectionMethods.ServiceFeeAndTelematicsOnInvestorBill}>{ConvertFeeCollectionMethodTypeToLabel(FeeCollectionMethods.ServiceFeeAndTelematicsOnInvestorBill)}</MenuItem>

                                                {subscription.usageUnit === UsageUnit.Miles ? (
                                                    <MenuItem
                                                    value={FeeCollectionMethods.ServiceFeeAndTelematicsOnAssetOperatorBill}>{ConvertFeeCollectionMethodTypeToLabel(FeeCollectionMethods.ServiceFeeAndTelematicsOnAssetOperatorBill)}</MenuItem>
                                                ) : '' }
                                                
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} item style={{marginBottom:'20px'}}>
                        <Card>
                            <CardHeader title="Reporting Information"></CardHeader>
                            <CardContent>
                            <Grid container sm={12} md={12} spacing={4} item>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        variant="standard"
                                        label="Days until Bill is in Arrears"
                                        InputLabelProps={largeLabelProps}
                                        value={subscription.numberOfDaysUntilBillFallsIntoArrears}
                                        onChange={(e) =>
                                            setSubscription({...subscription, numberOfDaysUntilBillFallsIntoArrears: e.target.value as unknown as number})
                                        }
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        variant="standard"
                                        label="Total Facility"
                                        InputLabelProps={genericLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {ConvertCurrencyToSymbol(subscription.currencyIsoCode)}
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={subscription.totalFacility}
                                        onChange={(e) => setSubscription({...subscription, totalFacility: e.target.value as unknown as number})}
                                    />
                                </Grid>           
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        variant="standard"
                                        label="Total Deposit"
                                        InputLabelProps={genericLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {ConvertCurrencyToSymbol(subscription.currencyIsoCode)}
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={subscription.deposit}
                                        onChange={(e) => setSubscription({...subscription, deposit: e.target.value as unknown as number})}
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <Tooltip title={"Please select a date as close to the 1st of the month as possible"}>
                                        <TextField
                                            variant="standard"
                                            type="date"
                                            label="Billing Start Date"
                                            InputLabelProps={genericLabelProps}
                                            value={subscription.billingStartDate ? format(new Date(subscription.billingStartDate), "yyyy-MM-dd"): ""}
                                            onChange={(e) =>
                                                setSubscription(
                                                    {...subscription, billingStartDate: e.target.value}
                                                )
                                            }
                                        />
                                    </Tooltip>
                                </Grid>
                                {subscription.usageUnit === UsageUnit.Miles && <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        variant="standard"
                                        type="date"
                                        label="Target End Date"
                                        InputLabelProps={genericLabelProps}
                                        value={subscription.targetEndDate ? format(new Date(subscription.targetEndDate), "yyyy-MM-dd"): ""}
                                        onChange={(e) =>
                                            setSubscription(
                                                {...subscription, targetEndDate: e.target.value}
                                            )
                                        }
                                    />
                                </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        variant="standard"
                                        type="date"
                                        label={subscription.usageUnit === UsageUnit.Miles ? "Maximum End Date": "End Date"}
                                        InputLabelProps={largeLabelProps}
                                        value={subscription.maximumEndDate ? format(new Date(subscription.maximumEndDate), "yyyy-MM-dd"): ""}
                                        onChange={(e) =>
                                            subscription.usageUnit === UsageUnit.Miles ? setSubscription({...subscription, maximumEndDate: e.target.value}) : setSubscription({...subscription, maximumEndDate: e.target.value, targetEndDate: e.target.value}
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        variant="standard"
                                        label="Months in Drawdown Period"
                                        InputLabelProps={largeLabelProps}
                                        value={subscription.lengthOfDrawdownPeriodInMonths}
                                        onChange={(e) => handleLengthOfDrawdownPeriodChange(Number(e.target.value))}
                                    />
                                </Grid>
                            </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} item style={{marginBottom:'20px'}}>
                        <Card>
                        <CardHeader title="Contract Options"></CardHeader>
                        <CardContent>
                            <Grid container sm={12} md={12} spacing={0} item>
                                <Grid xs={6} sm={4} md={4} item>
                                    <Tooltip
                                        title="This will activate billing for the entire subscription."
                                    >
                                        <FormControlLabel
                                            control={<Switch color="primary" checked={subscription.isActive} />}
                                            onChange={() => setSubscription({...subscription, isActive: !subscription.isActive})}
                                            label="Activate Billing"
                                        />
                                    </Tooltip>
                                </Grid>
                                {subscription.usageUnit === UsageUnit.Miles && <Grid xs={6} sm={4} md={4} item>
                                    <Tooltip
                                        title="This option should only be enabled if the billing for this subscription isn't through Stripe or GoCardless."
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch color="primary" checked={subscription.offSystemPaymentsOnly} />
                                            }
                                            onChange={() =>
                                                setSubscription({...subscription, offSystemPaymentsOnly: !subscription.offSystemPaymentsOnly})
                                            }
                                            label="Off System Payments"
                                        />
                                    </Tooltip>
                                </Grid>}
                                {subscription.usageUnit === UsageUnit.Miles && <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={subscription.adjustCostPerUsageUnitWithInflation}
                                            />
                                        }
                                        onChange={() =>
                                            setSubscription({...subscription, adjustCostPerUsageUnitWithInflation: !subscription.adjustCostPerUsageUnitWithInflation})
                                        }
                                        label="Adjust Cost Per Usage Unit With Inflation?"
                                    />
                                </Grid>}
                                {subscription.usageUnit === UsageUnit.Miles && <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={<Switch color="primary" checked={subscription.vatApplicable} />}
                                        onChange={() => setSubscription({...subscription, vatApplicable: !subscription.vatApplicable})}
                                        label="VAT Applicable?"
                                    />
                                </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={
                                            <Switch color="primary" checked={subscription.isBalloonPaymentRequired} />
                                        }
                                        onChange={() =>
                                            setSubscription({...subscription, isBalloonPaymentRequired: !subscription.isBalloonPaymentRequired})
                                        }
                                        label="Balloon Payment Required?"
                                    />
                                </Grid>
                                {subscription.usageUnit === UsageUnit.Miles && <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={
                                            <Switch color="primary" checked={subscription.showStartEndOdometerReadingsOnBill} />
                                        }
                                        onChange={() =>
                                            setSubscription({...subscription, showStartEndOdometerReadingsOnBill: !subscription.showStartEndOdometerReadingsOnBill})}
                                        label="Show start and end odometer readings on bills?"
                                    />
                                </Grid>}
                                {subscription.usageUnit === UsageUnit.Miles && <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={
                                            <Switch color="primary" checked={subscription.automaticallyCollectCatchupPayments} />
                                        }
                                        onChange={() =>
                                            setSubscription({...subscription, automaticallyCollectCatchupPayments: !subscription.automaticallyCollectCatchupPayments})}
                                        label="Automatically Collect Catchup Payments?"
                                    />
                                </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={<Switch color="primary" checked={subscription.includeInPublicCO2AndNOxCount} />}
                                        onChange={(_) => setSubscription({...subscription, includeInPublicCO2AndNOxCount: !subscription.includeInPublicCO2AndNOxCount})}
                                        label="Include CO2 and NOx savings on main website?"
                                    />
                                </Grid>
                                {subscription.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <FormControlLabel
                                            control={
                                                <Switch color="primary"
                                                        checked={subscription.enableProRataingMinimumUsagePeriods}/>
                                            }
                                            onChange={() =>
                                                setSubscription({
                                                    ...subscription,
                                                    enableProRataingMinimumUsagePeriods: !subscription.enableProRataingMinimumUsagePeriods
                                                })
                                            }
                                            label="Enable ProRataing Minimum Usage Periods"
                                        />

                                    </Grid>}
                            </Grid>
                        </CardContent>
                        </Card>
                    </Grid>
                    {subscription.usageUnit === UsageUnit.Miles && 
                    <Grid xs={12} item style={{marginBottom:'20px'}}>
                        <SixMonthMinimumUsage subscription={subscription} setSubscription={setSubscription} setUsagePeriodUpdatePending={setUsagePeriodUpdatePending} usagePeriodUpdatePending={usagePeriodUpdatePending} />
                    </Grid>}
                    <Grid xs={12} item style={{marginBottom:'20px'}}>
                        <Card>
                        <CardHeader title="Market Values"></CardHeader>
                        <CardContent>
                            <SubscriptionComponent5 subscription={subscription} />
                        </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </> : ''}
            </TabPanel>
            <TabPanel value={value} index={1}>
                    <AssetsTab assets={subscription.assets} subscription={subscription} setAssets={(assets : SubscriptionAsset[]) => setSubscription({...subscription, assets: assets})} ></AssetsTab>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid
                    style={{ marginTop: '20px' }}
                    justifyContent="space-between"
                    container
                >
                    <Grid xs={12} item style={{marginBottom:'20px'}}>
                        <Card>
                            <CardHeader title="Payment Plans"></CardHeader>
                            <CardContent>
                                {seedingForPaymentPlans.length > 0 ?
                                    <>
                                <Button
                                    style={{marginBottom: "10px"}}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleCreateNewPaymentPlanModalOpen()}
                                >
                                    Create
                                </Button>
                                   
                                    </>
                                    : <></>
                                }                                
                                
                                <PaymentPlansTable
                                    subscription={subscription}
                                    setEditPaymentPlanModalOpen={setEditPaymentPlanModalOpen}
                                    setPaymentPlanIdToEdit={setPaymentPlanIdToEdit}
                                />
                                <CreatePaymentPlanModal
                                    subscriptionId={subscription?.id}
                                    data={seedingForPaymentPlans}
                                    handleCreateNewPaymentPlanModalClose={handleCreateNewPaymentPlanModalClose}
                                    createPaymentPlanModalOpen={createPaymentPlanModalOpen}
                                    setCreatePaymentPlanModalOpen={setCreatePaymentPlanModalOpen}
                                />
                                <EditPaymentPlanModal
                                    subscription={subscription}
                                    reloadSubscription={reloadSubscription}
                                    paymentPlanIdToEdit={paymentPlanIdToEdit ?? ""}
                                    handleEditNewPaymentPlanModalClose={handleEditNewPaymentPlanModalClose}
                                    editPaymentPlanModalOpen={editPaymentPlanModalOpen}
                                    setEditPaymentPlanModalOpen={setEditPaymentPlanModalOpen}
                                />
                            </CardContent>
                        </Card>
                    </Grid>                              
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
                    <IRRDrawdownPeriodTab subscription={subscription} setSubscription={setSubscription}
                    numberOfAssetsWarningMessage={numberOfAssetsWarningMessage}
                    totalLoanWarningMessage={totalLoanWarningMessage}
                    billingStartDateWarningMessage={billingStartDateWarningMessage}
                    lengthOfDrawdownPeriodWarningMessage={lengthOfDrawdownPeriodWarningMessage}
                    maximumEndDateWarningMessage={maximumEndDateWarningMessage}
                    />
            </TabPanel>
            <Card sx={{ marginBottom: '20px' }}>
                <CardHeader title={subscription.name} subheader={subscription.id} />
            </Card>
        </>
        :
        (
            <div className={classes.loader}>
                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                <LinearProgress color="secondary" />
            </div>
        )
    )
    
    
    
    
}

export {SubscriptionPage}

