import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container, ThemeProvider, Theme, StyledEngineProvider, CssBaseline } from "@mui/material";
import { theme } from "./styles/Theme";
import Header from "./components/Header";
import { menuLinks } from "./data/MenuLinks";
import { HomePage } from "./pages/HomePage";
import { AssetOperatorEditPage } from "./pages/AssetOperatorEditPage";
import { BillingRunOverviewPageFleetBills } from "./pages/BillingRuns/BillingRunOverviewPageFleetBills";
import { BillingRunOverviewPageInvestorBills } from "./pages/BillingRuns/BillingRunOverviewPageInvestorBills";
import { getUserInfo } from "./auth/AuthService";
import { useEffect, useState } from "react";
import { InvestorListingPage } from "./pages/InvestorListingPage";
import { InvestorCreatePage } from "./pages/InvestorCreatePage";
import { InvestorArchivePage } from "./pages/InvestorArchivePage";
import { SubscriptionDeletePage } from "./pages/SubscriptionDeletePage";
import { SubscriptionsListingPage } from "./pages/SubscriptionsListingPage";
import { AssetOperatorCreatePage } from './pages/AssetOperatorCreatePage'
import { PaymentPage } from "./pages/Payments/PaymentPage";
import { PaymentsToActionPage } from "./pages/Payments/PaymentsToActionPage";
import { AllPaymentsPage } from "./pages/Payments/AllPaymentsPage";
import { TrialBillingRunOverviewPageFleetBills } from "./pages/BillingRuns/TrialBillingRunOverviewPageFleetBills";
import { TrialBillingRunOverviewPageInvestorBills } from "./pages/BillingRuns/TrialBillingRunOverviewPageInvestorBills";
import { TelematicsPage } from "./pages/TelematicsPage";
import { InvestmentManagementFeePaymentsToActionPage } from "./pages/Payments/InvestmentManagementFeePaymentsToAction";
import { InvestmentManagementFeePayments } from "./pages/Payments/InvestmentManagementFeePayments";
import { InvestmentManagementFeePaymentPage } from "./pages/Payments/InvestmentManagementFeePage";
import { BillingRunOverviewPageInternalInvoices } from "./pages/BillingRuns/BillingRunOverviewPageInternalInvoices";
import {
  TrialBillingRunOverviewPageInternalInvoices
} from "./pages/BillingRuns/TrialBillingRunOverviewPageInternalInvoices";
import { InvestorEditPage } from "./pages/InvestorEditPage";
import { CookieBanner } from "./components/CookieBanner";
import { DemoFleetsPage } from "./pages/DemoFleetsPage";
import { SubscriptionPage } from "./pages/SubscriptionPage";
import { getSubscriptionsIdsToNames } from "./services/SubscriptionService";
import { SubscriptionIdsAndNames } from "./models/SubscriptionIdsAndNames";
import { getJuneWriteKey, june } from "./services/JuneService";
import { AnalyticsBrowser } from "@june-so/analytics-next";
import { ClientPrincipal } from "./models/ClientPrincipal";
import { DraftSubscriptionCreateEditPage } from "./pages/DraftSubscriptionCreateEditPage";
import { DraftSubscriptionsListingPage } from "./pages/DraftSubscriptionsListingPage";
import Hero from "./components/Hero";
import MarketValueRetrievalPage from "./pages/MarketValueRetrievalPage";
import { ExternalContractListingPage } from "./pages/ExternalContractListingPage";
import { ExternalContractCreateOrEditPage } from "./pages/ExternalContractCreateOrEditPage";
import {OdometerReadingsPage} from "./pages/OdometerReadingsPage";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

var subscriptionIdsToNames: SubscriptionIdsAndNames[] = [];

function App() {
  const [user, setUser] = useState<ClientPrincipal | null>(null);
  const [juneAnalytics, setJuneAnalytics] = useState<AnalyticsBrowser | undefined>(undefined);
  const setRerenderSubscriptionsIdsToNames = useState<SubscriptionIdsAndNames[]>([])[1]
  useEffect(() => {
    getUserInfo().then((userInfo) => setUser(userInfo));
    getSubscriptionsIdsToNames().then(response => { subscriptionIdsToNames = response; setRerenderSubscriptionsIdsToNames(response) })
    getJuneWriteKey().then((key) => { setJuneAnalytics(june(key)) }); // initialize June Analytics
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (user) {
    juneAnalytics?.identify(user?.userId, { email: user?.userDetails })
    juneAnalytics?.group("INTERNAL_ZETI", { name: "Zeti Limited" })
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {user ||
          !process.env.NODE_ENV ||
          process.env.NODE_ENV === "development" ? (
          <Router>
            <Header title="Zeti" menuLinks={menuLinks} />
            <div style={{ zIndex: -1, position: 'fixed', width: '100%', height: '100vh', background: theme.palette.background.default }}>
            </div>
            <Container maxWidth="xl" style={{ paddingTop: '20px' }}>
              <Switch>
                <Route path="/assetoperators/create" component={AssetOperatorCreatePage} />
                <Route path="/assetoperators/:id/edit" component={AssetOperatorEditPage} />


                <Route path="/billingrunreports/fleetBills" component={BillingRunOverviewPageFleetBills} />
                <Route path="/billingrunreports/investorBills" component={BillingRunOverviewPageInvestorBills} />
                <Route path="/billingrunreports/internalInvoices" component={BillingRunOverviewPageInternalInvoices} />

                <Route path="/investors/create" component={InvestorCreatePage} />
                <Route path="/investors/archive/:investorId" component={InvestorArchivePage} />
                <Route path="/investors/edit/:investorId" component={InvestorEditPage} />
                <Route path="/investors" component={InvestorListingPage} />

                <Route path="/subscriptions/delete/:subscriptionId" component={SubscriptionDeletePage} />
                <Route path="/subscriptions/:subscriptionId" component={SubscriptionPage} />
                <Route path="/subscriptions/create" component={SubscriptionsListingPage} />
                <Route path="/subscriptions" component={SubscriptionsListingPage} />

                <Route path="/draftSubscriptions/:draftSubscriptionId" component={DraftSubscriptionCreateEditPage} />
                <Route path="/draftSubscriptions/create" component={DraftSubscriptionCreateEditPage} />
                <Route path="/draftSubscriptions/" component={DraftSubscriptionsListingPage} />

                <Route path="/payments/toaction" component={PaymentsToActionPage} />
                <Route path="/payments/investmentManagementFeeToaction" component={InvestmentManagementFeePaymentsToActionPage} />
                <Route path="/payments/investmentManagementFee" component={InvestmentManagementFeePayments} />
                <Route path="/payments/details/:paymentId" component={PaymentPage} />
                <Route path="/payments" component={AllPaymentsPage} />

                <Route path="/investmentManagementFeePayments/details/:paymentId" component={InvestmentManagementFeePaymentPage} />

                <Route path="/trialBillingRuns/fleetBills" component={TrialBillingRunOverviewPageFleetBills} />
                <Route path="/trialBillingRuns/investorBills" component={TrialBillingRunOverviewPageInvestorBills} />
                <Route path="/trialBillingRuns/internalInvoices" component={TrialBillingRunOverviewPageInternalInvoices} />

                <Route path="/vehicles/telematics" component={TelematicsPage} />
                <Route path="/vehicles/odometerReadings" component={OdometerReadingsPage} />

                <Route path="/externalContracts/create" component={ExternalContractCreateOrEditPage} />
                <Route path="/externalContracts/:externalContractId/edit" component={ExternalContractCreateOrEditPage} />
                <Route path="/externalContracts" component={ExternalContractListingPage} />

                <Route path="/demofleets" component={DemoFleetsPage} />
                <Route path="/marketvalues" component={MarketValueRetrievalPage} />
                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            </Container>
            <CookieBanner />
          </Router>
        ) : (
          <Router>
            <Hero
              mainText="Zeti Manage"
              bodyText={
                <>
                  Login to manage your customers and their billing. If you are not a
                  member of an organization that has a license for Zeti Manage, you
                  will not be able to view anything here.
                </>
              }
              ctaLink="/login"
              ctaText="Sign in / up"
            />
            <CookieBanner />
            <CookieBanner />
          </Router>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
export { subscriptionIdsToNames };
