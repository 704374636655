import { FileDownloadDataAndName } from "../models/FileDownloadDataAndName";

function downloadFileFromFileDownloadDataAndName(fileType: string, fileData: FileDownloadDataAndName) {
    const blob = new Blob([fileData.data], { type: fileType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileData.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
}

export { downloadFileFromFileDownloadDataAndName }