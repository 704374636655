import {AssetOperator} from "../models/AssetOperator";
import { BillPeriodAndId } from "../models/BillPeriodAndId";
import {getSettings} from "./SettingsService"

let cacheExpiryDate: Date | null = null
let cache: AssetOperator[] = []

async function getAssetOperators() {
    const now = new Date()
    let assetOperators: AssetOperator[] = []
    if (cacheExpiryDate == null || now > cacheExpiryDate) {
        const settings = await getSettings();
        const assetOperatorsUri = `${settings.BillingServiceBaseUri}/assetoperators`
        const options = {
            method: "GET",
        };
        const assetOperatorsResponse = await fetch(assetOperatorsUri, options)
        assetOperators = await assetOperatorsResponse.json()
        cache = assetOperators
        let newCacheDate = now;
        newCacheDate.setSeconds(now.getSeconds() + 600)
        cacheExpiryDate = newCacheDate
    } else {
        assetOperators = cache
    }

    return assetOperators;
}

async function getAssetOperatorIds() : Promise<string[]>{
        const settings = await getSettings();
        const assetOperatorUri =`${settings.BillingServiceBaseUri}/assetOperator/*/id`
        const options = {
            method: "GET",
        };
        const assetOperatorIdResponse = await fetch(assetOperatorUri,options)
        const assetOperatorIds = await assetOperatorIdResponse.json()
        return assetOperatorIds;
    }
    
async function getAssetOperator(id: string, enriched: boolean) {
    const settings = await getSettings();
    const assetOperatorUri = `${settings.BillingServiceBaseUri}/assetoperators/${id}?enriched=${enriched}`
    const options = {
        method: "GET",
    };
    const assetOperatorResponse = await fetch(assetOperatorUri, options)

    if (assetOperatorResponse.ok) {

        const assetOperator = await assetOperatorResponse.json()
        return assetOperator;
    }

    return null;
}

async function getStripeLink(assetOperatorId: string): Promise<string> {
    const settings = await getSettings();
    const assetOperatorUri = `${settings.BillingServiceBaseUri}/assetoperators/${assetOperatorId}/stripesetupurl`
    const options = {
        method: "GET",
    };
    const stripeSetupUrlResponse = await fetch(assetOperatorUri, options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject.url;
}


async function getStripeAchSecret(assetOperatorId: string): Promise<string> {
    const settings = await getSettings();
    const assetOperatorUri = `${settings.BillingServiceBaseUri}/assetoperators/${assetOperatorId}/stripeachclientsecret`
    const options = {
        method: "GET",
    };
    const stripeSetupUrlResponse = await fetch(assetOperatorUri, options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject.secret;
}

async function createStripeAccount(assetOperatorId: string): Promise<string> {
    const settings = await getSettings();
    const assetOperatorUri = `${settings.BillingServiceBaseUri}/assetoperators/${assetOperatorId}/stripeaccount`
    const options = {
        method: "POST",
    };
    const stripeSetupUrlResponse = await fetch(assetOperatorUri, options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject.customerId;
}

async function createOrUpdateAssetOperator(assetOperator: AssetOperator): Promise<string> {
    const settings = await getSettings();
    const investorUri = `${settings.BillingServiceBaseUri}/assetoperators/${assetOperator.id}`
    const options = {
        method: "PUT",
        body: JSON.stringify(assetOperator)
    };
    const assetOperatorResponse = await fetch(investorUri, options)
    const assetOperatorCreated = await assetOperatorResponse.json()
    return assetOperatorCreated.id;
}

async function sendAssetOperatorWelcomeEmail(assetOperatorId: string | null): Promise<boolean> {
    if(assetOperatorId === null) return false;
    const sendEmailUri = `/api/assetoperators/${assetOperatorId}/sendwelcomeemail`
    const options = {
        method: "POST"
    };
    const sendEmailResponse = await fetch(sendEmailUri, options)
    return sendEmailResponse.ok
}

async function getBillPeriodsWithIssuedBills(assetOperatorId: string | undefined) : Promise<BillPeriodAndId[]> {
    if(assetOperatorId === undefined) return [];

    const assetOperatorUri =`/api/assetOperators/${assetOperatorId}/getBillPeriodsWithIssuedBills`
    const options = {
        method: "GET",
    };
    const billPeriodsResponse = await fetch(assetOperatorUri, options)
    const billPeriods = await billPeriodsResponse.json()
    return billPeriods;
}

export {
    getAssetOperators,
    getAssetOperator,
    getStripeLink,
    createStripeAccount,
    createOrUpdateAssetOperator,
    getStripeAchSecret,
    sendAssetOperatorWelcomeEmail,
    getAssetOperatorIds,
    getBillPeriodsWithIssuedBills
}
