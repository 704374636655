import { Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid"
import { Link } from "react-router-dom";
import { ConvertCurrencyToSymbol } from "../../helpers/ConvertCurrencyToSymbol";
import { GetPaymentStatusTextColor } from "../../helpers/GetPaymentStatusTextColor";
import { Payment } from "../../models/Payment"

interface InvestmentManagementFeePaymentsToActionTableProps {
    payments: Payment[];
    onCancelClick: Function;
    onArchiveClick: Function;
    onRetryClick: Function;
  }

const InvestmentManagementFeePaymentsToActionTable: React.FC<InvestmentManagementFeePaymentsToActionTableProps> = props => {
    const theme = useTheme()
    const columns: any[] = [
        { field: 'investorName', headerName: 'Lender Name', width: 200 },
        { field: 'currency', headerName: 'Currency', width: 130 },
        { field: 'paymentAmount', headerName: 'Amount', width: 130, renderCell: (params: any): string => {
            return params.row.currencyIsoCode == null ? '' : ConvertCurrencyToSymbol(params.row.currencyIsoCode) + params.row.paymentAmount.toLocaleString()
          }, },
        { field: 'paymentType', headerName: 'Type', width: 160 },
        { field: 'paymentStatus', headerName: 'Status', width: 200, renderCell: (params: any): JSX.Element => {
            return (<Typography style={{fontSize: 14, color: GetPaymentStatusTextColor(params.row.paymentStatus, theme)}}>{params.row.paymentStatus}</Typography>)
        } },
        { field: 'arrearsManagement', headerName: 'Arrears Management', width: 180 },
        {
          field: 'detailsButton', headerName: ' ', width: '100%', flex: 1, renderCell: (params: any): JSX.Element => {
            let toDetails = `/investmentmanagementfeepayments/details/${params.row.paymentId}`
              return (<>
                  <Button
                      component={Link}
                      to={toDetails}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginRight: 16, marginLeft: 'auto' }}
                  >
                      Details
                  </Button>
                  {params.row.paymentStatus === "Queued" ?
                  <Button
                      onClick={() => props.onCancelClick(params)}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginRight: 16 }}
                  >
                  Cancel
                  </Button>
              :
              params.row.paymentStatus === "Failed" ?
              <>
                <Button
                    onClick={() => props.onArchiveClick(params)}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: 16 }}
                >
                    Archive Record
                </Button>
                <Button
                    onClick={() => props.onRetryClick(params)}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginRight: 16 }}
                >
                    Retry Payment
                </Button>
                </>
                :
              <></>
              }
              </>
              )
            }
        }
      ];

return (
    <div style={{ height: '63vh', width: '100%' }}>
        <DataGrid
            rows={props.payments}
            columns={columns}
            rowsPerPageOptions={[10,50,100]}
            initialState={{
                pagination:{
                    pageSize: 10
                }
            }}
        />
    </div>
    )
}
export { InvestmentManagementFeePaymentsToActionTable }