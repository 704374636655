import React, {useEffect, useState} from "react";
import {DraftSubscription} from "../models/DraftSubscription";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    ListItemText,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Theme,
    Tooltip,
    Typography
} from "@mui/material";
import {genericLabelProps} from "../props/GenericLabelProps";
import {CurrencyIsoCodes} from "../models/CurrencyIsoCodes";
import {FeeCollectionMethods} from "../models/FeeCollectionMethods";
import {SubmitStatus} from "../models/SubmitStatus";
import SaveIcon from '@mui/icons-material/Save';
import {ConvertCurrencyToSymbol} from "../helpers/ConvertCurrencyToSymbol";
import {createStyles, makeStyles} from '@mui/styles';
import {UsageUnit} from "../models/UsageUnit";
import {largeLabelProps} from "../props/LargeLabelProps";
import {ConvertFeeCollectionMethodTypeToLabel} from "../helpers/ConvertFeeCollectionMethodTypeToLabel";
import {format} from "date-fns";
import {activateDraftSubscription, getDraftSubscription, postDraftSubscription} from "../services/DraftSubscriptionService";
import {DraftSubscriptionViewModel} from "../models/DraftSubscriptionViewModel";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { CheckIfDraftSubscriptionHasMandatoryFieldsFilled } from "../helpers/CheckIfDraftSubscriptionHasMandatoryFieldsFilled";
import { SixMonthMinimumUsage } from "../components/SixMonthMinimumUsage";
import { ReturnMandatoryFieldsTextForDraftSubscriptions } from "../helpers/ReturnMandatoryFieldsTextForDraftSubscriptions";
import { createInvestor, getInvestor, getInvestors, sendInvestorWelcomeEmail } from "../services/InvestorService";
import { InvestorViewModel } from "../models/InvestorViewModel";
import { InvoicingAndPaymentCollectionMethod } from "../models/InvoicingAndPaymentCollectionMethod";
import { LoadingButton } from "@mui/lab";
import { Address } from "../models/Address";
import { InvestorCreate } from "../components/InvestorCreate";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { createOrUpdateAssetOperator, getAssetOperatorIds, sendAssetOperatorWelcomeEmail } from "../services/AssetOperatorService";
import { AssetOperator } from "../models/AssetOperator";
import { CompanyType } from "../models/CompanyType";
import { AssetOperatorStatus } from "../models/AssetOperatorStatus";
import { AssetOperatorCreate } from "../components/AssetOperatorCreate";
import CloseIcon from "@mui/icons-material/Close";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        },
        dropDown: {
            textAlign: 'left',
            width: '100%',
            marginBottom: '10px',
            paddingTop: '10px',
        },
        textField: {
            width: '100%',
            paddingTop: '15px'
        }
    })
);


const DraftSubscriptionCreateEditPage: React.FC = ({match}: any) => {
    const classes = useStyles()
    const [draftSubscriptionViewModel, setDraftSubscriptionViewModel] = useState<DraftSubscriptionViewModel | null>(null)
    const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(SubmitStatus.NotSent)
    const [canEditContractId, setCanEditContractId] = useState<boolean>(false);
    const [creatingNewContract, setCreatingNewContract] = useState<boolean>(false);
    const [mandatoryFieldsFilled, setMandatoryFieldsFilled] = useState<boolean>(false);
    const [usagePeriodUpdatePending, setUsagePeriodUpdatePending] = useState<boolean>(false);
    const [activateContractModalIsOpen, setActivateContractModalIsOpen] = useState<boolean>(false);

    const [allInvestorIds, setAllInvestorIds] = useState<string[]>([]);
    const [newInvestorViewModel, setNewInvestorViewModel] = useState<InvestorViewModel>({ investmentManagementFeeInvoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, registeredAddress: { countryCode: 'GB' } as Address } as InvestorViewModel);
    const [createInvestorLoading, setCreateInvestorLoading] = useState<boolean>(false);
    const [selectedInvestorIds, setSelectedInvestorIds] = useState<string[]>([])
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState<number>(1);

    const [selectedAssetOperatorId, setSelectedAssetOperatorId] = useState<string | undefined>(undefined);
    const [allAssetOperatorIds, setAllAssetOperatorIds] = useState<string[]>([]);
    const [assetOperatorModelOpen, setAssetOperatorModalOpen] = useState(false);
    const [newAssetOperator, setNewAssetOperator] = useState<AssetOperator>({ companyType: CompanyType.Fleet, preferredDirectDebitPaymentDayOfMonth: 14, invoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, status: AssetOperatorStatus.Active } as AssetOperator)
    const [createAssetOperatorLoading, setCreateAssetOperatorLoading] = useState<boolean>(false);
    const [mandatoryAssetOperatorFieldsFilled, setMandatoryAssetOperatorFieldsFilled] = useState<boolean>(false)


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (match.params.draftSubscriptionId !== "create") {
                getDraftSubscription(match.params.draftSubscriptionId).then(existingDraftSubscription => {
                    setDraftSubscriptionViewModel(existingDraftSubscription)     
                    setCanEditContractId(false);
                });

            } else {
                var draftSubscriptionViewModel = new DraftSubscriptionViewModel(new DraftSubscription());
                draftSubscriptionViewModel.usageUnit = UsageUnit.Miles;
                draftSubscriptionViewModel.currencyIsoCode = CurrencyIsoCodes.Gbp;
                setDraftSubscriptionViewModel(draftSubscriptionViewModel);
                setCanEditContractId(true);
                setCreatingNewContract(true);
            }
        }

        return () => {
            mounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.subscriptionId])

    useEffect(() =>{
        if(draftSubscriptionViewModel){
            setMandatoryFieldsFilled(CheckIfDraftSubscriptionHasMandatoryFieldsFilled(draftSubscriptionViewModel))
        }
    }, [draftSubscriptionViewModel?.costPerUsageUnit,
        draftSubscriptionViewModel?.id,
        draftSubscriptionViewModel?.totalExpectedRepayment,
        draftSubscriptionViewModel?.monthsInContract,
        draftSubscriptionViewModel?.billingStartDate,
        draftSubscriptionViewModel?.maximumEndDate,
        draftSubscriptionViewModel?.minimumUsagePeriods,
        draftSubscriptionViewModel])

    function onSaveToDraft() {
        setSubmitStatus(SubmitStatus.Pending)

        if (draftSubscriptionViewModel) {
            postDraftSubscription(draftSubscriptionViewModel).then((response) => {

                if (response === "FAIL") {
                    setSubmitStatus(SubmitStatus.Failed)
                } else if (response === "FAIL - ID IN USE") {
                    setSubmitStatus(SubmitStatus.FailedIdInUse)
                } else {
                    setSubmitStatus(SubmitStatus.Success)
                    setCanEditContractId(false);
                }

            })
        } else {
            setSubmitStatus(SubmitStatus.NotSent)
        }
    }

    function SaveAsDraftButton() {
        switch (submitStatus) {
            case SubmitStatus.NotSent: {
                return (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onSaveToDraft()}
                        startIcon={<SaveIcon/>}
                        style={{margin: 16}}
                    >
                        Save as Draft
                    </Button>
                )
            }
            case SubmitStatus.Pending: {
                return <Button
                    color="primary"
                    variant="contained"
                    style={{margin: 16}}
                    disabled
                >
                    Pending
                </Button>
            }
            case SubmitStatus.Failed: {
                return <Button
                    color="secondary"
                    variant="contained"
                    style={{margin: 16}}
                    onClick={() => onSaveToDraft()}
                >
                    Failed, Please Try Again
                </Button>
            }
            case SubmitStatus.FailedIdInUse: {
                return <Button
                    color="secondary"
                    variant="contained"
                    style={{margin: 16}}
                    onClick={() => onSaveToDraft()}
                >
                    Failed, Id In Use
                </Button>
            }
            case SubmitStatus.Success: {
                return <Button
                    color="primary"
                    style={{margin: 16}}
                    variant="contained"
                    disabled
                >
                    Success
                </Button>
            }
        }
    }

    function onActivateDraftContract() {
        if(draftSubscriptionViewModel?.id) {
            getInvestors().then((investorViewModels: InvestorViewModel[]) => {
                let investorIds: string[] = []
                investorViewModels.forEach(investorViewModel => {
                    if (investorViewModel.id != null) investorIds.push(investorViewModel.id)
                })
                setAllInvestorIds(investorIds);                
            })
            getAssetOperatorIds().then(allFetchedAssetOperatorIds => setAllAssetOperatorIds(allFetchedAssetOperatorIds))

            setActivateContractModalIsOpen(true)
        }
    }

    const emailAddressIsValid = (emailAddress: string | undefined) => {
        if (typeof emailAddress === 'string') {
            const atSymbolIndex = emailAddress.indexOf('@');
            if (atSymbolIndex > 0 && emailAddress.substring(atSymbolIndex).includes('.')) {
                return true;
            }
        }
        return false;
    }

    const investorIdsHandleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value } } = event;
        setSelectedInvestorIds(value as string[]);
    }
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewInvestorViewModel({ investmentManagementFeeInvoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, registeredAddress: { countryCode: 'GB' } as Address } as InvestorViewModel);
    }

    const onClickCreateInvestor = () => {
        setCreateInvestorLoading(true);
        createInvestor(newInvestorViewModel).then((investorId) => {
            setSelectedInvestorIds(selectedInvestorIds => [...selectedInvestorIds, investorId]);
            setAllInvestorIds(allInvestorIds => [...allInvestorIds, investorId]);
            setNewInvestorViewModel({ investmentManagementFeeInvoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, registeredAddress: { countryCode: 'GB' } as Address } as InvestorViewModel);
            getInvestor(investorId).then((investor: InvestorViewModel | null) => {
                setCreateInvestorLoading(false);
            });
            setOpen(false);
        }
        )
    }

    const assetOperatorHandleChange = (event: SelectChangeEvent<string>) => {
        const { target: { value } } = event;
        setSelectedAssetOperatorId(value as string);
    }

    const handleAssetOperatorModalOpen = () => setAssetOperatorModalOpen(true);
    const handleAssetOperatorModalClose = () => {
        setAssetOperatorModalOpen(false);
        setNewAssetOperator({ companyType: CompanyType.Fleet, preferredDirectDebitPaymentDayOfMonth: 14, invoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, status: AssetOperatorStatus.Active } as AssetOperator);
    }

    const onClickCreateAssetOperator = () => {
        setCreateAssetOperatorLoading(true);
        createOrUpdateAssetOperator(newAssetOperator).then((assetOperatorId) => {
            setSelectedAssetOperatorId(assetOperatorId);
            setAllAssetOperatorIds(allAssetOperatorIds => [...allAssetOperatorIds, assetOperatorId]);
            setNewAssetOperator({ companyType: CompanyType.Fleet, preferredDirectDebitPaymentDayOfMonth: 14, invoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, status: AssetOperatorStatus.Active } as AssetOperator);
            setCreateAssetOperatorLoading(false);
            setAssetOperatorModalOpen(false);
            }
        )
    }

    function ActivateButton() {
        return (
            creatingNewContract ?
            <></>
            :
            mandatoryFieldsFilled ?
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onActivateDraftContract()}
                    startIcon={<ThumbUpIcon/>}
                    style={{margin: 16}}
                >
                    Activate
                </Button>
                :
                <Tooltip title={ReturnMandatoryFieldsTextForDraftSubscriptions(draftSubscriptionViewModel)}>
                    <span>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onActivateDraftContract()}
                            startIcon={<ThumbUpIcon/>}
                            style={{margin: 16}}
                            disabled={true}
                        >
                            Activate
                        </Button>
                    </span>
                </Tooltip>
            )
    }

    function saveAndActivate(): void {
        if(draftSubscriptionViewModel && selectedAssetOperatorId){
            setSubmitStatus(SubmitStatus.Pending)

            if (draftSubscriptionViewModel) {
                postDraftSubscription({...draftSubscriptionViewModel, assetOperatorId: selectedAssetOperatorId, investorIds: selectedInvestorIds}).then((response) => {

                    if (response === "FAIL") {
                        setSubmitStatus(SubmitStatus.Failed)
                    } else if (response === "FAIL - ID IN USE") {
                        setSubmitStatus(SubmitStatus.FailedIdInUse)
                    } else {
                        setSubmitStatus(SubmitStatus.Success)
                        setCanEditContractId(false);
                        activateDraftSubscription(draftSubscriptionViewModel.id)
                            .then(() => {
                                sendAssetOperatorWelcomeEmail(selectedAssetOperatorId)
                                selectedInvestorIds.forEach(x => sendInvestorWelcomeEmail(x))
                                window.location.href = `/subscriptions/${draftSubscriptionViewModel.id}`
                            })
                        
                    }

                })
            } else {
                setSubmitStatus(SubmitStatus.NotSent)
            }
            
        }
    }

    return (
        draftSubscriptionViewModel ?
            <>{activateContractModalIsOpen && page === 1 ?
                <Grid container sm={12} md={12} spacing={4} item
                    direction="column"
                    style={{ margin: '0', width: '80vw', padding: '0', marginLeft: '0' }}
                >
                <Card elevation={2} style={{ margin: "10px", width: '80vw', minHeight: '80vh', marginLeft: '0' }}>
                    <CardHeader subheader="Select Lenders" />
                    <CardContent>
                        <Grid xs={12} sm={12} md={12} item >
                            <InputLabel id="investorId-multiple-checkbox-label">Lender Ids</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                labelId="investorId-multiple-checkbox-label"
                                id="investorId-multiple-checkbox"
                                multiple
                                value={selectedInvestorIds}
                                onChange={investorIdsHandleChange}
                                variant="standard"
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {allInvestorIds.map((investorId) => (
                                    <MenuItem key={investorId} value={investorId}>
                                        <Checkbox checked={selectedInvestorIds.indexOf(investorId) > -1} />
                                        <ListItemText primary={investorId} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid>
                            <div>
                                <Grid container item xs={6} md={12} direction="row" alignItems="center"
                                    justifyContent="center"
                                    style={{ textAlign: "center", paddingTop: '10px', marginTop: '10px' }} spacing={4}>
                                    <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginTop: '20px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>Create a new lender</Button>
                                </Grid>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Card elevation={2} style={{ margin: "10px", width: '80vw', height: '95vh', marginLeft: 'auto', marginRight: 'auto', overflow: "hidden", overflowY: "auto" }}>
                                        <CardContent>
                                            <Grid
                                                style={{ marginBottom: '20px', marginTop: '20px' }}
                                                justifyContent="space-between"
                                                container
                                            >
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle1">Create a Lender</Typography>
                                                </Grid>
                                                <Grid item>
                                                    {createInvestorLoading ?
                                                        <LoadingButton loading variant="outlined">
                                                            Submit
                                                        </LoadingButton>
                                                        :
                                                        ( emailAddressIsValid(newInvestorViewModel.contactEmail)
                                                    ?
                                                    <Button variant="contained" color="primary"
                                                        onClick={() => onClickCreateInvestor()}>Create</Button>
                                                    : <Tooltip title="Please provide an email address">
                                                        <span>
                                                            <Button variant="contained" color="primary" disabled>Create</Button>
                                                        </span>
                                                    </Tooltip>)
                                                    }
                                                </Grid>
                                            </Grid>
                                            <InvestorCreate investorViewModel={newInvestorViewModel} setInvestorViewModel={setNewInvestorViewModel} />
                                        </CardContent>
                                    </Card>
                                </Modal>
                            </div>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container item xs={6} direction="row" alignItems="center"
                    justifyContent="center"
                    style={{ textAlign: "center", paddingTop: '10px' }} spacing={4}>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<NavigateBefore />}
                            color="primary"
                            onClick={() => setActivateContractModalIsOpen(false)}>
                            BACK
                        </Button>
                    </Grid>
                    <Grid item>
                    <Button
                        variant="contained"
                        startIcon={<NavigateNext />}
                        color="primary"
                        disabled={selectedInvestorIds.length === 0}
                        onClick={() => setPage(2)}
                        >
                        NEXT
                    </Button>
                    </Grid>
                </Grid>
            </Grid>
            :
            activateContractModalIsOpen && page === 2 ?
            <Grid container sm={12} md={12} spacing={4} item
                direction="column"
                style={{ margin: '0', width: '80vw', padding: '0', marginLeft: '0' }}
            >
                <Card elevation={2} style={{ margin: "10px", width: '80vw', height: '80vh', marginLeft: '0' }}>
                    <CardHeader subheader="Select Asset Operator" />
                    <CardContent>
                        <Grid xs={12} sm={12} md={12} item >
                            <InputLabel id="assetOperatorId-multiple-checkbox-label">Asset Operator Id</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                labelId="assetOperatorId-multiple-checkbox-label"
                                id="assetOperatorId-multiple-checkbox"
                                value={selectedAssetOperatorId ?? ""}
                                onChange={assetOperatorHandleChange}
                                variant="standard">
                                {allAssetOperatorIds.map((assetOperatorId) => (
                                    <MenuItem key={assetOperatorId} value={assetOperatorId}>
                                        <ListItemText primary={assetOperatorId} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid>
                            <div>
                                <Grid container item xs={6} md={12} direction="row" alignItems="center"
                                    justifyContent="center"
                                    style={{ textAlign: "center", paddingTop: '10px', marginTop: '10px' }} spacing={4}>
                                    <Button variant="contained" color="primary" onClick={handleAssetOperatorModalOpen} style={{ marginTop: '20px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>Create a new asset operator</Button>
                                </Grid>
                                <Modal
                                    open={assetOperatorModelOpen}
                                    onClose={handleAssetOperatorModalClose}
                                    aria-labelledby="create-asset-operator-modal-title"
                                    aria-describedby="create-asset-operator-modal-description"
                                >
                                    <Card elevation={2} style={{ margin: "10px", width: '80vw', height: '95vh', marginLeft: 'auto', marginRight: 'auto', overflow: "hidden", overflowY: "scroll" }}>
                                        <CardContent>
                                            <Grid
                                                style={{ marginBottom: '20px', marginTop: '20px' }}
                                                justifyContent="space-between"
                                                container
                                            >
                                                <Grid item>
                                                    <Typography
                                                        variant="subtitle1">Create an asset operator</Typography>
                                                </Grid>
                                                <Grid item>
                                                    {createAssetOperatorLoading ?
                                                        <LoadingButton loading variant="outlined">
                                                            Submit
                                                        </LoadingButton>
                                                        :
                                                        (mandatoryAssetOperatorFieldsFilled
                                                            ?
                                                            <Button variant="contained" color="primary"
                                                                onClick={() => onClickCreateAssetOperator()}>Create</Button>
                                                            : <Tooltip title="Please add an address, trading name, and at least one billing email address">
                                                                <span>
                                                                    <Button variant="contained" color="primary" disabled>Create</Button>
                                                                </span>
                                                            </Tooltip>)
                                                    }
                                                </Grid>
                                            </Grid>
                                            <AssetOperatorCreate assetOperator={newAssetOperator} setAssetOperator={setNewAssetOperator} setMandatoryAssetOperatorFieldsFilled={setMandatoryAssetOperatorFieldsFilled} />
                                        </CardContent>
                                    </Card>
                                </Modal>
                            </div>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container item xs={6} direction="row" alignItems="center"
                    justifyContent="center"
                    style={{ textAlign: "center", paddingTop: '10px' }} spacing={4}>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<NavigateBefore />}
                            color="primary"
                            onClick={() => setPage(1)}>
                            BACK
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            startIcon={<ThumbUpIcon />}
                            color="primary"
                            disabled={selectedAssetOperatorId === undefined}
                            onClick={() => saveAndActivate()}
                            loading={submitStatus === SubmitStatus.Pending}>
                            ACTIVATE
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
            :
            <Grid container>
                <Grid xs={12} item style={{marginBottom: '20px'}}>
                    <Card>
                        <CardHeader
                            title={draftSubscriptionViewModel?.name ? draftSubscriptionViewModel.name : "Awaiting Contract Name"}
                            subheader={draftSubscriptionViewModel?.id ? draftSubscriptionViewModel.id : "Awaiting Contract Id"}/>
                        <CardContent>
                            <Grid container>
                                <Grid xs={12} sm={6} md={6} item>

                                    <InputLabel id="usage-unit-input">Contract Type</InputLabel>
                                    <Select
                                        className={classes.dropDown}
                                        value={draftSubscriptionViewModel.usageUnit}
                                        labelId="usage-unit-input"
                                        id="UsageUnitSelect"
                                        variant="standard"
                                        defaultValue={UsageUnit.Miles}
                                        onChange={(e) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            usageUnit: e.target.value
                                        })}
                                    >
                                        <MenuItem value={UsageUnit.Miles}>Pay Per Mile</MenuItem>
                                        <MenuItem value={UsageUnit.ActiveAssets}>Pay Per Vehicle</MenuItem>
                                    </Select>

                                </Grid>
                                <Grid xs={12} sm={6} md={6} item textAlign='end'>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => window.location.href = "/draftSubscriptions"}
                                        startIcon={<CloseIcon/>}
                                        style={{margin: 16}}
                                    >
                                        Discard Changes
                                    </Button>
                                    <SaveAsDraftButton/>
                                    <ActivateButton/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} item style={{marginBottom: '20px'}}>
                    <Card>
                        <CardHeader title="Contract IDs"></CardHeader>
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid xs={3} sm={3} md={3} item>
                                    <TextField
                                        variant="standard"
                                        label="Contract ID"
                                        InputLabelProps={genericLabelProps}
                                        InputProps={
                                            draftSubscriptionViewModel?.id?.substring(0, 3) === "SUB"
                                                ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end"> </InputAdornment>
                                                    ),
                                                }
                                                : {
                                                    startAdornment: (
                                                        <InputAdornment position="start">{"SUB"}</InputAdornment>
                                                    ),
                                                }
                                        }
                                        value={draftSubscriptionViewModel.id ?? ""}
                                        onChange={(e) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            id: e.target.value?.substring(0, 3) === "SUB" ? e.target.value : "SUB" + e.target.value
                                        })}
                                        disabled={!canEditContractId}
                                    />
                                </Grid>
                                {draftSubscriptionViewModel?.shortId &&
                                    <Grid xs={3} sm={3} md={3} item>
                                        <TextField
                                            variant="standard"
                                            label="Contract Short ID"
                                            InputLabelProps={genericLabelProps}
                                            InputProps={

                                                draftSubscriptionViewModel?.shortId?.substring(0, 3) === "SUB" ? {}
                                                    : {
                                                        startAdornment: (
                                                            <InputAdornment position="start">{"SUB"}</InputAdornment>
                                                        ),
                                                    }
                                            }
                                            value={draftSubscriptionViewModel?.shortId ?? ''}
                                            disabled={true}
                                        />
                                    </Grid>}
                                <Grid xs={6} item>
                                    <TextField style={{width: "100%"}}
                                                variant="standard"
                                                label="Name"
                                                InputLabelProps={genericLabelProps}
                                                value={draftSubscriptionViewModel.name ?? ""}
                                                onChange={(e) => setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    name: e.target.value
                                                })}
                                    />
                                </Grid>
                                <Grid xs={12} sm={12} md={12} item>

                                    <TextField style={{width: "100%"}}
                                                variant="standard"
                                                label="Message to appear on every bill"
                                                InputLabelProps={genericLabelProps}
                                                value={draftSubscriptionViewModel.billMessage ?? ""}
                                                onChange={(e) => setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    billMessage: e.target.value
                                                })}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} item style={{marginBottom: '20px'}}>
                    <Card>
                        <CardHeader title="Rates"/>
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid xs={12} sm={4} md={4} item>
                                    <InputLabel id="currency-input">Currency</InputLabel>
                                    <Select
                                        className={classes.dropDown}
                                        value={draftSubscriptionViewModel.currencyIsoCode}
                                        inputProps={{
                                            name: "Currency",
                                        }}

                                        labelId="currency-input"
                                        id="CurrencySelect"
                                        variant="standard"
                                        onChange={(e) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            currencyIsoCode: e.target.value
                                        })}
                                    >
                                        <MenuItem value={CurrencyIsoCodes.Gbp}>{CurrencyIsoCodes.Gbp}</MenuItem>
                                        <MenuItem value={CurrencyIsoCodes.Usd}>{CurrencyIsoCodes.Usd}</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        variant="standard"
                                        className={classes.textField}
                                        label={draftSubscriptionViewModel.usageUnit === UsageUnit.Miles ? "Cost per Mile" : draftSubscriptionViewModel.usageUnit === UsageUnit.ActiveAssets ? "Monthly Cost per Asset" : ""}
                                        InputLabelProps={largeLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {ConvertCurrencyToSymbol(draftSubscriptionViewModel.currencyIsoCode)}
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={draftSubscriptionViewModel.costPerUsageUnit}
                                        onChange={(e) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            costPerUsageUnit: e.target.value as unknown as number
                                        })}
                                    />
                                </Grid>
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item style={{"width": "100%"}}>
                                        <TextField
                                            variant="standard"
                                            className={classes.textField}
                                            label="Total Contracted Usage"
                                            InputLabelProps={largeLabelProps}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end">{draftSubscriptionViewModel.usageUnit}</InputAdornment>
                                                ),
                                            }}
                                            value={draftSubscriptionViewModel.totalContractedUsage?.toLocaleString()}
                                            onChange={(e) => setDraftSubscriptionViewModel({
                                                ...draftSubscriptionViewModel,
                                                totalContractedUsage: e.target.value as unknown as number
                                            })}
                                        />
                                    </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        className={classes.textField}
                                        variant="standard"
                                        label="Monthly Telematics Cost per Asset"
                                        InputLabelProps={largeLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {ConvertCurrencyToSymbol(draftSubscriptionViewModel.currencyIsoCode)}
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={draftSubscriptionViewModel.telematicsCostPerAsset}
                                        onChange={(e) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            telematicsCostPerAsset: e.target.value as unknown as number
                                        })}
                                    />
                                </Grid>
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.ActiveAssets &&
                                    <Grid xs={6} sm={4} md={4} item style={{"width": "100%"}}>
                                        <Tooltip
                                            title="This is the Cost-Per-Mile * Total Contracted Usage."
                                        >
                                            <TextField
                                                variant="standard"
                                                className={classes.textField}
                                                label="Total Expected Repayment"
                                                InputLabelProps={largeLabelProps}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start">{ConvertCurrencyToSymbol(draftSubscriptionViewModel.currencyIsoCode)}</InputAdornment>
                                                    ),
                                                }}
                                                value={draftSubscriptionViewModel.totalExpectedRepayment?.toLocaleString()}
                                                onChange={(e) => setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    totalExpectedRepayment: e.target.value as unknown as number
                                                })}
                                            />
                                        </Tooltip>
                                    </Grid>}
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.ActiveAssets &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <TextField className={classes.textField}
                                                    variant="standard"
                                                    label="Months per Asset"
                                                    InputLabelProps={largeLabelProps}
                                                    value={draftSubscriptionViewModel.monthsInContract}
                                                    onChange={(e) => setDraftSubscriptionViewModel({
                                                        ...draftSubscriptionViewModel,
                                                        monthsInContract: e.target.value as unknown as number
                                                    })}
                                        />
                                    </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField className={classes.textField}
                                                variant="standard"
                                                label="Investment Management Fee Rate"
                                                InputLabelProps={largeLabelProps}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={draftSubscriptionViewModel.vmFeeRate}
                                                onChange={(e) => {
                                                    setDraftSubscriptionViewModel({
                                                        ...draftSubscriptionViewModel,
                                                        vmFeeRate: e.target.value as unknown as number
                                                    })                                                       
                                                }}
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField className={classes.textField}
                                                variant="standard"
                                                label="Tax Rate"
                                                InputLabelProps={largeLabelProps}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={draftSubscriptionViewModel.vmTaxRate}
                                                onChange={(e) => setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    vmTaxRate: e.target.value as unknown as number
                                                })}
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item style={{"width": "100%"}}>
                                    <InputLabel id="fee-collection-input-label">Fee Collection Method</InputLabel>

                                    <Select
                                        className={classes.dropDown}
                                        value={draftSubscriptionViewModel.feeCollectionMethod}
                                        inputProps={{
                                            name: "Currency",
                                        }}
                                        labelId="fee-collection-method-input"
                                        id="fee-collection-select"
                                        variant="standard"
                                        onChange={
                                            (e) => setDraftSubscriptionViewModel({
                                                ...draftSubscriptionViewModel,
                                                feeCollectionMethod: e.target.value
                                            })
                                        }
                                    >
                                        <MenuItem
                                            value={FeeCollectionMethods.ServiceFeeAndTelematicsOnInvestorBill}>{ConvertFeeCollectionMethodTypeToLabel(FeeCollectionMethods.ServiceFeeAndTelematicsOnInvestorBill)}</MenuItem>

                                            {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles ? (
                                                <MenuItem
                                                    value={FeeCollectionMethods.ServiceFeeAndTelematicsOnAssetOperatorBill}>{ConvertFeeCollectionMethodTypeToLabel(FeeCollectionMethods.ServiceFeeAndTelematicsOnAssetOperatorBill)}</MenuItem>
                                            ) : null}

                                    </Select>

                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} item style={{marginBottom: '20px'}}>
                    <Card>
                        <CardHeader title="Reporting Information"></CardHeader>
                        <CardContent>
                            <Grid container spacing={4} item>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        className={classes.textField}
                                        variant="standard"
                                        label="Days until Bill is in Arrears"
                                        InputLabelProps={largeLabelProps}
                                        value={draftSubscriptionViewModel.numberOfDaysUntilBillFallsIntoArrears}
                                        onChange={(e) =>
                                            setDraftSubscriptionViewModel({
                                                ...draftSubscriptionViewModel,
                                                numberOfDaysUntilBillFallsIntoArrears: e.target.value as unknown as number
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        className={classes.textField}
                                        variant="standard"
                                        label="Total Facility"
                                        InputLabelProps={genericLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {ConvertCurrencyToSymbol(draftSubscriptionViewModel.currencyIsoCode)}
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={draftSubscriptionViewModel.totalFacility}
                                        onChange={(e) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            totalFacility: e.target.value as unknown as number
                                        })}
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        className={classes.textField}
                                        variant="standard"
                                        label="Total Deposit"
                                        InputLabelProps={genericLabelProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {ConvertCurrencyToSymbol(draftSubscriptionViewModel.currencyIsoCode)}
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={draftSubscriptionViewModel.deposit}
                                        onChange={(e) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            deposit: e.target.value as unknown as number
                                        })}
                                    />
                                </Grid>
                                <Grid xs={6} sm={4} md={4} item>
                                    <Tooltip
                                        title={"Please select a date as close to the 1st of the month as possible"}>
                                        <TextField
                                            className={classes.textField}
                                            variant="standard"
                                            type="date"
                                            label="Billing Start Date"
                                            InputLabelProps={genericLabelProps}
                                            value={draftSubscriptionViewModel.billingStartDate ? format(new Date(draftSubscriptionViewModel.billingStartDate), "yyyy-MM-dd") : ""}
                                            onChange={(e) =>
                                                setDraftSubscriptionViewModel(
                                                    {
                                                        ...draftSubscriptionViewModel,
                                                        billingStartDate: e.target.value
                                                    }
                                                )
                                            }
                                        />
                                    </Tooltip>
                                </Grid>
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <TextField
                                            className={classes.textField}
                                            variant="standard"
                                            type="date"
                                            label="Target End Date"
                                            InputLabelProps={genericLabelProps}
                                            value={draftSubscriptionViewModel.targetEndDate ? format(new Date(draftSubscriptionViewModel.targetEndDate), "yyyy-MM-dd") : ""}
                                            onChange={(e) =>
                                                setDraftSubscriptionViewModel(
                                                    {...draftSubscriptionViewModel, targetEndDate: e.target.value}
                                                )
                                            }
                                        />
                                    </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <TextField
                                        className={classes.textField}
                                        variant="standard"
                                        type="date"
                                        label={draftSubscriptionViewModel.usageUnit === UsageUnit.Miles ? "Maximum End Date" : "End Date"}
                                        InputLabelProps={largeLabelProps}
                                        value={draftSubscriptionViewModel.maximumEndDate ? format(new Date(draftSubscriptionViewModel.maximumEndDate), "yyyy-MM-dd") : ""}
                                        onChange={(e) =>
                                            draftSubscriptionViewModel.usageUnit === UsageUnit.Miles ? setDraftSubscriptionViewModel({
                                                ...draftSubscriptionViewModel,
                                                maximumEndDate: e.target.value
                                            }) : setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    maximumEndDate: e.target.value,
                                                    targetEndDate: e.target.value
                                                }
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} item style={{marginBottom: '20px'}}>
                    <Card>
                        <CardHeader title="Contract Options"></CardHeader>
                        <CardContent>
                            <Grid container spacing={0} item>
                                <Grid xs={6} sm={4} md={4} item>
                                    <Tooltip
                                        title="This will activate billing once this contract has been moved to 'Active'."
                                    >
                                        <FormControlLabel
                                            control={<Switch color="primary"
                                                                checked={draftSubscriptionViewModel.isActive}/>}
                                            onChange={() => setDraftSubscriptionViewModel({
                                                ...draftSubscriptionViewModel,
                                                isActive: !draftSubscriptionViewModel.isActive
                                            })}
                                            label="Activate Billing"
                                        />
                                    </Tooltip>
                                </Grid>
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <Tooltip
                                            title="This option should only be enabled if the billing for this contract isn't through Stripe or GoCardless once Active."
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Switch color="primary"
                                                            checked={draftSubscriptionViewModel.offSystemPaymentsOnly}/>
                                                }
                                                onChange={() =>
                                                    setDraftSubscriptionViewModel({
                                                        ...draftSubscriptionViewModel,
                                                        offSystemPaymentsOnly: !draftSubscriptionViewModel.offSystemPaymentsOnly
                                                    })
                                                }
                                                label="Off System Payments"
                                            />
                                        </Tooltip>
                                    </Grid>}
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={draftSubscriptionViewModel.adjustCostPerUsageUnitWithInflation}
                                                />
                                            }
                                            onChange={() =>
                                                setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    adjustCostPerUsageUnitWithInflation: !draftSubscriptionViewModel.adjustCostPerUsageUnitWithInflation
                                                })
                                            }
                                            label="Adjust Cost Per Usage Unit With Inflation?"
                                        />
                                    </Grid>}
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <FormControlLabel
                                            control={<Switch color="primary"
                                                                checked={draftSubscriptionViewModel.vatApplicable}/>}
                                            onChange={() => setDraftSubscriptionViewModel({
                                                ...draftSubscriptionViewModel,
                                                vatApplicable: !draftSubscriptionViewModel.vatApplicable
                                            })}
                                            label="VAT Applicable?"
                                        />
                                    </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={
                                            <Switch color="primary"
                                                    checked={draftSubscriptionViewModel.isBalloonPaymentRequired}/>
                                        }
                                        onChange={() =>
                                            setDraftSubscriptionViewModel({
                                                ...draftSubscriptionViewModel,
                                                isBalloonPaymentRequired: !draftSubscriptionViewModel.isBalloonPaymentRequired
                                            })
                                        }
                                        label="Balloon Payment Required?"
                                    />
                                </Grid>
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <FormControlLabel
                                            control={
                                                <Switch color="primary"
                                                        checked={draftSubscriptionViewModel.showStartEndOdometerReadingsOnBill}/>
                                            }
                                            onChange={() =>
                                                setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    showStartEndOdometerReadingsOnBill: !draftSubscriptionViewModel.showStartEndOdometerReadingsOnBill
                                                })}
                                            label="Show start and end odometer readings on bills?"
                                        />
                                    </Grid>}
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>
                                        <FormControlLabel
                                            control={
                                                <Switch color="primary"
                                                        checked={draftSubscriptionViewModel.automaticallyCollectCatchupPayments}/>
                                            }
                                            onChange={() =>
                                                setDraftSubscriptionViewModel({
                                                    ...draftSubscriptionViewModel,
                                                    automaticallyCollectCatchupPayments: !draftSubscriptionViewModel.automaticallyCollectCatchupPayments
                                                })}
                                            label="Automatically Collect Catchup Payments?"
                                        />
                                    </Grid>}
                                <Grid xs={6} sm={4} md={4} item>
                                    <FormControlLabel
                                        control={<Switch color="primary"
                                                            checked={draftSubscriptionViewModel.includeInPublicCO2AndNOxCount}/>}
                                        onChange={(_) => setDraftSubscriptionViewModel({
                                            ...draftSubscriptionViewModel,
                                            includeInPublicCO2AndNOxCount: !draftSubscriptionViewModel.includeInPublicCO2AndNOxCount
                                        })}
                                        label="Include CO2 and NOx savings on main website?"
                                    />
                                </Grid>
                                {draftSubscriptionViewModel.usageUnit === UsageUnit.Miles &&
                                    <Grid xs={6} sm={4} md={4} item>                                       
                                            <FormControlLabel
                                                control={
                                                    <Switch color="primary"
                                                            checked={draftSubscriptionViewModel.enableProRataingMinimumUsagePeriods}/>
                                                }
                                                onChange={() =>
                                                    setDraftSubscriptionViewModel({
                                                        ...draftSubscriptionViewModel,
                                                        enableProRataingMinimumUsagePeriods: !draftSubscriptionViewModel.enableProRataingMinimumUsagePeriods
                                                    })
                                                }
                                                label="Enable ProRataing Minimum Usage Periods"
                                            />
                                        
                                    </Grid>}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {(draftSubscriptionViewModel.usageUnit === UsageUnit.Miles || draftSubscriptionViewModel.usageUnit === UsageUnit.Kilometres)
                && draftSubscriptionViewModel.totalContractedUsage && draftSubscriptionViewModel.totalContractedUsage !== 0 ?
                    <Grid xs={12} item style={{marginBottom: '20px'}}>
                        <SixMonthMinimumUsage subscription={draftSubscriptionViewModel} setSubscription={setDraftSubscriptionViewModel} setUsagePeriodUpdatePending={setUsagePeriodUpdatePending} usagePeriodUpdatePending={usagePeriodUpdatePending} />
                    </Grid>
                    :
                    null
                }
            </Grid>}
            </>
            :
            (
                <div className={classes.loader}>
                    <Typography variant="subtitle1" gutterBottom>Please Wait</Typography>
                    <LinearProgress color="secondary"/>
                </div>
            )
    )


}

export {DraftSubscriptionCreateEditPage}

