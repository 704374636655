import {Address} from "./Address";
import {Investment} from "./Investment";
import {InvoicingAndPaymentCollectionMethod} from "./InvoicingAndPaymentCollectionMethod";
import {InvestorViewModel} from "./InvestorViewModel";

export class Investor {
    id: string | undefined
    shortId!: string
    name!: string
    registeredAddress!: Address
    stripeConnectedAccountId: string | undefined
    stripeFeePaymentCustomerId: string | undefined
    contactEmail!: string
    adminIds!: string[]
    userIds!: string[]
    investments!: Investment[]
    investmentManagementFeeInvoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod | undefined
    welcomeEmailSent!: boolean
    primaryCurrencyIsoCode!: string
    isArchived!: boolean
    companyNumber!: string
    taxRateChargedOnFees!: number | undefined
    
    public static Convert(investorViewModel: InvestorViewModel): Investor {
        const investor: Investor = {...investorViewModel};
        
        investor.taxRateChargedOnFees = investorViewModel.vmTaxRateChargedOnFees === null ? undefined :investorViewModel.vmTaxRateChargedOnFees / 100;
        return investor;
    }
}



