import { Button, Card, CardActions, CardContent, CardHeader, LinearProgress, Theme, Typography } from "@mui/material"
import React, { useEffect, useState } from 'react';
import { getLatestDraftInternalInvoicesCsv, getLatestTrialInternalInvoices, } from "../../services/BillService";
import { InternalInvoice } from "../../models/InternalInvoice";
import { createStyles, makeStyles } from '@mui/styles';
import { FileDownloadDataAndName } from "../../models/FileDownloadDataAndName";
import { downloadFileFromFileDownloadDataAndName } from "../../services/FileDownloadService";
import { TrialBillingRunInternalInvoiceTable } from "../../components/TrialBillingRunInternalInvoiceTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        },
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20,
        },
        details: {
            alignItems: 'center',
        },
        column: {
            flexBasis: '33.33%',
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        }
    }))

const TrialBillingRunOverviewPageInternalInvoices: React.FC = () => {
    const [invoices, setInvoices] = useState<InternalInvoice[]>([])
    const [mappedInvoices, setMappedInvoices] = useState<any[]>([])
    const [showResults, setShowResults] = useState<boolean>(false)

    useEffect(() => {
        const mappedInvoices = mapInvoiceModelsToDataTableRows(invoices);
        setMappedInvoices(mappedInvoices)
        if (invoices !== undefined && invoices.length > 0) {
            setShowResults(true)
        }
    }, [invoices])

    useEffect(() => {
        let mounted = true;
        getLatestTrialInternalInvoices().then(invoices => {
            if (mounted) setInvoices(invoices)
        })
    }, [])

    function mapInvoiceModelsToDataTableRows(invoices: InternalInvoice[]): any[] {
        const mappedInvoices = invoices.map((invoice, index) => {
            return {
                id: index,
                invoiceNumber: invoice.invoiceNumber,
                subscriptionId: invoice.subscriptionId,
                dateOfBill: invoice.billingDate,
                billingPeriodStartDate: invoice.billingPeriodStartDate,
                billingPeriodEndDate: invoice.billingPeriodEndDate,
                amount: invoice.totalPaymentLine.net,
                invoiceId: invoice.id,
                usagePeriod: new Date(invoice.billingPeriodEndDate).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' }),
            }
        });

        return mappedInvoices;
    }

    function downloadCsv() {
        getLatestDraftInternalInvoicesCsv().then((csvDownloadDataAndName: FileDownloadDataAndName) => {
            downloadFileFromFileDownloadDataAndName('text/csv', csvDownloadDataAndName)
        })
    }

    const classes = useStyles()

    const calculationTimeHeader = invoices !== undefined && invoices.length > 0 ? "Calculated at " + new Date(invoices[0]?.calculatedAtDateTime).toLocaleDateString() + " " + new Date(invoices[0]?.calculatedAtDateTime).toLocaleTimeString() : ""
    const billingRunIdHeader = invoices !== undefined && invoices.length > 0 ? "Billing run id: " + invoices[0]?.billingRunReportId : ""

    return <Card>
        <CardHeader title="Latest Trial Billing Run - Internal Invoices" subheader={calculationTimeHeader + ", " + billingRunIdHeader} />
        <CardContent style={{ minHeight: '65vh' }}>
            {
                showResults !== undefined
                    ?
                    (
                        <>
                            {mappedInvoices.length > 0 ?
                                <TrialBillingRunInternalInvoiceTable invoices={mappedInvoices} /> :
                                <div className={classes.loader}>
                                    <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for
                                        your
                                        patience</Typography>
                                    <LinearProgress color="secondary" />
                                </div>}
                        </>
                    )
                    :
                    (
                        <div className={classes.loader}>
                            <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your
                                patience</Typography>
                            <LinearProgress color="secondary" />
                        </div>
                    )
            }
        </CardContent>
        <CardActions>


            <Button variant="contained" color="primary"
                href={`/api/latestDraftInternalInvoices/zip`}>
                {"Download All Pdfs As Zip"}</Button>

            <Button variant="contained" color="primary"
                onClick={downloadCsv}
                style={{ marginLeft: 10 }}>{"Download Table Data As Csv"}</Button>
        </CardActions>
    </Card>
    ;
}

export { TrialBillingRunOverviewPageInternalInvoices }
