import { Theme } from "@mui/material";

function GetBillPaymentStatusTextColor(billStatus: string | undefined, theme: Theme): string {
    switch (billStatus) {
        case "Queued":
            return theme.palette.text.primary;
        case "Processing":
            return theme.palette.primary.light;
        case "Paid":
            return theme.palette.success.main;
        case "Failed":
            return theme.palette.error.main;
        case "Retrying":
            return theme.palette.warning.main;
        case "Cancelled":
            return theme.palette.info.main;
        case "Unreconciled":
            return theme.palette.text.primary;
        default:
            return theme.palette.text.primary;
    }
}

export { GetBillPaymentStatusTextColor };
