import {
    LinearProgress,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
    Theme,
    Card,
    CardActions,
    CardContent,
    CardHeader
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { CountdownToNextPaymentSubmission } from "../../components/CountdownToNextPaymentSubmission";
import { ConvertPaymentStatusToString } from "../../helpers/ConvertPaymentStatusToString";
import { PaymentStatus } from "../../models/PaymentStatus";
import { InvestmentManagementFeePayment } from "../../models/InvestmentManagementFeePayment";
import { getInvestors } from "../../services/InvestorService";
import { AllInvestmentManagementFeePaymentsTable } from "../../components/Payments/AllInvestmentManagementFeePaymentsTable";
import { cancelInvestmentManagementFeePayment, getAllInvestorFeePayments } from "../../services/InvestmentManagementFeePaymentService";
import { makeStyles, createStyles } from "@mui/styles";


const useStyles = makeStyles(() =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))

type InvestorPaymentGroup = {
    commonPaymentMonth: Date,
    payments: InvestmentManagementFeePayment[]
}

const InvestmentManagementFeePayments: React.FC = () => {
    const [payInvestmentManagementFeePaymentSent, setPayInvestmentManagementFeePaymentSent] = useState<InvestmentManagementFeePayment[]>([])
    const [mappedPayments, setMappedPayments] = useState<any[]>([])
    const [selectedPaymentGroup, setSelectedPaymentGroup] = useState<InvestorPaymentGroup | undefined>()
    const [selectedPaymentGroupDate, setSelectedPaymentGroupDate] = useState<string>('')
    const [feedbackMessage, setFeedbackMessage] = useState<string>()
    const [feedbackMessageColor, setFeedbackMessageColor] = useState<string>()

    async function onCancelClick(paymentRow: any, theme: Theme): Promise<void> {
        let response = await cancelInvestmentManagementFeePayment(paymentRow.id as string);
        let paymentName = paymentRow.investorName === '' ? paymentRow.investorId : paymentRow.investorName

        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully cancelled')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: InvestmentManagementFeePayment[] = []
            getAllInvestorFeePayments()
                .then(investorFeePayment => {
                    getInvestors()
                        .then(investor =>
                            investorFeePayment.map(
                                (payment) => {
                                    let actualInvestor = investor.find(investor => investor.id === payment.investorId)
                                    payment.investorName = actualInvestor == null ? '' : actualInvestor.name
                                    return alteredPayments.push(payment)
                                }))
                        .then(() => {
                            const validAlteredPayments = alteredPayments.filter(payment => payment.status !== PaymentStatus.FailedAndRetried);
                            setPayInvestmentManagementFeePaymentSent(validAlteredPayments)
                        })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to cancel')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    const groupNonQueuedInvestorPaymentsByPaymentMonth = (investmentManagementFeePayments: InvestmentManagementFeePayment[]): InvestorPaymentGroup[] => {
        let paymentGroups: InvestorPaymentGroup[] = []
        for (let investmentManagementFeePayment of investmentManagementFeePayments) {
            let createdDate = new Date(investmentManagementFeePayment.dateTimePaymentCreated)
            let createdMonth = createdDate.getMonth()
            let createdYear = createdDate.getFullYear()
            const matchingPaymentGroupIndex = paymentGroups.findIndex(x => x.commonPaymentMonth.valueOf() === new Date(createdYear, createdMonth, 1).valueOf())
            if (matchingPaymentGroupIndex === -1) {
                paymentGroups.push({
                    commonPaymentMonth: new Date(createdYear, createdMonth, 1),
                    payments: [investmentManagementFeePayment]
                })
            } else {
                paymentGroups[matchingPaymentGroupIndex].payments.push(investmentManagementFeePayment)
            }
        }
        return paymentGroups;
    }

    const classes = useStyles()

    useEffect(() => {
        let mounted = true;
        let alteredPayments: InvestmentManagementFeePayment[] = []
        getAllInvestorFeePayments()
            .then(investorFeePayment => {
                getInvestors()
                    .then(investor =>
                        investorFeePayment.map(
                            (payment) => {
                                let actualInvestor = investor.find(investor => investor.id === payment.investorId)
                                payment.investorName = actualInvestor == null ? '' : actualInvestor.name
                                return alteredPayments.push(payment)
                            }))
                    .then(() => {
                        if (mounted) {
                            const validAlteredPayments = alteredPayments.filter(payment => payment.status !== PaymentStatus.FailedAndRetried);
                            setPayInvestmentManagementFeePaymentSent(validAlteredPayments)
                        }
                    })
            })

        return () => {
            mounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function mapPaymentModelsToDataTableRows(investmentManagementFeePayments: InvestmentManagementFeePayment[]): any[] {

        return investmentManagementFeePayments.map(investmentManagementFeePayment => {

            return {
                id: investmentManagementFeePayment?.id,
                investorName: investmentManagementFeePayment?.investorName,
                investorId: investmentManagementFeePayment?.investorId,
                dateTimePaymentCreated: investmentManagementFeePayment.dateTimePaymentCreated,
                currencyIsoCode: investmentManagementFeePayment?.currencyIsoCode,
                amountCharged: investmentManagementFeePayment?.amountCharged,
                paymentType: investmentManagementFeePayment?.paymentType,
                paymentStatus: ConvertPaymentStatusToString(investmentManagementFeePayment?.status),
                arrearsManagement: investmentManagementFeePayment?.previouslyFailedAttempts?.length > 0 ? "Retry attempt: " + investmentManagementFeePayment?.previouslyFailedAttempts?.length : ''
            }
        });
    }

    const paymentsGrouped = groupNonQueuedInvestorPaymentsByPaymentMonth(payInvestmentManagementFeePaymentSent).sort((a, b) => new Date(b.commonPaymentMonth).getTime() - new Date(a.commonPaymentMonth).getTime())

    useEffect(() => {
        if (selectedPaymentGroupDate === '') {
            if (paymentsGrouped[0]?.commonPaymentMonth.toLocaleDateString() !== undefined) {
                setSelectedPaymentGroupDate(paymentsGrouped[0]?.commonPaymentMonth.toLocaleDateString())
                let filteredGroupedPayments = paymentsGrouped?.filter(paymentGroup => paymentGroup.commonPaymentMonth.toLocaleDateString() === paymentsGrouped[0]?.commonPaymentMonth.toLocaleDateString() as string)[0]
                setSelectedPaymentGroup(filteredGroupedPayments)
                const localMappedPayments = mapPaymentModelsToDataTableRows(filteredGroupedPayments?.payments);
                setMappedPayments(localMappedPayments)
            }
        }
    }, [paymentsGrouped, selectedPaymentGroupDate, payInvestmentManagementFeePaymentSent])

    return (
        <Card>
            <CardHeader
                action={
                    selectedPaymentGroup &&
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedPaymentGroupDate}
                        label="Age"
                        onChange={(event: SelectChangeEvent) => {
                            setSelectedPaymentGroupDate(event.target.value as string)
                            let filteredGroupedPayments = paymentsGrouped.filter(paymentGroup => paymentGroup.commonPaymentMonth.toLocaleDateString() === event.target.value as string)[0]
                            setSelectedPaymentGroup(filteredGroupedPayments)
                            const localMappedPayments = mapPaymentModelsToDataTableRows(filteredGroupedPayments.payments);
                            setMappedPayments(localMappedPayments)
                        }}
                    >
                        {paymentsGrouped.map(paymentGroup => <MenuItem
                            value={paymentGroup.commonPaymentMonth.toLocaleDateString()}>{paymentGroup.commonPaymentMonth.toLocaleString('default', { month: 'long' })} {paymentGroup.commonPaymentMonth.getFullYear()}</MenuItem>)}
                    </Select>
                }
                title={"All Lender Fee Payments created in " + (selectedPaymentGroup?.commonPaymentMonth.toLocaleString('default', { month: 'long' }) ?? "...") + " " + (selectedPaymentGroup?.commonPaymentMonth.getFullYear() ?? "")} />
            <Typography variant="h2"></Typography>
            <CardContent style={{height: '65vh'}}>
                {
                    selectedPaymentGroup !== undefined
                        ?
                        (
                            <>
                                <AllInvestmentManagementFeePaymentsTable payments={mappedPayments} onCancelClick={onCancelClick} />
                            </>
                        )
                        :
                        (
                            <div className={classes.loader}>
                                <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your
                                    patience</Typography>
                                <LinearProgress color="secondary" />
                            </div>
                        )
                }
            </CardContent>
            <CardActions>
                <CountdownToNextPaymentSubmission />
                <Typography style={{ marginLeft: 'auto', color: feedbackMessageColor }} variant="subtitle1">{feedbackMessage}</Typography>
            </CardActions>
        </Card>
    )
}

export { InvestmentManagementFeePayments }
