import { CreateABillByRestPostMessage } from "../models/CreateBillByRest";
import { getSettings } from "./SettingsService"
import { Bill } from "../models/Bill";
import { InvestmentManagementFeeBill } from "../models/InvestmentManagementFeeBill";
import { FileDownloadDataAndName } from "../models/FileDownloadDataAndName";
import {InternalInvoice} from "../models/InternalInvoice";

async function postBill(id: string, message: CreateABillByRestPostMessage): Promise<Response> {
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/assetoperators/${id}/bills`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(message)
    })
}

async function getBill(billIdOrName: string): Promise<Bill> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/bills/${billIdOrName}`, options)
    const bill: Bill = await response.json()
    return bill;
}

async function getAllNonDraftBills(billingMonth: string): Promise<Bill[]> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/bills?billingMonth=${billingMonth}&includeDraftBills=false`, options)
    const bills: Bill[] = await response.json()
    return bills
}

async function getLatestTrialBills(): Promise<Bill[]> {
    const settings = await getSettings()
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
        },
    };const response = await fetch(`${settings.BillingServiceBaseUri}/latestDraftBills`, options)
    const bills: Bill[] = await response.json()
    return bills;
}

async function getLatestTrialBillsCsv() : Promise<FileDownloadDataAndName> {
    const settings = await getSettings()
    const options = {
        method: "GET",
        headers: {
            "Accept": "text/csv",
        }
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/latestDraftBills`, options)
    const data = await response.text()
    
    var name = "UnableToFetchFileName.csv"
    var contentDispositionHeader = response.headers.get('content-disposition')
    if(contentDispositionHeader !== null){
        name = contentDispositionHeader.split('filename=')[1];
    }
    return {data, name}
}

async function triggerTrialBillingRun(): Promise<boolean> {
    const settings = await getSettings()

    const options = {
        method: "GET"
    }

    const response = await fetch(`${settings.BillingServiceBaseUri}/trialBillingRun`, options)

    return response.status === 200
}

async function approveHeldBill(billId: string): Promise<string> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    var data = await fetch(`${settings.BillingServiceBaseUri}/approveheldbill/${billId}`, options)
        .then(function (response) {
            return response.text();
        }).then(function (data) {
            return data;
        });
    if (data) {
        return data;
    } else {
        return "Something went wrong - unable to read a response from BillingService";
    }
}

async function getAllNonDraftInvestmentManagementFeeBills(): Promise<InvestmentManagementFeeBill[]> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/investmentManagementFeeBills?includeDraftBills=false`, options)
    const bills: InvestmentManagementFeeBill[] = await response.json()
    return bills
}

async function getAllNonDraftInternalInvoices(): Promise<InternalInvoice[]> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/internalInvoices?includeDraftBills=false`, options)
    const invoices: InternalInvoice[] = await response.json()
    return invoices
}

async function getInvestmentManagementFeeBill(billIdOrName: string): Promise<InvestmentManagementFeeBill> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/investmentManagementFeeBills/${billIdOrName}`, options)
    const bill: InvestmentManagementFeeBill = await response.json()
    return bill;
}

async function approveHeldInvestmentManagementFeeBill(billId: string): Promise<string> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    var data = await fetch(`${settings.BillingServiceBaseUri}/approveheldinvestmentmanagementfeebill/${billId}`, options)
        .then(function (response) {
            return response.text();
        }).then(function (data) {
            return data;
        });
    if (data) {
        return data;
    } else {
        return "Something went wrong - unable to read a response from BillingService";
    }
}

async function getLatestTrialInvestmentManagementFeeBills(): Promise<InvestmentManagementFeeBill[]> {
    const settings = await getSettings()
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
        }
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/latestDraftInvestmentManagementFeeBills`, options)
    const bills: InvestmentManagementFeeBill[] = await response.json()
    return bills;
}

async function getLatestTrialInternalInvoices(): Promise<InternalInvoice[]> {
    const settings = await getSettings()
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
        }
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/latestDraftInternalInvoices`, options)
    const invoices: InternalInvoice[] = await response.json()
    return invoices;
}

async function getLatestTrialInvestmentManagementFeeBillsCsv() : Promise<FileDownloadDataAndName> {
    const settings = await getSettings()
    const options = {
        method: "GET",
        headers: {
            "Accept": "text/csv",
        }
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/latestDraftBills`, options)
    const data = await response.text()
    
    var name = "UnableToFetchFileName.csv"
    var contentDispositionHeader = response.headers.get('content-disposition')
    if(contentDispositionHeader !== null){
        name = contentDispositionHeader.split('filename=')[1];
    }
    return {data, name}
}

async function getLatestDraftInternalInvoicesCsv(): Promise<FileDownloadDataAndName> {
    const settings = await getSettings()
    const options = {
        method: "GET",
        headers: {
            "Accept": "text/csv",
        }
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/latestDraftInternalInvoices`, options)
    const data = await response.text()
    
    var name = "UnableToFetchFileName.csv"
    var contentDispositionHeader = response.headers.get('content-disposition')
    if(contentDispositionHeader !== null){
        name = contentDispositionHeader.split('filename=')[1];
    }
    return {data, name}
}

async function getHeldNonDraftBillsForMonth(billingMonth: string): Promise<Bill[]> {
    const settings = await getSettings()
    const options = {
        method: "GET",
    };
    const response = await fetch(`${settings.BillingServiceBaseUri}/heldBills?billingMonth=${billingMonth}&includeDraftBills=false`, options)
    const bills: Bill[] = await response.json()
    return bills
}

async function getIssuedAndNonDraftBillViewModelsForAssetOperator(id: string | undefined): Promise<Bill[]> {
    if(id === undefined) return []
    const options = {
        method: "GET",
    };
    const response = await fetch(`/api/assetoperators/${id}/billViewModels/IssuedAndNonDraft`, options)
    const bills: Bill[] = await response.json()
    return bills
}

async function getNonDraftBillsForAssetOperator(id: string | undefined): Promise<Bill[]> {
    if(id === undefined) return []
    const options = {
        method: "GET",
    };
    const response = await fetch(`/api/assetoperators/${id}/nonDraftBills`, options)
    const bills: Bill[] = await response.json()
    return bills
}

async function getAllBillingMonths(): Promise<string[]> {
    const options = {
        method: "GET",
    };
    const response = await fetch(`/api/billMonths`, options)
    const billMonths: string[] = await response.json()
    
    return billMonths
}

export { getIssuedAndNonDraftBillViewModelsForAssetOperator, postBill, getBill, getAllNonDraftBills, approveHeldBill, getLatestTrialBills, triggerTrialBillingRun, getAllNonDraftInvestmentManagementFeeBills, getInvestmentManagementFeeBill, approveHeldInvestmentManagementFeeBill, getLatestTrialInvestmentManagementFeeBills, getHeldNonDraftBillsForMonth, getLatestTrialBillsCsv, getLatestTrialInvestmentManagementFeeBillsCsv, getLatestTrialInternalInvoices, getAllNonDraftInternalInvoices, getLatestDraftInternalInvoicesCsv, getNonDraftBillsForAssetOperator, getAllBillingMonths }
