import 'date-fns';
import { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, FormControl, FormGroup, Grid, InputLabel, List, MenuItem, Select, SelectChangeEvent, TextField, Theme, Tooltip, Typography } from '@mui/material'
import { Bill } from '../models/Bill';
import { CreateAPaymentMessage } from '../models/CreateAPaymentMessage';
import { SubscriptionBillPaymentLines } from './SubscriptionBillPaymentLines'
import { CreateAPaymentByRest } from "../services/PaymentService"
import { addDays } from 'date-fns/esm';
import { TypeOfPayment } from "../models/TypeOfPayment";
import { ConvertCurrencyToSymbol } from "../helpers/ConvertCurrencyToSymbol";
import { CurrencyIsoCodes } from "../models/CurrencyIsoCodes";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { makeStyles, createStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AssetOperator } from '../models/AssetOperator';
import { getBill, getNonDraftBillsForAssetOperator } from '../services/BillService';
import { HelpOutline } from '@mui/icons-material';

type CreateAPaymentProps = {
    assetOperator: AssetOperator
    typeOfPayment: TypeOfPayment
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            width: '100%'
        },
        success: {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.main
        },
        warning: {
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main
        }
    }),
);


function CreateAPayment({ assetOperator, typeOfPayment }: CreateAPaymentProps) {
    const classes = useStyles();
    let now = new Date()
    let inAFewDaysTime = addDays(now, 4)

    const [paymentDate, setPaymentDate] = useState<Date>(inAFewDaysTime)
    const [paymentCreatedSuccessfully, setPaymentCreatedSuccessfully] = useState<boolean | null>(null);
    const [bills, setBills] = useState<Bill[]>([]);
    const [selectedBill, setSelectedBill] = useState<Bill | undefined>(undefined);
    const [selectedBillId, setSelectedBillId] = useState<string|null>(null);

    const handlePaymentDateChange = (value: unknown) => {
        let date = value as Date | null
        if (date != null) {
            setPaymentDate(date);
        }
    };

    const handleClick = async () => {
        let message: CreateAPaymentMessage = {
            datePaymentShouldBeTaken: paymentDate,
            typeOfPayment: typeOfPayment,
            billId: selectedBillId ?? ""
        }
        let result = await CreateAPaymentByRest(selectedBill?.assetOperatorId ?? "", selectedBill?.id ?? "", message);
        setPaymentCreatedSuccessfully(result.ok)
    }

    const handleChangeSelectedBill = (event: SelectChangeEvent) => {
        setSelectedBillId(event.target.value as string);
      };

    useEffect(() => {
        let mounted = true;
        selectedBillId &&
        getBill(selectedBillId)
            .then(item => {
                if (mounted) {
                    setSelectedBill(item)
                }
            })
        return () => {
            mounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBillId])

    useEffect(() => {
        let mounted = true;
        getNonDraftBillsForAssetOperator(assetOperator.id).then((item: Bill[]) => {
            if (mounted) {
                setBills([...item]);
            }
        });
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetOperator.id]);

    const title = `Request a ${typeOfPayment === TypeOfPayment.GoCardless ? "GoCardless" : "Stripe"} Payment`;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Card className={classes.card} elevation={10}>
                <CardHeader title={title} />
                <CardContent>
                    <Grid container direction="row" alignItems="center">
                    <Box sx={{ minWidth: 120, marginBottom: '10px', flexGrow: '1' }}>
                        <FormControl fullWidth>
                            <InputLabel id="bill-date-select-label">Billing Month</InputLabel>
                            <Select
                                labelId="bill-date-select-label"
                                id="bill-date-select"
                                value={selectedBillId ?? ''}
                                label="Selected Bill ID"
                                onChange={handleChangeSelectedBill}
                            >
                                {bills.sort((a, b) => new Date(a.billingDate).getTime() - new Date(b.billingDate).getTime()).map(bill => <MenuItem key={bill.id} value={bill.id}>{new Date(bill.billingDate).toLocaleString('en-GB', {year: 'numeric', month: 'short'})}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                    <Tooltip title="Dec 2022 would be the bill issued on 1st December 2022 for usage between 1st November 2022 and 30th November 2022">
                        <HelpOutline sx={{maxWidth: '30px'}}/>
                    </Tooltip>
                    </Grid>
                    {
                        selectedBill?.subscriptionBills != null
                            ?
                            (
                                <List>
                                    {
                                        selectedBill?.subscriptionBills?.map(subBill => <SubscriptionBillPaymentLines subscriptionBill={subBill} />)
                                    }
                                </List>

                            )
                            :
                            (
                                <>
                                </>
                            )
                    }

                    <Typography>Total Amount: {ConvertCurrencyToSymbol(selectedBill?.currencyIsoCode ?? CurrencyIsoCodes.Gbp)}{selectedBill?.totalPaymentLine?.net?.toLocaleString("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                    <FormControl className={classes.card} component="fieldset" sx={{ marginTop: '10px' }}>
                        <FormGroup aria-label="position">
                            {
                                <DesktopDatePicker
                                    label="Choose your Payment Date"
                                    inputFormat='yyyy-MM-dd'
                                    value={paymentDate}
                                    onChange={handlePaymentDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            }
                            {
                                paymentCreatedSuccessfully == null
                                    ?
                                    (<Button variant="contained" color="primary" onClick={handleClick} sx={{ marginTop: '10px' }}>
                                        Create Payment
                                    </Button>)
                                    :
                                    paymentCreatedSuccessfully
                                        ?
                                        (
                                            <Button variant="contained" className={classes.success} sx={{ marginTop: '10px' }}>
                                                Successful Payment Creation
                                            </Button>
                                        )
                                        :
                                        (
                                            <Button variant="contained" className={classes.warning} onClick={handleClick} sx={{ marginTop: '10px' }}>
                                                Failed, Please Try Again Here
                                            </Button>
                                        )
                            }

                        </FormGroup>
                    </FormControl>
                </CardContent>
            </Card>
        </LocalizationProvider>
    )
}


export { CreateAPayment }