import { Box, Button, Card, CardActions, CardContent, CardHeader, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DraftSubscriptionViewModel } from "../models/DraftSubscriptionViewModel";
import { getDraftSubscriptions } from "../services/DraftSubscriptionService";
import DraftSubscriptionTable from "../components/DraftSubscriptionTable";

const DraftSubscriptionsListingPage: React.FC = ({ match }: any) => {
    const [draftSubscriptions, setDraftSubscriptions] = useState<DraftSubscriptionViewModel[]>(
        []
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {

        let mounted = true;
        setIsLoading(true);
        getDraftSubscriptions().then((item) => {
            if (mounted) {
                setIsLoading(false);
                setDraftSubscriptions(item);
            }
        });

        return () => {
            mounted = false;
        };
    }, []);

    return (
        <Card>
            <CardHeader title={"Draft Contracts"} />
            <CardContent>
                <Box height={500}>
                    {
                        isLoading ? <LinearProgress color="primary" /> : <DraftSubscriptionTable
                            subscriptions={draftSubscriptions} setSubscriptions={setDraftSubscriptions} />
                    }
                </Box>
            </CardContent>

            <CardActions>
                <Button style={{ marginLeft: 'auto' }} variant="contained" component={Link} color="primary"
                    to={"draftSubscriptions/create"}>Create Draft</Button>
            </CardActions>
        </Card>
    );
};
export { DraftSubscriptionsListingPage };
