import 'date-fns';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, CardHeader, FormControl, FormGroup, MenuItem, TextField, Theme } from '@mui/material'
import { CreateAPaymentMessage} from '../models/CreateAPaymentMessage';
import {CreateAManualPaymentByRest} from "../services/PaymentService"
import CurrencyTextField from './CurrencyTextField'
import {TypeOfPayment} from "../models/TypeOfPayment";
import {ConvertCurrencyToSymbol} from "../helpers/ConvertCurrencyToSymbol";
import {CurrencyIsoCodes} from "../models/CurrencyIsoCodes";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { makeStyles,createStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getBillPeriodsWithIssuedBills } from '../services/AssetOperatorService';
import { BillPeriodAndId } from '../models/BillPeriodAndId';

type RecordAManualOffSystemPaymentProps = {
    assetOperatorId: string | undefined
    subIds : string[]
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1)
            }
        },
        card: {
            width: '100%'
        },
        success: {
            color: theme.palette.success.contrastText,
            backgroundColor: theme.palette.success.main
        },
        warning: {
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main
        }
    }),
);


function RecordAManualOffSystemPayment({ assetOperatorId, subIds }: RecordAManualOffSystemPaymentProps) {
    const classes = useStyles();
    let now = new Date()
    const subscriptionIds = subIds?.length !== 0 ? subIds : [] 
    const [paymentDate, setPaymentDate] = useState<Date>(now)
    const [currencyIsoCode, setCurrencyIsoCode] = useState<string>(CurrencyIsoCodes.Gbp)
    const [paymentAmount, setPaymentAmount] = useState<number>(0);
    const [paymentCreatedSuccessfully, setPaymentCreatedSuccessfully] = useState<boolean | null>(null);
    const [subscriptionId, setSubscriptionId] = useState<string>(subscriptionIds?.length > 0 ? subscriptionIds[0] : "");
    const [billPeriodAndIds, setBillPeriodAndIds] = useState<BillPeriodAndId[]>([]);
    const [billPeriodAndId, setBillPeriodAndId] = useState<BillPeriodAndId>();

    useEffect(() => {
        let mounted = true;
        getBillPeriodsWithIssuedBills(assetOperatorId)
            .then(billPeriodAndIds => {
                if (mounted) {
                    setBillPeriodAndIds(billPeriodAndIds)
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePaymentDateChange = (value: unknown) => {
        let date = value as Date | null
        if (date != null) {
            setPaymentDate(date);
        }
    };

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrencyIsoCode(event.target.value);
    };

    const handleClick = async () => {
        if(billPeriodAndId === undefined) return;
        let message: CreateAPaymentMessage = {
            datePaymentShouldBeTaken: paymentDate,
            typeOfPayment: TypeOfPayment.Manual,
            amount: paymentAmount,
            currencyIsoCode: currencyIsoCode,
            subscriptionId: subscriptionId,
            billId: billPeriodAndId.billId
        }
        let result = await CreateAManualPaymentByRest(assetOperatorId ?? "", message);
        setPaymentCreatedSuccessfully(result.ok)
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Card className={classes.card} elevation={10}>
                <CardHeader
                    title="Record a Manual Off-System Payment"
                    subheader="Please note this will not request payment from the customer. This will simply make ZERO aware the payment has happened, and include it in arrears reporting etc."
                    titleTypographyProps={{style: {fontSize:16}}}
                    />
                <CardContent>
                    <FormControl className={classes.card} component="fieldset">
                        <FormGroup className={classes.root} aria-label="position">
                            <TextField
                                value={currencyIsoCode}
                                onChange={handleCurrencyChange}
                                select
                                id="amount"
                                label="Currency"
                                variant="outlined"
                            >
                                <MenuItem key={CurrencyIsoCodes.Gbp} value={CurrencyIsoCodes.Gbp}>
                                    {ConvertCurrencyToSymbol(CurrencyIsoCodes.Gbp)} {CurrencyIsoCodes.Gbp}
                                </MenuItem>
                                <MenuItem key={CurrencyIsoCodes.Usd} value={CurrencyIsoCodes.Usd}>
                                    {ConvertCurrencyToSymbol(CurrencyIsoCodes.Usd)} {CurrencyIsoCodes.Usd}
                                </MenuItem>                                
                            </TextField>
                            <TextField
                                value={subscriptionId}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSubscriptionId(event.target.value)}
                                select
                                id="subId"
                                label="Subscription ID"
                                variant="outlined"
                            >
                                {
                                    subscriptionIds.map((subscriptionId) => (
                                        <MenuItem key={subscriptionId} value={subscriptionId}>
                                            {subscriptionId}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <TextField
                                value={billPeriodAndId}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setBillPeriodAndId(billPeriodAndIds.find(x => x.billId === event.target.value))}
                                select
                                id="billPeriod"
                                label="Bill Period"
                                variant="outlined"
                            >
                                {
                                    billPeriodAndIds.map((billPeriodAndId) => (
                                        <MenuItem key={billPeriodAndId.billPeriod} value={billPeriodAndId.billId}>
                                            {billPeriodAndId.billPeriod}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <CurrencyTextField
                                label="Amount"
                                variant="outlined"
                                value={paymentAmount}
                                currencySymbol={ConvertCurrencyToSymbol(currencyIsoCode)}
                                outputFormat="number"
                                onChange={(event: any, value: number) => setPaymentAmount(value)}
                            />
                            <DesktopDatePicker
                                label="Choose your Payment Date"
                                inputFormat='yyyy-MM-dd'
                                value={paymentDate}
                                onChange={handlePaymentDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {
                                paymentCreatedSuccessfully == null
                                    ?
                                    (<Button variant="contained" color="primary" onClick={handleClick}>
                                        Create Payment
                                    </Button>)
                                    :
                                    paymentCreatedSuccessfully
                                        ?
                                        (
                                            <Button variant="contained" className={classes.success}>
                                                Successful Payment Creation
                                            </Button>
                                        )
                                        :
                                        (
                                            <Button variant="contained" className={classes.warning} onClick={handleClick}>
                                                Failed, Please Try Again Here
                                            </Button>

                                        )
                            }

                        </FormGroup>
                    </FormControl>
                </CardContent>
            </Card>
        </LocalizationProvider>
    )
}


export { RecordAManualOffSystemPayment }
