import { Link } from "@mui/material";
import { Bill } from "../models/Bill";
import React, { useState } from "react";
import { ListPopover } from "./ListPopover";
import { SubscriptionBillValidationError } from "../models/SubscriptionBillValidationError";
import GetAppIcon from '@mui/icons-material/GetApp';
import { DataGrid } from "@mui/x-data-grid";

interface TrialBillingRunTableProps {
    bills: Bill[]
}

const TrialBillingRunTable: React.FC<TrialBillingRunTableProps> = props => {
    const [sortModel, setSortModel] = useState<any[]>([
        {
            field: 'status',
            sort: 'desc',
        },
    ]);

    const columns: any[] = [
        { field: 'customerName', headerName: 'Customer Name', width: 200 },
        { field: 'invoiceNumber', headerName: 'Invoice Number', width: 150 },
        {
            field: 'dateOfBill',
            headerName: 'Billing Date',
            width: 100,
            filterable: false,
            renderCell: (params: any): string => {
                return params.row.dateOfBill == null ? '' : new Date(params.row.dateOfBill).toLocaleDateString()
            }
        },
        {
            field: 'billingPeriodStartDate',
            headerName: 'Period Start Date',
            width: 120,
            filterable: false,
            renderCell: (params: any): string => {
                return params.row.billingPeriodStartDate == null ? '' : new Date(params.row.billingPeriodStartDate).toLocaleDateString()
            }
        },
        {
            field: 'billingPeriodEndDate',
            headerName: 'Period End Date',
            width: 120,
            filterable: false,
            renderCell: (params: any): string => {
                return params.row.billingPeriodEndDate == null ? '' : new Date(params.row.billingPeriodEndDate).toLocaleDateString()
            }
        },
        { field: 'currency', headerName: 'Currency', width: 100 },
        {
            field: 'amount', headerName: 'Amount', width: 100, type: 'number'
        },
        {
            field: 'status', headerName: 'Status', width: 200
        },
        {
            field: "downloadButton",
            headerName: "Download",
            width: 100,
            renderCell: (params: any): JSX.Element => {


                return (
                    <Link style={{ paddingTop: "25px" }}
                        href={`/api/assetoperators/${params.row.assetOperatorId}/bills/${params.row.billId}`}>
                        <GetAppIcon fontSize="large" color="primary" style={{paddingBottom: '10px'}}/>
                    </Link>
                )

            }
        },
        {
            field: 'validationErrors',
            headerName: 'Validation Errors',
            width: 250,
            renderCell: (params: any): JSX.Element => {
                const validationErrors: SubscriptionBillValidationError[] = params.row.validationErrors;
                return (
                    validationErrors === null || validationErrors.length === 0 ?
                        <>
                        </> :
                        <div style={{ paddingLeft: "20px" }}>

                            <ListPopover listItems={validationErrors.map(validationError => validationError.errorType)}
                                buttonText={"Validation Errors : " + validationErrors.length}></ListPopover>

                        </div>

                )
            }
        }
    ]

    return (
        <div style={{ height: '65vh', width: '100%' }}>
            <DataGrid
                rows={props.bills}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                initialState={{
                    pagination: {
                        pageSize: 50
                    }
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
            />
        </div>
    )
}
export { TrialBillingRunTable }
