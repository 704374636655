import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    Paper,
    Theme,
    Typography,
    useTheme
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { AssetOperator } from '../models/AssetOperator'
import { getAssetOperators } from '../services/AssetOperatorService'
import { Link } from 'react-router-dom';
import { ConvertCompanyTypeToString } from "../helpers/ConvertCompanyTypeToString";
import { GetAssetOperatorStatusTextColor } from "../helpers/GetAssetOperatorStatusTextColor";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { makeStyles, createStyles } from '@mui/styles';



const mapAssetOperatorToRow = (assetOperator: AssetOperator, i: number) => {
    return {
        id: i,
        name: assetOperator.tradingName,
        companyType: ConvertCompanyTypeToString(assetOperator.companyType),
        status: assetOperator.status,
        view: assetOperator.id
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        },
        box: {
            display: "flex",
        },
        spreadBox: {
            justifyContent: "space-around",
            alignItems: "center"
        }
    }))

const HomePage: React.FC = props => {
    const [assetOperators, setAssetOperators] = useState<AssetOperator[] | null>(null)
    const [sortModel, setSortModel] = useState<any[]>([
        {
            field: 'status',
            sort: 'asc',
        },
    ]);
    const theme = useTheme()

    const classes = useStyles()
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'companyType', headerName: 'Company Type', flex: 1, getApplyQuickFilterFn: undefined },
        {
            field: 'status', headerName: 'Status', flex: 1, getApplyQuickFilterFn: undefined, renderCell: (params: any): JSX.Element => {
                return (<Typography style={{ fontSize: 14, color: GetAssetOperatorStatusTextColor(params.row.status, theme) }}>{params.row.status}</Typography>)
            }
        },
        {
            field: 'view', headerName: 'View', width: 100, getApplyQuickFilterFn: undefined, renderCell: (params: any): JSX.Element => {
                let toEdit = `/assetoperators/${params.value}/edit`
                return (<>
                    <Button
                        component={Link}
                        to={toEdit}
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginRight: 16 }}
                    >
                        View
                    </Button>
                </>
                )
            },
        }
    ];
    useEffect(() => {
        let mounted = true;
        getAssetOperators()
            .then(items => {
                if (mounted) {
                    setAssetOperators(items)
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Card>
            <CardHeader title="Asset Operators" />
            <CardContent>
                <Box height={500}>
                    {
                        assetOperators
                            ?
                            (
                                <DataGrid
                                    rows={assetOperators.map(mapAssetOperatorToRow)}
                                    columns={columns}
                                    sortModel={sortModel}
                                    onSortModelChange={(model) => setSortModel(model)}
                                    components={{ Toolbar: GridToolbar, Panel: Paper }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                            )
                            :
                            (
                                <div className={classes.loader}>
                                    <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                    <LinearProgress color="secondary" />
                                </div>
                            )
                    }
                </Box>
            </CardContent>
            <CardActions>
                <Button component={Link}
                    to={"/assetoperators/create"}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: "auto" }}>
                    Create
                </Button>
            </CardActions>
        </Card>
    )
}

export { HomePage }
