/* eslint-disable no-shadow */
import * as React from "react";
import { Link } from "react-router-dom";
import {
  List,
  AppBar,
  Typography,
  Theme,
  Toolbar,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import AppMenuItem from "./AppMenuItem";
import DesktopAppMenuItem from "./DesktopAppMenuItem";
import { SignInSignOutButton } from "../auth/SignInSignOutButton";
import {MenuLink} from "../models/MenuLink";
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles, createStyles } from '@mui/styles';
import logo from './../styles/ZETI_Logo_White.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      color: theme.palette.text.primary,
    },
    appBar: {
      position: 'sticky',
      backgroundColor: theme.palette.background.paper,
    },
    menuLink: {
      cursor: "pointer",
      marginRight: theme.spacing(2),
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    homeLink: {
      flexGrow: 1,
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    drawerLink: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    user: {
      textAlign: "right",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    list: {
      width: "250px",
      backgroundColor: theme.palette.primary.main,
      padding: "10px",
      height: "100%",
    },
    active: {
      color: theme.palette.secondary.main,
    },
    drawer: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);

interface HeaderProps {
  title: string;
  menuLinks: MenuLink[];
}

const Header: React.FC<HeaderProps> = ({ title, menuLinks }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  const theme = useTheme();
  const biggerThanMobile = useMediaQuery(theme.breakpoints.up("md"));

  const mobileMenuDrawer = (links: MenuLink[]) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List style={{ height: "100%" }}>
        {links.map((menuLink) => (
          <AppMenuItem
            title={menuLink.title}
            url={menuLink.url}
            nestedLinks={menuLink.nestedLinks}
            key={menuLink.title}
          />
        ))}
        <Box width="250px" style={{ bottom: 30, position: "absolute" }}>
          <SignInSignOutButton />
        </Box>
      </List>
    </div>
  );

  const mobileAppBar = ({ title, menuLinks }: HeaderProps) => (
    <AppBar className={classes.appBar} position="sticky">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          aria-haspopup="true"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          size="large">
          <MenuIcon />
        </IconButton>
        <Link className={classes.homeLink} to="/">
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        </Link>
      </Toolbar>
      <Drawer
        classes={{ paper: classes.drawer }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {mobileMenuDrawer(menuLinks)}
      </Drawer>
    </AppBar>
  );

  const desktopAppBar = ({ title, menuLinks }: HeaderProps) => (
    <AppBar className={classes.appBar} position="sticky">
      <Toolbar>
        <Link className={classes.homeLink} to="/">
          <img style={{height: '3vh'}} src={logo} alt="logo saying Zeti"/>
        </Link>
        {menuLinks.map((menuLink, index) => {
          return (
            <DesktopAppMenuItem
              key={index}
              title={menuLink.title}
              url={menuLink.url}
              nestedLinks={menuLink.nestedLinks}
            />
          );
        })}
        <Box width="100px">
          <SignInSignOutButton />
        </Box>
      </Toolbar>
    </AppBar>
  );

  return biggerThanMobile
    ? desktopAppBar({ title, menuLinks })
    : mobileAppBar({ title, menuLinks });
};

export default Header;
