import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { AssetOperator } from "../models/AssetOperator";
import {
    createOrUpdateAssetOperator,
    createStripeAccount,
    getAssetOperator, 
    getStripeAchSecret,
    getStripeLink,
    sendAssetOperatorWelcomeEmail,
} from "../services/AssetOperatorService";
import { UploadMeterReading } from "../components/UploadMeterReading";
import { IndividualArrearsReport } from "../components/IndividualArrearsReport";
import SubscriptionTable from "../components/SubscriptionTable";
import { Skeleton } from "@mui/lab";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { loadStripe } from '@stripe/stripe-js';
import CheckIcon from "@mui/icons-material/Check";
import { getStripePublishableKey } from "../services/PaymentService";
import { AssetOperatorBillTable } from "../components/AssetOperatorBillTable";
import { RecordAManualOffSystemPayment } from "../components/RecordAManualOffSystemPayment";
import { NotesTable } from "../components/NotesTable";
import { getInvestors } from "../services/InvestorService";
import { getSubscriptions } from "../services/SubscriptionService";
import { InvestorViewModel } from "../models/InvestorViewModel";
import { Note } from "../models/Note";
import { AssetOperatorStatus } from "../models/AssetOperatorStatus";
import { CompanyType } from "../models/CompanyType";
import { InvoicingAndPaymentCollectionMethod } from "../models/InvoicingAndPaymentCollectionMethod";
import { genericLabelProps } from "../props/GenericLabelProps";
import { Countries } from "../data/Countries";
import { makeStyles, createStyles } from '@mui/styles';
import { Address } from "../models/Address";
import { useHistory } from "react-router-dom";
import { SubscriptionViewModel } from "../models/SubscriptionViewModel";
import { CreateAPayment } from "../components/CreateAPayment";
import { TypeOfPayment } from "../models/TypeOfPayment";
import { NoteAttachment } from "../models/NoteAttachment";
import { uploadNoteAttachmentFile } from "../services/NoteAttachmentService";


function getExplanationMessageForWelcomeEmail(assetOperator: AssetOperator | null): string {
    if (assetOperator === null) return ""
    if (assetOperator?.welcomeEmailSent) {
        return "Welcome email already sent"
    }
    if (assetOperatorIsReadyToReceiveWelcomeEmail(assetOperator)) {
        return ""
    } else {
        let message = `This Asset Operator is not ready to receive a Welcome Email. We need all the following fields to have a value: Trading Name: ${assetOperator?.tradingName ?? "<Empty>"}, Preferred Direct Debit Payment Day of Month: ${assetOperator?.preferredDirectDebitPaymentDayOfMonth ?? "<Empty>"}, `
        let subscriptions = assetOperator.subscriptions;
        if (subscriptions.length > 0) {
            const firstSubscription = subscriptions[0]
            if ((firstSubscription.minimumUsagePeriods?.length ?? 0) > 0) {
                if ((firstSubscription.assets?.length ?? 0) > 0) {
                    message += ` Cost Per Usage Unit is ${firstSubscription?.costPerUsageUnit ?? "<Empty>"}, Currency Code is ${firstSubscription.currencyIsoCode ?? "<Empty>"}, Minimum Usage is ${firstSubscription?.minimumUsagePeriods[0]?.minimumUsage ?? "<Empty>"}, Number of Assets is ${firstSubscription?.assets?.length ?? "<Empty>"}.`
                } else {
                    message += " Assets are empty!"
                }
            } else {
                message += " Minimum Usage Periods are empty!"
            }
        } else {
            message += " Subscriptions are empty!"
        }
        return message
    }
}

function assetOperatorIsReadyToReceiveWelcomeEmail(assetOperator: AssetOperator | null): boolean {
    if (assetOperator === null) return false;
    if (assetOperator?.welcomeEmailSent) return false;
    const directDebitDay = assetOperator.preferredDirectDebitPaymentDayOfMonth;
    const assetOperatorName = assetOperator.tradingName;
    let subscriptions = assetOperator.subscriptions;
    if (subscriptions.length > 0) {
        const firstSubscription = subscriptions[0]
        const costPerUsageUnit = firstSubscription.costPerUsageUnit;
        const currency = firstSubscription.currencyIsoCode;
        if ((firstSubscription.minimumUsagePeriods?.length ?? 0) > 0) {
            const minimumUsage = firstSubscription.minimumUsagePeriods[0].minimumUsage;
            if ((firstSubscription.assets?.length ?? 0) > 0) {
                const numberOfAssets = firstSubscription.assets.length;
                if (costPerUsageUnit && currency && directDebitDay && minimumUsage && numberOfAssets && assetOperatorName) {
                    return true;
                }
            }
        }
    }
    return false;
}

let stripePromise: any = null
getStripePublishableKey().then((publishableKey: string) => stripePromise = loadStripe(publishableKey))

function isReadyForStripeAccount(assetOperator: AssetOperator | null): boolean {
    if (assetOperator && assetOperator.registeredAddress && assetOperator.registeredAddress.lineOne && assetOperator.registeredAddress.city && assetOperator.registeredAddress.postCode && assetOperator.tradingName) return true
    return false
}

function isAssetOperatorUsaBased(assetOperator: AssetOperator | null): boolean {
    return (assetOperator && assetOperator.registeredAddress && assetOperator.registeredAddress.countryCode === "US") ?? false
}

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '10px'
        },
        tooltip: {
            fontSize: "1rem",
        },
        dropDown: {
            textAlign: 'left',
            width: '100%',
            marginBottom: '10px'
        }
    }))

const AssetOperatorEditPage: React.FC = ({ match }: any) => {
    let history = useHistory();
    const [assetOperator, setAssetOperator] = useState<AssetOperator | null>(
        null
    );

    const [stripeDirectDebitSetupUrl, setStripeDirectDebitSetupUrl] = useState<string | null>(null);
    const [stripeAchDirectDebitSecret, setStripeAchDirectDebitSecret] = useState<string | null>(null)
    const [stripeAccountId, setStripeAccountId] = useState<string | undefined>(
        undefined
    );
    const [copied, setCopied] = useState<boolean>(false);
    const [displayUsaAchConfirmation, setDisplayUsaAchConfirmation] = useState<boolean>(false)
    const [achSetupSucceeded, setAchSetupSucceeded] = useState<boolean>(false)
    const [investorViewModels, setInvestorViewModels] = useState<InvestorViewModel[]>([]);
    const [preferredDebitDayError, setPreferredDebitDayError] = useState<boolean>(false)
    const [allSubscriptions, setAllSubscriptions] = useState<SubscriptionViewModel[]>([]);

    const classes = useStyles()

    const onClickCreate = () => {
        if (assetOperator === null) return;
        createOrUpdateAssetOperator(assetOperator).then((assetOperatorId) => history.push("/assetOperators/" + assetOperatorId))
    }, countriesDropDown: any[] = [];

    Countries.forEach(country => {
        countriesDropDown.push(<MenuItem key={country.IsoCode} value={country.IsoCode}>{country.Name}</MenuItem>)
    })

    const onClickGetLink = (assetOperatorId: string) => {
        if (isAssetOperatorUsaBased(assetOperator)) {
            getStripeAchSecret(assetOperatorId).then((stripeAchSecret) => {
                setStripeAchDirectDebitSecret(stripeAchSecret)
            })
        } else {
            getStripeLink(assetOperatorId).then((stripeUrl) => {
                setStripeDirectDebitSetupUrl(stripeUrl);
            });
        }
    };

    const onClickCreateStripeAccount = (assetOperatorId: string) => {
        createStripeAccount(assetOperatorId).then((customerId) => {
            setStripeAccountId(customerId);
        });
    };

    const onClickCopy = (url: string) => {
        navigator.clipboard.writeText(url);
        setCopied(true);
    };

    const onClickStripeAchSetup: (clientSecret: string) => void = (clientSecret: string) => {
        stripePromise.then((stripe: any) => {
            stripe?.collectBankAccountForSetup({
                clientSecret: clientSecret,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {
                            name: assetOperator?.tradingName ?? "",
                            email: assetOperator?.billingEmailAddress ?? "",
                        },
                    },
                },
                expand: ['payment_method'],
            })
                .then(({ setupIntent, error }: any) => {
                    if (error) {
                        // PaymentMethod collection failed for some reason.
                    } else if (setupIntent?.status === 'requires_payment_method') {
                        // Customer canceled the hosted verification modal. Present them with other
                        // payment method type options.
                    } else if (setupIntent?.status === 'requires_confirmation') {
                        // We collected an account - possibly instantly verified, but possibly
                        // manually-entered. Display payment method details and mandate text
                        // to the customer and confirm the intent once they accept
                        // the mandate.
                        setDisplayUsaAchConfirmation(true)
                    }
                });
        })

    }

    function handleWelcomeEmailClick() {
        sendAssetOperatorWelcomeEmail(assetOperator?.id ?? null)
            .then((success) => {
                if (success) {
                    getAssetOperator(match.params.id, true)
                        .then((assetOperator) => setAssetOperator(assetOperator))
                }
            })
    }


    function confirmAch(clientSecret: string) {
        stripePromise.then((stripe: any) => {
            stripe?.confirmUsBankAccountSetup(clientSecret)
                .then(({ setupIntent, error }: any) => {
                    if (error) {
                        console.error(error.message);
                        // The payment failed for some reason.
                    } else if (setupIntent?.status === "requires_payment_method") {
                    } else if (setupIntent?.status === "succeeded") {
                        // Display a message to customer.
                        setAchSetupSucceeded(true)
                    } else if (setupIntent?.next_action?.type === "verify_with_microdeposits") {
                        // Display a message to consumer with next steps (consumer waits for
                        // microdeposits, then enters a statement descriptor code on a page sent to them via email).
                    }
                    setDisplayUsaAchConfirmation(false)
                });
        });
    }

    const handlePreferredDirectDebitPaymentDayOfMonth = (e: ChangeEvent<HTMLInputElement>) => {
        if (assetOperator === null) return;

        let value = !!e.target.value ? Number(e.target.value) : undefined
        if (value !== undefined && (value < 3 || value > 28)) {
            setPreferredDebitDayError(true)
        } else {
            setPreferredDebitDayError(false)
        }

        setAssetOperator(
            { ...assetOperator, preferredDirectDebitPaymentDayOfMonth: value }
        )
    }

    const onSubscriptionSelect = (event: SelectChangeEvent<string[]>) => {
        if (assetOperator === null) return;

        const { target: { value }, } = event;

        setAssetOperator({ ...assetOperator, subscriptionIds: value as string[] });
    }

    useEffect(() => {
        let mounted = true;
        getAssetOperator(match.params.id, true).then((item) => {
            if (mounted) {
                setAssetOperator(item);
            }
        });

        getInvestors().then((investorViewModels: InvestorViewModel[]) => {
            if (mounted) {
                setInvestorViewModels(investorViewModels)
            }
        })
        
        getSubscriptions()
            .then(subs => {
                if (mounted) {
                    setAllSubscriptions(subs);
                }
            })

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id]);

    let accountLink =
        "https://dashboard.stripe.com/customers/" +
        (stripeAccountId == null
            ? assetOperator?.stripeCustomerId
            : stripeAccountId);

    async function createAssetOperatorNotes(assetOperatorId: string, noteText: string, noteTitle: string, noteAttachments: NoteAttachment[] | null) {

        var newNote: any = {
            text: noteText,
            createdDate: new Date(),
            title: noteTitle,
            pinned: false
        }
        if (noteAttachments) {
            for (var attachment of noteAttachments) {
                var response = await uploadNoteAttachmentFile(attachment)
                if (response.includes('Error')) {
                    throw new Error(response);
                } else {
                    if (!newNote.attachments) {
                        newNote.attachments = [];
                    }
                    attachment.filePath = response;
                    var existingAttachments = newNote.attachments;
                    existingAttachments.push(attachment);
                    newNote.attachments = existingAttachments;
                }
            }
        }

        getAssetOperator(assetOperatorId, false).then((assetOperator: AssetOperator) => {
            if (assetOperator.notes ?
                assetOperator.notes = [...assetOperator.notes, newNote] :
                assetOperator.notes = [newNote])

                createOrUpdateAssetOperator(assetOperator).then(() => {
                    getAssetOperator(assetOperatorId, true).then((newAssetOperator) => {
                        setAssetOperator(newAssetOperator)
                    })
                })
        }
        )
    }

    async function editAssetOperatorNotes(assetOperatorId: string, editingNote: Note) {
        if (editingNote.attachments) {
            var newAttachments = editingNote.attachments.filter(x => !x.filePath);
            for (let attachment of newAttachments) {
                var response = await uploadNoteAttachmentFile(attachment)
                if (response.includes('Error')) {
                    throw new Error(response);
                } else {
                    var existingAttachments: NoteAttachment[] = editingNote.attachments;
                    var relevantAttachment = existingAttachments.filter(x => x.name === attachment.name)[0];
                    var indexOfRelevantAttachment = existingAttachments.indexOf(relevantAttachment);
                    existingAttachments.splice(indexOfRelevantAttachment);
                    relevantAttachment.filePath = response;
                    existingAttachments.push(relevantAttachment);
                    editingNote.attachments = existingAttachments;
                }
            }
        }

        getAssetOperator(assetOperatorId, false).then((assetOperator) => {
            assetOperator.notes ?
                assetOperator.notes = [...assetOperator.notes.filter((note: Note) => note.id !== editingNote.id), {
                    ...editingNote
                }]
                :
                assetOperator.notes = [{ ...editingNote }]

            createOrUpdateAssetOperator(assetOperator).then(() => {
                getAssetOperator(assetOperatorId, true).then((newAssetOperator) => {
                    setAssetOperator(newAssetOperator)
                })
            })
        }
        )
    }

    return assetOperator === null ? <></> : <>
        <Grid
            style={{ marginBottom: '20px', marginTop: '20px' }}
            justifyContent="space-between"
            container
        >
            <Grid item xs={12}>

                <Card>
                    <CardHeader title={"Asset Operator: " + assetOperator?.tradingName ?? "..."}
                        subheader="Edit an Asset Operator"
                        action={
                            <Button variant="contained" color="primary" onClick={() => onClickCreate()}>Submit</Button>
                        }
                    />
                </Card>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="stretch" spacing={5}>
            {assetOperator != null && assetOperator?.subscriptions !== null ? assetOperator?.subscriptions?.map((sub: SubscriptionViewModel, index: number) => {
                return (
                    <Grid key={index} item sm={6} style={{ marginBottom: '20px' }}>
                        <IndividualArrearsReport subscriptionId={sub.id} />
                    </Grid>
                );
            }) : null}
            <Grid item sm={6}>
                <NotesTable notes={assetOperator?.notes ? assetOperator?.notes : []}
                    assetOperatorId={assetOperator?.id ? assetOperator?.id : ""}
                    handleAddButtonClick={createAssetOperatorNotes}
                    editAssetOperatorNotes={editAssetOperatorNotes}
                    assetOperator={assetOperator}
                    setAssetOperator={setAssetOperator}
                />
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="stretch" spacing={5}>
            <Grid item sm={6}>
                <Card elevation={10} style={{ marginBottom: "20px" }}>
                    <CardHeader title="Stripe" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        {assetOperator == null ? (
                            <Skeleton variant="rectangular" height={50} />
                        ) : assetOperator.stripeCustomerId || stripeAccountId ? (
                            <Grid container direction="row" spacing={5}>
                                <Grid item>
                                    <Button color="primary" variant="contained" href={accountLink}>
                                        View Profile On Stripe
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => onClickGetLink(assetOperator.id)}
                                        disabled={!!stripeDirectDebitSetupUrl}
                                    >
                                        Setup Payment Details
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {
                                        stripeAchDirectDebitSecret ?
                                            !achSetupSucceeded
                                                ?
                                                (
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => onClickStripeAchSetup(stripeAchDirectDebitSecret)}>Authorize
                                                        ACH</Button>
                                                )
                                                :
                                                (
                                                    <CheckIcon style={{ height: '100%' }} color="primary" />
                                                )
                                            :
                                            (
                                                <></>
                                            )
                                    }
                                    <Dialog
                                        open={displayUsaAchConfirmation}
                                        onClose={() => setDisplayUsaAchConfirmation(false)}
                                        aria-labelledby="ach-confirmation-title"
                                        aria-describedby="ach-confirmation-description"
                                    >
                                        <DialogTitle id="ach-confirmation-title">
                                            {"Confirm Zeti's ACH permission"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="ach-confirmation-description">
                                                By clicking accept, you authorize Zeti, Inc. to debit the bank
                                                account specified above for any amount owed for charges arising from
                                                your use of Zeti, Inc.’s services and/or purchase of products from
                                                Zeti, Inc., pursuant to Zeti, Inc.’s website and terms, until this
                                                authorization is revoked. You may amend or cancel this authorization
                                                at any time by providing notice to Zeti, Inc. with 30 (thirty) days
                                                notice. If you use Zeti, Inc.’ services or purchase additional
                                                products periodically pursuant to Zeti, Inc.’ terms, you authorize
                                                Zeti, Inc. to debit your bank account periodically. Payments that
                                                fall outside of the regular debits authorized above will only be
                                                debited after your authorization is obtained.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => setDisplayUsaAchConfirmation(false)}>Cancel</Button>
                                            <Button onClick={() => confirmAch(stripeAchDirectDebitSecret ?? "")}
                                                autoFocus>
                                                Accept
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    {stripeDirectDebitSetupUrl ? (
                                        <>
                                            <div style={{ maxWidth: "30vw" }}>
                                                <Typography style={{ lineHeight: "35px" }} noWrap>
                                                    {stripeDirectDebitSetupUrl}
                                                </Typography>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                                <Grid item>
                                    {stripeDirectDebitSetupUrl ? (
                                        <IconButton
                                            style={{ marginTop: "-10px" }}
                                            aria-label="copy"
                                            onClick={() => onClickCopy(stripeDirectDebitSetupUrl ?? "")}
                                            size="large">
                                            {copied ? <DoneAllIcon /> : <FileCopyIcon />}
                                        </IconButton>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                        ) : isReadyForStripeAccount(assetOperator)
                            ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onClickCreateStripeAccount(assetOperator.id)}
                                    >
                                        Create Account On Stripe
                                    </Button>
                                </>
                            )
                            :
                            (
                                <>
                                    <Tooltip
                                        title="You need to set up the asset operator's basic details (trading name, address line one, city, county and postcode) before you can set up their stripe account. Please go to the asset operator edit screen to complete this.">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Create Account On Stripe
                                        </Button>
                                    </Tooltip>

                                </>
                            )}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={6}>
                <Card elevation={10} style={{ marginBottom: "20px" }}>
                    <CardHeader title="Welcome Email" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        <Tooltip title={getExplanationMessageForWelcomeEmail(assetOperator)}>
                            <span>
                                <Button color="primary" variant="contained" onClick={() => handleWelcomeEmailClick}
                                    disabled={!assetOperatorIsReadyToReceiveWelcomeEmail(assetOperator)}>Send Welcome Email</Button>
                            </span>
                        </Tooltip>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid alignItems="stretch" style={{ marginBottom: "20px" }}>
            <Grid item sm={6}>
                <AssetOperatorBillTable assetOperator={assetOperator} />
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="stretch" spacing={5} >
            <Grid item sm={6} container direction='column'>
                <Grid item sm={6} style={{ marginBottom: '20px' }}>
                    <RecordAManualOffSystemPayment assetOperatorId={assetOperator?.id}
                        subIds={assetOperator?.subscriptionIds ?? []} />

                    {assetOperator?.subscriptions && investorViewModels.length > 0  ?
                        <div style={{marginTop: '20px'}}>
                            <Card elevation={10}>
                        <SubscriptionTable
                            subscriptions={assetOperator?.subscriptions}
                            /></Card></div>
                            
                        :
                        null
                    }
                </Grid>
            </Grid>

            <Grid item sm={6} container direction='column' >
                {assetOperator == null ? (
                    <Skeleton variant="rectangular" height={500} />
                ) : (
                    <UploadMeterReading assetOperator={assetOperator} />
                )}
            </Grid>
        </Grid>

        <Grid container direction="row" alignItems="stretch" spacing={5} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6}>
                <CreateAPayment typeOfPayment={TypeOfPayment.GoCardless} assetOperator={assetOperator} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CreateAPayment typeOfPayment={TypeOfPayment.Stripe} assetOperator={assetOperator} />
            </Grid>
        </Grid>

        <Grid container spacing={5}>
            <Grid md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Basic Account Info" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        <Grid container spacing={5} style={{ marginBottom: '10px' }}>
                            <Grid md={6} item>
                                <TextField
                                    label="Asset Operator ID"
                                    variant="standard"
                                    disabled={true}
                                    InputLabelProps={genericLabelProps}
                                    value={assetOperator?.id ?? ''}
                                />
                            </Grid>

                            <Grid md={6} item>
                                <TextField
                                    label="Short ID"
                                    variant="standard"
                                    disabled={true}
                                    InputLabelProps={genericLabelProps}
                                    style={{ marginLeft: "50px" }}
                                    value={assetOperator?.shortId ?? ''}
                                />
                            </Grid>
                        </Grid>

                        <TextField
                            style={{ width: '100%', marginBottom: '10px' }}
                            label="Welcome email sent?"
                            disabled={true}
                            InputLabelProps={genericLabelProps}
                            variant="standard"
                            value={assetOperator?.welcomeEmailSent ? 'Yes' : 'No'}
                        />

                        <InputLabel htmlFor="SelectCountryLabel">Country</InputLabel>
                        <Select className={classes.dropDown}
                            labelId="SelectCountryLabel"
                            variant="standard"
                            id="SelectCountry"
                            value={assetOperator?.registeredAddress?.countryCode ?? "GB"}
                            onChange={(event) => {
                                let address: Address = {
                                    ...assetOperator.registeredAddress,
                                    countryCode: (event.target.value as string)
                                }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        >
                            {countriesDropDown}

                        </Select>

                        <TextField
                            className={classes.textField}
                            label="Trading Name"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.tradingName ?? ''}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, tradingName: event.target.value })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Registered Company Name"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredCompanyName ?? ''}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, registeredCompanyName: event.target.value })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Company Number"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.companyNumber ?? ''}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, companyNumber: event.target.value })}
                        />

                        {assetOperator?.registeredAddress?.countryCode === "US" ?
                            <></>
                            :
                            <TextField
                                className={classes.textField}
                                label="VAT Number"
                                variant="standard"
                                hidden
                                InputLabelProps={genericLabelProps}
                                value={assetOperator?.vatNumber ?? ''}
                                onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, vatNumber: event.target.value })}
                            />
                        }

                        <TextField
                            className={classes.textField}
                            label="Telephone Number"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.phoneNumber ?? ''}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, phoneNumber: event.target.value })}
                        />
                        <InputLabel id="company-type-label">Company Type</InputLabel>
                        <Select
                            className={classes.dropDown}
                            variant="standard"
                            id="SelectCompanyType"
                            value={assetOperator?.companyType?.toString() ?? ''}
                            inputProps={{
                                name: "Company Type",
                                style: {
                                    "width": "100%"
                                }
                            }}
                            onChange={(e: SelectChangeEvent) => setAssetOperator({ ...assetOperator, companyType: Number.parseInt(e?.target?.value) as CompanyType })}
                            labelId="company-type-label"
                        >
                            <MenuItem key={CompanyType.Fleet} value={CompanyType.Fleet}>Fleet</MenuItem>
                            <MenuItem key={CompanyType.SoleTrader} value={CompanyType.SoleTrader}>Sole Trader</MenuItem>
                        </Select>

                        <InputLabel id="status-label" style={{ marginTop: "10px" }}>Status</InputLabel>
                        <Select
                            className={classes.dropDown}
                            value={assetOperator?.status ?? ''}
                            variant="standard"
                            id="SelectStatus"
                            inputProps={{
                                name: "Status",
                                style: {
                                    "width": "100%"
                                }
                            }}
                            onChange={(e: SelectChangeEvent) => setAssetOperator({ ...assetOperator, status: e?.target?.value })}
                            labelId="status-label"
                        >
                            <MenuItem key={AssetOperatorStatus.Active} value={AssetOperatorStatus.Active}>{AssetOperatorStatus.Active}</MenuItem>
                            <MenuItem key={AssetOperatorStatus.Inactive} value={AssetOperatorStatus.Inactive}>{AssetOperatorStatus.Inactive}</MenuItem>
                            <MenuItem key={AssetOperatorStatus.Demo} value={AssetOperatorStatus.Demo}>{AssetOperatorStatus.Demo}</MenuItem>
                        </Select>
                    </CardContent>
                </Card>
            </Grid>

            <Grid md={6} item container direction='column'>
                <Card elevation={10} style={{ marginBottom: '20px' }}>
                    <CardHeader title="Registered Address" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        <TextField
                            className={classes.textField}
                            label="Address Line One"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.lineOne ?? ''}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, lineOne: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="Address Line Two"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.lineTwo ?? ''}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, lineTwo: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />
                        <TextField
                            className={classes.textField}
                            label={assetOperator?.registeredAddress?.countryCode === "US" ? "Zip Code" : "Post Code"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.postCode}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, postCode: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label="City"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.city ?? ''}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, city: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />

                        <TextField
                            className={classes.textField}
                            label={assetOperator?.registeredAddress?.countryCode === "US" ? "State" : "County"}
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.registeredAddress?.county ?? ''}
                            onChange={(event: { target: { value: any; }; }) => {
                                let address: Address = { ...assetOperator.registeredAddress, county: event.target.value }
                                setAssetOperator({ ...assetOperator, registeredAddress: address })
                            }}
                        />
                    </CardContent>
                </Card>
                <Card elevation={10}>
                    <CardHeader title="Billing Information" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        <TextField
                            className={classes.textField}
                            label="Billing Email Address (To enter multiple recipients, please separate with a comma)"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.billingEmailAddress ?? ''}
                            onChange={(event: { target: { value: any; }; }) => setAssetOperator({ ...assetOperator, billingEmailAddress: event.target.value })}
                        />
                        <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title="Please note changing this value will not change the repayment dates of any active payment plan on this account"
                        >
                            <TextField
                                type="number"
                                className={classes.textField}
                                variant="standard"
                                error={preferredDebitDayError}
                                helperText={preferredDebitDayError ? "Please enter a number from 4 to 17" : ""}
                                label="Preferred Direct Debit Day of Month (4th - 17th)"
                                InputLabelProps={genericLabelProps}
                                InputProps={{ inputProps: { min: 4, max: 17 } }}
                                value={assetOperator?.preferredDirectDebitPaymentDayOfMonth ?? ""}
                                onChange={handlePreferredDirectDebitPaymentDayOfMonth}
                            />
                        </Tooltip>

                        <InputLabel id="invoicing-label">Invoicing and Payment Collection Method</InputLabel>
                        <Select
                            style={{
                                width: "100%",
                                "marginBottom": "10px"
                            }}
                            variant="standard"
                            value={assetOperator?.invoicingAndPaymentCollectionMethod?.toString()}
                            inputProps={{
                                name: "Invoicing and Payment Collection Method",
                                style: {
                                    "width": "100%",
                                    "marginBottom": "10px"
                                }
                            }}
                            onChange={(e: SelectChangeEvent) => setAssetOperator({ ...assetOperator, invoicingAndPaymentCollectionMethod: Number.parseInt(e?.target?.value) as InvoicingAndPaymentCollectionMethod })}
                            labelId="invoicing-label"
                            id="SelectInvoicingMethod"
                        >
                            <MenuItem key={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment} value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment}>System generated invoice and system-collected payment (e.g. Direct Debit)</MenuItem>
                            <MenuItem key={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceManualPayment} value={InvoicingAndPaymentCollectionMethod.MonthlyInvoiceManualPayment}>System generated Invoice, Manual Payment (e.g. Bank Transfer)</MenuItem>
                            <MenuItem key={InvoicingAndPaymentCollectionMethod.OffSystem} value={InvoicingAndPaymentCollectionMethod.OffSystem}>Off System Invoice and Payments (e.g. manually generated invoice paid by Bank Transfer)</MenuItem>
                        </Select>
                    </CardContent>
                </Card>
            </Grid>
            <Grid md={6} item>
                <Card elevation={10}>
                    <CardHeader title="User Access Control" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        <Typography>Please use comma seperated values with no spaces, e.g. mickey@disney.com,minnie@disney.com,pluto@disney.com</Typography>
                        <TextField
                            className={classes.textField}
                            label="Read/Write Admin Email Addresses"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            value={assetOperator?.adminIds?.join(",") ?? ''}
                            onChange={(event: { target: { value: string; }; }) => setAssetOperator({ ...assetOperator, adminIds: event.target.value.split(",") })}
                        />

                        <TextField
                            className={classes.textField}
                            label="Read Only User Email Addresses"
                            variant="standard"
                            InputLabelProps={genericLabelProps}
                            disabled={assetOperator?.id === "CUSTUKDEMO" || assetOperator?.id === "CUSTUSDEMO" || assetOperator?.id === "CUSTUKDEMOHGV" }
                            value={assetOperator?.userIds?.join(",") ?? ''}
                            onChange={(event: { target: { value: string; }; }) => setAssetOperator({ ...assetOperator, userIds: event.target.value.split(",") })}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Contracts" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        <Select
                            variant="standard"
                            style={{ width: "100%" }}
                            labelId="subscriptionId-multiple-checkbox-label"
                            id="subscriptionId-multiple-checkbox"
                            multiple
                            value={assetOperator.subscriptionIds ?? []}
                            onChange={onSubscriptionSelect}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {allSubscriptions?.map((subscription: SubscriptionViewModel) =>
                                <MenuItem key={subscription.id} value={subscription.id}>
                                    <Checkbox checked={assetOperator.subscriptionIds?.indexOf(subscription.id) > -1} />
                                    <ListItemText primary={subscription.id} />
                                </MenuItem>
                            )}
                        </Select>
                    </CardContent>
                </Card>
            </Grid>
            <Grid md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Full Billing And Payment History" titleTypographyProps={{ style: { fontSize: 16 } }} />
                    <CardContent>
                        <Button
                            href={`/api/assetoperators/${assetOperator?.id}/DownloadBillingAndPaymentHistoryReport`}
                            variant="contained"
                            color="primary"

                            style={{ marginBottom: "15px" }}
                        >
                            Download Report
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>;
};

export { AssetOperatorEditPage };
