import {SubscriptionViewModel} from "./SubscriptionViewModel";
import {DraftSubscription} from "./DraftSubscription";

class DraftSubscriptionViewModel extends SubscriptionViewModel {
    vmFeeRate: null | number
    vmTaxRate: null | number

    constructor(draftSubscription: DraftSubscription) {
        super(draftSubscription);       
        this.vmFeeRate = draftSubscription.feeRate ? draftSubscription.feeRate * 100 : null;
        this.vmTaxRate = draftSubscription.taxRate ? draftSubscription.taxRate * 100 : null;       
    }
}

export {DraftSubscriptionViewModel}
