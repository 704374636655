import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Button, Popover, Theme, Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItemText: {
            padding: theme.spacing(2),
        },
    }),
);

interface ListPopoverProps {
    listItems?: string[]
    buttonText?: string
}

function ListPopover({ listItems, buttonText }: ListPopoverProps): JSX.Element {
    const classes = useStyles();
    const [showPopover, setShowPopover] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setShowPopover(event.currentTarget);
    };

    const handleClose = () => {
        setShowPopover(null);
    };

    const open = Boolean(showPopover);
    const ariaDescription = open ? buttonText + '-popoverList' : undefined;

    function PopoverContents(listItems: string[]): JSX.Element {
        return (
            <>
                {listItems.map((item, index) => <Typography key={item + "_" + index} variant="body2"
                    className={classes.listItemText}>{item}</Typography>)}
            </>
        )
    }

    return (
        <div>

            <Button aria-describedby={ariaDescription} variant="contained" color="primary" onClick={handleClick}>
                {buttonText}
            </Button>

            <Popover
                id={ariaDescription}
                open={open}
                anchorEl={showPopover}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {listItems !== undefined && listItems.length > 0 ? PopoverContents(listItems) : <></>}

            </Popover>
        </div>
    );
}

export { ListPopover }
