import {MinimumUsagePeriodDates} from "../models/MinimumUsagePeriodDates";
import {PaymentPlan} from "../models/PaymentPlan";
import {PaymentPlanRequest} from "../models/PaymentPlanRequest";
import {SubscriptionIdsAndNames} from "../models/SubscriptionIdsAndNames";
import {getSettings} from "./SettingsService"
import {SubscriptionViewModel} from "../models/SubscriptionViewModel";
import {Subscription} from "../models/Subscription";

let cacheExpiryDate: Date | null = null 
let cache: SubscriptionViewModel[] = []

async function getSubscriptions() {
    const now = new Date()
    let subscriptions: SubscriptionViewModel[] = []
    if(cacheExpiryDate == null || now > cacheExpiryDate){
        const settings = await getSettings();
        const subscriptionsUri =`${settings.BillingServiceBaseUri}/subscriptions`
        const options = {
            method: "GET",
        };
        const subscriptionsResponse = await fetch(subscriptionsUri,options)
        subscriptions = await subscriptionsResponse.json()
        cache = subscriptions
        let newCacheDate = now;
        newCacheDate.setSeconds(now.getSeconds() + 1)
        cacheExpiryDate = newCacheDate
    } else {
        subscriptions = cache
    }    

    return subscriptions;
}

async function getSubscription(id: string) {
    const settings = await getSettings();
    const subscriptionUri =`${settings.BillingServiceBaseUri}/subscriptions/${id}`
    const options = {
        method: "GET",
    };
    const subscriptionResponse = await fetch(subscriptionUri,options)
    const subscription = await subscriptionResponse.json()

    return new SubscriptionViewModel(subscription);
}

async function createSubscription(subscriptionViewModel: SubscriptionViewModel): Promise<string> {
    const subscription = Subscription.Convert(subscriptionViewModel);

    const settings = await getSettings();
    const subscriptionUri =`${settings.BillingServiceBaseUri}/subscriptions`
    const options = {
        method: "POST",
        body: JSON.stringify(subscription)
    };
    const subscriptionResponse = await fetch(subscriptionUri,options)
    const subscriptionCreated = await subscriptionResponse.json()
    return subscriptionCreated.id;
}

async function deleteSubscription(id: string): Promise<void> {
    const settings = await getSettings();
    const subUri =`${settings.BillingServiceBaseUri}/subscriptions/${id}`
    const options = {
        method: "DELETE"
    };
    await fetch(subUri,options)
}

async function getDealershipGroupIds(): Promise<string[]> {
    const settings = await getSettings();
    const subscriptionUri =`${settings.BillingServiceBaseUri}/dealershipgroups/*/id`
    const options = {
        method: "GET",
    };
    const subscriptionResponse = await fetch(subscriptionUri,options)
    const subscription = await subscriptionResponse.json()
    return subscription;
}

async function createPaymentPlan(subscriptionId: string, paymentPlanInput: PaymentPlanRequest): Promise<PaymentPlan> {
    const settings = await getSettings();
    
    const subscriptionUri =`${settings.BillingServiceBaseUri}/subscriptions/${subscriptionId}/paymentplans`
    const options = {
        method: "POST",
        body: JSON.stringify(paymentPlanInput)
    };
    const subscriptionResponse = await fetch(subscriptionUri, options)
    const paymentPlan = await subscriptionResponse.json()    
    return paymentPlan;
}

async function getSubscriptionsIdsToNames() {
    let subscriptionsIdsToNames: SubscriptionIdsAndNames[] = []
    const settings = await getSettings();
    const subscriptionsUri =`${settings.BillingServiceBaseUri}/subscriptionIdsToNames`
    const options = {
        method: "GET",
    };
    const subscriptionsResponse = await fetch(subscriptionsUri,options)
    subscriptionsIdsToNames = await subscriptionsResponse.json() 

    return subscriptionsIdsToNames;
}

async function calculateMinimumUsageDates(startDate: string, numberOfPeriods: number): Promise<MinimumUsagePeriodDates[]> {
    const minimumUsagePeriodDatesUri =`/api/MinimumUsageDates?startDate=${startDate}&numberOfPeriods=${numberOfPeriods}`
    const options = {
        method: "GET"
    };
    const response = await fetch(minimumUsagePeriodDatesUri, options)
    const minimumUsagePeriodDates = await response.json()    
    return minimumUsagePeriodDates;
}



export { getSubscriptions, getSubscription, createSubscription, deleteSubscription, getDealershipGroupIds, createPaymentPlan, getSubscriptionsIdsToNames, calculateMinimumUsageDates }
