import { DataGrid } from "@mui/x-data-grid";
import { PaymentPlanRepayment } from "../models/PaymentPlanRepayment";
import { format } from "date-fns";
import { Subscription } from "../models/Subscription";
import { ConvertCurrencyToSymbol } from "../helpers/ConvertCurrencyToSymbol";
import { Button } from "@mui/material";

interface PaymentPlansTableProps {
    subscription: Subscription
    setEditPaymentPlanModalOpen: Function
    setPaymentPlanIdToEdit: Function
}

function PaymentPlansTable({subscription, setEditPaymentPlanModalOpen, setPaymentPlanIdToEdit}: PaymentPlansTableProps) {
    const columns: any[] = [
        { field: 'billPeriod', headerName: 'Bill Period', flex: 1, renderCell: (params: any): string => {
            return params.row.billingDate == null ? 'N/A' : format(new Date(params.row.billingDate), "MMM yyyy")
          }  },
        { field: 'totalAmountDelayed', headerName: 'Bill Amount', flex: 1, renderCell: (params: any): string => {
            return `${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${params.row.totalAmountDelayed}`
        } },
        { field: 'numberOfRepayments', headerName: 'No. of Installments', flex: 1},
        { field: 'collectedToDate', headerName: 'Collected to Date', flex: 1, renderCell: (params: any): string => {
            let amounts = params.row.repayments.filter((x: PaymentPlanRepayment) =>  new Date(x.repaymentDate) <= new Date()).map((x: PaymentPlanRepayment) => x.amount)
            let collectedToDate = amounts == null || amounts.length === 0 ? 0 : amounts.reduce(function (a: number, b: number) {
                return a + b;
            })
            return `${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${collectedToDate}`
          }, },
        { field: 'outstanding', headerName: 'Outstanding', flex: 1, renderCell: (params: any): string => {
            let amounts = params.row.repayments.filter((x: PaymentPlanRepayment) => new Date(x.repaymentDate) > new Date()).map((x: PaymentPlanRepayment) => x.amount)
            let outstanding = amounts == null || amounts.length === 0 ? 0 : amounts.reduce(function (a: number, b: number) {
                return a + b;
            })
            return `${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${outstanding}`
          }, },
        {
            field: 'viewButton', headerName: '', flex: 1,
            renderCell: (params: any): JSX.Element =>
                <Button
                    variant="contained"
                    onClick={() => {
                        setPaymentPlanIdToEdit(params.row.id)
                        setEditPaymentPlanModalOpen(true)
                    }}
                >
                    View
                </Button>
        }
    ];

    return (
        <div style={{ height: '60vh', width: '100%' }}>
            <DataGrid
                rows={subscription.paymentPlans}
                columns={columns}
                rowsPerPageOptions={[10,50,100]}
                initialState={{
                    pagination:{
                        pageSize: 10
                    }
                }}
            />
        </div>
    )
}
export { PaymentPlansTable }
