import { Note } from '../models/Note'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Link,
    Modal,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import { PushPin, PushPinOutlined, AttachFile } from '@mui/icons-material';
import { AssetOperator } from '../models/AssetOperator';
import { NoteAttachment } from '../models/NoteAttachment';

type NotesTableProps = {
    notes: Note[]
    assetOperatorId: string
    handleAddButtonClick: Function
    editAssetOperatorNotes: Function
    assetOperator: AssetOperator
    setAssetOperator: Function
}

function getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        parentAccordionBox: {
            maxHeight: '300px',
            overflow: 'auto'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

function NotesTable({
    notes,
    assetOperatorId,
    handleAddButtonClick,
    editAssetOperatorNotes,
    assetOperator,
    setAssetOperator
}: NotesTableProps): JSX.Element {

    const classes = useStyles();
    const [newNote, setNewNote] = useState<Note>({} as Note);
    const [modalOpen, setModalOpen] = useState(false);
    const [editingNote, setEditingNote] = useState<Note | null>(null);
    const [noteSaveError, setNoteSaveError] = useState<string>('');

    const handleModalOpen = (note: Note) => {
        setModalOpen(true);
        setEditingNote(note);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setEditingNote(null);
        setNoteSaveError('')
    }

    async function handleModalUpdateClicked() {
        if(!editingNote) return;
        try{
            await editAssetOperatorNotes(assetOperatorId, editingNote);
        } catch(e: any) {
            setNoteSaveError(e.message)
            return;
        }
        setModalOpen(false);
        setEditingNote(null);
    }

    async function handlePinNote(note: Note) {
        const updatedNotes: Note[] = notes.map(oldNote => oldNote.id === note.id ? { ...oldNote, pinned: true } : oldNote);
        setAssetOperator({ ...assetOperator, notes: updatedNotes })
        await editAssetOperatorNotes(assetOperatorId, { ...note, pinned: true })
    }

    const handleUnpinNote = (note: Note) => {
        const updatedNotes: Note[] = notes.map(oldNote => oldNote.id === note.id ? { ...oldNote, pinned: false } : oldNote);
        setAssetOperator({ ...assetOperator, notes: updatedNotes })
        editAssetOperatorNotes(assetOperatorId, { ...note, pinned: false })
    }

    const handleAttachment = (noteToEdit: Note, input: any, isNewNote: boolean) => {
        
        var fileData = input.target.files;
        var file: File = fileData[0];

        var fileName = file.name;
        var attachment: NoteAttachment = {name: fileName, file: file};

        if(isNewNote){
            let existingAttachments = newNote?.attachments ?? [];
            existingAttachments.push(attachment);
            setNewNote({...newNote, attachments: existingAttachments} as Note)
        } else {
            let existingAttachments = noteToEdit.attachments ?? [];
            existingAttachments.push(attachment);
            setEditingNote({ ...noteToEdit, attachments: existingAttachments })
        }
    }

    const theme = useTheme();

    return (
        <Card elevation={10} style={{marginBottom: '20px'}}>
            <CardHeader title="Notes" titleTypographyProps={{ style: { fontSize: 16 } }} />
            <CardContent style={{ paddingTop: '0' }}>
                {notes.findIndex(note => note.pinned) > -1 &&
                    <div>
                        <Card elevation={2}>
                            <CardHeader title="Pinned" titleTypographyProps={{ style: { fontSize: 16 } }} />
                            <CardContent style={{ paddingTop: '0' }}>
                                <div className={classes.parentAccordionBox}>
                                    {notes?.filter(note => note.pinned).sort((a, b) => getTime(b.createdDate) - getTime(a.createdDate))?.map((note) => {
                                        return (<>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    id="panelHeader"
                                                >
                                                    <Typography className={classes.heading}>{note.attachments ? <AttachFile style={{marginLeft: '-20px', marginBottom: '-5px'}} fontSize={'small'}/>:null} {new Date(note.createdDate).toLocaleDateString()} : {note.title} <IconButton onClick={(event) => { event.stopPropagation(); event.preventDefault(); handleUnpinNote(note) }}><PushPin color="primary" /></IconButton></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        {note.text}
                                                    </Typography>
                                                    {note?.attachments?.map((attachment, index) => {return attachment?.name ?
                                                        <>
                                                            <AttachFile fontSize={'small'} style={{marginBottom: '-5px'}} key={"pinned-attachment-logo-" + index}/>
                                                            <Link
                                                                key={"pinned-attachment-link-" + index}
                                                                href={`/api/downloadNoteAttachmentFile/${note.id}/${attachment.filePath}`}>
                                                                {attachment?.name}
                                                            </Link>
                                                            <br/>
                                                        </>
                                                        :
                                                        null
                                                        })
                                                    }
                                                    <Button
                                                        style={{marginTop: '10px'}}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleModalOpen(note)}>
                                                        Edit
                                                    </Button>
                                                    <Modal
                                                        open={modalOpen}
                                                        onClose={handleModalClose}
                                                        aria-labelledby="modal-edit-note-title"
                                                        aria-describedby="modal-edit-note-text"
                                                        className={classes.modal}
                                                    >
                                                        <Card style={{ height: "fit-content", width: "50vw" }} >
                                                            <CardContent >
                                                                <div>
                                                                    <TextField id="modalEditNoteTitleField"
                                                                        style={{ marginBottom: "10px" }}
                                                                        variant="outlined"
                                                                        label="Edit Note Title"
                                                                        multiline
                                                                        rows={1}
                                                                        fullWidth
                                                                        value={editingNote?.title ?? ''}
                                                                        onChange={(e) => editingNote && setEditingNote({ ...editingNote, title: e.target.value })}>
                                                                    </TextField>
                                                                    <TextField id="modalEditNoteTextField"
                                                                        sx={{ mt: 2 }}
                                                                        style={{ marginBottom: "5px" }}
                                                                        variant="outlined"
                                                                        label="Edit Note Text"
                                                                        multiline
                                                                        rows={2}
                                                                        fullWidth
                                                                        value={editingNote?.text ?? ''}
                                                                        onChange={(e) => editingNote && setEditingNote({ ...editingNote, text: e.target.value })}>
                                                                    </TextField>
                                                                    <Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        onChange={(x: React.FormEvent<HTMLLabelElement>) => editingNote && handleAttachment(editingNote, x, false)}
                                                                        >
                                                                        Upload Attachment
                                                                        <input
                                                                            type="file"
                                                                            hidden
                                                                        />
                                                                    </Button>
                                                                    <br/>
                                                                    {editingNote?.attachments?.map(attachment => {return attachment?.name ?
                                                                        <>
                                                                            <AttachFile fontSize={'small'} style={{marginBottom: '-5px'}}/>
                                                                            <Link
                                                                                href={`/api/downloadNoteAttachmentFile/${note.id}/${attachment.filePath}`}>
                                                                                {attachment?.name}
                                                                            </Link>
                                                                            <br/>
                                                                        </> 
                                                                        :
                                                                        null
                                                                        })
                                                                    }
                                                                    <Button
                                                                        style={{marginTop: '10px'}}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleModalUpdateClicked}>
                                                                        Update
                                                                    </Button>
                                                                    <Typography color={theme.palette.warning.main}>{noteSaveError}</Typography>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </Modal>
                                                </AccordionDetails>
                                            </Accordion>
                                        </>)
                                    })}
                                </div>
                            </CardContent>
                        </Card>
                        <br />
                    </div>}

                {notes.findIndex(note => !note.pinned) > -1 &&
                    <div>
                        <Card elevation={2}>
                            <CardContent style={{ paddingTop: '0' }}>
                                <div className={classes.parentAccordionBox}>
                                    {notes?.filter(note => !note.pinned).sort((a, b) => getTime(b.createdDate) - getTime(a.createdDate))?.map((note) => {
                                        return (<>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    id="panelHeader"
                                                >
                                                    <Typography className={classes.heading}>{note.attachments ? <AttachFile style={{marginLeft: '-20px', marginBottom: '-5px'}} fontSize={'small'}/>:null} {new Date(note.createdDate).toLocaleDateString()} : {note.title}<IconButton onClick={(event) => { event.stopPropagation(); event.preventDefault(); handlePinNote(note); note.pinned = true }}><PushPinOutlined /></IconButton></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography style={{ marginBottom: '10px' }}>
                                                        {note.text}
                                                    </Typography>
                                                    {note?.attachments?.map((attachment, index) => {return attachment?.name ?
                                                        <>
                                                            <AttachFile fontSize={'small'} style={{marginBottom: '-5px'}} key={"non-pinned-attachment-logo-" + index}/>
                                                            {attachment.filePath ?
                                                                <Link
                                                                    key={"non-pinned-attachment-link-" + index}
                                                                    href={`/api/downloadNoteAttachmentFile/${note.id}/${attachment.filePath}`}>
                                                                    {attachment?.name}
                                                                </Link>
                                                                :
                                                                attachment?.name
                                                            }
                                                            <br/>
                                                        </> 
                                                        :
                                                        null
                                                        })
                                                    }
                                                    <Button
                                                        style={{marginTop: '10px'}}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleModalOpen(note)}>
                                                        Edit
                                                    </Button>
                                                    <Modal
                                                        open={modalOpen}
                                                        onClose={handleModalClose}
                                                        aria-labelledby="modal-edit-note-title"
                                                        aria-describedby="modal-edit-note-text"
                                                        className={classes.modal}
                                                    >
                                                        <Card style={{ height: "fit-content", width: "50vw" }} >
                                                            <CardContent >
                                                                <div>
                                                                    <TextField id="modalEditNoteTitleField"
                                                                        style={{ marginBottom: "10px" }}
                                                                        variant="outlined"
                                                                        label="Edit Note Title"
                                                                        multiline
                                                                        rows={1}
                                                                        fullWidth
                                                                        value={editingNote?.title ?? ''}
                                                                        onChange={(e) => editingNote && setEditingNote({ ...editingNote, title: e.target.value })}>
                                                                    </TextField>
                                                                    <TextField id="modalEditNoteTextField"
                                                                        sx={{ mt: 2 }}
                                                                        style={{ marginBottom: "5px" }}
                                                                        variant="outlined"
                                                                        label="Edit Note Text"
                                                                        multiline
                                                                        rows={2}
                                                                        fullWidth
                                                                        value={editingNote?.text ?? ''}
                                                                        onChange={(e) => editingNote && setEditingNote({ ...editingNote, text: e.target.value })}>
                                                                    </TextField>
                                                                    <Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        onChange={(x: React.FormEvent<HTMLLabelElement>) => editingNote && handleAttachment(editingNote, x, false)}
                                                                        >
                                                                        Upload Attachment
                                                                        <input
                                                                            type="file"
                                                                            hidden
                                                                        />
                                                                    </Button>
                                                                    <br/>
                                                                    {editingNote?.attachments?.map((attachment, index) => {return attachment?.name ?
                                                                        <>
                                                                            <AttachFile fontSize={'small'} style={{marginBottom: '-5px'}} key={"non-pinned-attachment-logo-modal-" + index}/>
                                                                            {attachment.filePath ?
                                                                                <Link
                                                                                    key={"non-pinned-attachment-link-modal-" + index}
                                                                                    href={`/api/downloadNoteAttachmentFile/${note.id}/${attachment.filePath}`}>
                                                                                    {attachment?.name}
                                                                                </Link>
                                                                                :
                                                                                attachment?.name
                                                                            }
                                                                            <br/>
                                                                        </> 
                                                                        :
                                                                        null
                                                                        })
                                                                    }
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleModalUpdateClicked}
                                                                        style={{marginTop: '10px'}}>
                                                                        Update
                                                                    </Button>
                                                                    <Typography color={theme.palette.warning.main}>{noteSaveError}</Typography>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </Modal>
                                                </AccordionDetails>
                                            </Accordion>
                                        </>)
                                    })}
                                </div>
                            </CardContent>
                        </Card>
                        <br />
                    </div>
                }
                <TextField id="newNoteTitleField" style={{ marginBottom: "10px" }} variant="outlined"
                    label="Add New Title"
                    multiline rows={1} fullWidth value={newNote?.title}
                    onChange={(e) => setNewNote({...newNote, title: e.target.value as string} as Note)}></TextField>
                <TextField id="newNoteTextField" style={{ marginBottom: "10px" }} variant="outlined" label="Add New Note"
                    multiline rows={2} fullWidth value={newNote?.text}
                    onChange={(e) => setNewNote({...newNote, text: e.target.value as string} as Note)}></TextField>
                <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    onChange={(x: React.FormEvent<HTMLLabelElement>) => handleAttachment(newNote, x, true)}
                    >
                    Upload Attachment
                    <input
                        type="file"
                        hidden
                    />
                </Button>
                <br/>
                {newNote?.attachments?.map((attachment, index) => {return attachment?.name ?
                    <>
                        <AttachFile fontSize={'small'} style={{marginBottom: '-5px'}} key={"new-attachment-logo-" + index}/>
                        {attachment?.name}
                        <br/>
                    </>
                    :
                    null
                })
                }
                <Button id="addNewNoteButton" variant="contained" color="primary" fullWidth style={{marginTop: '10px'}}
                    onClick={async () => {
                        try {
                            await handleAddButtonClick(assetOperatorId, newNote?.text, newNote?.title, newNote?.attachments)
                        } catch(e: any){
                            setNoteSaveError(e.message)
                            return;
                        }
                        setNewNote({text: '', title: '', attachments: null} as Note)
                        setNoteSaveError('')
                    }}>Add</Button>
                    <Typography color={theme.palette.warning.main}>{noteSaveError}</Typography>
            </CardContent>
        </Card>
    )
}

export { NotesTable }
