import {InvestmentManagementFeeBill} from "../models/InvestmentManagementFeeBill"
import {InvestorStripeSetUpDetails} from "../models/InvestorStripeSetUpDetails"
import {SubscriptionViewModel} from "../models/SubscriptionViewModel"
import {Subscription} from "../models/Subscription"
import {OneOffInvoiceRequest} from "../models/OneOffInvoiceRequest"
import {InvestorViewModel} from "../models/InvestorViewModel";
import {Investor} from "../models/Investor";

let cacheExpiryDate: Date | null = null
let cache: InvestorViewModel[] = []

async function getInvestors() {
    const now = new Date()
    let investors: InvestorViewModel[]
    
    if (cacheExpiryDate == null || now > cacheExpiryDate) {
        const investorsUri = `/api/investors`
        const options = {
            method: "GET",
        };
        const investorsResponse = await fetch(investorsUri, options)
                
        investors = await investorsResponse.json()
        cache = investors.map((investor: Investor) => {
            return new InvestorViewModel(investor)});
        let newCacheDate = now;
        newCacheDate.setSeconds(now.getSeconds() + 1)
        cacheExpiryDate = newCacheDate
    } else {
        investors = cache
    }

    return investors;
}

async function getInvestor(id: string): Promise<InvestorViewModel|null> {
    const investorUri = `/api/investors/${id}`
    const options = {
        method: "GET",
    };
    const investorResponse = await fetch(investorUri, options)

    if(!investorResponse.ok) return null;
    
    var investor = await investorResponse.json();
    
    return new InvestorViewModel(investor);
}

async function getStripeConnectLink(investorId: string): Promise<string> {
    const investorUri = `/api/investors/${investorId}/stripeconnectedaccountonboardingurl`
    const options = {
        method: "GET",
    };
    const stripeSetupUrlResponse = await fetch(investorUri, options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject.onboardingUrl;
}

async function createStripeConnectAccount(investorId: string): Promise<InvestorStripeSetUpDetails> {
    const investorUri = `/api/investors/${investorId}/stripeaccount`
    const options = {
        method: "POST",
    };
    const stripeSetupUrlResponse = await fetch(investorUri, options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject;
}

async function createInvestor(investorViewModel: InvestorViewModel): Promise<string> {
    const investor = Investor.Convert(investorViewModel);

    const investorUri = `/api/investors`
    const options = {
        method: "POST",
        body: JSON.stringify(investor)
    };
    const investorResponse = await fetch(investorUri, options)
    if(!investorResponse.ok)
    {
        return "FAIL";
    }
    const investorCreated = await investorResponse.json()
    return investorCreated.id;
}

async function archiveInvestor(id: string): Promise<void> {
    const investorUri = `/api/investors/${id}/archive`
    const options = {
        method: "PUT"
    };
    await fetch(investorUri, options)
}
async function getStripeFeePaymentLink(investorId: string): Promise<string> {
    const investorStripeFeePaymentSetupUri = `/api/investors/${investorId}/stripefeepaymentsetupurl`
    const options = {
        method: "GET",
    };
    const stripeSetupUrlResponse = await fetch(investorStripeFeePaymentSetupUri, options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject.url;
}

async function createStripeFeePaymentAccount(investorId: string): Promise<string> {
    const investorUri = `/api/investors/${investorId}/stripefeepaymentaccount`
    const options = {
        method: "POST",
    };
    const stripeSetupUrlResponse = await fetch(investorUri, options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject.customerId;
}

async function getInvestmentManagementFeeBills(investorId: string): Promise<InvestmentManagementFeeBill[]> {
    const investorUri = `/api/investors/${investorId}/investmentmanagementfeebills`
    const options = {
        method: "GET",
    };
    const response = await fetch(investorUri, options)
    return await response.json();
}

async function getStripeFeePaymentAchSecret(investorId: string): Promise<string> {
    const investorUri =`/api/investors/${investorId}/stripeachclientsecret`
    const options = {
        method: "GET",
    };
    const stripeSetupUrlResponse = await fetch(investorUri,options)
    const stripeSetupUrlObject = await stripeSetupUrlResponse.json()
    return stripeSetupUrlObject.secret;
}

async function getInvestorSubscriptions(investorId: string): Promise<SubscriptionViewModel[]> {
    const investorUri =`/api/investors/${investorId}/subscriptions`
    const options = {
        method: "GET",
    };
    const subscriptionResponse = await fetch(investorUri, options)
    const subscriptions = await subscriptionResponse.json()
       
    var subscriptionsViewModels = subscriptions.forEach((sub: Subscription) => {new SubscriptionViewModel(sub)})    
 
    return subscriptionsViewModels;
}

async function submitOneOffInvoiceRequest(oneOffInvoiceRequest: OneOffInvoiceRequest): Promise<boolean>{
    const investorUri = `/api/investors/${oneOffInvoiceRequest.investorId}/oneOffInvoice`
    
    const options = {
        method: "PUT",
        body: JSON.stringify(oneOffInvoiceRequest)
    };
    const response = await fetch(investorUri, options)
    return response.ok
}
async function sendInvestorWelcomeEmail(investorId: string | null): Promise<boolean> {
    if(investorId === null) return false;
    const sendEmailUri = `/api/investors/${investorId}/sendwelcomeemail`
    const options = {
        method: "POST"
    };
    const sendEmailResponse = await fetch(sendEmailUri, options)
    return sendEmailResponse.ok
}

async function createNewInvestorUser(investorId: string, email: string, newUserIsAdmin: boolean): Promise<[string, boolean]> {
    const investorUri = `/api/investors/${investorId}/users`
    const options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({ email: email, newUserIsAdmin: newUserIsAdmin })
    };
    const addUserResponse = await fetch(investorUri, options)
    const isSuccessful = addUserResponse.ok
    const addUserObject = await addUserResponse.json()
    return [addUserObject.message, isSuccessful];

}

async function deleteSelectedInvestorUsers(investorId: string, selectedUserEmails: string[]): Promise<[string, boolean]> {
    const investorUri = `/api/investors/${investorId}/users`
    const options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "DELETE",
        body: JSON.stringify({ emails: selectedUserEmails })
    };
    const deleteUsersResponse = await fetch(investorUri, options)
    const isSuccessful = deleteUsersResponse.ok
    const deleteUsersObject = await deleteUsersResponse.json()
    return [deleteUsersObject.message, isSuccessful];
}

async function makeInvestorUsersNormal(investorId: string, selectedUserEmails: string[]): Promise<[string, boolean]> {
    const investorUri = `/api/investors/${investorId}/users/normal`
    const options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "PUT",
        body: JSON.stringify({ emails: selectedUserEmails })
    };
    const makeUsersNormalResponse = await fetch(investorUri, options)
    const isSuccessful = makeUsersNormalResponse.ok
    const makeUsersNormalObject = await makeUsersNormalResponse.json()
    return [makeUsersNormalObject.message, isSuccessful];
}

async function makeInvestorUsersAdmin(investorId: string, selectedUserEmails: string[]): Promise<[string, boolean]> {
    const investorUri = `/api/investors/${investorId}/users/admin`
    const options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "PUT",
        body: JSON.stringify({ emails: selectedUserEmails })
    };
    const makeUsersAdminResponse = await fetch(investorUri, options)
    const isSuccessful = makeUsersAdminResponse.ok
    const makeUsersAdminObject = await makeUsersAdminResponse.json()
    return [makeUsersAdminObject.message, isSuccessful];
}


export { getInvestors, getInvestor, getStripeConnectLink, createStripeConnectAccount, createInvestor, archiveInvestor, getStripeFeePaymentLink, createStripeFeePaymentAccount,getInvestmentManagementFeeBills, getStripeFeePaymentAchSecret, getInvestorSubscriptions, sendInvestorWelcomeEmail, submitOneOffInvoiceRequest, createNewInvestorUser, deleteSelectedInvestorUsers, makeInvestorUsersNormal, makeInvestorUsersAdmin }
