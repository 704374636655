enum BillStatus {
    LegacyBillStatus, 
    IssuedAndLive, 
    IssuedButRetracted, 
    GeneratedButHeldAndNotIssued, 
    GeneratedButSendingFailedAndNotIssued,
    ApprovedAutomatically
}

export {BillStatus}
