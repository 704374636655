import { CreateAPaymentMessage } from "../models/CreateAPaymentMessage";
import { InvestmentManagementFeePayment } from "../models/InvestmentManagementFeePayment";
import { getSettings } from "./SettingsService";

async function CreateAnInvestmentManagementFeePaymentByRest(investorId: string, billId: string, paymentCreationRequest: CreateAPaymentMessage): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/investors/${investorId}/pay?${billId === 'HOLDING_STRING' ? '' : 'billId=' + billId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(paymentCreationRequest)
    })
}

async function getAllInvestorFeePayments(toAction: boolean = false): Promise<InvestmentManagementFeePayment[]>{
    const settings = await getSettings()
    let uri = `${settings.BillingServiceBaseUri}/investorFeePayments`
    if (toAction) uri += '?toAction=true'
    const response = await fetch(uri, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET'
    });

    return await response.json();
}

async function cancelInvestmentManagementFeePayment(paymentId: string): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/investmentManagementFeePayments/cancel/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT'
    });
}

async function archiveFailedInvestmentManagementFeePayment(paymentId: string): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/investmentManagementFeePayments/archive/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT'
    });
}

async function retryFailedInvestmentManagementFeePayment(paymentId: string): Promise<Response>{
    const settings = await getSettings()
    return await fetch(`${settings.BillingServiceBaseUri}/investmentManagementFeePayments/retry/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT'
    });
}

async function getInvestmentManagementFeePayment(paymentId: string): Promise<InvestmentManagementFeePayment>{
    const settings = await getSettings()
    var response = await fetch(`${settings.BillingServiceBaseUri}/investmentManagementFeePayments/${paymentId}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET'
    })
    return await response.json();
}

export {CreateAnInvestmentManagementFeePaymentByRest, getAllInvestorFeePayments, cancelInvestmentManagementFeePayment, archiveFailedInvestmentManagementFeePayment, retryFailedInvestmentManagementFeePayment, getInvestmentManagementFeePayment}