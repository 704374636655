import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {SubscriptionAsset} from '../models/SubscriptionAsset';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {RoadVehicle} from "../models/RoadVehicle";
import {SubscriptionViewModel} from "../models/SubscriptionViewModel";
import {getTelematicsData} from "../services/AssetsService";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {EditAssetModal} from "./EditAssetModal";
import { AssetType } from "../models/AssetType";
import {genericLabelProps} from "../props/GenericLabelProps";
import { AddVehicleDetails } from "./AddVehicleDetails";
import CloseIcon from '@mui/icons-material/Close';
import { convertFromGramsToTonnes, convertFromTonnesToGrams } from '../helpers/ConvertTonnesAndGrams';
import { addHours } from "date-fns";
import { ConvertSubscriptionAssetStatusToString } from "../helpers/ConvertSubscriptionAssetStatusToString";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        modal: {
            position: 'fixed',
            border: '2px solid',
            borderColor: theme.palette.text.primary,
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.down('md')]: {
                width: "100%",
                height: "100%",
                overflow: 'scroll',
            },
            [theme.breakpoints.up('md')]: {
                width: "50%",

            }
        },
        modalContainer: {
            padding: "10px",

        },
    }));

type AssetsTabProps = {
    assets: SubscriptionAsset[],
    setAssets: Function,
    subscription: SubscriptionViewModel,
}

function AssetsTab(props: AssetsTabProps): JSX.Element {

    const classes = useStyles();
    const [roadVehicles, setRoadVehicles] = React.useState<RoadVehicle[] | undefined>(undefined)
    const [roadVehiclesLoading, setRoadVehiclesLoading] = React.useState<boolean>(true)
    const [editAssetModalOpen, setEditAssetModalOpen] = React.useState(false);
    const [assetBeingEdited, setAssetBeingEdited] = React.useState<SubscriptionAsset | undefined>(undefined);
    const [indexOfAssetBeingEdited, setIndexOfAssetBeingEdited] = React.useState<number>(0);
    const [assetGeneratorAssetType, setAssetGeneratorAssetType] = React.useState<AssetType>(AssetType.Vehicle);
    const [assetGeneratorMake, setAssetGeneratorMake] = React.useState<string>("");
    const [assetGeneratorModel, setAssetGeneratorModel] = React.useState<string>("");
    const [assetGeneratorTrim, setAssetGeneratorTrim] = React.useState<string>("");
    const [assetGeneratorYearOrVersion, setAssetGeneratorYearOrVersion] = React.useState("");
    const [assetGeneratorCo2Saved, setAssetGeneratorCo2Saved] = React.useState("");
    const [assetGeneratorNoxSaved, setAssetGeneratorNoxSaved] = React.useState("");   
    const [assetGeneratorNumberToCreate, setAssetGeneratorNumberToCreate] = React.useState<number|undefined>(0);
    const [numberOfNewAssetsAdded, setNumberOfNewAssetsAdded] = React.useState<number>(0);
    const [addAssetsModalOpen, setAddAssetsModalOpen] = React.useState(false);
    const [addVehicleDetailsModalOpen, setAddVehicleDetailsModalOpen] = React.useState(false);
    const handleAddAssetsModalOpen = () => setAddAssetsModalOpen(true);
    const handleAddAssetsModalClose = () => {
        setAddAssetsModalOpen(false);
        setNumberOfNewAssetsAdded(0);
        setAssetGeneratorAssetType(AssetType.Vehicle)
        setAssetGeneratorMake("")
        setAssetGeneratorModel("")
        setAssetGeneratorTrim("")
        setAssetGeneratorYearOrVersion("")
        setAssetGeneratorCo2Saved("")
        setAssetGeneratorNoxSaved("")
        setAssetGeneratorNumberToCreate(0)
    };
    const handleAddVehicleDetailsModalOpen = () => setAddVehicleDetailsModalOpen(true);
    const handleAddVehicleDetailsModalClose = () => {
        setAddVehicleDetailsModalOpen(false);
    };
    
    const refreshTelematics = () => 
        getTelematicsData(props.subscription.id).then(results => {
        setRoadVehicles(results)
        setRoadVehiclesLoading(false)
    })

    const onPropertyChange = (index: number, event: any, setProperty: (currentAssets: SubscriptionAsset[], index: number, value: any) => SubscriptionAsset[]) => {

        let currentAssets = props.assets ?? [];
        setProperty(currentAssets, index, event)
        props.setAssets([...currentAssets])
    }

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            setRoadVehiclesLoading(true)
            refreshTelematics().then(() => {
                    return () => {
                        mounted = false;
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subscription.id])

    function handleEditAssetButtonClick(asset: SubscriptionAsset, index: number) {
        setAssetBeingEdited(asset);
        setIndexOfAssetBeingEdited(index);
        setEditAssetModalOpen(true);
    }

    function mandatoryFieldComplete(asset: SubscriptionAsset) {
        if (asset.usageSourceId && asset.alternativeId) {
            return true
        }
        return false
    }

    return (
        <>
            <EditAssetModal editAssetModalOpen={editAssetModalOpen} setEditAssetModalOpen={setEditAssetModalOpen}
                            assetBeingEdited={assetBeingEdited} setAssetBeingEdited={setAssetBeingEdited}
                            assets={props.assets} setAssets={props.setAssets}
                            indexOfAssetBeingEdited={indexOfAssetBeingEdited}></EditAssetModal>

            <Card>
                <CardHeader title={"Assets"}/>

                <Grid
                    style={{ marginTop: '20px' }}
                    justifyContent="space-between"
                    container
                >
                    <Grid container
                    justifyContent="space-evenly"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{marginRight: "20px"}}
                            onClick={handleAddAssetsModalOpen}>
                                Add new assets
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{marginRight: "20px"}}
                            onClick={handleAddVehicleDetailsModalOpen}>
                            Upload Asset Data
                        </Button>
                    </Grid>
                    
                    <Modal open={addVehicleDetailsModalOpen} onClose={handleAddVehicleDetailsModalClose}>
                        <div className={classes.modal}>
                            <AddVehicleDetails subscription={props.subscription} setAssets={props.setAssets} handleAddVehicleDetailsModalClose={handleAddVehicleDetailsModalClose}/>
                        </div>
                    </Modal>
                    <Modal
                        open={addAssetsModalOpen}
                        onClose={handleAddAssetsModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className={classes.modal}>
                            <Grid xs={12} item>
                                <Card>
                                <Grid container direction="row" justifyContent="space-between">
                                    <CardHeader title="Generate New Assets" />
                                    <IconButton onClick={() => handleAddAssetsModalClose()}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                    <CardContent>
                                        <Grid
                                            container
                                            direction="column"
                                        >
                                            <Grid item container xs={12} md={6}>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Asset Type
                                                        </InputLabel>
                                                        <Select
                                                            value={assetGeneratorAssetType}
                                                            inputProps={{
                                                                name: "Asset Type",
                                                            }}
                                                            onChange={(e) => {
                                                                setAssetGeneratorAssetType(Number.parseInt(e.target.value as string))
                                                            }
                                                            }
                                                            variant="standard"
                                                        >
                                                            <MenuItem key={0} value={0}>Vehicle</MenuItem>
                                                            <MenuItem key={1} value={1}>Tyres</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <TextField
                                                        variant="standard"
                                                        label="Make"
                                                        InputLabelProps={genericLabelProps}
                                                        value={assetGeneratorMake}
                                                        onChange={(e) => setAssetGeneratorMake(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <TextField
                                                        variant="standard"
                                                        label="Model"
                                                        InputLabelProps={genericLabelProps}
                                                        value={assetGeneratorModel}
                                                        onChange={(e) => setAssetGeneratorModel(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        
                                            <Grid item container xs={12} md={6}>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <TextField
                                                        variant="standard"
                                                        label="Trim"
                                                        InputLabelProps={genericLabelProps}
                                                        value={assetGeneratorTrim}
                                                        onChange={(e) => setAssetGeneratorTrim(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <TextField
                                                        variant="standard"
                                                        label="Year or Version"
                                                        InputLabelProps={genericLabelProps}
                                                        value={assetGeneratorYearOrVersion}
                                                        onChange={(e) =>
                                                            setAssetGeneratorYearOrVersion(e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <TextField
                                                        variant="standard"
                                                        label="CO2 Saved Per Usage Unit"
                                                        InputLabelProps={genericLabelProps}
                                                        value={convertFromTonnesToGrams(assetGeneratorCo2Saved)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                g
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={(e) =>
                                                            setAssetGeneratorCo2Saved(convertFromGramsToTonnes(e.target.value) ?? "")
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item container xs={12} md={6}>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <TextField
                                                        variant="standard"
                                                        label="NOx Saved Per Usage Unit"
                                                        InputLabelProps={genericLabelProps}
                                                        value={convertFromTonnesToGrams(assetGeneratorNoxSaved)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                g
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={(e) =>
                                                            setAssetGeneratorNoxSaved(convertFromGramsToTonnes(e.target.value) ?? "")
                                                        }
                                                    />
                                                </Grid>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <TextField
                                                        variant="standard"
                                                        type="number"
                                                        label="Number to Create"
                                                        InputLabelProps={genericLabelProps}
                                                        value={assetGeneratorNumberToCreate}
                                                        onChange={(e) =>
                                                            setAssetGeneratorNumberToCreate(
                                                                e.target.value === '' ? undefined : Number(e.target.value)
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid xs={6} sm={4} md={4} item className={classes.modalContainer}>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => {
                                                            if(!assetGeneratorNumberToCreate) return;
                                                            const assetCopy = [...props.subscription.assets ?? []];
                                                            for (var i = 0; i < assetGeneratorNumberToCreate; i++)
                                                                assetCopy.push({
                                                                    index: i,
                                                                    assetType: assetGeneratorAssetType,
                                                                    make: assetGeneratorMake,
                                                                    model: assetGeneratorModel,
                                                                    trim: assetGeneratorTrim,
                                                                    yearOrVersion: assetGeneratorYearOrVersion,
                                                                    co2SavedPerUsageUnit: assetGeneratorCo2Saved,
                                                                    noxSavedPerUsageUnit: assetGeneratorNoxSaved                                                                    
                                                                } as unknown as SubscriptionAsset);
                                                            props.setAssets(assetCopy)
                                                            setNumberOfNewAssetsAdded(assetGeneratorNumberToCreate);
                                                        }}
                                                    >
                                                        Add new Asset
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} md={6}>
                                                <Typography style={{ paddingLeft: "1rem" }}>
                                                    {numberOfNewAssetsAdded > 0 ? "   " + numberOfNewAssetsAdded + " Created. These changes will not be saved into the database until you click the submit button at the top of the screen." : ""}
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </div>
                    </Modal>
                    <Grid xs={12} item style={{marginBottom: '20px'}}>
                        <Card>
                            <CardContent>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TableContainer component={Paper}>

                                        <Table className={classes.table} aria-label="simple table">
                                            <colgroup>
                                                <col style={{width:'15%'}}/>
                                                <col style={{width:'15%'}}/>
                                                <col style={{width:'10%'}}/>
                                                <col style={{width:'10%'}}/>
                                                <col style={{width:'10%'}}/>
                                                <col style={{width:'25%'}}/>
                                                <col style={{width:'10%'}}/>
                                                <col style={{width:'5%'}}/>
                                            </colgroup>
                                            <TableHead>
                                                <TableRow key={"HEADER_ROW"}>
                                                    <TableCell>VIN</TableCell>
                                                    <TableCell>License Plate</TableCell>
                                                    <TableCell>Vehicle Make</TableCell>
                                                    <TableCell>Collection Date</TableCell>
                                                    <TableCell>Install Booked Date</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Connected</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.assets?.map((row: SubscriptionAsset, index: number) => {

                                                    const matchingTelematicsInfo = roadVehicles?.find(x => x.vin === row.usageSourceId)
                                                    return (
                                                        <TableRow key={row.id ?? index}>
                                                            <TableCell component="th" scope="row">
                                                                <TextField
                                                                    style={{minWidth: "200px"}}
                                                                    variant="outlined"
                                                                    value={row.usageSourceId ?? ""}
                                                                    onChange={(event: any) => onPropertyChange(index, event, (currentAssets: SubscriptionAsset[], index: number, event: any) => currentAssets[index].usageSourceId = event.target.value)}/>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <TextField
                                                                    style={{minWidth: "100px"}}
                                                                    variant="outlined"
                                                                    value={row.alternativeId ?? ""}
                                                                    onChange={(event: any) => onPropertyChange(index, event, (currentAssets: SubscriptionAsset[], index: number, event: any) => currentAssets[index].alternativeId = event.target.value)}/>
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.make}
                                                            </TableCell>
                                                            <TableCell>
                                                                <DesktopDatePicker
                                                                    inputFormat="dd-MM-yyyy"
                                                                    value={row.collectionDate ?? ""}
                                                                    onChange={(value: any) => onPropertyChange(index, value, (currentAssets: SubscriptionAsset[], index: number, event: any) => currentAssets[index].collectionDate = event)}
                                                                    renderInput={(params) => 
                                                                        <TextField {...params} style={{minWidth: "160px"}} />}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <DesktopDatePicker
                                                                    inputFormat="dd-MM-yyyy"
                                                                    componentsProps={{
                                                                        actionBar: {
                                                                        actions: ['clear']
                                                                        }
                                                                    }}
                                                                    value={row.installDate ?? null}
                                                                    onChange={(value: any) => {
                                                                        var date: any = (value === null ? null : addHours(value, 3))
                                                                        onPropertyChange(index, value, (currentAssets: SubscriptionAsset[], index: number, event: any) => currentAssets[index].installDate = date)}
                                                                    }
                                                                    renderInput={(params) =>
                                                                        <TextField {...params} style={{minWidth: "160px"}} />}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {ConvertSubscriptionAssetStatusToString(row.status)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {roadVehiclesLoading ? 
                                                                "Loading"
                                                                :
                                                                matchingTelematicsInfo && row.usageSourceId ? <CheckIcon/> : <></>}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    onClick={() => handleEditAssetButtonClick(row, index)}
                                                                    style={{paddingLeft: "25px"}}
                                                                    variant="contained"
                                                                    color={mandatoryFieldComplete(row) ? "primary" : "warning"}
                                                                    startIcon={mandatoryFieldComplete(row) ?
                                                                        <EditIcon/>:
                                                                        <Tooltip title={"Please complete the mandatory fields of VIN and License Plate"}>
                                                                            <PriorityHighIcon/>
                                                                        </Tooltip>}></Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </LocalizationProvider>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export {AssetsTab}
