import { Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { ConvertCurrencyToSymbol } from "../../helpers/ConvertCurrencyToSymbol";
import { GetPaymentStatusTextColor } from "../../helpers/GetPaymentStatusTextColor";
import { Payment } from "../../models/Payment"

interface AllPaymentsTableProps {
    payments: Payment[];
    onCancelClick: Function;
  }

const AllPaymentsTable: React.FC<AllPaymentsTableProps> = props => {
    const theme = useTheme()
    const columns: any[] = [
        { field: 'assetOperatorName', headerName: 'Asset Operator Name', width: 200 },
        { field: 'subscriptionId', headerName: 'Subscription Id', width: 200 },
        { field: 'targetDate', headerName: 'Target Date', width: 130, filterable: false, renderCell: (params: any): string => {
            return params.row.targetDate == null ? '' : new Date(params.row.targetDate).toLocaleDateString()
          } },
        { field: 'currency', headerName: 'Currency', width: 130 },
        { field: 'paymentAmount', headerName: 'Amount', width: 130, renderCell: (params: any): string => {
            return params.row.currencyIsoCode == null ? '' : ConvertCurrencyToSymbol(params.row.currencyIsoCode) + params.row.paymentAmount?.toLocaleString()
          }, },
        { field: 'paymentType', headerName: 'Type', width: 160 },
        { field: 'paymentStatus', headerName: 'Status', width: 200, renderCell: (params: any): JSX.Element => {
            return (<Typography style={{fontSize: 14, color: GetPaymentStatusTextColor(params.row.paymentStatus, theme)}}>{params.row.paymentStatus}</Typography>)
        } },
        { field: 'arrearsManagement', headerName: 'Arrears Management', width: 180 },
        {
          field: 'detailsButton', headerName: ' ', width: '200', renderCell: (params: any): JSX.Element => {
              let toDetails = `/payments/details/${params.row.paymentId}`
              return (<>
                  <Button
                      component={Link}
                      to={toDetails}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginRight: 16 }}
                  >
                      Details
                  </Button>
                  {params.row.paymentStatus === "Queued" ?
                  <Button
                      onClick={() => props.onCancelClick(params)}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginRight: 16 }}
                  >
                  Cancel
                  </Button>
                  :
              <></>
              }
              </>
              )
          },
      }
      ];

return (
    <div style={{ height: '63vh', width: '100%' }}>
        <DataGrid
            rows={props.payments}
            columns={columns}
            rowsPerPageOptions={[10,50,100]}
            initialState={{
                pagination:{
                    pageSize: 10
                }
            }}
        />
    </div>
    )
}
export { AllPaymentsTable }