import {PaymentStatus} from "../models/PaymentStatus";

function ConvertPaymentStatusToString(paymentStatus: PaymentStatus|undefined): string {
    switch (paymentStatus) {
        case PaymentStatus.Failed:
            return "Failed";
        case PaymentStatus.Cancelled:
            return "Cancelled";
        case PaymentStatus.Pending:
            return "Submitted";
        case PaymentStatus.Complete:
            return "Successfully Completed";
        case PaymentStatus.Queued:
            return "Queued";
        case PaymentStatus.FailedAndRetried:
            return "Failed - retried";
        default:
            return "";
    }
}

function ConvertStringToPaymentStatus(paymentStatus: string) : PaymentStatus {
    switch (paymentStatus) {
        case "Failed":
            return PaymentStatus.Failed;
        case "Cancelled":
            return PaymentStatus.Cancelled;
        case "Submitted":
            return PaymentStatus.Pending;
        case "Successfully Completed":
            return PaymentStatus.Complete;
        case "Queued":
            return PaymentStatus.Queued;
        case "Failed - retried":
            return PaymentStatus.FailedAndRetried;
        default:
            throw new Error("Cannot convert payment string [" + paymentStatus + "] into PaymentStatus, invalid value");
    }
}

export { ConvertPaymentStatusToString, ConvertStringToPaymentStatus };