import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    Theme,
    Typography
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { getInvestors } from "../../services/InvestorService";
import { InvestmentManagementFeePayment } from "../../models/InvestmentManagementFeePayment";
import { PaymentStatus } from "../../models/PaymentStatus";
import { ConvertPaymentStatusToString } from "../../helpers/ConvertPaymentStatusToString";
import { CountdownToNextPaymentSubmission } from "../../components/CountdownToNextPaymentSubmission";
import { InvestmentManagementFeePaymentsToActionTable } from "../../components/Payments/InvestmentManagementFeePaymentsToActionTable";
import { archiveFailedInvestmentManagementFeePayment, cancelInvestmentManagementFeePayment, getAllInvestorFeePayments, retryFailedInvestmentManagementFeePayment } from "../../services/InvestmentManagementFeePaymentService";
import { makeStyles, createStyles } from "@mui/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))

const InvestmentManagementFeePaymentsToActionPage: React.FC = props => {
    const [mappedPayments, setMappedPayments] = useState<any[]>([])
    const [feedbackMessage, setFeedbackMessage] = useState<string>()
    const [cancelFeedbackColor, setFeedbackMessageColor] = useState<string>()
    const [paymentsFetchCompleted, setPaymentsFetchCompleted] = useState<boolean>(false)

    const classes = useStyles()
    useEffect(() => {
        let mounted = true;
        let alteredPayments: InvestmentManagementFeePayment[] = []
        getAllInvestorFeePayments(true)
            .then(investorFeePayments => {
                getInvestors()
                    .then(investors =>
                        investorFeePayments.map(
                            (payment) => {
                                let actualInvestor = investors.find(investor => investor.id === payment.investorId)
                                payment.investorName = actualInvestor == null ? '' : actualInvestor.name
                                return alteredPayments.push(payment)
                            }))
                    .then(() => {
                        if (mounted) {
                            var mappedPayments = mapPaymentModelsToDataTableRows(alteredPayments)
                            setMappedPayments(mappedPayments)
                            setPaymentsFetchCompleted(true)
                        }
                    })
            })

        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onCancelClick(params: any, theme: Theme): Promise<void> {
        let payment = params.row;
        let response = await cancelInvestmentManagementFeePayment(payment.paymentId);
        let paymentName = (payment.investorName === '' ? payment.investorId : payment.investorName)
        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully cancelled')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: InvestmentManagementFeePayment[] = []
            getAllInvestorFeePayments()
                .then(payments => {
                    getInvestors()
                        .then(investors =>
                            payments.map(
                                (payment) => {
                                    let actualInvestor = investors.find(investor => investor.id === payment.investorId)
                                    payment.investorName = actualInvestor == null ? '' : actualInvestor.name
                                    return alteredPayments.push(payment)
                                }))
                        .then(() => {
                            var mappedPayments = mapPaymentModelsToDataTableRows(alteredPayments.filter(payment => payment.status === PaymentStatus.Queued || (payment.status === PaymentStatus.Failed && payment.isArchived !== true)))
                            setMappedPayments(mappedPayments)
                        })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to cancel')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    async function onArchiveClick(params: any, theme: Theme): Promise<void> {
        let payment = params.row;
        let response = await archiveFailedInvestmentManagementFeePayment(payment.paymentId);
        let paymentName = (payment.investorName === '' ? payment.investorId : payment.investorName)
        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully archived')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: InvestmentManagementFeePayment[] = []
            getAllInvestorFeePayments()
                .then(payments => {
                    getInvestors()
                        .then(investors =>
                            payments.map(
                                (payment) => {
                                    let actualInvestor = investors.find(investor => investor.id === payment.investorId)
                                    payment.investorName = actualInvestor == null ? '' : actualInvestor.name
                                    return alteredPayments.push(payment)
                                }))
                        .then(() => {
                            var mappedPayments = mapPaymentModelsToDataTableRows(alteredPayments.filter(payment => payment.status === PaymentStatus.Queued || (payment.status === PaymentStatus.Failed && payment.isArchived !== true)))
                            setMappedPayments(mappedPayments)
                        })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to archive')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    async function onRetryClick(params: any, theme: Theme): Promise<void> {
        let payment = params.row;
        let response = await retryFailedInvestmentManagementFeePayment(payment.paymentId);
        let paymentName = (payment.investorName === '' ? payment.investorId : payment.investorName)
        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully retried')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: InvestmentManagementFeePayment[] = []
            getAllInvestorFeePayments()
                .then(payments => {
                    getInvestors()
                        .then(investors =>
                            payments.map(
                                (payment) => {
                                    let actualInvestor = investors.find(investor => investor.id === payment.investorId)
                                    payment.investorName = actualInvestor == null ? '' : actualInvestor.name
                                    return alteredPayments.push(payment)
                                }))
                        .then(() => {
                            var mappedPayments = mapPaymentModelsToDataTableRows(alteredPayments.filter(payment => payment.status === PaymentStatus.Queued || (payment.status === PaymentStatus.Failed && payment.isArchived !== true)))
                            setMappedPayments(mappedPayments)
                        })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to retry')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    function mapPaymentModelsToDataTableRows(payments: InvestmentManagementFeePayment[]): any[] {
        return payments.map((payment, i) => {
            return {
                investorName: payment?.investorName,
                id: i,
                investorId: payment?.investorId,
                currency: payment?.currencyIsoCode,
                paymentAmount: payment?.amountCharged,
                paymentType: payment?.paymentType,
                paymentStatus: ConvertPaymentStatusToString(payment?.status),
                paymentId: payment?.id,
                currencyIsoCode: payment?.currencyIsoCode,
                arrearsManagement: payment?.previouslyFailedAttempts?.length > 0 ? "Retry attempt: " + payment?.previouslyFailedAttempts?.length : ''
            }
        });
    }

    return (
        <Card>
            <CardHeader title="Lender Fee Payments To Action" />
            <CardContent style={{ height: '65vh' }}>
                {
                    mappedPayments.length > 0
                        ?
                        (
                            <InvestmentManagementFeePaymentsToActionTable payments={mappedPayments} onCancelClick={onCancelClick} onArchiveClick={onArchiveClick} onRetryClick={onRetryClick} />
                        )
                        :
                        paymentsFetchCompleted ?
                            (
                                <Typography variant="subtitle1" gutterBottom>No payments to action!</Typography>
                            )
                            :
                            (
                                <div className={classes.loader}>
                                    <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                    <LinearProgress color="secondary" />
                                </div>
                            )
                }
            </CardContent>
            <CardActions>
                <CountdownToNextPaymentSubmission />
                <Typography style={{ marginLeft: 'auto', color: cancelFeedbackColor }} variant="subtitle1">{feedbackMessage}</Typography>
            </CardActions>
        </Card>
    )
}

export { InvestmentManagementFeePaymentsToActionPage }