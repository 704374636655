import React, {useEffect, useState} from "react";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {getAssets, getTelematicsDataForVins} from "../services/AssetsService";
import {EnrichedAsset} from "../models/EnrichedAsset";
import {RoadVehicle} from "../models/RoadVehicle";
import {
    DataGrid, GridColDef, GridSortItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton
} from "@mui/x-data-grid";
import {format} from "date-fns";
import {roundToXDecimalPlaces} from "../helpers/roundToXDecimalPlaces";

const OdometerReadingsPage: React.FC = () => {
    const [sortModel, setSortModel] = useState<GridSortItem[]>([{field: 'mileage', sort: 'desc'}]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [assets, setAssets] = useState<EnrichedAsset[]>([]);
    const [telematicsInfo, setTelematicsInfo] = useState<RoadVehicle[]>([]);

    const dateFormat = "dd/MM/yy HH:mm:ss";

    const rows: any[] = assets?.map((asset) => {
        const matchingTelematics = telematicsInfo.filter(vehicle => vehicle.vin === asset.usageSourceId)[0];
        let lastConnectedDate = null;
        if (matchingTelematics?.state?.gpsLocation?.lastUpdated != null) lastConnectedDate = new Date(matchingTelematics?.state?.gpsLocation?.lastUpdated)
        if (matchingTelematics?.state?.mileageLastUpdated != null) lastConnectedDate = new Date(matchingTelematics?.state?.mileageLastUpdated)
        debugger
        return {
            numberPlate: asset.alternativeId,
            id: asset.usageSourceId,
            mileage: roundToXDecimalPlaces(matchingTelematics?.state?.mileage, 0),
            lastConnected: lastConnectedDate,
            source: "Telematics"
        }
    })

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerClassName: 'zeti-column-headers',
            headerName: 'VIN',
            description: "Vehicle Identification Number",
            flex: 1
        },
        {
            field: 'numberPlate',
            headerClassName: 'zeti-column-headers',
            headerName: 'License Plate',
            width: 150
        },
        {
            field: 'mileage',
            headerClassName: 'zeti-column-headers',
            headerName: 'Odometer',
            flex: 1,
            type: 'number',
            renderCell: (params: any): JSX.Element => {
                return (<Typography
                    fontSize={14}
                    style={{
                        width: '100%',
                        textAlign: 'right'
                    }}>{(params.row.mileage as number) === -1 ? "-" : (params.row.mileage as number)?.toLocaleString(["en-gb"], {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                })}</Typography>)
            }
        },
        {
            field: 'lastConnected',
            headerClassName: 'zeti-column-headers',
            headerName: 'Last Read',
            type: 'date',
            flex: 1,

            renderCell: (params: any): JSX.Element => {
                return (<Typography
                    fontSize={14}>{params.row.lastConnected ? format(params.row.lastConnected, dateFormat) : ""}</Typography>)
            }
        },
        {
            field: 'source',
            headerClassName: 'zeti-column-headers',
            headerName: 'Source',
            flex: 1,
            renderCell: (params: any): JSX.Element => {
                return (<Typography
                    fontSize={14}>{params.row.lastConnected && params.row.mileage ? params.row.source : ""}</Typography>)
            }
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <GridToolbarExport csvOptions={{allColumns: true}} printOptions={{allColumns: true}}/>
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setIsLoading(true);
            getAssets().then(assetsReturned => {
                const assetsWithVins = assetsReturned.filter(asset => asset.usageSourceId != null);
                const nonArchivedAssetsWithVins = assetsWithVins.filter(asset => !asset.isArchived);
                getTelematicsDataForVins(nonArchivedAssetsWithVins.map((asset) => asset.usageSourceId)).then((telematics) => {
                    setAssets(nonArchivedAssetsWithVins)
                    setTelematicsInfo(telematics)
                    setIsLoading(false);
                })
            });
        }

        return () => {
            mounted = false;
        };
    }, []);

    return (
        <Card>
            <CardHeader title={"Odometer Readings"}/>
            <CardContent>
                <DataGrid
                    key="asset-list"
                    autoHeight
                    columns={columns}
                    rows={rows}
                    style={{minHeight: '58vh'}}
                    components={{Toolbar: CustomToolbar}}
                    sortModel={sortModel}
                    onSortModelChange={(model: any) => setSortModel(model.sortModel)}
                    loading={isLoading}
                />
            </CardContent>
        </Card>
    )
}

export {OdometerReadingsPage};
