import { AnalyticsBrowser } from '@june-so/analytics-next';
let analytics: AnalyticsBrowser | undefined = undefined;
function june(juneWriteKey?: string): AnalyticsBrowser | undefined {
    if (juneWriteKey) {
        analytics = AnalyticsBrowser.load({
            writeKey: juneWriteKey,
        });
    }
    return analytics;
}

const getJuneWriteKey = async () => {
    const path = `/api/junewritekey`
    const options = { method: "GET" }
    const response = await fetch(path, options)
    const juneWriteKeyObject: any = await response.json()
    return juneWriteKeyObject.juneWriteKey;
}

export { june, getJuneWriteKey }