import { NoteAttachment } from "../models/NoteAttachment";
import { getSettings } from "./SettingsService";

const uploadNoteAttachmentFile = async (noteAttachment: NoteAttachment): Promise<string> => {
    if(!noteAttachment.file) return "Error: no file found for attachment";

    const formData = new FormData();
    var attachmentFile = noteAttachment.file;
    formData.append('noteAttachment', attachmentFile as File);

    const settings = await getSettings()

    const uploadUrl = `${settings.BillingServiceBaseUri}/uploadNoteAttachmentFile`;

    const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData
    })
    if(response.ok){
        const responseObject = await response.text()
        return responseObject
    } else{
        return "Error uploading attachment: note not saved, please try again";
    }
}

export { uploadNoteAttachmentFile }