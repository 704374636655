import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { ResidualValueMatrix } from "../models/ResidualValueMatrix";
import { useState } from "react";
import { uploadAndSaveResidualValueMatrix } from "../services/ResidualValueMatrixService";
import { SubscriptionViewModel } from "../models/SubscriptionViewModel";
import { makeStyles,createStyles } from '@mui/styles';
import parseISO from "date-fns/parseISO";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filler: {
            width: '100%',
            height: '100%'
        }
    }),
);

interface UploadResidualValueMatricesProps {
    subscription: SubscriptionViewModel
}

enum Status {
    NotSubmittedYet, WaitingForResponse, SuccessfulResponse, FailureResponse
}

function SubscriptionComponent5({ subscription }: UploadResidualValueMatricesProps) {
    const [localResidualValueMatrix, setLocalResidualValueMatrix] = useState<ResidualValueMatrix>({ guaranteed: false, make: '', model: '', trim: '', yearOrVersion: '' } as ResidualValueMatrix)
    const [files, setFiles] = useState<File[]>([])
    const [status, setStatus] = useState<Status>(Status.NotSubmittedYet)
    const classes = useStyles();
    const displayBody = () => {
        switch (status) {
            case Status.NotSubmittedYet:
                return (
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                            <Grid container spacing={3} item>
                                <Grid item xs={3}>
                                    <TextField
                                        value={localResidualValueMatrix.make}
                                        onChange={(event) => setLocalResidualValueMatrix({ ...localResidualValueMatrix, make: event.target.value as string })}
                                        label="Make"
                                        variant="outlined"
                                        className={classes.filler}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        value={localResidualValueMatrix.model}
                                        onChange={(event) => setLocalResidualValueMatrix({ ...localResidualValueMatrix, model: event.target.value as string })}
                                        label="Model"
                                        variant="outlined"
                                        className={classes.filler}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>

                                    <TextField
                                        value={localResidualValueMatrix.trim}
                                        onChange={(event) => setLocalResidualValueMatrix({ ...localResidualValueMatrix, trim: event.target.value as string })}
                                        label="Trim"
                                        variant="outlined"
                                        className={classes.filler}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        value={localResidualValueMatrix.yearOrVersion}
                                        onChange={(event) => setLocalResidualValueMatrix({ ...localResidualValueMatrix, yearOrVersion: event.target.value as string })}
                                        label="Year/Version"
                                        variant="outlined"
                                        className={classes.filler}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        value={localResidualValueMatrix.sourceCompany}
                                        onChange={(event) => setLocalResidualValueMatrix({ ...localResidualValueMatrix, sourceCompany: event.target.value as string })}
                                        label="Source Company"
                                        variant="outlined"
                                        className={classes.filler}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={localResidualValueMatrix.guaranteed}
                                                onChange={(event) => setLocalResidualValueMatrix({ ...localResidualValueMatrix, guaranteed: event.target.checked })}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Guaranteed?"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {localResidualValueMatrix.guaranteed && (
                                        <TextField
                                            style={{ width: '100%', height: '100%' }}
                                            value={localResidualValueMatrix.guaranteeCompany}
                                            onChange={(event) => setLocalResidualValueMatrix({ ...localResidualValueMatrix, guaranteeCompany: event.target.value as string })}
                                            label="Company providing Guarantee"
                                            variant="outlined"
                                            className={classes.filler}
                                        >
                                        </TextField>)}
                                </Grid>
                                <Grid item xs={3}>
                                    <Tooltip title="The format you should use for this is a .csv with the topleft cell containing a 0, then age in months across the top row and *total* mileage down the first column, below the 0. Please do not format the cells to contain currency symbols.">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            component="label"
                                        >
                                            Upload CSV File
                                            <input
                                                onChange={event => setFiles(Array.from(event.target.files ?? []))}
                                                type="file"
                                                accept="text/csv"
                                                hidden
                                            />
                                        </Button>
                                    </Tooltip>
                                    <div style={{ overflow: 'hidden', width: '100%' }}>
                                        <Typography variant="caption" style={{ textOverflow: "ellipsis", overflow: "hidden", width: '50px', whiteSpace: 'nowrap' }}>
                                            {
                                                files.map(file => file.name).join(", ")
                                            }
                                        </Typography>
                                    </div>

                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={(event) => { uploadAndSaveResidualValueMatrix(subscription.id, localResidualValueMatrix, files[0]).then(response => setStatus(response.ok ? Status.SuccessfulResponse : Status.FailureResponse)); setStatus(Status.WaitingForResponse) }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </FormControl>
                )
            case Status.WaitingForResponse:
                return (
                    <Box height={300} style={{ width: '100%' }}>
                        <CircularProgress style={{ position: 'relative', top: '50%', left: '50%' }} />
                    </Box>
                )
            case Status.SuccessfulResponse:
                return (
                    <Box height={300} style={{ width: '100%' }}>
                        <Typography><br /><br />That was successful, would you like to upload another?</Typography><Button variant="contained" color="primary" onClick={() => setStatus(Status.NotSubmittedYet)}>Upload another</Button>
                    </Box>
                )
            case Status.FailureResponse:
                return (
                    <Box height={300} style={{ width: '100%' }}>
                        <Typography><br /><br />That did not succeed, would you like to try again?</Typography><Button variant="contained" color="primary" onClick={() => setStatus(Status.NotSubmittedYet)}>Try again</Button>
                    </Box>
                )

        }
    }
    const tableDisplay = (title: string, rows: ResidualValueMatrix[]) => (
        <>
            <Typography variant="subtitle1" gutterBottom style={{marginTop: '1rem'}}>
                {title}
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Asset Type</TableCell>
                            <TableCell align="right">Source Company</TableCell>
                            <TableCell align="right">Guarantee</TableCell>
                            <TableCell align="right">Active</TableCell>
                            <TableCell align="right">Uploaded At</TableCell>
                            <TableCell align="right">Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.sort((a, b) => (Number(parseISO(b.uploadedAt)) - Number(parseISO(a.uploadedAt))))?.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.make} {row.model} {row.trim} {row.yearOrVersion}
                                </TableCell>
                                <TableCell align="right">{row.sourceCompany}</TableCell>
                                <TableCell align="right">{row.guaranteed ? "Yes" : "No"}{row.guaranteed ? ", by " + row.guaranteeCompany : ""}</TableCell>
                                <TableCell align="right">{row.active ? "Yes" : "No"}</TableCell>
                                <TableCell align="right">{parseISO(row.uploadedAt).toLocaleString()}</TableCell>
                                <TableCell align="right"><Button variant="contained" color="primary" href={"/api/subscriptions/" + subscription.id + "/residualvaluematrices/" + row.id}>Download</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
    return (
        <Grid container sm={12} md={12} spacing={0} item>
            <Typography variant="subtitle1">Upload Residual Value Matrices</Typography>
            {
                displayBody()
            }
            {
                tableDisplay("Active Residual Value Matrices", subscription.residualValueMatrices?.filter(x => x.active) ?? [])
            }
            {
                tableDisplay("Archived Residual Value Matrices", subscription.residualValueMatrices?.filter(x => !x.active) ?? [])
            }
        </Grid>
    );
}

export { SubscriptionComponent5 };
