import { useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, FormControl, Grid, IconButton, MenuItem, Modal, Select, TextField, Theme, Typography } from "@mui/material";
import { genericLabelProps } from "../props/GenericLabelProps";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import { addCustomerEmailToDemoAccount } from "../services/DemoFleetServices";
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            border: '2px solid #000',
            borderRadius: "10px",
            boxShadow: theme.shadows[5],
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        dropDownLabel: {
            fontSize: "1.2rem",
            paddingBottom: "0.5rem",
        }
    }))

type AddNewCustomerEmailToDemoModalProps = {
    addNewCustomerModalOpen: boolean
    setAddNewCustomerModalOpen: Function
    feedbackMessage: string
    setFeedbackMessage: Function
}


function AddNewCustomerEmailToDemoModal({ addNewCustomerModalOpen, setAddNewCustomerModalOpen, feedbackMessage, setFeedbackMessage }: AddNewCustomerEmailToDemoModalProps): JSX.Element {
    const [newCustomerEmail, setNewCustomerEmail] = useState<string>("");
    const [newCustomerPortalAccess, setNewCustomerPortalAccess] = useState<string>("");
    let thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
    let thirtyDaysFromNowMidnight = (new Date(thirtyDaysFromNow.getFullYear(), thirtyDaysFromNow.getMonth(), thirtyDaysFromNow.getDate(), 23, 59, 59, 0))
    const [newCustomerAccessExpiryDate, setNewCustomerAccessExpiryDate] = useState<Date>(thirtyDaysFromNowMidnight);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const classes = useStyles();

    async function confirmAddNewCustomer() {
        setIsLoading(true);

        var subName = '';
        if(newCustomerPortalAccess === "UK_HGV"){
            subName = "SUBUKDEMOHGV";
        } else {
            subName = "SUB" + newCustomerPortalAccess + "DEMO";
        }
        const confirmationMessage = await addCustomerEmailToDemoAccount(subName, newCustomerEmail, newCustomerAccessExpiryDate);

        setIsLoading(false);

        if (confirmationMessage === "COMPLETED") {
            setAddNewCustomerModalOpen(false);
        }

        setFeedbackMessage(confirmationMessage);
    }

    function handleAddNewCustomerModalClose() {
        setNewCustomerEmail("");
        setNewCustomerPortalAccess("");
        setNewCustomerAccessExpiryDate(thirtyDaysFromNowMidnight);
        setAddNewCustomerModalOpen(false);
    }

    const handleExpiryDateChange = (value: unknown) => {
        let date = value as Date | null;
        if (date != null) {
            const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0);
            setNewCustomerAccessExpiryDate(adjustedDate);
        }
    };

    return (
        <Modal open={addNewCustomerModalOpen}
            onClose={handleAddNewCustomerModalClose}>
            <div className={classes.modal}>
                <Card>
                    <CardHeader
                        action={
                            <IconButton
                                color="primary"
                                onClick={handleAddNewCustomerModalClose}>
                                <CloseIcon />
                            </IconButton>}
                        title="Add New Customer Email To Demo Account" />

                    <CardContent>
                        <Grid container style={{ "rowGap": 30 }}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Enter Customer Email</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField style={{ width: "100%" }}
                                    variant="standard"
                                    InputLabelProps={genericLabelProps}
                                    onChange={(e) => setNewCustomerEmail(e.target.value)}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Portal Access</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>

                                    <Select
                                        inputProps={{
                                            name: "Portal Access",
                                        }}
                                        onChange={(e) => {
                                            setNewCustomerPortalAccess(e.target.value as string)
                                        }
                                        }
                                    >
                                        <MenuItem key={0} value={"UK"}>UK</MenuItem>
                                        <MenuItem key={0} value={"UK_HGV"}>UK HGV</MenuItem>
                                        <MenuItem key={1} value={"US"}>US</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Expiry Date</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Demo access expiry date (default 30 days)"
                                        inputFormat="dd/MM/yyyy"
                                        value={newCustomerAccessExpiryDate}
                                        onChange={handleExpiryDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                    </CardContent>
                    <CardActions>
                        <Typography variant="subtitle1">{feedbackMessage}</Typography>
                        <LoadingButton
                            style={{ marginLeft: 'auto' }}
                            variant="contained"
                            startIcon={<SendIcon style={{ "paddingRight": "10px" }} />}
                            color="primary"
                            onClick={confirmAddNewCustomer}
                            loading={isLoading}>
                            CONFIRM
                        </LoadingButton>
                    </CardActions>
                </Card>
            </div>
        </Modal>);
}

export { AddNewCustomerEmailToDemoModal }
