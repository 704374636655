import { Button } from "@mui/material"
import { useTheme } from "@mui/styles"
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"
import { clarityFunc } from "../helpers/Clarity"

function CookieBanner() {
  const theme = useTheme()
  return (
    getCookieConsentValue()
      ?
      <></>
      :
      <CookieConsent
        enableDeclineButton
        flipButtons
        buttonText="Accept All Cookies"
        declineButtonText="Accept Only Necessary Cookies"
        ButtonComponent={Button}
        onAccept={clarityFunc}
        customButtonProps={{
          style: {
            marginRight: '10px',
            marginTop: '10px'
          },
          variant: 'outlined'
        }}
        customDeclineButtonProps={{
          style: {
            marginRight: '10px',
            marginTop: '10px'
          },
          variant: 'outlined',
          color: 'secondary'
        }}
        style={{
          maxWidth: '1300px',
          borderRadius: '10px 10px 0px 0px',
          boxShadow: "0px 0px 20px " + theme.palette.background.default,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          marginLeft: '-650px',
          left: '50%',
          right: '0'
        }}
        disableButtonStyles
      >
        We use additional cookies to enable us to improve our product and your experience, in addition to necessary cookies that are required to power the portal. Please read our <a rel="noreferrer" target="_blank" href="https://zeti.group/privacy-and-cookies/">Privacy Policy</a> for more information.
      </CookieConsent>
  )
}

export { CookieBanner }