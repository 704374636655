import { Card, CardHeader, CardContent, Grid, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button, Link, Tooltip, IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from "react";
import { SubscriptionAsset } from "../models/SubscriptionAsset";
import { SubscriptionViewModel } from "../models/SubscriptionViewModel";
import { bulkUploadAssetsWithFile } from "../services/AssetsService";
import { DealershipAsset } from "../models/DealershipAsset";
import CloseIcon from '@mui/icons-material/Close';
import {convertFromGramsToTonnes} from "../helpers/ConvertTonnesAndGrams";

type AddVehicleDetailsProps = {
    subscription: SubscriptionViewModel,
    setAssets: Function,
    handleAddVehicleDetailsModalClose: Function
}

type AssetDetailsAndCount = {
    assetDetails: string
    count: number
}

enum FileUploadState {
    NotUploaded, UploadFailed, UploadSucceeded, Pending
}

function AddVehicleDetails({ subscription, setAssets, handleAddVehicleDetailsModalClose }: AddVehicleDetailsProps): JSX.Element {
    const [assetDetailTypes, setAssetDetailTypes] = useState<AssetDetailsAndCount[]>([])
    const [selectedAssetDetailType, setSelectedAssetDetailType] = useState<string>('')
    const [message, setMessage] = useState<string>("")
    const [fileUploadState, setFileUploadState] = useState<FileUploadState>(FileUploadState.NotUploaded)
    const [files, setFiles] = useState<File[]>([])

    useEffect(() => {
        refreshAssetDetailTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription])

    const refreshAssetDetailTypes = () => {
        const uniqueAssetDetailTypes = findUniqueAssetDetailTypes(subscription)
        setAssetDetailTypes(uniqueAssetDetailTypes)
    }
    
    const findUniqueAssetDetailTypes = (sub: SubscriptionViewModel) => {
        const uniqueAssetDetailTypes: AssetDetailsAndCount[] = [];
        const emptyAssetSlots = sub.assets.filter(asset => !asset.alternativeId && !asset.usageSourceId && !asset.collectionDate)
        for (const asset of emptyAssetSlots) {
            const assetDetailsString = assetDetailsToUniqueString(asset)
            const matchingAssetTypeIndex = uniqueAssetDetailTypes.findIndex(x => x.assetDetails === assetDetailsString)
            if (matchingAssetTypeIndex > -1) {
                uniqueAssetDetailTypes[matchingAssetTypeIndex].count++;
            } else {
                uniqueAssetDetailTypes.push({
                    assetDetails: assetDetailsString,
                    count: 1
                })
            }
        }
        return uniqueAssetDetailTypes
    }

    const assetDetailsToUniqueString = (asset: SubscriptionAsset) => {
        return `Type: ${asset.assetType}, Make: ${asset.make}, Model: ${asset.model}, Trim: ${asset.trim}, YearOrVersion: ${asset.yearOrVersion}, Co2SavedPerUsageUnit: ${asset.co2SavedPerUsageUnit}, NoxSavedPerUsageUnit: ${asset.noxSavedPerUsageUnit}`
    }

    const formatAssetDetailsForDropdown = (asset: AssetDetailsAndCount) => {
        const indexOfMake = asset.assetDetails.indexOf("Make");
        const indexOfCo2 = asset.assetDetails.indexOf("Co2Saved");
        const substring = asset.assetDetails.substring(indexOfMake, indexOfCo2 - 2)
        return `(${asset.count}) ${substring}`
    }

    const handleSelectedAssetDetailTypeChange = (event: SelectChangeEvent) => {
        setSelectedAssetDetailType(event.target.value as string);
    };

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files = Array.from(e.target.files ?? [])
        setFiles([...files])
    }

    const handleSubmitButtonClicked = async () => {
        try {
            setFileUploadState(FileUploadState.Pending)
            const response = await bulkUploadAssetsWithFile(files)
            const responseBody: { validationErrorMessage: string, dealershipAssets: DealershipAsset[] } = await response.json();
            
            if (response.ok) {
                const numberOfDealershipAssets = responseBody.dealershipAssets.length;
                const numberOfSelectedAssets = assetDetailTypes.filter(assetDetailsAndCount => assetDetailsAndCount.assetDetails === selectedAssetDetailType)[0].count
                if (numberOfDealershipAssets <= numberOfSelectedAssets) {
                    updateSubscriptionAssets(responseBody.dealershipAssets)
                    setFileUploadState(FileUploadState.UploadSucceeded)
                    setMessage("Vehicles uploaded. These changes will not be saved into the database until you click the submit button at the top of the screen.")
                } else {
                    setFileUploadState(FileUploadState.UploadFailed)
                    setMessage(`You are attempting to upload ${numberOfDealershipAssets} asset(s) into ${numberOfSelectedAssets} asset slot(s). Please either add more asset slots with the same details, or upload a new file with fewer assets.`)
                }
            } else {
                const validationErrorMessage = responseBody.validationErrorMessage;
                setFileUploadState(FileUploadState.UploadFailed);
                setMessage(validationErrorMessage ? validationErrorMessage : "Sorry, that didn't work. Please try again later or contact our team.")
            }
        } catch (e: any) {
            setFileUploadState(FileUploadState.UploadFailed);
            setMessage("Sorry, that didn't work. Please try again later or contact our team.")
        }
    }

    const uploadFileButton = () => {
        switch (fileUploadState) {
            case FileUploadState.NotUploaded: {
                return (
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        onClick={handleSubmitButtonClicked}
                        disabled={selectedAssetDetailType === ""}
                    >
                        Submit Files
                    </Button>
                )
            }
            case FileUploadState.Pending: {
                return (
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        disabled={true}
                    >
                        Pending
                    </Button>
                )
            }
            case FileUploadState.UploadFailed: {
                return (
                    <>
                        <Button
                            variant="contained"
                            component="label"
                            color="error"
                            onClick={handleSubmitButtonClicked}
                        >
                            Submit Files
                        </Button>
                        <Typography>{message.split("\n").map(chunk => (<span>{chunk}<br /></span>))}</Typography>
                    </>
                )
            }
            case FileUploadState.UploadSucceeded: {
                return (
                    <>
                        <Button
                            variant="contained"
                            component="label"
                            color="success"
                            onClick={handleSubmitButtonClicked}
                        >
                            Success!
                        </Button>
                        <Typography>{message.split("\n").map(chunk => (<span>{chunk}<br /></span>))}</Typography>
                    </>
                )
            }
        }
    }

    const updateSubscriptionAssets = (dealershipAssets: DealershipAsset[]) => {
        for (const dealershipAsset of dealershipAssets) {
            const firstFreeAssetSlot = subscription.assets.find(asset => assetDetailsToUniqueString(asset) === selectedAssetDetailType && !asset.alternativeId && !asset.usageSourceId && !asset.collectionDate)
            if (firstFreeAssetSlot) {                
                firstFreeAssetSlot.id = dealershipAsset.vin
                firstFreeAssetSlot.usageSourceId = dealershipAsset.vin
                firstFreeAssetSlot.alternativeId = dealershipAsset.licensePlate
                firstFreeAssetSlot.collectionDate = dealershipAsset.collectionDate
                firstFreeAssetSlot.installDate = dealershipAsset.installDate                
                firstFreeAssetSlot.co2SavedPerUsageUnit = convertFromGramsToTonnes(dealershipAsset.co2)
                firstFreeAssetSlot.noxSavedPerUsageUnit = convertFromGramsToTonnes(dealershipAsset.noX)                
            }
        }
        setAssets(subscription.assets)
        refreshAssetDetailTypes()
    }

    return (
        <Card>
            <Grid container direction="row" justifyContent="space-between">
                <CardHeader title="Upload VINs and License Plates" />
                <IconButton>
                    <CloseIcon onClick={() => handleAddVehicleDetailsModalClose()} />
                </IconButton>
            </Grid>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography>Your files have to be in a certain format for us to be able to read them. Please download an example <Link download="Example.xlsx" href="/example-files/Example.xlsx">here</Link>.</Typography>
                    </Grid>
                    <Grid item container direction="row">
                        {assetDetailTypes.length === 0 && <Grid item xs={1}>
                            <Tooltip title="Currently there are no empty asset slots to upload information into. Please first use the 'Add New Assets' feature to add new slots.">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>}
                        <Grid item xs={11}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Asset Details</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedAssetDetailType}
                                    label="Asset Details"
                                    onChange={handleSelectedAssetDetailTypeChange}
                                >
                                    {assetDetailTypes.map(asset => {
                                        return (
                                            <MenuItem value={asset.assetDetails}>{formatAssetDetailsForDropdown(asset)}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid item container direction={"row"} justifyContent={"space-between"}>
                        <Button
                            variant="contained"
                            component="label"
                            color="primary"
                        >
                            Upload Files
                            <input
                                onChange={handleFileSelected}
                                type="file"
                                accept="*"
                                multiple
                                hidden
                            />
                        </Button>
                        {
                            files.map(file => file.name).join(", ")
                        }
                        {
                            uploadFileButton()
                        }
                    </Grid>
                </Grid>
            </CardContent >
        </Card >
    )
}

export { AddVehicleDetails }
