import { Card, CardHeader, CardContent, TextField, InputLabel, Select, MenuItem, TextFieldProps, InputAdornment, Button, Typography, CircularProgress, useTheme } from "@mui/material"
import { OneOffInvoiceRequest } from "../models/OneOffInvoiceRequest"
import { ConvertCurrencyToSymbol } from '../helpers/ConvertCurrencyToSymbol';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { useEffect, useState } from "react"
import { OneOffPaymentMethods } from "../models/OneOffPaymentMethods"
import { createStyles, makeStyles } from "@mui/styles"
import { getInvestorSubscriptions, submitOneOffInvoiceRequest } from "../services/InvestorService"
import { InvestorViewModel } from "../models/InvestorViewModel";

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: '100%',
            textAlign: 'center',
            marginBottom: '10px'
        },
        dropDown: {
            textAlign: 'left',
            width: '100%',
            marginBottom: '10px'
        }
    }))
const genericLabelProps = {
    style: {
        fontSize: "1.2rem",
        paddingBottom: "0.5rem",
    },
    shrink: true,
};

type QueueOneOffInvoiceProps = {
    investorViewModel: InvestorViewModel
}

function QueueOneOffInvoice(props: QueueOneOffInvoiceProps) {
    let investorViewModel = props.investorViewModel;

    const earliestPossiblePaymentCollectionDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 3)

    const [oneOffInvoiceSubmissionWarnings, setOneOffInvoiceSubmissionWarnings] = useState<string[]>([])
    const [oneOffInvoiceRequest, setOneOffInvoiceRequest] = useState<OneOffInvoiceRequest>({ investorId: investorViewModel.id, paymentCollectionDate: earliestPossiblePaymentCollectionDate.toJSON(), paymentMethod: OneOffPaymentMethods.StripeDirectDebitCollection, invoicingDate: (new Date()).toJSON(), taxType: "NoTax", taxToCollect: 0 } as OneOffInvoiceRequest)
    const [subscriptionsDropDown, setSubscriptionsDropDown] = useState<Element[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [oneOffInvoiceResponse, setOneOffInvoiceResponse] = useState<string>('')

    var classes = useStyles();

    useEffect(() => {
        if (investorViewModel.id) {
            getInvestorSubscriptions(investorViewModel.id).then(subscriptions => {
                let subscriptionSelect: any[] = [];
                subscriptions.forEach(subscription => {
                    subscriptionSelect.push(<MenuItem value={subscription.id}>{subscription.id}</MenuItem>)
                })
                setSubscriptionsDropDown(subscriptionSelect);
            })
        }
    }, [investorViewModel])

    const onClickQueueOneOffInvoice = () => {
        let updatedWarnings: string[] = []
        if (typeof (oneOffInvoiceRequest.subscriptionId) === 'undefined') {
            updatedWarnings.push('Please select a subscription')
        }
        if (typeof (oneOffInvoiceRequest.invoicingDate) === 'undefined') {
            updatedWarnings.push('Please select an invoicing date')
        }
        if (typeof (oneOffInvoiceRequest.netAmountToCollect) === 'undefined') {
            updatedWarnings.push('Net amount to collect in invoice cannot be empty')
        }
        if (new Date(oneOffInvoiceRequest.invoicingDate) > new Date(oneOffInvoiceRequest.paymentCollectionDate)) {
            updatedWarnings.push('Invoice date is later than payment collection date')
        }
        setOneOffInvoiceSubmissionWarnings(updatedWarnings)
        if (updatedWarnings.length === 0) {
            setIsLoading(true)
            submitOneOffInvoiceRequest(oneOffInvoiceRequest)
                .then((success: boolean) => {
                    setIsLoading(false)
                    if (success) setOneOffInvoiceResponse('Successfully created')
                    else setOneOffInvoiceResponse('Request failed, please try again')
                })
                .catch(() => {
                    setOneOffInvoiceResponse('Request failed, please try again')
                })
        }
    }

    const handlePaymentDateChange = (value: unknown) => {
        let date = value as Date | null
        if (date != null) {
            let dateThreeAm = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 3, 0, 0);
            setOneOffInvoiceRequest({...oneOffInvoiceRequest, paymentCollectionDate: dateThreeAm.toJSON()})
        }
    };

    const handleInvoicingDateChange = (value: unknown) => {
        let date = value as Date | null
        if (date != null) {
            setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, invoicingDate: date.toJSON() })
        }
    };

    function roundToXDecimalPlaces(numberToRound: number | null, numberOfDecimalPlaces: number): number | null {
        if (numberToRound == null) {
            return null;
        } else {
            return Math.round(numberToRound * 10 ** numberOfDecimalPlaces) / 10 ** numberOfDecimalPlaces;
        }
    }

    function onChangeAmountToCollect(event: { target: { value: any; }; }) {
        if (event.target.value === '') {
            setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, netAmountToCollect: undefined, taxToCollect: 0 })
        } else {
            var input = event.target.value as number
            var roundedInput = roundToXDecimalPlaces(input, 2)

            if (roundedInput === null) {
                setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, netAmountToCollect: undefined, taxToCollect: 0 })
            } else {
                let applyTax = oneOffInvoiceRequest.taxType !== "NoTax";
                if (!applyTax) {
                    setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, netAmountToCollect: roundedInput, taxToCollect: 0 })
                } else {
                    let tax = roundToXDecimalPlaces(roundedInput * 0.2, 2)
                    setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, netAmountToCollect: roundedInput, taxToCollect: tax ?? 0 })
                }
            }
        }
    }

    function onChangeTaxToBeApplied(event: { target: { value: any; }; }) {
        let applyTax = event?.target?.value !== "NoTax";

        if (!applyTax) {
            setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, taxType: event?.target?.value, taxToCollect: 0 })
        } else {
            let tax: number | null = 0
            if (oneOffInvoiceRequest.netAmountToCollect) {
                tax = roundToXDecimalPlaces(oneOffInvoiceRequest.netAmountToCollect * 0.2, 2)
            }

            setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, taxType: event?.target?.value, taxToCollect: tax ?? 0 })
        }
    }

    const theme = useTheme()

    return <Card elevation={10} style={{ marginBottom: '20px' }}>
        <CardHeader titleTypographyProps={{ style: { fontSize: 16 } }} title="Generate one-off invoice" />
        <CardContent>
            <InputLabel id="subscription-label">Subscription</InputLabel>
            <Select
                variant="standard"
                className={classes.dropDown}
                value={oneOffInvoiceRequest?.subscriptionId != null ? oneOffInvoiceRequest?.subscriptionId : ''}
                onChange={(e) => setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, subscriptionId: e.target.value })}
                labelId="subscription-label"
                id="SubscriptionSelect"
                error={typeof (oneOffInvoiceRequest?.subscriptionId) === 'undefined'}
            >
                {subscriptionsDropDown}
            </Select>

            <InputLabel id="invoicing-date-label">Date for invoice (invoice will be sent today)</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    disabled
                    inputFormat='yyyy-MM-dd'
                    value={oneOffInvoiceRequest.invoicingDate}
                    onChange={handleInvoicingDateChange}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField variant="standard" style={{ marginBottom: 10 }} {...params} />}
                />

                <InputLabel id="payment-method-label">Payment Method</InputLabel>
                <Select
                    variant="standard"
                    value={oneOffInvoiceRequest?.paymentMethod}
                    className={classes.dropDown}
                    defaultValue={OneOffPaymentMethods.StripeDirectDebitCollection}
                    labelId="payment-method-label"
                    id="PaymentMethodSelect"
                    onChange={(e) => setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, paymentMethod: e?.target?.value })}
                // make it obvious that a payment will be queued if stripe is collected
                >
                    <MenuItem value={OneOffPaymentMethods.StripeDirectDebitCollection}>{OneOffPaymentMethods.StripeDirectDebitCollection}</MenuItem>
                    <MenuItem value={OneOffPaymentMethods.DirectBankTransfer}>{OneOffPaymentMethods.DirectBankTransfer}</MenuItem>
                </Select>

                <InputLabel id="invoicing-date-label">Payment Collection Date</InputLabel>
                <DesktopDatePicker
                    inputFormat='yyyy-MM-dd'
                    value={oneOffInvoiceRequest.paymentCollectionDate}
                    minDate={earliestPossiblePaymentCollectionDate}
                    onChange={handlePaymentDateChange}
                    disabled={oneOffInvoiceRequest.paymentMethod === OneOffPaymentMethods.DirectBankTransfer}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField variant="standard" style={{ marginBottom: 10 }} {...params} />}
                />
            </LocalizationProvider>

            <InputLabel id="payment-description-label">Reason for one off payment (will appear on invoice!)</InputLabel>
            <TextField
                variant="standard"
                className={classes.textField}
                InputLabelProps={genericLabelProps}
                value={oneOffInvoiceRequest.reason}
                onChange={(e) => setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, reason: e?.target?.value })}
            />

            <InputLabel id="payout-reference-label">{investorViewModel !== null && typeof (investorViewModel.shortId) !== 'undefined' ? "Payout reference (limited to " + (22 - investorViewModel.shortId.length).toString() + " characters excluding the pre-entered Investor short ID)" : "Payout reference (limited to 22 characters)"}</InputLabel>
            <TextField
                variant="standard"
                className={classes.textField}
                InputLabelProps={genericLabelProps}
                inputProps={{
                    maxLength: investorViewModel !== null && typeof (investorViewModel.shortId) !== 'undefined' ? 22 - investorViewModel.shortId?.length : 22
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {investorViewModel?.shortId}
                        </InputAdornment>
                    ),
                }}
                value={oneOffInvoiceRequest.payoutReference}
                disabled={oneOffInvoiceRequest.paymentMethod === OneOffPaymentMethods.DirectBankTransfer}
                onChange={(e) => setOneOffInvoiceRequest({ ...oneOffInvoiceRequest, payoutReference: e?.target?.value })}
            />

            <InputLabel id="tax-type-label">Tax to be applied</InputLabel>
            <Select
                variant="standard"
                className={classes.dropDown}
                value={oneOffInvoiceRequest.taxType}
                labelId="tax-type-label"
                id="TaxTyoeSelect"
                onChange={(e) => onChangeTaxToBeApplied(e)}
            >
                <MenuItem key="NoTax" value="NoTax">None</MenuItem>
                <MenuItem key="VAT" value="VAT">VAT (20%)</MenuItem>
            </Select>

            <TextField
                variant="standard"
                style={{ marginRight: '10%' }}
                value={oneOffInvoiceRequest?.netAmountToCollect}
                error={typeof (oneOffInvoiceRequest?.netAmountToCollect) === 'undefined'}
                onChange={(event: { target: { value: any; }; }) => onChangeAmountToCollect(event)}
                margin="normal"
                id="one-off-amount"
                label="Net amount to collect in invoice"
                type="number"
                InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                        <InputAdornment position="start">
                            {typeof (investorViewModel?.primaryCurrencyIsoCode) !== 'undefined' ? ConvertCurrencyToSymbol(investorViewModel?.primaryCurrencyIsoCode) : '£'}
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                variant="standard"
                style={{ marginRight: '10%' }}
                disabled
                value={oneOffInvoiceRequest?.taxToCollect}
                margin="normal"
                id="one-off-tax-amount"
                label="Tax to collect in invoice"
                type="number"
                InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                        <InputAdornment position="start">
                            {typeof (investorViewModel?.primaryCurrencyIsoCode) !== 'undefined' ? ConvertCurrencyToSymbol(investorViewModel?.primaryCurrencyIsoCode) : '£'}
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                variant="standard"
                disabled
                value={(oneOffInvoiceRequest?.netAmountToCollect ?? 0) + oneOffInvoiceRequest?.taxToCollect}
                margin="normal"
                id="one-off-gross-amount"
                label="Gross amount to collect in invoice"
                type="number"
                InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                        <InputAdornment position="start">
                            {typeof (investorViewModel?.primaryCurrencyIsoCode) !== 'undefined' ? ConvertCurrencyToSymbol(investorViewModel?.primaryCurrencyIsoCode) : '£'}
                        </InputAdornment>
                    ),
                }}
            />

            <Button
                variant="contained"
                color="primary"
                style={{ marginRight: '60%' }}
                onClick={onClickQueueOneOffInvoice}
                disabled={isLoading}
            >
                Queue One-Off Payment
            </Button>
            {isLoading ? <div><CircularProgress size={20} /></div> : <></>}
            {oneOffInvoiceSubmissionWarnings.map(warning => <Typography style={{ color: theme.palette.warning.main }}>{warning}</Typography>)}
            {<Typography style={{ color: theme.palette.text.primary }}>{oneOffInvoiceResponse}</Typography>}
        </CardContent>
    </Card>
}
export { QueueOneOffInvoice }
