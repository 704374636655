import {PaymentPlanSeedingData} from "../models/PaymentPlanSeedingData";
import {getSettings} from "./SettingsService";
import {PaymentPlan} from "../models/PaymentPlan";

async function GetSeedingForPaymentPlan (subscriptionId: string): Promise<PaymentPlanSeedingData[]> {
    const settings = await getSettings();
    const paymentPlanDataUri = `${settings.BillingServiceBaseUri}/setupPaymentPlanData/${subscriptionId}`
    const options = {
        method: "GET",
    };
    const paymentPlanDataResponse = await fetch(paymentPlanDataUri, options)
    const paymentPlanData = await paymentPlanDataResponse.json()
    return paymentPlanData;
}

async function PostNewPaymentPlan(paymentPlanViewModel : PaymentPlan) {
    const settings = await getSettings();
    const paymentPlanDataUri = `${settings.BillingServiceBaseUri}/subscriptions/${paymentPlanViewModel.subscriptionId}/paymentplans`

    const options = {
        method: "POST",
        body: JSON.stringify(paymentPlanViewModel)
    };
     
    const paymentPlanDataResponse = await fetch(paymentPlanDataUri, options)

    if(paymentPlanDataResponse.status !== 200) {       
        const response = await paymentPlanDataResponse.json();           
        paymentPlanViewModel.responseException = response.Message;       
        return paymentPlanViewModel;
    }
    
    const paymentPlanData = await paymentPlanDataResponse.json()
    return paymentPlanData;
}

async function PostPaymentPlanToEdit(paymentPlanViewModel : PaymentPlan) {
    const settings = await getSettings();
    const paymentPlanDataUri = `${settings.BillingServiceBaseUri}/subscriptions/${paymentPlanViewModel.subscriptionId}/paymentplan/edit` //should be paymentplanS

    const options = {
        method: "POST",
        body: JSON.stringify(paymentPlanViewModel)
    };
    const paymentPlanDataResponse = await fetch(paymentPlanDataUri, options)

    if(paymentPlanDataResponse.status !== 200) {
        const response = await paymentPlanDataResponse.json();
        paymentPlanViewModel.responseException = response.Message;
        return paymentPlanViewModel;
    }
    
    const paymentPlanData = await paymentPlanDataResponse.json()
    return paymentPlanData;
}

async function getPaymentPlanViewModel(subscriptionId: string, paymentPlanId: string): Promise<PaymentPlan> {
    const paymentPlanViewModelUri = `/api/subscriptions/${subscriptionId}/paymentplans/${paymentPlanId}`
    const options = {
        method: "GET",
    };
    const paymentPlanViewModelResponse = await fetch(paymentPlanViewModelUri, options)
    const paymentPlanViewModelData = await paymentPlanViewModelResponse.json()

    return paymentPlanViewModelData;
}

export {GetSeedingForPaymentPlan, PostNewPaymentPlan, PostPaymentPlanToEdit, getPaymentPlanViewModel}
