import { Button, Card, CardActions, CardContent, CardHeader, LinearProgress, Typography } from "@mui/material"
import React, { useEffect, useState } from 'react';
import { TrialBillingRunInvestmentManagementFeeBillTable } from "../../components/TrialBillingRunInvestmentManagementFeeBillTable";
import { ConvertBillStatusToString } from "../../helpers/ConvertBillStatusToString";
import { InvestmentManagementFeeBill } from "../../models/InvestmentManagementFeeBill";
import { getLatestTrialInvestmentManagementFeeBills, getLatestTrialInvestmentManagementFeeBillsCsv } from "../../services/BillService";
import { makeStyles, createStyles } from '@mui/styles';
import { FileDownloadDataAndName } from "../../models/FileDownloadDataAndName";
import { downloadFileFromFileDownloadDataAndName } from "../../services/FileDownloadService";

const useStyles = makeStyles(() =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))

const TrialBillingRunOverviewPageInvestorBills: React.FC = () => {
    const [bills, setBills] = useState<InvestmentManagementFeeBill[]>([])
    const [mappedBills, setMappedBills] = useState<any[]>([])
    const [showResults, setShowResults] = useState<boolean>(false)

    useEffect(() => {
        var mappedBills = mapBillModelsToDataTableRows(bills)
        setMappedBills(mappedBills)
        if (bills !== undefined && bills.length > 0) {
            setShowResults(true)
        }
    }, [bills])

    useEffect(() => {
        let mounted = true;
        getLatestTrialInvestmentManagementFeeBills()
            .then(bills => {
                if (mounted) setBills(bills)
            })

        return () => {
            mounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function mapBillModelsToDataTableRows(bills: InvestmentManagementFeeBill[]): any[] {
        return bills.map((bill, index) => {
            return {
                id: index,
                customerName: bill.tradingName,
                dateOfBill: bill.billingDate,
                billingPeriodStartDate: bill.billingPeriodStartDate,
                billingPeriodEndDate: bill.billingPeriodEndDate,
                amount: bill.totalPaymentLine.net,
                status: ConvertBillStatusToString(bill.billStatus),
                currency: bill.currencyIsoCode,
                billId: bill.id,
                investorId: bill.investorId,
                validationErrors: bill.validationErrors,
                invoiceNumber: bill.invoiceNumber
            }
        });
    }

    function downloadCsv() {
        getLatestTrialInvestmentManagementFeeBillsCsv().then((csvDownloadDataAndName: FileDownloadDataAndName) => {
            downloadFileFromFileDownloadDataAndName('text/csv', csvDownloadDataAndName)
        })
    }

    const classes = useStyles()

    const calculationTimeHeader = bills !== undefined && bills.length > 0 ? "Calculated at " + new Date(bills[0]?.calculatedAtDateTime).toLocaleDateString() + " " + new Date(bills[0]?.calculatedAtDateTime).toLocaleTimeString() : ""
    const billingRunIdHeader = bills !== undefined && bills.length > 0 ? "Billing Run Id: " + bills[0].billingRunReportId : ""

    return <Card>
        <CardHeader title="Latest Trial Billing Run - Investor Bills" subheader={calculationTimeHeader + ", " + billingRunIdHeader} />
        <CardContent style={{ minHeight: '65vh' }}>
            {
                showResults !== undefined
                    ?
                    (
                        <>
                            {mappedBills.length > 0 ?
                                <TrialBillingRunInvestmentManagementFeeBillTable bills={mappedBills} /> : <div className={classes.loader}>
                                    <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for
                                        your
                                        patience</Typography>
                                    <LinearProgress color="secondary" />
                                </div>}
                        </>
                    )
                    :
                    (
                        <div className={classes.loader}>
                            <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your
                                patience</Typography>
                            <LinearProgress color="secondary" />
                        </div>
                    )
            }
        </CardContent>
        <CardActions>
            <Button variant="contained" color="primary"
                href={`/api/latestDraftInvestmentManagementFeeBills/zip`}>
                {"Download All Pdfs As Zip"}</Button>

            <Button variant="contained" color="primary"
                onClick={downloadCsv}
                style={{ marginLeft: 10 }}>{"Download Table Data As Csv"}</Button>
        </CardActions>
    </Card>;
}

export { TrialBillingRunOverviewPageInvestorBills }