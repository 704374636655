import { SubscriptionAssetStatus } from "../models/SubscriptionAssetStatus"

function ConvertSubscriptionAssetStatusToString(assetStatus: SubscriptionAssetStatus): string {
    switch (assetStatus) {
        case SubscriptionAssetStatus.AwaitingInstallAndCollection: {
            return "Awaiting Install and Collection"
        }
        case SubscriptionAssetStatus.CollectedNotInstalled: {
            return "Collected, not Installed"
        }
        case SubscriptionAssetStatus.InstalledNotCollected: {
            return "Installed, not Collected"
        }
        case SubscriptionAssetStatus.InstalledAndCollected: {
            return "Installed and Collected"
        }
        case SubscriptionAssetStatus.VehicleOnboarded: {
            return "Vehicle Onboarded"
        }
        default: {
            return "-";
        }
    }
}

export {ConvertSubscriptionAssetStatusToString};
