import { Skeleton } from "@mui/material"
import { useEffect, useState } from "react"
import { AssetOperator } from "../models/AssetOperator"
import { Bill } from "../models/Bill"
import { getIssuedAndNonDraftBillViewModelsForAssetOperator } from "../services/BillService"
import { BillTable } from "./BillTable"

type AssetOperatorBillTableProps = {
    assetOperator: AssetOperator | null
}

function AssetOperatorBillTable({ assetOperator }: AssetOperatorBillTableProps): JSX.Element {
    const [bills, setBills] = useState<Bill[]>([])
    useEffect(() => {
        let mounted = true;
        getIssuedAndNonDraftBillViewModelsForAssetOperator(assetOperator?.id).then((item: Bill[]) => {
            if (mounted) {
                setBills([...item]);
            }
        });
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetOperator?.id]);
    return assetOperator == null ? (
        <Skeleton variant="rectangular" height={500} />
    ) : (
        <BillTable bills={bills} />
    );

}

export { AssetOperatorBillTable }
