import {CompanyType} from "../models/CompanyType";

function ConvertCompanyTypeToString(companyType: CompanyType | undefined): string {
    if (companyType === CompanyType.SoleTrader) {
        return "Sole Trader"
    } else {
        return "Rental Fleet"
    }
}

export {ConvertCompanyTypeToString};
