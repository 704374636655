import { format } from "date-fns";
import { DraftSubscriptionViewModel } from "../models/DraftSubscriptionViewModel"
import { UsageUnit } from "../models/UsageUnit";

function CheckIfDraftSubscriptionHasMandatoryFieldsFilled(draftSubscription: DraftSubscriptionViewModel): boolean {
    const d = new Date();
    const dateToday = format(d, "yyyy-MM-dd");

    if (draftSubscription.usageUnit === UsageUnit.Miles) {
        if (draftSubscription.costPerUsageUnit
            && draftSubscription.totalContractedUsage
            && draftSubscription.id
            && draftSubscription.billingStartDate
            && draftSubscription.targetEndDate !== dateToday
            && draftSubscription.maximumEndDate !== dateToday
            && draftSubscription.feeRate !== null
            && draftSubscription.totalFacility !== null
            && draftSubscription.name !== null && draftSubscription.name !== ""
            && draftSubscription.minimumUsagePeriods && draftSubscription.minimumUsagePeriods.length > 0) {
            return true;
        } else {
            return false;
        }
    } else if (draftSubscription.usageUnit === UsageUnit.ActiveAssets) {
        if (draftSubscription.costPerUsageUnit
            && draftSubscription.id
            && draftSubscription.totalExpectedRepayment
            && draftSubscription.monthsInContract
            && draftSubscription.billingStartDate
            && draftSubscription.maximumEndDate !== dateToday
            && draftSubscription.feeRate !== null
            && draftSubscription.totalFacility !== null
            && draftSubscription.name !== null && draftSubscription.name !== "") {
            return true;
        } else {
            return false;
        }
    }

    return false;
}

export {CheckIfDraftSubscriptionHasMandatoryFieldsFilled};