import { Button, Card, CardHeader, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { Subscription } from "../models/Subscription"
import { genericLabelProps } from "../props/GenericLabelProps";
import { DrawdownPeriod } from "../models/DrawdownPeriod";
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConvertCurrencyToSymbol } from "../helpers/ConvertCurrencyToSymbol";
import { format } from "date-fns";

type IRRDrawdownPeriodProps = {
    subscription: Subscription
    setSubscription: Function
    numberOfAssetsWarningMessage: string
    totalLoanWarningMessage: string
    billingStartDateWarningMessage: string
    lengthOfDrawdownPeriodWarningMessage: string
    maximumEndDateWarningMessage: string
}

function IRRDrawdownPeriodTab({ subscription, setSubscription, numberOfAssetsWarningMessage,
    totalLoanWarningMessage,
    billingStartDateWarningMessage,
    lengthOfDrawdownPeriodWarningMessage, maximumEndDateWarningMessage }: IRRDrawdownPeriodProps): JSX.Element {

    function onNumberOfAssetsChange(index: number, updatedNumber: number) {
        const drawdownPeriodToUpdate = subscription.drawdownPeriodProfile[index]
        drawdownPeriodToUpdate.numberOfAssets = updatedNumber
        const loanPerAsset = (subscription.totalFacility / subscription.assets.length) ?? 0
        drawdownPeriodToUpdate.loanPerAsset = loanPerAsset
        drawdownPeriodToUpdate.totalLoanForDateTime = updatedNumber * loanPerAsset
        const updatedDrawdownProfile = subscription.drawdownPeriodProfile.map((monthlyProfile, i) => index === i ? drawdownPeriodToUpdate : monthlyProfile)
        setSubscription({ ...subscription, drawdownPeriodProfile: updatedDrawdownProfile })
    }

    const handleDeleteRow = (index: number) => {
        const newDrawdownPeriodProfile = subscription.drawdownPeriodProfile.filter((x, i) => i !== index)
        setSubscription({ ...subscription, drawdownPeriodProfile: newDrawdownPeriodProfile })
    }

    const handleCreateNewDrawdownPeriodRow = () => {
        if (subscription.drawdownPeriodProfile === null || (subscription.drawdownPeriodProfile !== null && subscription.drawdownPeriodProfile.length === 0)) {
            const updatedDrawdownProfile: DrawdownPeriod[] = [{
                dateTime: subscription.billingStartDate ? format(new Date(subscription.billingStartDate), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"),
                numberOfAssets: 0,
                loanPerAsset: (subscription.totalFacility / subscription.assets.length) ?? 0,
                totalLoanForDateTime: 0}]
            setSubscription({ ...subscription, drawdownPeriodProfile: updatedDrawdownProfile })
        } else {
            const latestDate = new Date(subscription.drawdownPeriodProfile[subscription.drawdownPeriodProfile.length - 1].dateTime)
            const newDateTime = new Date(latestDate.setMonth(latestDate.getMonth()+1))
            const newRow: DrawdownPeriod = {
                dateTime: format(newDateTime, "yyyy-MM-dd"),
                numberOfAssets: 0,
                loanPerAsset: (subscription.totalFacility / subscription.assets.length) ?? 0,
                totalLoanForDateTime: 0
            }
            const updatedDrawdownProfile = subscription.drawdownPeriodProfile
            updatedDrawdownProfile.push(newRow)
            setSubscription({ ...subscription, drawdownPeriodProfile: updatedDrawdownProfile })
        }
    }

    const handleDateChange = (newDateTime: string | null, index: number) => {
        if (newDateTime) {
            const drawdownPeriodToUpdate = subscription.drawdownPeriodProfile[index]
            drawdownPeriodToUpdate.dateTime = format(new Date(newDateTime), "yyyy-MM-dd")
            let updatedDrawdownProfile = subscription.drawdownPeriodProfile.map((monthlyProfile, i) => index === i ? drawdownPeriodToUpdate : monthlyProfile)
            updatedDrawdownProfile = updatedDrawdownProfile.sort((a, b) => (new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()))
            setSubscription({ ...subscription, drawdownPeriodProfile: updatedDrawdownProfile })
        }
    }

    return (
        <Card>
            <CardHeader title={"IRR Drawdown Period"} />
            <Grid container>
                    {(subscription.assets?.length > 0) && <Grid item xs={12} padding="16px">
                        <TextField
                            variant="standard"
                            label="Loan per Asset"
                            InputLabelProps={genericLabelProps}
                            disabled
                            value={`${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${(subscription.totalFacility / subscription.assets.length).toFixed(2)}`}
                        />
                    </Grid>}
                    <Grid item xs={12} padding="16px">
                        <Typography color="error">{numberOfAssetsWarningMessage}</Typography>
                        <Typography color="error">{totalLoanWarningMessage}</Typography>
                        <Typography color="error">{billingStartDateWarningMessage}</Typography>
                        <Typography color="error">{lengthOfDrawdownPeriodWarningMessage}</Typography>
                        <Typography color="error">{maximumEndDateWarningMessage}</Typography>
                    </Grid>
                <Grid item padding="16px" width="100%">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow key={"HEADER_ROW"}>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Number of Assets</TableCell>
                                    <TableCell>Total Loan Amount</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscription.drawdownPeriodProfile?.map((row: DrawdownPeriod, index: number) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        value={row.dateTime}
                                                        onChange={(newDate) => handleDateChange(newDate, index)}
                                                        renderInput={(params) => <TextField {...params} helperText={null} />}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <TextField
                                                    style={{ minWidth: "100px" }}
                                                    variant="outlined"
                                                    value={row.numberOfAssets ?? ""}
                                                    onChange={(event: any) => onNumberOfAssetsChange(index, Number(event.target.value))} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <TextField
                                                    style={{ minWidth: "100px" }}
                                                    variant="outlined"
                                                    disabled
                                                    value={`${ConvertCurrencyToSymbol(subscription.currencyIsoCode)}${row.totalLoanForDateTime.toFixed(2)}`} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton onClick={() => handleDeleteRow(index)}>
                                                    <DeleteIcon color="primary" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item padding="16px">
                    <Button variant="contained" color="primary" onClick={handleCreateNewDrawdownPeriodRow}>
                        Add new row
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export { IRRDrawdownPeriodTab }