import { DraftSubscriptionViewModel } from "../models/DraftSubscriptionViewModel"
import { UsageUnit } from "../models/UsageUnit";

function ReturnMandatoryFieldsTextForDraftSubscriptions(draftSubscription: DraftSubscriptionViewModel | null): string {
    if(!draftSubscription) return "";
    
    if(draftSubscription.usageUnit === UsageUnit.Miles){
        return "Please add one six month minimum mileage period, and complete the mandatory fields: Contract ID, Asset Operator ID, Investor IDs, Cost per Usage Unit, Total Contracted Usage, Billing Start Date, Investment Management Fee Rate, Total Facility, and Contract Name. Target End Date and Maximum End Date must not be today's date."
    } else if(draftSubscription.usageUnit === UsageUnit.ActiveAssets){
        return "Please complete the mandatory fields: Contract ID, Asset Operator ID, Investor IDs, Cost per Usage Unit, Total Expected Repayment, Months per Asset, Billing Start Date, Investment Management Fee Rate, Total Facility, and Contract Name. Maximum End Date must not be today's date."
    } else {
        return "";
    }
}

export { ReturnMandatoryFieldsTextForDraftSubscriptions };