import { Collapse, List, ListItem, ListItemText, Theme } from "@mui/material";
import React from "react";
import { SubscriptionBill } from "../models/SubscriptionBill";
import { ConvertCurrencyToSymbol } from "../helpers/ConvertCurrencyToSymbol";
import { CurrencyIsoCodes } from "../models/CurrencyIsoCodes";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { makeStyles, createStyles } from '@mui/styles';

type SubscriptionBillPaymentLinesProps = {
    subscriptionBill: SubscriptionBill | undefined
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    }),
);

function SubscriptionBillPaymentLines({ subscriptionBill }: SubscriptionBillPaymentLinesProps) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={"Bill for Subscription: " + subscriptionBill?.subscriptionId} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        subscriptionBill?.paymentLines?.map(paymentLine => {
                            const primaryText = `${paymentLine.reason} - ${paymentLine.usageBilled?.toLocaleString("en-GB", { maximumFractionDigits: 0, minimumFractionDigits: 0 })} ${subscriptionBill.usageUnit} @ ${ConvertCurrencyToSymbol(subscriptionBill?.currencyIsoCode ?? CurrencyIsoCodes.Gbp)}${subscriptionBill.costPerUsageUnit.toLocaleString("en-GB", { maximumFractionDigits: 3, minimumFractionDigits: 3 })} = ${ConvertCurrencyToSymbol(subscriptionBill?.currencyIsoCode ?? CurrencyIsoCodes.Gbp)}${paymentLine.net?.toLocaleString("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
                            return (
                                <ListItem className={classes.nested}>
                                    <ListItemText primary={primaryText} />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Collapse>
        </>
    )

}
export { SubscriptionBillPaymentLines }