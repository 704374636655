import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    Theme,
    Typography,
    useTheme
} from "@mui/material"
import React, { useEffect, useState } from 'react';
import { Payment } from "../../models/Payment";
import { archiveFailedPayment, cancelPayment, getAllPayments, retryFailedPayment } from "../../services/PaymentService";
import { getAssetOperators } from "../../services/AssetOperatorService";
import { CountdownToNextPaymentSubmission } from "../../components/CountdownToNextPaymentSubmission";
import { PaymentStatus } from "../../models/PaymentStatus";
import { ConvertPaymentStatusToString } from "../../helpers/ConvertPaymentStatusToString";
import { PaymentsToActionTable } from "../../components/Payments/PaymentsToActionTable";
import { getHeldNonDraftBillsForMonth } from "../../services/BillService";
import { makeStyles, createStyles } from "@mui/styles";
import { format } from "date-fns";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loader: {
            width: '100%',
            textAlign: 'center'
        }
    }))

const PaymentsToActionPage: React.FC = props => {
    const [mappedPayments, setMappedPayments] = useState<any[]>([])
    const [feedbackMessage, setFeedbackMessage] = useState<string>()
    const [cancelFeedbackColor, setFeedbackMessageColor] = useState<string>()
    const [paymentsFetchCompleted, setPaymentsFetchCompleted] = useState<boolean>(false)
    const [showHeldBillsExistMessage, setShowHeldBillsExistMessage] = useState<boolean>(false)

    const classes = useStyles()
    useEffect(() => {
        let mounted = true;
        let alteredPayments: Payment[] = []
        getAllPayments()
            .then(payments => {
                getAssetOperators()
                    .then(assetOperators =>
                        payments.map(
                            (payment) => {
                                let assetOperator = assetOperators.find(operator => operator.id === payment.customerId)
                                payment.assetOperatorName = assetOperator == null ? '' : assetOperator.tradingName
                                return alteredPayments.push(payment)
                            }))

                            .then(() =>{
                                if (mounted) {
                                    var paymentsToAction = alteredPayments.filter(payment => payment.status === PaymentStatus.Queued || (payment.status === PaymentStatus.Failed && payment.isArchived !== true))
                                    var mappedPayments = mapPaymentModelsToDataTableRows(paymentsToAction)
                                    setMappedPayments(mappedPayments)
                                    setPaymentsFetchCompleted(true)
                                }
                            })
                })

            let thisMonth = new Date()
            let lastDayLastMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth(),0)
            var lastMonth = format(lastDayLastMonth, 'MMM yyyy')
            getHeldNonDraftBillsForMonth(lastMonth)
                .then(heldBills => {
                    if (mounted) {
                        if(heldBills.length > 0) setShowHeldBillsExistMessage(true)
                    }
                })

        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onCancelClick(params: any, theme: Theme): Promise<void> {
        let payment = params.row;
        let response = await cancelPayment(payment.paymentId);
        let paymentName = payment.subscriptionId + ' - ' + (payment.assetOperatorName === '' ? payment.customerId : payment.assetOperatorName)
        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully cancelled')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: Payment[] = []
            getAllPayments()
                .then(payments => {
                    getAssetOperators()
                        .then(assetOperators =>
                            payments.map(
                                (payment) => {
                                    let assetOperator = assetOperators.find(operator => operator.id === payment.customerId)
                                    payment.assetOperatorName = assetOperator == null ? '' : assetOperator.tradingName
                                    return alteredPayments.push(payment)
                                }))
                        .then(() => {
                            var mappedPayments = mapPaymentModelsToDataTableRows(alteredPayments.filter(payment => payment.status === PaymentStatus.Queued || (payment.status === PaymentStatus.Failed && payment.isArchived !== true)))
                            setMappedPayments(mappedPayments)
                        })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to cancel')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    async function onArchiveClick(params: any, theme: Theme): Promise<void> {
        let payment = params.row;
        let response = await archiveFailedPayment(payment.paymentId);
        let paymentName = payment.subscriptionId + ' - ' + (payment.assetOperatorName === '' ? payment.customerId : payment.assetOperatorName)
        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully archived')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: Payment[] = []
            getAllPayments()
                .then(payments => {
                    getAssetOperators()
                        .then(assetOperators =>
                            payments.map(
                                (payment) => {
                                    let assetOperator = assetOperators.find(operator => operator.id === payment.customerId)
                                    payment.assetOperatorName = assetOperator == null ? '' : assetOperator.tradingName
                                    return alteredPayments.push(payment)
                                }))
                        .then(() => {
                            var mappedPayments = mapPaymentModelsToDataTableRows(alteredPayments.filter(payment => payment.status === PaymentStatus.Queued || (payment.status === PaymentStatus.Failed && payment.isArchived !== true)))
                            setMappedPayments(mappedPayments)
                        })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to archive')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    async function onRetryClick(params: any, theme: Theme): Promise<void> {
        let payment = params.row;
        let response = await retryFailedPayment(payment.paymentId);
        let paymentName = payment.subscriptionId + ' - ' + (payment.assetOperatorName === '' ? payment.customerId : payment.assetOperatorName)
        if (response.ok) {
            setFeedbackMessage('Payment ' + paymentName + ' successfully retried')
            setFeedbackMessageColor(theme.palette.success.main)
            let alteredPayments: Payment[] = []
            getAllPayments()
                .then(payments => {
                    getAssetOperators()
                        .then(assetOperators =>
                            payments.map(
                                (payment) => {
                                    let assetOperator = assetOperators.find(operator => operator.id === payment.customerId)
                                    payment.assetOperatorName = assetOperator == null ? '' : assetOperator.tradingName
                                    return alteredPayments.push(payment)
                                }))
                        .then(() => {
                            var mappedPayments = mapPaymentModelsToDataTableRows(alteredPayments.filter(payment => payment.status === PaymentStatus.Queued || (payment.status === PaymentStatus.Failed && payment.isArchived !== true)))
                            setMappedPayments(mappedPayments)
                        })
                })
        }
        else {
            setFeedbackMessage('Payment ID ' + paymentName + ' failed to retry')
            setFeedbackMessageColor(theme.palette.warning.main)
        }
    }

    function mapPaymentModelsToDataTableRows(payments: Payment[]): any[] {
        return payments.map((payment, i) => {
            return {
                assetOperatorName: payment?.assetOperatorName,
                id: i,
                customerId: payment?.customerId,
                targetDate: payment.targetPaymentDate,
                currency: payment?.currencyIsoCode,
                paymentAmount: payment?.amountCharged,
                paymentType: payment?.paymentType,
                paymentStatus: ConvertPaymentStatusToString(payment?.status),
                paymentId: payment?.id,
                subscriptionId: payment?.subscriptionId,
                currencyIsoCode: payment?.currencyIsoCode,
                arrearsManagement: payment?.previouslyFailedAttempts?.length > 0 ? "Retry attempt: " + payment?.previouslyFailedAttempts?.length : '',
                numberOfPreviouslyFailedAttempts: payment?.previouslyFailedAttempts?.length ?? 0
            }
        });
    }
    const theme = useTheme()

    return (
        <Card>
            <CardHeader title="Payments To Action" />
            <CardContent style={{ height: '65vh' }}>
                {
                    showHeldBillsExistMessage ?
                        <Typography style={{ color: theme.palette.warning.main }} variant="subtitle1">{"There are Held bills that need attending to, please visit the \"Fleet Bills\" page found under the Billing Runs tab."}</Typography>
                        :
                        <></>
                }

                {
                    mappedPayments.length > 0
                        ?
                        (
                            <PaymentsToActionTable payments={mappedPayments} onCancelClick={onCancelClick} onArchiveClick={onArchiveClick} onRetryClick={onRetryClick} />
                        )
                        :
                        paymentsFetchCompleted ?
                            (
                                <Typography variant="subtitle1" gutterBottom>No payments to action!</Typography>
                            )
                            :
                            (
                                <div className={classes.loader}>
                                    <Typography variant="subtitle1" gutterBottom>Just grabbing your data, thank you for your patience</Typography>
                                    <LinearProgress color="secondary" />
                                </div>
                            )
                }
            </CardContent>
            <CardActions>
                <CountdownToNextPaymentSubmission />
                <Typography style={{ marginLeft: 'auto', color: cancelFeedbackColor }} variant="subtitle1">{feedbackMessage}</Typography>
            </CardActions>
        </Card>
    )
}

export { PaymentsToActionPage }