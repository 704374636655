import { Theme } from "@mui/material";

function GetBillStatusTextColor(billStatus: string | undefined, theme: Theme): string {
    switch (billStatus) {
        case "Issued":
            return theme.palette.success.main;
        case "Retracted":
            return theme.palette.warning.main;
        default:
            return theme.palette.text.primary;
    }
}

export { GetBillStatusTextColor };
