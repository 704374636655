import { Link } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { InternalInvoice } from "../models/InternalInvoice";

interface TrialBillingRunInternalInvoiceTableProps {
    invoices: InternalInvoice[]
}

const TrialBillingRunInternalInvoiceTable: React.FC<TrialBillingRunInternalInvoiceTableProps> = props => {

    const columns: any[] = [
        { field: 'subscriptionId', headerName: 'Subscription Id', width: 200 },
        { field: 'invoiceNumber', headerName: 'Invoice Number', width: 150 },

        {
            field: 'dateOfBill',
            headerName: 'Billing Date',
            width: 150,
            filterable: false,
            renderCell: (params: any): string => {
                return params.row.dateOfBill == null ? '' : new Date(params.row.dateOfBill).toLocaleDateString()
            }
        },
        {
            field: 'billingPeriodStartDate',
            headerName: 'Period Start Date',
            width: 170,
            filterable: false,
            renderCell: (params: any): string => {
                return params.row.billingPeriodStartDate == null ? '' : new Date(params.row.billingPeriodStartDate).toLocaleDateString()
            }
        },
        {
            field: 'billingPeriodEndDate',
            headerName: 'Period End Date',
            width: 170,
            filterable: false,
            renderCell: (params: any): string => {
                return params.row.billingPeriodEndDate == null ? '' : new Date(params.row.billingPeriodEndDate).toLocaleDateString()
            }
        },
        { field: 'usagePeriod', headerName: 'Usage Period', width: 100 },
        {
            field: 'amount', headerName: 'Amount', width: 130, type: 'number'
        },
        {
            field: 'downloadButton',
            headerName: 'Download',
            filterable: false,
            type: 'number',
            flex: 1,
            renderCell: (params: any): JSX.Element => {
                return (
                    <>
                        <Link style={{ paddingTop: "25px", marginLeft: 'auto' }}
                            href={`/api/internalInvoices/${params.row.invoiceId}/download`}>
                            <GetAppIcon fontSize="large" color="primary" style={{ paddingBottom: '10px' }} />
                        </Link>
                    </>)
            }
        }
    ]

    return (

        <div style={{ height: '65vh', width: '100%' }}>
            <DataGrid
                rows={props.invoices}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                initialState={{
                    pagination: {
                        pageSize: 10
                    }
                }}
            />
        </div>
    )
}
export { TrialBillingRunInternalInvoiceTable }
