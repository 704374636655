import { Settings } from '../models/Settings'

let settings: Settings
let settingsUri = "/settings/settings.json"


async function getSettings(): Promise<Settings>{
    if(settings == null){
        var settingsResponse = await fetch(settingsUri)
        settings = await settingsResponse.json()
        const url = window.location.href;
        if(url.includes("localhost")){
            settings.BillingServiceBaseUri = "/api"
        }
    }
    return settings
}

export { getSettings }