import { Button } from "@mui/material";
import { Bill } from "../models/Bill";
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from '@mui/icons-material/Download';
import { ListPopover } from "./ListPopover";
import { SubscriptionBillValidationError } from "../models/SubscriptionBillValidationError";

interface TrialBillingRunTableProps {
    bills: Bill[]
    approveMethod: Function | null
}

const BillingRunTable: React.FC<TrialBillingRunTableProps> = props => {
    const [sortModel, setSortModel] = useState<any[]>([
        {
            field: 'status',
            sort: 'desc',
        },
    ]);

    const columns: any[] = [
        { field: 'customerName', headerName: 'Customer', width: 200 },
        { field: 'invoiceNumber', headerName: 'Invoice Number', width: 200 },
        { field: 'currency', headerName: 'Currency', width: 130 },
        {
            field: 'amount', headerName: 'Amount', width: 130, type: 'number'
        },
        {
            field: 'dateOfBill',
            headerName: 'Billing Date',
            width: 150,
            filterable: false,
            renderCell: (params: any): string => {
                return params.row.dateOfBill == null ? '' : new Date(params.row.dateOfBill).toLocaleDateString()
            }
        },
        { field: 'usagePeriod', headerName: 'Usage Period', width: 150 },
        {
            field: 'status', headerName: 'Status', width: 200
        },
        {
            field: 'downloadButton', headerName: 'Download', width: 150, renderCell: (params: any): JSX.Element => {
                return (
                    <Button
                        href={params.value}
                        variant="contained"
                        color="primary"
                        endIcon={<DownloadIcon />}
                        style={{ marginRight: 6 }}
                    >
                        Download
                    </Button>
                )
            },
        },
        {
            field: 'validationErrors',
            headerName: 'Validation Errors',
            width: 250,
            renderCell: (params: any): JSX.Element => {
                const validationErrors: SubscriptionBillValidationError[] = params.row.validationErrors;
                return (
                    validationErrors === null || validationErrors === undefined || validationErrors.length === 0 ?
                        <>
                        </> :
                        <div style={{ paddingLeft: "20px" }}>

                            <ListPopover listItems={validationErrors.map(validationError => validationError.errorType)}
                                buttonText={"Validation Errors : " + validationErrors?.length}></ListPopover>

                        </div>

                )
            }
        }
    ]

    if (props.approveMethod) {
        columns.push(
            {
                field: 'approveButton', headerName: 'Approve', width: 150, renderCell: (params: any): JSX.Element => {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 16 }}
                            // @ts-ignore
                            onClick={() => props.approveMethod(params.row.billId)}
                        >
                            Approve
                        </Button>
                    )
                },
            })
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid
                rows={props.bills}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                initialState={{
                    pagination: {
                        pageSize: 10
                    }
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
            />
        </div>
    )
}
export { BillingRunTable }