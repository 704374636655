import {
  Theme,
  Popper,
  Typography,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {MenuLink} from "../models/MenuLink";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    menuLink: {
      cursor: "pointer",
      marginRight: theme.spacing(2),
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    subMenuLink: {
      cursor: "pointer",
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    drawer: {
      zIndex: 2,
    },
    menuText: {
      color: theme.palette.text.primary,
    },
  })
);

const DesktopAppMenuItem: React.FC<MenuLink> = (props) => {
  const { title, url, nestedLinks = [] } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    }
  }

  const open = Boolean(anchorEl);
  const id = open
    ? `submenu-${title.toLowerCase().replace(" ", "-")}`
    : undefined;
  const isNested = nestedLinks && nestedLinks.length > 0;
  return isNested ? (
    <>
      <Typography
        className={classes.menuLink}
        aria-describedby={id}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {title}
      </Typography>
      <Popper
        key={id}
        className={classes.drawer}
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {nestedLinks?.map((menuLink: MenuLink) => {
                    return (
                        <Link
                          className={classes.subMenuLink}
                          to={menuLink.url as string}
                          onClick={handleClose} 
                        >
                          <MenuItem>
                            <Typography className={classes.menuText}>
                              {menuLink.title}
                            </Typography>
                          </MenuItem>
                        </Link>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <Link className={classes.menuLink} to={url ?? "/"}>
      <Typography>{title}</Typography>
    </Link>
  );
};

export default DesktopAppMenuItem;
