import { Button, Card, CardContent, CardHeader, Grid, Select, Typography, SelectChangeEvent, MenuItem, Checkbox, ListItemText, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AssetOperator } from '../models/AssetOperator';
import { createOrUpdateAssetOperator } from '../services/AssetOperatorService';
import { InvoicingAndPaymentCollectionMethod } from "../models/InvoicingAndPaymentCollectionMethod";
import { CompanyType } from "../models/CompanyType";
import { AssetOperatorStatus } from "../models/AssetOperatorStatus";
import { getSubscriptions } from '../services/SubscriptionService';
import { SubscriptionViewModel } from '../models/SubscriptionViewModel';
import { AssetOperatorCreate } from '../components/AssetOperatorCreate';

const AssetOperatorCreatePage: React.FC = () => {
    let history = useHistory();
    const [assetOperator, setAssetOperator] = useState<AssetOperator>({ companyType: CompanyType.Fleet, preferredDirectDebitPaymentDayOfMonth: 14, invoicingAndPaymentCollectionMethod: InvoicingAndPaymentCollectionMethod.MonthlyInvoiceDirectDebitPayment, status: AssetOperatorStatus.Active } as AssetOperator);
    const [mandatoryAssetOperatorFieldsFilled, setMandatoryAssetOperatorFieldsFilled] = useState<boolean>(false)
    const [allSubscriptions, setAllSubscriptions] = useState<SubscriptionViewModel[]>([]);

    useEffect(() => {
        let mounted = true;
        getSubscriptions()
            .then(subs => {
                if (mounted) {
                    setAllSubscriptions(subs);
                }
            })
        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickCreate = () => {
        createOrUpdateAssetOperator(assetOperator).then((assetOperatorId) => history.push("/assetOperators/" + assetOperatorId))
    }

    const onSubscriptionSelect = (event: SelectChangeEvent<string[]>) => {
        if (assetOperator === null) return;

        const { target: { value }, } = event;

        setAssetOperator({ ...assetOperator, subscriptionIds: value as string[] });
    }
    const theme = useTheme()
    return <>
        <Card style={{ marginBottom: theme.spacing(3) }}>
            <CardHeader title="Create an Asset Operator"
                action={mandatoryAssetOperatorFieldsFilled ?
                    <Button variant="contained" color="primary" onClick={() => onClickCreate()}>Submit</Button>
                    : <>
                        <Typography variant="caption" style={{ color: theme.palette.warning.main }}>
                            Please add a registered address, trading name, and at least one valid billing email address
                        </Typography>
                        <Button variant="contained" color="primary" style={{ marginLeft: theme.spacing(1) }} disabled>Submit</Button>
                    </>
                } />
        </Card>

        <Grid container spacing={5}>
            <Grid xs={12} item style={{ width: '100vw', paddingTop: 0 }}>
                <AssetOperatorCreate assetOperator={assetOperator} setAssetOperator={setAssetOperator} setMandatoryAssetOperatorFieldsFilled={setMandatoryAssetOperatorFieldsFilled} />
            </Grid>
            <Grid xs={12} md={6} item>
                <Card elevation={10}>
                    <CardHeader title="Subscriptions" />
                    <CardContent>
                        <Select
                            style={{ width: "100%" }}
                            labelId="subscriptionId-multiple-checkbox-label"
                            id="subscriptionId-multiple-checkbox"
                            multiple
                            value={typeof (assetOperator.subscriptionIds) !== 'undefined' ? assetOperator.subscriptionIds : []}
                            onChange={onSubscriptionSelect}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {allSubscriptions?.map((subscription: SubscriptionViewModel) =>
                                <MenuItem key={subscription.id} value={subscription.id}>
                                    <Checkbox checked={assetOperator.subscriptionIds?.indexOf(subscription.id) > -1} />
                                    <ListItemText primary={subscription.id} />
                                </MenuItem>
                            )}
                        </Select>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>;

}

export { AssetOperatorCreatePage }
