import {getSettings} from "./SettingsService";
import {DraftSubscription} from "../models/DraftSubscription";
import {DraftSubscriptionViewModel} from "../models/DraftSubscriptionViewModel";

async function postDraftSubscription(draftSubscriptionViewModel: DraftSubscriptionViewModel): Promise<string> {

    const draftSubscription = DraftSubscription.Convert(draftSubscriptionViewModel);

    const settings = await getSettings();
    const draftSubscriptionUri = `${settings.BillingServiceBaseUri}/draftSubscriptions`
    const options = {
        method: "POST",
        body: JSON.stringify(draftSubscription)
    };
    const draftSubscriptionResponse = await fetch(draftSubscriptionUri, options)
    if (draftSubscriptionResponse.status === 200 || draftSubscriptionResponse.status === 201) {
        return "SUCCESS"
    }
    var responseText = await draftSubscriptionResponse.text()
    if(responseText === "ID in use"){
        return "FAIL - ID IN USE"
    }
    return "FAIL";
}

async function getDraftSubscriptions() {
    const settings = await getSettings();
    const draftSubscriptionsUri = `${settings.BillingServiceBaseUri}/draftSubscriptions`
    const options = {
        method: "GET",
    };
    const draftSubscriptionsResponse = await fetch(draftSubscriptionsUri, options)
    const draftSubscriptions: DraftSubscription[] = await draftSubscriptionsResponse.json();
    return draftSubscriptions.map(draft => new DraftSubscriptionViewModel(draft));
}

async function getDraftSubscription(draftSubscriptionId: string) {
    const settings = await getSettings();
    const draftSubscriptionsUri = `${settings.BillingServiceBaseUri}/draftSubscriptions/${draftSubscriptionId}`
    const options = {
        method: "GET",
    };
    const draftSubscriptionsResponse = await fetch(draftSubscriptionsUri, options)
    const draftSubscription : DraftSubscription = await draftSubscriptionsResponse.json();
    return new DraftSubscriptionViewModel(draftSubscription);
}

async function activateDraftSubscription(draftSubscriptionId: string): Promise<any> {
    const settings = await getSettings();
    const draftSubscriptionsUri = `${settings.BillingServiceBaseUri}/draftSubscriptions/${draftSubscriptionId}/activate`
    const options = {
        method: "POST",
    };
    return await fetch(draftSubscriptionsUri, options)
}

async function deleteDraftSubscription(draftSubscriptionId: string): Promise<string> {
    const settings = await getSettings();
    const draftSubscriptionsUri = `${settings.BillingServiceBaseUri}/draftSubscriptions/${draftSubscriptionId}`
    const options = {
        method: "DELETE",
    };
    const deleteDraftSubscriptionResponse = await fetch(draftSubscriptionsUri, options)
    if (deleteDraftSubscriptionResponse.status === 200) {
        return "Success"
    } else {
        return "Fail"
    }
}

export {postDraftSubscription, getDraftSubscriptions, getDraftSubscription, activateDraftSubscription, deleteDraftSubscription}
