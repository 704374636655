import {Subscription} from "./Subscription";

class SubscriptionViewModel extends Subscription {
    vmFeeRate: null | number
    vmTaxRate: null | number

    
    
    constructor(subscription: Subscription) {
        super();
        this.id = subscription.id;
        this.vmFeeRate = subscription.feeRate === null || subscription.feeRate === undefined ? null : subscription.feeRate * 100;
        this.vmTaxRate = subscription.taxRate === null || subscription.taxRate === undefined ? null : subscription.taxRate * 100;
        this.name = subscription.name;
        this.currencyIsoCode = subscription.currencyIsoCode;
        this.investorIds = subscription.investorIds;
        this.isActive = subscription.isActive;
        this.startingCostPerUsageUnit = subscription.startingCostPerUsageUnit;
        this.costPerUsageUnit = subscription.costPerUsageUnit;
        this.assetOperatorId = subscription.assetOperatorId;
        this.assets = subscription.assets;
        this.totalAssetsCollected = subscription.totalAssetsCollected;
        this.totalAssetsRemainingToCollect = subscription.totalAssetsRemainingToCollect;
        this.totalFinancePerAsset = subscription.totalFinancePerAsset;
        this.deliveryDate = subscription.deliveryDate;
        this.billingStartDate = subscription.billingStartDate;
        this.numberOfDaysUntilBillFallsIntoArrears = subscription.numberOfDaysUntilBillFallsIntoArrears;
        this.targetContractLengthInYears = subscription.targetContractLengthInYears;
        this.contractExtensionInYears = subscription.contractExtensionInYears;
        this.targetEndDate = subscription.targetEndDate;
        this.maximumContractLengthInYears = subscription.maximumContractLengthInYears;
        this.maximumEndDate = subscription.maximumEndDate;
        this.usageUnit = subscription.usageUnit;
        this.totalContractedUsage = subscription.totalContractedUsage;
        this.remainingContractedUsage = subscription.remainingContractedUsage;
        this.contractedMinimumUsagePeriodUsage = subscription.contractedMinimumUsagePeriodUsage;
        this.contractedMinimumUsagePeriodFrequency = subscription.contractedMinimumUsagePeriodFrequency;
        this.totalFacility = subscription.totalFacility;
        this.totalExpectedRepayment = subscription.totalExpectedRepayment;
        this.deposit = subscription.deposit;
        this.loanToValueRatio = subscription.loanToValueRatio;
        this.projectedInternalRateOfReturn = subscription.projectedInternalRateOfReturn;
        this.adjustCostPerUsageUnitWithInflation = subscription.adjustCostPerUsageUnitWithInflation;
        this.adjustmentsToCostPerUsageUnit = subscription.adjustmentsToCostPerUsageUnit;
        this.minimumUsagePeriods = subscription.minimumUsagePeriods;
        this.isBalloonPaymentRequired = subscription.isBalloonPaymentRequired;
        this.offSystemPaymentsOnly = subscription.offSystemPaymentsOnly;
        this.vatApplicable = subscription.vatApplicable;
        this.telematicsCostPerAsset = subscription.telematicsCostPerAsset;
        this.showStartEndOdometerReadingsOnBill = subscription.showStartEndOdometerReadingsOnBill;
        this.automaticallyCollectCatchupPayments = subscription.automaticallyCollectCatchupPayments;
        this.residualValueMatrices = subscription.residualValueMatrices;
        this.feeCollectionMethod = subscription.feeCollectionMethod;
        this.paymentPlan = subscription.paymentPlan;
        this.paymentPlans = subscription.paymentPlans;
        this.shortId = subscription.shortId;
        this.billMessage = subscription.billMessage;
        this.monthsInContract = subscription.monthsInContract;
        this.includeInPublicCO2AndNOxCount = subscription.includeInPublicCO2AndNOxCount;
        this.lengthOfDrawdownPeriodInMonths = subscription.lengthOfDrawdownPeriodInMonths;
        this.drawdownPeriodProfile = subscription.drawdownPeriodProfile;
        this.enableProRataingMinimumUsagePeriods = subscription.enableProRataingMinimumUsagePeriods;
    }
}

export {SubscriptionViewModel}
