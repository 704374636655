import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Slide, Tab, Tabs, TextField, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { VehicleClass } from "../models/VehicleClass";
import React from "react";
import { searchVehicleClasses } from "../services/ResidualValueService";
import { LoadingButton } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import { CurrentMarketValueMatrixUpload } from "../components/CurrentMarketValueMatrixUpload";

const useStyles = makeStyles(() =>
    createStyles({
        textInput: {
            width: '100%',
            marginBottom: '1em'
        }
    }));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const MarketValueRetrievalPage: React.FC = () => {
    const [year, setYear] = React.useState<string>("");
    const [make, setMake] = React.useState<string>("");
    const [model, setModel] = React.useState<string>("");
    const [trim, setTrim] = React.useState<string>("");
    const [annualMileage, setAnnualMileage] = React.useState<number>(25000);
    const [filteredVehicleClasses, setFilteredVehicleClasses] = React.useState<VehicleClass[]>([]);
    const [searching, setSearching] = React.useState<boolean>(false);
    const [loadingId, setLoadingId] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [value, setValue] = React.useState(1);

    const classes = useStyles();


    const onSearch = async (e: any) => {
        setSearching(true)
        try {
            setFilteredVehicleClasses(await searchVehicleClasses(make, model, trim, year))

        } catch (error) {
            setErrorMessage("The Zeti Hub encountered an error reaching Black Book. Please contact support at ")
        }
        setSearching(false)
    }
    const onDownload = (vehicleClass: any) => {
        return async (e: any) => {
            setLoadingId(vehicleClass.id)
            try {
                const response = await fetch(`/api/residualvalues/vehicleclasses/${vehicleClass.id}/matrix?mileage=${annualMileage}`);
                // stackoverflow that I then lost the link for (sorry)
                if (response.ok) {
                    const url = window.URL.createObjectURL(await response.blob())
                    const link = document.createElement('a')
                    link.href = url
                    const fileName = `${vehicleClass.make ?? ""} ${vehicleClass.model ?? ""} ${vehicleClass.trim ?? ""} ${vehicleClass.yearOrVersion ?? ""} Residual Values.csv`;
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                } else {
                    throw new Error("Bad Response")
                }

            } catch (error) {
                setErrorMessage("This is most often due to Black Book not having sufficient auction data yet on a vehicle to allow them to calculate residual values. This can often be quickly rectified by trying the vehicle from the year before and using it as a proxy - otherwise please contact support at ")
            }

            setLoadingId("")
        }
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
    
        return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box style={{paddingTop: '24px'}}>
                    {children}
            </Box>
            )}
        </Box>
        );
    }

    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
        <Card sx={{ borderBottom: 1, borderColor: 'divider' }}>
            
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Market Values" {...a11yProps(0)} />
                <Tab label="Current Market Values Matrix Upload" {...a11yProps(1)} />
            </Tabs>
        </Card>
        <Grid container maxWidth={"xl"} spacing={2}>
            <TabPanel value={value} index={0}>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Market Values" />
                        <CardContent>
                            <TextField className={classes.textInput} label="Make (e.g. Tesla)" value={make} onChange={((e) => setMake(e.target.value))} />
                            <TextField className={classes.textInput} label="Model (e.g. Model 3)" value={model} onChange={((e) => setModel(e.target.value))} />
                            <TextField className={classes.textInput} label="Trim (e.g. Long Range)" value={trim} onChange={((e) => setTrim(e.target.value))} />
                            <TextField className={classes.textInput} label="Year (e.g. 2022)" value={year} onChange={((e) => setYear(e.target.value))} />
                            <TextField
                                select
                                className={classes.textInput}
                                id="demo-simple-select"
                                value={annualMileage}
                                label="Annual Mileage"
                                onChange={(e) => setAnnualMileage(Number.parseInt(e.target.value))}
                            >
                                <MenuItem value={5000}>5,000 miles</MenuItem>
                                <MenuItem value={10000}>10,000 miles</MenuItem>
                                <MenuItem value={15000}>15,000 miles</MenuItem>
                                <MenuItem value={20000}>20,000 miles</MenuItem>
                                <MenuItem value={25000}>25,000 miles</MenuItem>
                                <MenuItem value={30000}>30,000 miles</MenuItem>
                                <MenuItem value={35000}>35,000 miles</MenuItem>
                                <MenuItem value={40000}>40,000 miles</MenuItem>
                                <MenuItem value={45000}>45,000 miles</MenuItem>
                                <MenuItem value={50000}>50,000 miles</MenuItem>
                                <MenuItem value={55000}>55,000 miles</MenuItem>
                                <MenuItem value={60000}>60,000 miles</MenuItem>
                                <MenuItem value={65000}>65,000 miles</MenuItem>
                                <MenuItem value={70000}>70,000 miles</MenuItem>
                                <MenuItem value={75000}>75,000 miles</MenuItem>
                                <MenuItem value={80000}>80,000 miles</MenuItem>
                                <MenuItem value={85000}>85,000 miles</MenuItem>
                                <MenuItem value={90000}>90,000 miles</MenuItem>
                                <MenuItem value={95000}>95,000 miles</MenuItem>
                                <MenuItem value={100000}>100,000 miles</MenuItem>
                            </TextField>
                            <Button variant="contained" style={{ marginBottom: '1em' }} onClick={onSearch}>Search for Vehicle</Button>
                            <br />
                            <img src="/blackbook.png" style={{ width: "8em", float: 'right' }} alt="powered by blackbook" /><Typography variant="caption">©{new Date().getFullYear()} Hearst Business Media Corp. ALL RIGHTS RESERVED. Black Book® is a registered trademark of Hearst Business Media Corp. </Typography>
                        </CardContent>
                    </Card>
                    <Dialog
                        open={!!errorMessage}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={(e) => setErrorMessage("")}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"An error occurred"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">{errorMessage} <a href="mailto:support@zeti.group">support@zeti.group</a></DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item xs={6}>
                    {
                        searching &&
                        (
                            <Card style={{ height: '100%', minHeight: '100%' }}>
                                <CardContent style={{ minHeight: '100%', height: '100%' }}>
                                    <div style={{ minHeight: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CircularProgress />
                                    </div>
                                </CardContent>
                            </Card>
                        )
                    }
                    {
                        filteredVehicleClasses.length !== 0 && (
                            <Card style={{ minHeight: '100%' }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        {
                                            filteredVehicleClasses.map((vehicleClass, index) => {
                                                return (

                                                    <Grid item xs={12} style={{height: '100%'}}>
                                                        <Card elevation={0}>
                                                            <CardHeader  title={`${vehicleClass.make} ${vehicleClass.model} ${vehicleClass.trim}`} subheader={vehicleClass.yearOrVersion} action={<LoadingButton loading={vehicleClass.id === loadingId} loadingIndicator={<CircularProgress size={16} />} variant="contained" onClick={onDownload(vehicleClass)}>Download Residual Values</LoadingButton>}/>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        )
                    }

                </Grid>
            </TabPanel>
        </Grid>
            <TabPanel value={value} index={1}>
                <CurrentMarketValueMatrixUpload />
            </TabPanel>
        </>)
}

export default MarketValueRetrievalPage;
